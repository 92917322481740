import React, { useState } from "react";
import { useLazyQuery } from "@apollo/client";

import { Button, ButtonSize, Icon } from "@components/atoms";
import { CustomDialog } from "@components/molecules";
import {
  IngestionJobsExportDialog_UnloadJobsDocument,
  IngestionJobsExportDialog_UnloadJobsQueryVariables,
} from "@graphql/operations";

import { Card } from "./Card";

export type IngestionJobsExportDialogFormData = {
  numberDays: string;
  selectedColumns?: string[];
};

enum Category {
  INGESTION_DATA = "Ingestion Data",
  VIDEO_DATA = "Video Data",
}

export type PreviousDays = {
  title: string;
  days: string;
};

export const timeRangeSelectableFields: Record<string, PreviousDays> = {
  prev_30: { title: "Previous 30 days", days: "30" },
  prev_60: { title: "Previous 60 days", days: "60" },
  prev_90: { title: "Previous 90 days", days: "90" },
  prev_120: { title: "Previous 120 days", days: "120" },
};

export const ingestionJobsSelectableFields: Record<any, string> = {
  id: "Video Id",
  createdAt: "Created At",
  assetId: "Asset Id",
  itemType: "Type",
  workflowExecutionId: "Workflow Execution Id",
};

const videoJobsSelectableFields: Record<any, string> = {
  href: "Href",
  mediaFile: "Media File",
  thumbnail: "Thumbnail",
  thumbnailFile: "Thumbnail File",
  title: "Video Title",
  businessUnit: "Business Unit",
};

const selectableFields = [
  {
    id: Category.INGESTION_DATA as string,
    name: Category.INGESTION_DATA as string,
    selected: true,
    children: Object.entries(ingestionJobsSelectableFields).map(([k, v]) => ({
      id: k,
      name: v,
    })),
  },
  {
    id: Category.VIDEO_DATA as string,
    name: Category.VIDEO_DATA as string,
    selected: true,
    children: Object.entries(videoJobsSelectableFields).map(([k, v]) => ({
      id: k,
      name: v,
    })),
  },
];

const initialSelectedNodeIds = [
  ...Object.keys(ingestionJobsSelectableFields),
  ...Object.keys(videoJobsSelectableFields),
];

export type IngestionJobsExportDialogProps = {
  disabled?: boolean;
  size?: ButtonSize;
  title: string;
};

export const IngestionJobsExportDialog = ({
  disabled,
  title,
  size = "small",
}: IngestionJobsExportDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [unloadIngestionJobs, { loading, error }] = useLazyQuery(
    IngestionJobsExportDialog_UnloadJobsDocument,
    { fetchPolicy: "no-cache" }
  );

  const onExportSubmission = async (v: IngestionJobsExportDialogFormData) => {
    const numberDays = parseInt(v.numberDays, 10);
    const columns = v.selectedColumns;

    const variables: IngestionJobsExportDialog_UnloadJobsQueryVariables = {
      unload: {
        numberDays,
        columns,
      },
    };

    const { data } = await unloadIngestionJobs({ variables });

    return data?.jobs?.unload?.href || undefined;
  };

  return (
    <>
      <Button
        disabled={disabled}
        size={size}
        color="default"
        onClick={() => setIsOpen(true)}
        icon={<Icon.Export className="text-black-100 mr-2" />}
        data-e2e="button-export"
      >
        Export
      </Button>
      <CustomDialog.Container
        isDialogOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Card
          title={title}
          loading={loading}
          selectableFields={selectableFields}
          initialSelectedNodeIds={initialSelectedNodeIds}
          onExportSubmission={onExportSubmission}
          timeOptions={timeRangeSelectableFields}
          error={(error || "").toString()}
          onClose={() => setIsOpen(false)}
        />
      </CustomDialog.Container>
    </>
  );
};
