export function url(
  input: string,
  maxLength: number,
  separator: string
): string {
  const intermediary = input.replace(/(^\w+:|^)\/\//, "");
  return middle(intermediary, maxLength, separator);
}

export function middle(
  input: string,
  maxLength: number,
  separator: string
): string {
  if (input.length <= maxLength) {
    return input;
  }

  const sepLen = separator.length;
  const charsToShow = maxLength - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    input.substr(0, frontChars) +
    separator +
    input.substr(input.length - backChars)
  );
}
