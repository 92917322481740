import React, { useRef, useState } from "react";
import { useDrag } from "react-dnd";

import { Icon, Segment } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";
import { useDragScrolling } from "@hooks/drag-and-drop";
import { FilterType } from "@hooks/segments";

export interface MinimisedConditionProps<FilterValue> {
  type: FilterType;
  children: React.ReactNode;
  icon: React.ReactNode;
  filter?: FilterValue;
  showTooltip?: boolean;
  tooltipText?: string;
}

export const MinimisedCondition = <FilterValue,>({
  children,
  type,
  icon,
  filter,
  showTooltip,
  tooltipText,
}: MinimisedConditionProps<FilterValue>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [clicked, setClicked] = useState<boolean>(false);
  const { addEventListenerForWindow, removeEventListenerForWindow } =
    useDragScrolling();

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: Segment.ItemTypes.CONDITION,
    item: () => {
      addEventListenerForWindow();
      return { type, action: "NEW", height: ref.current?.clientHeight, filter };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      removeEventListenerForWindow();
      setClicked(false);
    },
  }));

  preview(ref);

  return (
    <Segment.MinimisedCondition
      rootRef={ref}
      dragRef={drag}
      rightIcon={<Icon.Draggable />}
      leftIcon={icon}
      classNames={{
        "cursor-move": true,
        [classnames("bg-navy-300", "bg-opacity-8")]: clicked && !isDragging,
      }}
      colour={Segment.FilterTypeColorMapping[type]}
      onMouseDown={({ button }) => {
        if (button === 0) {
          setClicked(true);
        }
      }}
      onMouseUp={() => setClicked(false)}
      showTooltip={showTooltip}
      tooltipText={tooltipText}
    >
      {children}
    </Segment.MinimisedCondition>
  );
};
