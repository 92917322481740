import React from "react";

import { Checkbox } from "@components/atoms";

import { Filters, FilterType, useFilters } from "../../hooks";

const OPTIONS = [
  {
    label: "Business",
    value: "business",
  },
  {
    label: "News",
    value: "news",
  },
];

const FILTER_TYPE = FilterType.BUSINESS_UNIT;

const handleChange = (
  context: Filters,
  filterValue: string[],
  value: string
) => {
  const newValue = filterValue.includes(value)
    ? filterValue.filter((v) => v !== value)
    : [...filterValue, value];

  if (newValue.length === 0) {
    context.removeFilter(FILTER_TYPE);
  } else {
    context.addFilter({
      filterType: FILTER_TYPE,
      values: newValue,
    });
  }
};

export const BusinessUnit = () => {
  const context = useFilters();
  const filter = context.getFilter(FILTER_TYPE);
  const filterValue = filter?.values || [];

  return (
    <div className="space-y-3">
      {OPTIONS.map((option) => (
        <Checkbox
          key={option.value}
          data-testid={`business-unit-${option.value}`}
          onChange={() => handleChange(context, filterValue, option.value)}
          checked={!!filterValue.includes(option.value)}
        >
          <span className="text-bodySm font-medium">{option.label}</span>
        </Checkbox>
      ))}
    </div>
  );
};
