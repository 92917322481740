import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Sentiment = createSvgIcon({
  content: (
    <>
      <path
        d="M3.0957 6C3.4497 6.80515 4.32727 7.37447 5.24918 7.37447C6.16718 7.37447 7.04305 6.80367 7.40012 6.00201"
        className="stroke-current"
        strokeLinecap="square"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.24935 1.15023C2.99419 1.15023 1.16602 2.94843 1.16602 5.16663C1.16602 7.38482 2.99419 9.18302 5.24935 9.18302C7.50451 9.18302 9.33268 7.38482 9.33268 5.16663C9.33268 2.94843 7.50451 1.15023 5.24935 1.15023ZM0.166016 5.16663C0.166016 2.4052 2.4419 0.166626 5.24935 0.166626C8.0568 0.166626 10.3327 2.4052 10.3327 5.16663C10.3327 7.92805 8.0568 10.1666 5.24935 10.1666C2.4419 10.1666 0.166016 7.92805 0.166016 5.16663Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.998 6.98361C8.77985 6.98361 6.98165 8.78181 6.98165 11C6.98165 13.2182 8.77985 15.0164 10.998 15.0164C13.2162 15.0164 15.0144 13.2182 15.0144 11C15.0144 8.78181 13.2162 6.98361 10.998 6.98361ZM5.99805 11C5.99805 8.23858 8.23662 6 10.998 6C13.7595 6 15.998 8.23858 15.998 11C15.998 13.7614 13.7595 16 10.998 16C8.23662 16 5.99805 13.7614 5.99805 11Z"
        className="fill-current"
      />
      <ellipse
        cx="4.00065"
        cy="4.00004"
        rx="0.666667"
        ry="0.666666"
        className="fill-current"
      />
      <ellipse
        cx="6.66667"
        cy="4.00004"
        rx="0.666667"
        ry="0.666666"
        className="fill-current"
      />
      <path
        d="M9.12109 12.5835C9.7322 12.278 10.3428 12.1254 10.953 12.1257C11.565 12.1254 12.1766 12.278 12.7878 12.5835"
        className="stroke-current"
        strokeLinecap="square"
      />
      <ellipse
        cx="9.53776"
        cy="9.9998"
        rx="0.666667"
        ry="0.666667"
        className="fill-current"
      />
      <ellipse
        cx="12.2038"
        cy="9.9998"
        rx="0.666667"
        ry="0.666667"
        className="fill-current"
      />
    </>
  ),
  width: 16,
  height: 16,
});
