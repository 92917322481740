import { TypePolicy } from "@apollo/client/cache/inmemory/policies";

import data from "./data";

export const SegmentImport: TypePolicy = {
  fields: {
    data,
  },
};

export default SegmentImport;
