import React, { type ReactNode } from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface FooterProps {
  className?: any;
  children: ReactNode;
}

export const Footer = ({ children, className }: FooterProps) => (
  <div
    className={classnames(
      className,
      "mt-auto",
      "text-bodyXs",
      "p-3",
      "border-coolGrey-200",
      "bg-lightGrey-100",
      "border-t"
    )}
  >
    {children}
  </div>
);
