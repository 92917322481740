import { useCallback, useState } from "react";
import { gql, useApolloClient } from "@apollo/client";
import Papa, { ParseError, ParseResult } from "papaparse";

import { toColumnTypeIndex } from "./columnTypeIndex";
import { ImportColumnType } from "./importColumnType";

export type FileFormatError = {
  filetype: string;
};

export type ImportError =
  | {
      type: "FileFormat";
      payload: FileFormatError;
    }
  | {
      type: "Papa";
      payload: ParseError;
    };

export const useSegmentImport = () => {
  const client = useApolloClient();
  const [errors, setErrors] = useState<ImportError[]>();

  const setFirstRowContainsHeader = (firstRowContainsHeader: boolean) => {
    client.cache.updateQuery<{
      segmentImport: {
        id: string;
        firstRowContainsHeader: boolean;
      };
    }>(
      {
        returnPartialData: true,
        query: gql`
          query SetSegmentImportFirstRowContainsHeader {
            segmentImport {
              id
              firstRowContainsHeader
            }
          }
        `,
      },
      (data) => {
        if (data) {
          return {
            segmentImport: {
              ...data.segmentImport,
              firstRowContainsHeader,
            },
          };
        }
      }
    );
  };

  const setRawDataCellByColumnType = useCallback(
    (row: number, columnType: ImportColumnType, value: any) => {
      client.cache.updateQuery<{
        segmentImport: {
          id: string;
          columnTypes: ImportColumnType[];
          rawData: any[][];
        };
      }>(
        {
          returnPartialData: true,
          query: gql`
            query SetSegmentImportColumnType {
              segmentImport {
                id
                rawData
                columnTypes
              }
            }
          `,
        },
        (data) => {
          if (data) {
            const col = toColumnTypeIndex(data.segmentImport.columnTypes)[
              columnType
            ];
            if (col !== undefined) {
              const rawData = [...data.segmentImport.rawData];
              rawData[row] = [...rawData[row]];
              rawData[row][col] = value;

              return {
                segmentImport: {
                  ...data.segmentImport,
                  rawData,
                },
              };
            }
          }
        }
      );
    },
    [client]
  );

  const deleteSegmentImport = () => {
    client.cache.modify({
      id: "ROOT_QUERY",
      fields: {
        segmentImport(_, { DELETE }) {
          return DELETE;
        },
      },
    });
    client.cache.gc();
  };

  const setFile = async (file?: File) => {
    const data = await client.cache.readQuery<{
      segmentImport: {
        id: string;
        firstRowContainsHeader: boolean;
      };
    }>({
      query: gql`
        query GetSegmentImport {
          segmentImport @client {
            firstRowContainsHeader
          }
        }
      `,
    });

    const firstRowContainsHeader =
      data?.segmentImport.firstRowContainsHeader || false;

    deleteSegmentImport();
    setErrors(undefined);

    if (file && isTextCsv(file)) {
      const results = await new Promise<ParseResult<any[]>>((complete, error) =>
        Papa.parse<any[]>(file, {
          complete,
          error,
          skipEmptyLines: true,
        })
      );

      const numberOfColumns = results.data[0].length;

      client.writeQuery({
        query: gql`
          query SetSegmentImportFile {
            segmentImport {
              __typename
              numberOfColumns
              firstRowContainsHeader
              rawData
              columnTypes
              file {
                name
                size
              }
            }
          }
        `,
        data: {
          segmentImport: {
            __typename: "SegmentImport",
            id: file.name,
            numberOfColumns,
            rawData: results.data,
            firstRowContainsHeader,
            columnTypes: new Array(numberOfColumns).fill(null),
            file: {
              name: file.name,
              size: file.size,
            },
          },
        },
      });
      if (results.errors && results.errors.length > 0) {
        setErrors(
          results.errors.map((err) => ({ type: "Papa", payload: err }))
        );
      }
    } else if (file && !isTextCsv(file)) {
      setErrors([
        {
          type: "FileFormat",
          payload: {
            filetype: file.type,
          },
        },
      ]);
    }
  };

  const setColumnType = (columnType: ImportColumnType, idx: number) => {
    client.cache.updateQuery(
      {
        returnPartialData: true,
        query: gql`
          query SetSegmentImportColumnType {
            segmentImport {
              id
              columnTypes
            }
          }
        `,
      },
      (data) => {
        const update = [...data.segmentImport.columnTypes];
        update[idx] = columnType;
        return {
          segmentImport: {
            ...data.segmentImport,
            columnTypes: update,
          },
        };
      }
    );
  };

  return {
    errors,

    setRawDataCellByColumnType,
    setColumnType,
    setFile,
    setFirstRowContainsHeader,
    deleteSegmentImport,
  };
};

const isTextCsv = (file: File): boolean => file.type.startsWith("text/csv");
