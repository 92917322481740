import React from "react";

export interface AllProps {
  values: { [key: string]: string };
}

export const All = ({ values }: AllProps) => (
  <div className="text-black-300 text-h7">
    {Object.values(values).join(", ")}
  </div>
);
