import React from "react";

import { classnames } from "@external/tailwindcss-classnames";

import { Label, LabelProps } from "../Label";

export interface GenericLabelProps extends LabelProps {
  children: React.ReactNode;
}

export const GenericLabel = ({ className, ...rest }: GenericLabelProps) => (
  <Label
    {...rest}
    className={classnames(
      className,
      "inline-block",
      "border",
      "text-black-300",
      "bg-lightGrey-100",
      "border-coolGrey-200",
      "font-semibold",
      "text-xs",
      "capitalize"
    )}
  />
);
