import React, { useRef } from "react";
import { useParams } from "react-router";
import { Link, Route, Routes, useMatch, useNavigate } from "react-router-dom";

import { Breadcrumb, Portal, Tabs } from "@components/atoms";
import { VideoProvider } from "@hooks/useVideo";

import { Celebrities } from "./Celebrities";
import { ContentModeration } from "./ContentModeration";
import { ContentLabels } from "./Labels";
import { Overview } from "./Overview";
import { Sentiments } from "./Sentiments";
import { SpokenNouns } from "./SpokenNouns";

type QueryParams = {
  id: string;
};

export const Insights = () => {
  const navigate = useNavigate();
  const { id } = useParams<QueryParams>();
  const portalRef = useRef<HTMLDivElement | null>(null);
  const matchesOverview = useMatch(`insights/:id/overview`);
  const matchesLabels = useMatch(`insights/:id/labels`);
  const matchesSyntax = useMatch(`insights/:id/syntax`);
  const matchesCelebrities = useMatch(`insights/:id/celebrities`);
  const matchesSentiments = useMatch(`insights/:id/sentiments`);
  const matchesContentModeration = useMatch(`insights/:id/content-moderation`);

  if (!id) {
    navigate("/");
    return null;
  }

  return (
    <VideoProvider>
      <Portal.PortalProvider target={portalRef}>
        <article className="px-lg py-8 bg-lightGrey-100 flex flex-col flex-1">
          <Breadcrumb.Container className="mb-6">
            <Breadcrumb.Item showChevron={true}>
              <Link className="font-semibold" to="/insights">
                Video On Demand
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{id}</Breadcrumb.Item>
          </Breadcrumb.Container>
          <section className="mb-4 flex">
            <h2 className="flex-1">Insights</h2>
            <section>
              <Tabs.Container connected={true}>
                <Link to="overview">
                  <Tabs.Tab active={!!matchesOverview}>Overview</Tabs.Tab>
                </Link>
                <Link to="labels">
                  <Tabs.Tab active={!!matchesLabels}>Content Label</Tabs.Tab>
                </Link>
                <Link to="syntax">
                  <Tabs.Tab active={!!matchesSyntax}>Syntax Label</Tabs.Tab>
                </Link>
                <Link to="celebrities">
                  <Tabs.Tab active={!!matchesCelebrities}>Celebrities</Tabs.Tab>
                </Link>
                <Link to="sentiments">
                  <Tabs.Tab active={!!matchesSentiments}>Sentiment</Tabs.Tab>
                </Link>
                <Link to="content-moderation">
                  <Tabs.Tab active={!!matchesContentModeration}>
                    Content Moderation
                  </Tabs.Tab>
                </Link>
              </Tabs.Container>
            </section>
            <div ref={portalRef} className="flex-1" />
          </section>
          <Routes>
            <Route path="overview" element={<Overview videoId={id} />} />
            <Route path="labels" element={<ContentLabels videoId={id} />} />
            <Route path="syntax" element={<SpokenNouns videoId={id} />} />
            <Route path="celebrities" element={<Celebrities videoId={id} />} />
            <Route path="sentiments" element={<Sentiments videoId={id} />} />
            <Route
              path={`content-moderation`}
              element={<ContentModeration videoId={id} />}
            />
          </Routes>
        </article>
      </Portal.PortalProvider>
    </VideoProvider>
  );
};
