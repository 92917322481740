import React, { useMemo, useState } from "react";
import { isEqual } from "lodash";

import { Button, Card, Icon, TextInput } from "@components/atoms";

import { FilterGroup } from "./FilterGroup";
import { SegmentJobStatus, SegmentJobStatusValue } from "./SegmentJobStatus";
import { SegmentSize, SegmentSizeValue } from "./SegmentSize";
import { SegmentType, SegmentTypeValue } from "./SegmentType";

export type Filters = {
  type?: SegmentTypeValue;
  status?: SegmentJobStatusValue[];
  size?: SegmentSizeValue;
};

type FilterKeys = keyof Filters;

const filters: {
  [K in FilterKeys]: string;
} = {
  type: "Type",
  status: "Status",
  size: "Size",
};

export const filterTypes = Object.keys(filters) as FilterKeys[];

interface SidebarProps {
  searchValue?: string;
  onSearchValueChange: (value: string) => void;
  value: Filters;
  onChange: (state: Filters | null) => void;
}

export const Sidebar = ({
  searchValue,
  onSearchValueChange,
  value,
  onChange,
}: SidebarProps) => {
  const [state, setState] = useState<Filters>(value);
  const applyIsDisabled = useMemo(() => isEqual(value, state), [value, state]);

  return (
    <div className="col-span-3">
      <TextInput
        icon={<Icon.Search size={16} className="ml-2 text-black-100" />}
        onChange={(e) => onSearchValueChange(e.target.value)}
        value={searchValue || ""}
        className="mx-6 mb-4"
        placeholder="Title or ID"
      />

      <Card.Container className="mb-6 rounded-md">
        {Object.entries(filters).map(([key, label]): React.ReactNode => {
          const typedKey = key as FilterKeys;
          let content;

          switch (typedKey) {
            case "size": {
              content = (
                <SegmentSize
                  value={state.size}
                  onChange={(newValue) =>
                    setState((s) => ({ ...s, size: newValue }))
                  }
                />
              );
              break;
            }
            case "status": {
              content = (
                <SegmentJobStatus
                  value={state.status || []}
                  onChange={(newValue) =>
                    setState((s) => ({ ...s, status: newValue }))
                  }
                />
              );
              break;
            }
            case "type": {
              content = (
                <SegmentType
                  value={state.type}
                  onChange={(newValue) =>
                    setState((s) => ({ ...s, type: newValue }))
                  }
                />
              );
              break;
            }
          }

          return (
            <FilterGroup key={key} title={label}>
              {content}
            </FilterGroup>
          );
        })}

        <div className="flex justify-between items-center px-6 py-4">
          <Button
            type="button"
            variant="text"
            onClick={() => {
              onChange(null);
              setState({});
            }}
          >
            Reset
          </Button>
          <Button
            type="button"
            onClick={() => {
              onChange(state);
            }}
            size="medium"
            color="primary"
            disabled={applyIsDisabled}
          >
            Apply filters
          </Button>
        </div>
      </Card.Container>
    </div>
  );
};
