import React from "react";

import { Button, Icon } from "@components/atoms";

export interface NoJobsYetProps {
  onNewJobClicked: () => void;
}

export const NoJobsYet = ({ onNewJobClicked }: NoJobsYetProps) => (
  <div className="flex flex-col items-center justify-center">
    <Icon.ListOutline size={40} color="navy-100" className="mb-6" />
    <h4 className="mb-12">No jobs yet</h4>
    <Button
      color="primary"
      variant="contained"
      size="medium"
      icon={<Icon.Plus className="mr-2" />}
      onClick={() => onNewJobClicked()}
    >
      New Job
    </Button>
  </div>
);
