import React, { type ReactNode } from "react";

import { classnames } from "@external/tailwindcss-classnames";

type HeaderProps = {
  classNames?: any;
  onClick?: () => void;
  children: ReactNode;
};

export const Header = ({ children, classNames, onClick }: HeaderProps) => {
  const styles = classnames(
    "px-8",
    "py-3",
    "bg-lightGrey-200",
    "text-h8",
    "uppercase",
    "text-black-200",
    "font-bold",
    classNames
  );
  return (
    <div className={styles} onClick={onClick}>
      {children}
    </div>
  );
};
