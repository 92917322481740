import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Venue = createSvgIcon({
  content: (
    <>
      <path
        d="M2.6665 7.99996C2.6665 8.35358 3.22841 8.69272 4.2286 8.94277C5.2288 9.19282 6.58535 9.33329 7.99984 9.33329C9.41433 9.33329 10.7709 9.19282 11.7711 8.94277C12.7713 8.69272 13.3332 8.35358 13.3332 7.99996C13.3332 7.64634 12.7713 7.3072 11.7711 7.05715C10.7709 6.8071 9.41433 6.66663 7.99984 6.66663C6.58535 6.66663 5.2288 6.8071 4.2286 7.05715C3.22841 7.3072 2.6665 7.64634 2.6665 7.99996Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.6665 8V12.6667C2.6665 13.2933 4.33984 13.8567 6.6665 14V12H9.33317V14C11.6232 13.85 13.3332 13.2867 13.3332 12.6667V8"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 4H12.6667V2H10V6.66667"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.6665 4H7.33317V2H4.6665V6.66667"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
  defaultColor: "navy-100",
});
