import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const CheckmarkOutline = createSvgIcon({
  content: (
    <>
      <path
        d="M0.5 9.99332C0.5 4.75176 4.75176 0.5 9.99332 0.5C15.2478 0.5 19.4866 4.75133 19.4866 9.99332C19.4866 15.2349 15.2349 19.4866 9.99332 19.4866C4.75176 19.4866 0.5 15.2349 0.5 9.99332Z"
        className="stroke-current fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2068 6.00809C15.4155 6.21678 15.4155 6.55515 15.2068 6.76384L7.99236 13.9783C7.78366 14.187 7.4453 14.187 7.2366 13.9783C7.0279 13.7696 7.0279 13.4312 7.2366 13.2225L14.451 6.00809C14.6597 5.79939 14.9981 5.79939 15.2068 6.00809Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16434 10.1487C4.37303 9.94001 4.7114 9.94001 4.92009 10.1487L7.92611 13.1547C8.1348 13.3634 8.1348 13.7018 7.92611 13.9105C7.71741 14.1192 7.37904 14.1192 7.17035 13.9105L4.16434 10.9045C3.95564 10.6958 3.95564 10.3574 4.16434 10.1487Z"
        fill="white"
      />
    </>
  ),
  width: 20,
  height: 20,
});
