import { TArg } from "@external/tailwindcss-classnames";
import { FilterType } from "@hooks/segments";

export enum ConditionColor {
  Red,
  CoolGrey,
  Teal,
  Navy,
  Blue,
  Black,
  Orange,
  CoolGrey400,
}

export const FilterTypeColorMapping: { [key in FilterType]: ConditionColor } = {
  [FilterType.CELEBRITY__EQUALS]: ConditionColor.Navy,
  [FilterType.LABEL__CONTAINS]: ConditionColor.Blue,
  [FilterType.VIDEO_TITLE__CONTAINS]: ConditionColor.Black,
  [FilterType.TRANSCRIPT__CONTAINS]: ConditionColor.Orange,
  [FilterType.SENTIMENT__EQUALS]: ConditionColor.Teal,
  [FilterType.CONTENT_MODERATION__EQUALS]: ConditionColor.Red,
  [FilterType.VIDEO_DURATION__EQUALS]: ConditionColor.CoolGrey,
  [FilterType.NESTED_SEGMENT_ID__EQUALS]: ConditionColor.Black,
  [FilterType.TEXT_IN_VIDEO__CONTAINS]: ConditionColor.CoolGrey400,
  [FilterType.METADATA__CONTAINS]: ConditionColor.Navy,
  [FilterType.MIN_OCCURRENCE__COUNT]: ConditionColor.CoolGrey400,

  [FilterType.RUN_DIFFERENTIAL__COMPARE]: ConditionColor.Orange,
  [FilterType.INNING__COMPARE]: ConditionColor.Orange,
  [FilterType.EVENT_LEVERAGE_INDEX__COMPARE]: ConditionColor.Orange,
  [FilterType.EVENT_RUNS_SCORED__COMPARE]: ConditionColor.Orange,
  [FilterType.CLOSE_GAME__EQUALS]: ConditionColor.Orange,
  [FilterType.DELAYED_GAME__EQUALS]: ConditionColor.Orange,
  [FilterType.DOUBLE_HEADER__EQUALS]: ConditionColor.Orange,
  [FilterType.NIGHT_GAME__EQUALS]: ConditionColor.Orange,
  [FilterType.VENUE__EQUALS]: ConditionColor.Orange,
  [FilterType.PRE_ALL_STAR__EQUALS]: ConditionColor.Orange,
  [FilterType.POST_ALL_STAR__EQUALS]: ConditionColor.Orange,
  [FilterType.SEASON_TYPE__EQUALS]: ConditionColor.Orange,
  [FilterType.HOME_TEAM__EQUALS]: ConditionColor.Orange,
  [FilterType.AWAY_TEAM__EQUALS]: ConditionColor.Orange,
  [FilterType.DIVISION__EQUALS]: ConditionColor.Orange,
  [FilterType.LEAGUE__EQUALS]: ConditionColor.Orange,
  [FilterType.PLAYER__EQUALS]: ConditionColor.Orange,
  [FilterType.WINNING_TEAM__EQUALS]: ConditionColor.Orange,
  [FilterType.EXTRA_INNINGS__EQUALS]: ConditionColor.Orange,
  [FilterType.SERIES_TYPE__EQUALS]: ConditionColor.Orange,
  [FilterType.INNING_HALF__EQUALS]: ConditionColor.Orange,
  [FilterType.EVENT_TYPE__EQUALS]: ConditionColor.Orange,
  [FilterType.EVENT_DESCRIPTION__CONTAINS]: ConditionColor.Orange,
  [FilterType.LINEUP_CHANGE__EQUALS]: ConditionColor.Orange,
  [FilterType.STOLEN_BASE__EQUALS]: ConditionColor.Orange,
};

export const ConditionColorMapping: { [key in ConditionColor]: TArg[] } = {
  [ConditionColor.Red]: ["bg-red-300"],
  [ConditionColor.CoolGrey]: ["bg-coolGrey-300"],
  [ConditionColor.Teal]: ["bg-teal-300"],
  [ConditionColor.Navy]: ["bg-navy-200"],
  [ConditionColor.Blue]: ["bg-blue-300"],
  [ConditionColor.Black]: ["bg-black-300"],
  [ConditionColor.Orange]: ["bg-orange-300"],
  [ConditionColor.CoolGrey400]: ["bg-coolGrey-400"],
};
