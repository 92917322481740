import { noop } from "lodash";

import { Tooltip } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

import { ColourLine, type ColourLineProps } from "../ColourLine";

export type MinimisedConditionProps = {
  colour: ColourLineProps["colour"];
  leftIcon: React.ReactNode;
  rightIcon: React.ReactNode;
  children: React.ReactNode;
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  onMouseUp?: React.MouseEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  rootRef?: React.MutableRefObject<HTMLDivElement | null>;
  dragRef?: React.LegacyRef<HTMLDivElement>;
  classNames?: any;
  showTooltip?: boolean;
  tooltipText?: string;
};

export const MinimisedCondition = ({
  colour,
  children,
  leftIcon,
  rightIcon,
  onMouseUp = noop,
  onMouseDown = noop,
  onClick = noop,
  rootRef,
  dragRef,
  classNames,
  showTooltip,
  tooltipText,
}: MinimisedConditionProps) => {
  const minimisedCondition = (
    <div
      ref={dragRef}
      draggable={false}
      className="flex flex-row flex-1 h-12"
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onClick={onClick}
    >
      <ColourLine colour={colour} />
      <div className="pl-5 flex items-center">{leftIcon}</div>
      <div className="pl-3 flex-1 flex items-center font-semibold text-bodySm">
        <span className="line-clamp-1">{children}</span>
      </div>
      <div className="flex items-center text-coolGrey-300 pr-6">
        {rightIcon}
      </div>
    </div>
  );
  const withTooltip = (
    <Tooltip verticalAlign="below" tip={tooltipText} className="mt-7">
      {minimisedCondition}
    </Tooltip>
  );

  return (
    <div
      ref={rootRef}
      data-testid="minimised-condition"
      data-e2e="minimised-condition"
      className={classnames(
        "bg-white",
        "h-12",
        "border-b",
        "border-coolGrey-200",
        "flex",
        "flex-col",
        "transition-colors",
        "ease-in",
        "duration-75",
        "text-black-300",
        classNames
      )}
    >
      {showTooltip ? withTooltip : minimisedCondition}
    </div>
  );
};
