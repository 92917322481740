import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const EqualNot = createSvgIcon({
  content: (
    <>
      <path
        d="M3.3335 6.66669H12.6668"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.3335 9.33331H12.6668"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.3335 12.6666L12.6668 3.33331"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
});
