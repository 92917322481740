import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { type ApolloClient, gql, useQuery } from "@apollo/client";
import { GraphQLError } from "graphql";
import { isNull } from "lodash";

import {
  Button,
  Card,
  DialogBox,
  Icon,
  Select,
  TextInput,
} from "@components/atoms";
import { CustomDialog, Pager, Segment, Table } from "@components/molecules";
import {
  DeleteSegmentDialog,
  OccurrencesSlideOver,
  SegmentJobsExportDialog,
} from "@components/organisms";
import {
  OccurrencesTimes,
  SegmentJobDetails_JobsAggregate_PreviewDocument,
  SegmentJobDetails_SegmentJob_StaticDocument,
  SegmentJobDetails_SegmentJobDocument,
  type SegmentJobDetails_SegmentJobQuery,
} from "@graphql/operations";
import { useDebounce } from "@hooks/useDebounce";
import { limitOptions, usePaging } from "@hooks/usePaging";
import * as clipboard from "@utils/clipboard";

import { SegmentDoesntExist } from "../SegmentDoesntExist";
import { ActionsMenu } from "../SegmentsOverview/SegmentJobTable/ActionsMenu";

import { CollapsibleNestedSegments } from "./CollapsibleNestedSegments";
import { SegmentJobId } from "./SegmentJobId";
import { VideoRow } from "./VideoRow";

export interface CustomGraphQLError extends GraphQLError {
  errorType?: string;
}

interface SelectedVideo {
  title: string;
  videoId: string;
  occurrences: OccurrencesTimes[];
}

export const SegmentJobDetails = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const debounceSearchValue = useDebounce(searchValue, 100);

  const [isDeleteSegmentDialogOpen, setIsDeleteSegmentDialogOpen] =
    useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [slideOpen, setSlideOpen] = useState<boolean>(false);
  const [nestedSegmentWarningModal, setNestedSegmentWarningModal] =
    useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<SelectedVideo>();
  const { id, version: versionParam } = useParams();
  const navigate = useNavigate();
  const version = versionParam ? parseInt(versionParam, 10) : undefined;

  // Pagination
  const { clear, getCurrentPage, setCurrentPage } = usePaging({
    tokenStack: [""],
    currentPage: 1,
  });
  const [limit, setLimit] = useState(limitOptions["10"]);

  // For Preview component
  const { data: totalJobsData, loading: totalJobsLoading } = useQuery(
    SegmentJobDetails_JobsAggregate_PreviewDocument
  );
  const { data: segmentJobStaticData, loading: segmentJobStaticLoading } =
    useQuery(SegmentJobDetails_SegmentJob_StaticDocument, {
      variables: {
        filter: {
          segmentId: {
            eq: id,
          },
          segmentVersion: {
            eq: version,
          },
        },
      },
    });

  // For Table component
  // Load initial data
  const {
    data: initialData,
    loading,
    error,
    client,
  } = useQuery(SegmentJobDetails_SegmentJobDocument, {
    variables: {
      limit: parseInt(limit, 10),
      filter: {
        segmentId: { eq: id },
        segmentVersion: { eq: version },
      },
    },
  });

  const segmentDoesntExist =
    error &&
    error.graphQLErrors.some(
      (gqlError: CustomGraphQLError) => gqlError.errorType === "NOT_FOUND"
    );

  // For search component
  const { data } = useSearchData({ initialData, client, id, version });
  const filteredVideos = data.filter((segmentVideoAssociation: any) => {
    if (!debounceSearchValue) {
      return true;
    }

    const { videoId, title } = segmentVideoAssociation.IngestionJobConnection;
    const searchWord = debounceSearchValue.toLowerCase().trim();

    return (
      videoId.toLowerCase().includes(searchWord) ||
      title.toLowerCase().includes(searchWord)
    );
  });

  const totalPages = Math.ceil(filteredVideos.length / parseInt(limit, 10));
  const hasNextPage = getCurrentPage() < totalPages;

  const visibleItems = filteredVideos.slice(
    (getCurrentPage() - 1) * parseInt(limit, 10),
    getCurrentPage() * parseInt(limit, 10)
  );

  // because the visibleItems array is always a new array,
  // any comparisions done by the useEffect will always return false
  // which means the hook will always run, so we concat all the videoIds
  // into a string, so that we can make correct comparisions
  const [hashIds, setHashIds] = useState("");
  const { data: videoIngestionData, previousHashIds } = useRefreshThumbnails(
    visibleItems,
    hashIds,
    client
  );
  useEffect(() => setHashIds(previousHashIds), [previousHashIds]);

  // @warn this being late in the code, might result in errors
  if (segmentDoesntExist) {
    return <SegmentDoesntExist />;
  }

  return (
    <article
      className="flex-auto grid items-start grid-cols-12 gap-x-6 pt-8 pb-10 px-8 bg-lightGrey-100"
      data-e2e="segment-details"
    >
      <div className="col-span-9">
        <Card.Container className="overflow-clip">
          <div className="px-10 pt-10 pb-8 space-y-4" data-e2e="details">
            <div className="flex">
              <h4 className="font-bold text-navy-300 flex items-center gap-2">
                <Icon.Segment size={16} />{" "}
                {segmentJobStaticData?.segmentJob?.Title || "-"}
              </h4>
              {segmentJobStaticData?.segmentJob && (
                <Segment.Status.Label
                  className="mx-6"
                  redshiftQueryStatus={
                    segmentJobStaticData.segmentJob.RedshiftQueryStatus
                  }
                  status={segmentJobStaticData.segmentJob.Status}
                />
              )}
              <div className="flex grow justify-end">
                {segmentJobStaticData?.segmentJob?.Version && (
                  <SegmentJobsExportDialog
                    segmentId={id}
                    segmentVersion={segmentJobStaticData?.segmentJob?.Version}
                  />
                )}
              </div>
            </div>
            <div className="flex items-center text-sm gap-2">
              <div className="font-bold flex">
                ID
                <Icon.Info
                  className="ml-1 cursor-pointer"
                  size={11}
                  onClick={() => setIsDialogOpen(true)}
                />
                :
              </div>

              <div data-e2e="segment-id">
                {segmentJobStaticData?.segmentJob?.SegmentId ? (
                  <SegmentJobId
                    id={segmentJobStaticData.segmentJob.SegmentId}
                  />
                ) : (
                  "-"
                )}
              </div>

              <DialogBox.Container
                isDialogOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
              >
                <DialogBox.Card onClose={() => setIsDialogOpen(false)}>
                  <DialogBox.Title>Segment ID</DialogBox.Title>
                  <p className="text-sm mb-6">
                    Use the Segment ID to target videos that meet the Segment
                    conditions.
                    <br />
                    Use the Inverse ID ('$' + 'Segment ID') to target videos
                    that do NOT meet the Segment conditions.
                  </p>
                  <p className="flex text-sm mb-3">
                    <span className="font-bold pr-1">Segment ID:</span>{" "}
                    {segmentJobStaticData ? (
                      <>
                        {segmentJobStaticData.segmentJob?.SegmentId}
                        <Button
                          className="ml-2 text-black-100"
                          variant="contained"
                          color="clear"
                          icon={<Icon.Copy size={16} />}
                          onClick={clipboard.copyToHandler({
                            value:
                              segmentJobStaticData?.segmentJob?.SegmentId ||
                              "-",
                            onComplete: clipboard.toastSuccess({
                              message: "Copied to clipboard",
                            }),
                          })}
                        />
                      </>
                    ) : (
                      "-"
                    )}
                  </p>
                  <p className="flex text-sm">
                    <span className="font-bold pr-1">Inverse ID:</span>{" "}
                    {segmentJobStaticData ? (
                      <>
                        {"$" + segmentJobStaticData.segmentJob?.SegmentId}
                        <Button
                          className="ml-2 text-black-100"
                          variant="contained"
                          color="clear"
                          icon={<Icon.Copy size={16} />}
                          onClick={clipboard.copyToHandler({
                            value:
                              "$" +
                                segmentJobStaticData?.segmentJob?.SegmentId ||
                              "-",
                            onComplete: clipboard.toastSuccess({
                              message: "Copied to clipboard",
                            }),
                          })}
                        />
                      </>
                    ) : (
                      "-"
                    )}
                  </p>
                </DialogBox.Card>
              </DialogBox.Container>
            </div>
            <div className="text-bodySm">
              {segmentJobStaticData?.segmentJob?.Description || "-"}
            </div>

            <TextInput
              onChange={(e) => {
                setSearchValue(e.target.value);
                clear();
              }}
              value={searchValue}
              placeholder={
                data
                  ? `Search ${data.length} asset titles or IDs`
                  : "Search asset title or ID"
              }
              className="max-w-126"
              icon={<Icon.Search size={16} className="ml-2 text-black-100" />}
            />

            <CollapsibleNestedSegments
              segments={segmentJobStaticData?.segmentJob?.Children}
            />
          </div>

          <Table.Table
            columns={[
              {
                title: "Video",
                width: "w-6/12",
              },
              {
                title: "Business unit",
                width: "w-2/12",
              },
              {
                title: "Started at",
                width: "w-2/12",
              },
              {
                title: "Occurrences",
                width: "w-2/12",
              },
            ]}
            loading={loading}
            loadingRows={Number(limitOptions["10"])}
            loadingRowsHeight="h-18"
            error={!!error}
            align="middle"
          >
            {videoIngestionData.length > 0
              ? videoIngestionData.map(
                  (segmentVideoAssociation: any, idx: number) => (
                    <VideoRow
                      key={
                        segmentVideoAssociation.IngestionJobConnection.videoId
                      }
                      index={idx}
                      segmentVideoAssociationItem={segmentVideoAssociation}
                      data-e2e="segment-video"
                      onClick={() => {
                        setSelectedVideo({
                          title:
                            segmentVideoAssociation.IngestionJobConnection
                              ?.title || "-",
                          occurrences: segmentVideoAssociation.OccurrencesTimes,
                          videoId:
                            segmentVideoAssociation.IngestionJobConnection
                              .videoId,
                        });
                        setSlideOpen(true);
                      }}
                    />
                  )
                )
              : null}
          </Table.Table>
        </Card.Container>

        <div
          data-testid="pagination"
          className="mt-4 flex flex-row justify-between"
        >
          <div className="flex flex-row items-center align-middle pl-6">
            <p className="mr-2 text-sm text-black-100 font-medium">Per page</p>
            <Select
              direction="up"
              className="h-8"
              options={limitOptions}
              value={limit}
              rightToLeft={false}
              fixedWidth={true}
              onChange={(item) => {
                clear();
                setLimit(item);
              }}
            />
          </div>

          <Pager
            className="mr-0"
            data-testid="pager"
            currentPage={getCurrentPage()}
            nextButtonEnabled={hasNextPage}
            onPageChanged={(pageNumber) => setCurrentPage(pageNumber)}
            totalPages={totalPages}
          />
        </div>
      </div>

      <div className="col-span-3 flex flex-col">
        <Segment.Preview
          loading={segmentJobStaticLoading || totalJobsLoading}
          totalVideos={totalJobsData?.jobs?.aggregate?.Total!}
          totalOccurrences={
            segmentJobStaticData?.segmentJob?.SegmentCount || undefined
          }
          totalIsolateVideos={
            segmentJobStaticData?.segmentJob?.VideoCount || undefined
          }
          loadingQuery={segmentJobStaticLoading}
          query={
            segmentJobStaticData?.segmentJob?.Query &&
            JSON.parse(segmentJobStaticData.segmentJob.Query)
          }
        />
        <div className="flex justify-between items-center px-5">
          {segmentJobStaticData?.segmentJob && (
            <>
              <ActionsMenu
                position="above"
                onDuplicateClick={() =>
                  navigate(
                    `/segments/${
                      segmentJobStaticData.segmentJob!.SegmentId
                    }/version/${
                      segmentJobStaticData.segmentJob!.Version
                    }/duplicate`
                  )
                }
                onEditClick={() =>
                  navigate(
                    `/segments/${
                      segmentJobStaticData.segmentJob!.SegmentId
                    }/version/${segmentJobStaticData.segmentJob!.Version}/edit`
                  )
                }
                onDeleteClick={() => {
                  setIsDeleteSegmentDialogOpen(true);
                }}
              />
              <DeleteSegmentDialog
                segmentData={{
                  segmentId: segmentJobStaticData.segmentJob.SegmentId,
                  hasParent:
                    (segmentJobStaticData.segmentJob.Parents?.length || 0) > 0,
                  segmentVersion: segmentJobStaticData.segmentJob.Version,
                }}
                isDialogOpen={isDeleteSegmentDialogOpen}
                onCancel={() => setIsDeleteSegmentDialogOpen(false)}
                onClose={() => setIsDeleteSegmentDialogOpen(false)}
                onSubmission={() => {
                  setIsDeleteSegmentDialogOpen(false);
                  navigate("/segments");
                }}
              />
            </>
          )}
          {segmentJobStaticData?.segmentJob && (
            <Button
              type="button"
              size="medium"
              color="primary"
              onClick={() => {
                if (segmentJobStaticData?.segmentJob?.Parents) {
                  setNestedSegmentWarningModal(true);
                } else {
                  navigate(
                    `/segments/${
                      segmentJobStaticData.segmentJob!.SegmentId
                    }/version/${segmentJobStaticData.segmentJob!.Version}/edit`
                  );
                }
              }}
              data-e2e="button-edit-segment"
            >
              Edit Segment
            </Button>
          )}
        </div>
      </div>
      <OccurrencesSlideOver
        open={slideOpen}
        title={selectedVideo?.title}
        occurrences={selectedVideo?.occurrences}
        onClose={() => setSlideOpen(false)}
        videoId={selectedVideo?.videoId}
      />
      <CustomDialog.Container
        isDialogOpen={nestedSegmentWarningModal}
        onClose={() => setNestedSegmentWarningModal(false)}
      >
        <CustomDialog.Card
          className="h-80 max-w-10"
          onClose={() => setNestedSegmentWarningModal(false)}
        >
          <CustomDialog.Title centerText={false} className="px-16 text-left">
            Making changes to this Segment will affect other Segments
          </CustomDialog.Title>
          <CustomDialog.Description
            centerText={false}
            className="px-16 text-left"
          >
            This segment is nested in other Segments. Any changes you make will
            affect all parent Segments as well.
          </CustomDialog.Description>
          <div className="flex flex-row justify-between">
            <Button
              variant="text"
              onClick={() => setNestedSegmentWarningModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="danger"
              size="medium"
              onClick={() =>
                navigate(`/segments/${id}/version/${version}/edit`)
              }
            >
              Edit this segment
            </Button>
          </div>
        </CustomDialog.Card>
      </CustomDialog.Container>
    </article>
  );
};

type useSearchDataParams = {
  initialData: SegmentJobDetails_SegmentJobQuery | undefined;
  client: ApolloClient<any>;
  id?: string;
  version?: number;
};
export const useSearchData = ({
  initialData,
  client,
  id,
  version,
}: useSearchDataParams) => {
  const videoAssociation = initialData?.segmentJob?.VideoAssociationConnection;
  const [needsFetching, setNeedsFetching] = useState(false);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [data, setData] = useState<any[]>([]);

  const fetchNextPage = useCallback(
    async (nextToken: string | null) => {
      const { data: queryData } = await client.query({
        query: SegmentJobDetails_SegmentJobDocument,
        variables: {
          limit: 200,
          nextToken: nextToken,
          filter: {
            segmentId: { eq: id },
            segmentVersion: { eq: version },
          },
        },
      });

      const video = queryData.segmentJob?.VideoAssociationConnection;
      const accumulatedData = data.concat(video?.items).filter(Boolean);

      setData(accumulatedData);
      setNextToken(video?.nextToken || null);
      setNeedsFetching(!!video?.nextToken);
    },
    [client, id, version, data]
  );

  useEffect(() => {
    if (!nextToken && !isNull(nextToken) && videoAssociation?.nextToken) {
      setNextToken(videoAssociation?.nextToken);
      setNeedsFetching(true);
    }
  }, [nextToken, videoAssociation]);

  useEffect(() => {
    if (!data.length && videoAssociation?.items) {
      setData(videoAssociation?.items);
      setNextToken(videoAssociation?.nextToken || null);
      setNeedsFetching(!!videoAssociation?.nextToken);
    }
  }, [data, videoAssociation]);

  useEffect(() => {
    if (needsFetching) {
      fetchNextPage(nextToken);
    }
  }, [needsFetching, nextToken, fetchNextPage]);

  return { data, loading: !!needsFetching };
};

const useRefreshThumbnails = (
  visibleItems: any[],
  previousHashIds: string,
  client: ApolloClient<unknown>
) => {
  const [mergedData, setMergedData] = useState<any>([]);
  const hashIds = visibleItems
    .map((items) => items.IngestionJobConnection.videoId)
    .join("");

  const loadThumbnails = useCallback(async () => {
    const query = `query SegmentJob {
        ${visibleItems.map(
          (job: any) => `v${job.IngestionJobConnection.videoId.replace(
            "-",
            "_"
          )}: job(filter: {
        videoId: { eq: "${job.IngestionJobConnection.videoId}" }
      }) { thumbnailHref, videoId }`
        )}
    }`;

    const thumbnailQuery = gql(query);
    const { data } = await client.query({ query: thumbnailQuery });

    const merged = visibleItems.map((item) => {
      const job = item.IngestionJobConnection;
      return {
        ...item,
        IngestionJobConnection: {
          ...job,
          thumbnailHref:
            data[`v${job.videoId.replace("-", "_")}`].thumbnailHref ||
            job.thumbnailHref,
        },
      };
    });

    setMergedData(merged);
  }, [visibleItems, client]);

  useEffect(() => {
    if (hashIds && previousHashIds !== hashIds) {
      loadThumbnails();
    }
  }, [hashIds, previousHashIds, loadThumbnails]);

  return { data: mergedData, previousHashIds: hashIds };
};
