import React from "react";

import { Button } from "@components/atoms";
import { CustomDialog } from "@components/molecules";
import { IndexedFilters } from "@hooks/segments";

interface RemoveConditionsGroupProps {
  indexedFilter: IndexedFilters;
  remove: (id: string) => void;
  isDialogOpen: boolean;
  setIsDialogOpen: (status: boolean) => void;
}

export const RemoveConditionsGroupDialog = ({
  isDialogOpen,
  indexedFilter,
  remove,
  setIsDialogOpen,
}: RemoveConditionsGroupProps) => (
  <CustomDialog.Container
    isDialogOpen={isDialogOpen}
    onClose={() => setIsDialogOpen(false)}
  >
    <CustomDialog.Card className="w-50" onClose={() => setIsDialogOpen(false)}>
      <CustomDialog.Title
        centerText={false}
        marginBottom="mb-4"
        className="mx-16 text-xl"
      >
        This will remove {indexedFilter.length} conditions
      </CustomDialog.Title>
      <p className="mb-12">Would you like to proceed?</p>
      <div className="flex justify-between">
        <Button
          color="default"
          variant="text"
          onClick={() => setIsDialogOpen(false)}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            indexedFilter.forEach((el) => remove(el.id));
            setIsDialogOpen(false);
          }}
          color="primary"
          variant="contained"
          size="small"
        >
          Continue
        </Button>
      </div>
    </CustomDialog.Card>
  </CustomDialog.Container>
);
