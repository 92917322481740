import React from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { merge } from "lodash";

import { Card, Player, Portal } from "@components/atoms";
import { GenericExportDialog } from "@components/organisms/GenericExportDialog";
import {
  Celebrities_JobDocument,
  Celebrities_ListCelebrityOccurrenceUnloadDocument,
  Celebrities_ListCelebrityOccurrenceUnloadQueryVariables,
} from "@graphql/operations";

import { CelebritiesTable } from "./CelebritiesTable";

enum Category {
  CONDITION_DATA = "Condition data",
}

const conditionSelectableFields = {
  Confidence: "Confidence",
  Name: "Name",
  Id: "Id",
  Urls: "Urls",
  Timestamp: "Timestamp",
  TimestampMillis: "Timestamp Millis",
  VideoId: "Video Id",
};

const selectableFields = [
  {
    id: Category.CONDITION_DATA,
    name: Category.CONDITION_DATA,
    children: Object.entries(conditionSelectableFields).map(([k, v]) => ({
      id: k,
      name: v,
    })),
  },
];

const selectedNodesId = [...Object.keys(conditionSelectableFields)];

export interface CelebritiesProps {
  videoId: string;
}

export const Celebrities = ({ videoId }: CelebritiesProps) => {
  const [
    unloadListCelebrityOccurrence,
    { loading: unloadLoading, error: unloadError },
  ] = useLazyQuery(Celebrities_ListCelebrityOccurrenceUnloadDocument, {
    fetchPolicy: "no-cache",
  });

  const { data } = useQuery(Celebrities_JobDocument, {
    skip: !videoId,
    variables: {
      filter: {
        videoId: {
          eq: videoId,
        },
      },
    },
  });

  const videoUrl = data?.job?.mediaHref || "";

  return (
    <>
      <Portal.Teleport>
        <div className="flex flex-col items-end">
          <GenericExportDialog
            title="Export"
            selectableFields={selectableFields}
            initialSelectedNodeIds={selectedNodesId}
            onExportSubmission={async (formData: string[]) => {
              let variables: Celebrities_ListCelebrityOccurrenceUnloadQueryVariables =
                {
                  filter: {
                    videoId: {
                      eq: videoId,
                    },
                  },
                };

              if (formData.length) {
                variables = merge(variables, {
                  unload: {
                    columns: formData,
                  },
                });
              }

              const { data: unloadData } = await unloadListCelebrityOccurrence({
                variables,
              });

              return (
                unloadData?.listCelebrityOccurrence?.unload?.href || undefined
              );
            }}
            loading={unloadLoading}
            error={unloadError?.message}
          />
        </div>
      </Portal.Teleport>
      <section className="flex flex-row flex-1">
        <Card.Container className="w-2/5 self-start">
          <Player url={videoUrl} />
        </Card.Container>
        <section className="flex-1 ml-6">
          <CelebritiesTable videoId={videoId} />
        </section>
      </section>
    </>
  );
};
