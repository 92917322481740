import React from "react";

import { TextInput } from "@components/atoms";

import { FilterType, TitleLikeFilter, useFilters } from "../../hooks";

export const VideoTitleLike = () => {
  const context = useFilters();

  const onTextChange = (e: any) => {
    const text = e.target.value;

    const filter: TitleLikeFilter = {
      filterType: FilterType.TITLE__LIKE,
      title: text,
    };

    if (text.trim().length > 0) {
      context.addFilter(filter);
    } else {
      context.removeFilter(FilterType.TITLE__LIKE);
    }
  };

  const filter = context.getFilter(FilterType.TITLE__LIKE);

  return (
    <div>
      <TextInput
        inputClassName="w-52"
        placeholder="Search video titles"
        onChange={onTextChange}
        value={filter?.title || ""}
      />
    </div>
  );
};
