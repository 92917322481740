import React from "react";
import { useForm } from "react-hook-form";
import { pickBy } from "lodash";

import { Button } from "@components/atoms";
import { FormInput } from "@components/molecules";
import { classnames } from "@external/tailwindcss-classnames";

type FormData = {
  href: string;
  videoId: string;
  title?: string;
  thumbnail?: string;
  businessUnit?: string;
};

export interface CreateJobFormProps {
  onSubmit: (formData: FormData) => void;
  onCancel: () => void;
  className?: any;
}

export const CreateJobForm = ({
  onSubmit,
  onCancel,
  className,
}: CreateJobFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({ mode: "onChange" });

  const hasError = Object.keys(errors).length > 0;
  const onCancelClicked = (e: any) => {
    e.preventDefault();
    onCancel();
  };

  const submitHandler = (data: FormData) =>
    onSubmit(pickBy(data, (value) => value.length > 0) as FormData);

  return (
    <form
      data-testid="create-job-form"
      className={classnames(className, "flex", "flex-col")}
      onSubmit={handleSubmit(submitHandler)}
    >
      <FormInput
        placeholder="Video ID"
        label="Video ID"
        fieldName="videoId"
        errors={errors}
        register={() => register("videoId", { required: true })}
      />
      <FormInput
        className="mt-6"
        placeholder="Video URL"
        label="Video URL"
        fieldName="href"
        errors={errors}
        register={() => register("href", { required: true })}
      />
      <FormInput
        className="mt-6"
        placeholder="Video Title"
        label="Video Title - Optional"
        fieldName="title"
        errors={errors}
        register={() => register("title")}
      />
      <FormInput
        className="mt-6"
        placeholder="Thumbnail URL"
        label="Video Thumbnail URL - Optional"
        fieldName="thumbnail"
        errors={errors}
        register={() => register("thumbnail")}
      />
      <FormInput
        className="mt-6"
        placeholder="Business Unit"
        label="Business Unit - Optional"
        fieldName="businessUnit"
        errors={errors}
        register={() => register("businessUnit")}
      />
      <div className="flex flex-row justify-between mt-12">
        <Button variant="text" onClick={onCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          disabled={hasError || !isDirty || !isValid}
        >
          Start Ingestion
        </Button>
      </div>
    </form>
  );
};
