import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { MutationHookOptions, useMutation } from "@apollo/client";

import { Button } from "@components/atoms";
import { CustomDialog } from "@components/molecules";
import {
  DeleteSegmentDialog_DeleteSegmentJobDocument,
  DeleteSegmentDialog_DeleteSegmentJobMutation,
  DeleteSegmentDialog_DeleteSegmentJobMutationVariables,
  NestedSegmentIdEquals_SegmentJobDocument,
  SegmentJobDetails_SegmentJobDocument,
  SegmentJobTable_ListSegmentJobsDocument,
  SegmentsJobDuplicate_SegmentJobDocument,
  SegmentsJobEdit_SegmentJobDocument,
  SegmentsOverview_ListSegmentJobs_AggregateDocument,
} from "@graphql/operations";

export const deleteSegmentJobDefaultOptions: MutationHookOptions<
  DeleteSegmentDialog_DeleteSegmentJobMutation,
  DeleteSegmentDialog_DeleteSegmentJobMutationVariables
> = {
  refetchQueries: [
    SegmentsJobDuplicate_SegmentJobDocument,
    SegmentsJobEdit_SegmentJobDocument,
    NestedSegmentIdEquals_SegmentJobDocument,
    SegmentJobDetails_SegmentJobDocument,
    SegmentJobTable_ListSegmentJobsDocument,
    SegmentsOverview_ListSegmentJobs_AggregateDocument,
  ],
};

export type SegmentData = {
  segmentId: string;
  segmentVersion: number;
  hasParent: boolean;
};

export interface DeleteSegmentDialogProps {
  segmentData: SegmentData;
  isDialogOpen: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSubmission: () => void;
}

export const DeleteSegmentDialog = ({
  segmentData,
  isDialogOpen = false,
  onCancel,
  onClose,
  onSubmission,
}: DeleteSegmentDialogProps) => {
  const [deleteSegmentJob, { error }] = useMutation(
    DeleteSegmentDialog_DeleteSegmentJobDocument,
    deleteSegmentJobDefaultOptions
  );

  useEffect(() => {
    if (error) {
      try {
        const jsonError = JSON.parse(error.message);

        if (jsonError.message) {
          toast.error(jsonError.message, {
            id: "segment-delete-error",
          });
        } else {
          toast.error("An unknown error happened", {
            id: "segment-delete-error",
          });
        }
      } catch {
        toast.error(error.message, {
          id: "segment-delete-error",
        });
      }
    }
  }, [error]);

  const handleJobSubmission = async (
    payload: DeleteSegmentDialog_DeleteSegmentJobMutationVariables
  ) => {
    const { data } = await deleteSegmentJob({
      variables: {
        input: {
          version: payload.input.version,
          segmentId: payload.input.segmentId,
        },
      },
    });

    if (data?.deleteSegmentJob?.operationSuccessful) {
      toast.success("Segment successfully deleted", {
        id: "segment-deleted",
      });
    }

    onSubmission();
  };

  return (
    <CustomDialog.Container
      isDialogOpen={isDialogOpen}
      onClose={onClose}
      data-e2e="delete-segment-dialog"
    >
      {segmentData.hasParent ? (
        <CustomDialog.Card className="h-72 w-110" onClose={onClose}>
          <div className="fixed h-full inset-0 flex flex-col items-start px-8">
            <h3 className="mb-4 mt-10">Deleting will affect other Segments</h3>
            <span className="mb-10">
              <p className="text-justify pr-9">
                This segment is nested in other Segments. Deleting this Segment
                will remove it from all parent Segments as well. This cannot be
                undone. Are you sure you want to delete this Segment?
              </p>
            </span>
            <div className="pb-8 flex flex-row justify-between w-full">
              <Button variant="text" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="danger"
                size="medium"
                onClick={() =>
                  handleJobSubmission({
                    input: {
                      segmentId: segmentData.segmentId,
                      version: segmentData.segmentVersion,
                    },
                  })
                }
              >
                Delete
              </Button>
            </div>
          </div>
        </CustomDialog.Card>
      ) : (
        <CustomDialog.Card onClose={onClose}>
          <CustomDialog.Title>This can't be undone</CustomDialog.Title>
          <CustomDialog.Description>
            Are you sure you want to delete this Segment?
          </CustomDialog.Description>
          <div className="flex flex-row justify-between mt-12">
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="danger"
              size="medium"
              onClick={() =>
                handleJobSubmission({
                  input: {
                    segmentId: segmentData.segmentId,
                    version: segmentData.segmentVersion,
                  },
                })
              }
            >
              Delete
            </Button>
          </div>
        </CustomDialog.Card>
      )}
    </CustomDialog.Container>
  );
};
