import React, { ReactNode, useEffect, useRef } from "react";
import { useDrag, useDragLayer, useDrop, XYCoord } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import { Button, Icon } from "@components/atoms";
import { classnames, TArg } from "@external/tailwindcss-classnames";
import { useDragScrolling } from "@hooks/drag-and-drop";
import { FilterType, Location } from "@hooks/segments";

import { ColourLine } from "../ColourLine";
import { FilterTypeColorMapping } from "../ConditionColours";
import { Ref } from "../DropTarget";
import { ItemTypes } from "../ItemType";

export interface ConditionTemplateProps {
  type: FilterType;
  id: string;
  location: Location;
  ref?: Ref;
  onRemove?: () => void | Promise<void>;
  onDrop?: (...args: any[]) => void | Promise<void>;
  children?: ReactNode;
}

export const ConditionTemplate = ({
  id,
  type,
  onRemove,
  onDrop,
  children,
}: ConditionTemplateProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const { addEventListenerForWindow, removeEventListenerForWindow } =
    useDragScrolling();

  const dragLayer = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging: monitor.getItem(),
  }));

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: ItemTypes.CONDITION,
    item: () => {
      addEventListenerForWindow();
      return {
        type,
        id,
        action: "MOVE",
        ref: ref.current,
        width: ref.current?.clientWidth,
        handleRef: handleRef?.current,
        handleWidth: handleRef?.current?.clientWidth,
      };
    },
    previewOptions: {
      captureDraggingState: true,
    },
    end: () => removeEventListenerForWindow(),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.CONDITION,
    drop: (
      dragObject: {
        type: FilterType;
        index?: number;
        action?: string;
        source: "ANY" | "ALL" | "EXCLUDE";
      },
      monitor
    ) => {
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        ((hoverBoundingRect as DOMRect).bottom -
          (hoverBoundingRect as DOMRect).top) /
        2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY =
        (clientOffset as XYCoord).y - (hoverBoundingRect as DOMRect).top;

      onDrop &&
        onDrop(dragObject, id, hoverClientY < hoverMiddleY ? true : false);
    },
    hover: (
      item: {
        type: FilterType;
        id?: number;
        action?: string;
        source: "ANY" | "ALL" | "EXCLUDE";
      },
      monitor
    ) => {
      if (!ref.current || item?.id + "" === id) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (item.action === "MOVE") {
        onDrop && onDrop(item, id, hoverClientY < hoverMiddleY ? true : false);
      } else {
        ref.current.classList.remove("mb-[0.4375rem]");
        ref.current.classList.remove("last:mb-0");
        ref.current.classList.remove("last:border-b-0");

        if (hoverClientY < hoverMiddleY) {
          ref.current.classList.add("mt-4");
          ref.current.classList.remove("mb-4");
        } else {
          ref.current.classList.remove("mt-4");
          ref.current.classList.add("mb-4");
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  useEffect(() => {
    if (ref.current) {
      ref.current.classList.remove("mt-4");
      ref.current.classList.remove("mb-4");
      ref.current.classList.add("mb-[0.4375rem]");
      ref.current.classList.add("last:mb-0");
      ref.current.classList.add("last:border-b-0");
    }
  }, [isOver]);

  drop(ref);
  drag(handleRef);
  preview(getEmptyImage(), { captureDraggingState: true });

  return (
    <>
      <div
        key={id}
        ref={ref}
        className={classnames(
          "flex",
          "flex-row",
          "border-y" as TArg,
          "bg-white",
          "border-coolGrey-200",
          "mb-[0.4375rem]" as TArg,
          "last:mb-0" as TArg,
          "last:border-b-0" as TArg,
          "transition-all",
          {
            invisible:
              isDragging || (dragLayer.isDragging && dragLayer.item?.id === id),
          }
        )}
        data-e2e="applied-condition"
      >
        <ColourLine colour={FilterTypeColorMapping[type]} />
        <div className="flex-1 mr-7 ml-8 py-4">{children}</div>
        <div className="text-coolGrey-300 mt-12">
          <Button
            variant="text"
            color="clear"
            onClick={onRemove ? () => onRemove() : undefined}
          >
            <Icon.Remove />
          </Button>
        </div>
        <div
          ref={handleRef}
          className="flex px-6 items-center cursor-move text-coolGrey-300"
        >
          <Icon.Draggable />
        </div>
      </div>
    </>
  );
};
