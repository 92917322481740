import React from "react";

import { Button, Icon, Link } from "@components/atoms";

export interface CompleteProps {
  href: string;
  onClose: () => Promise<void> | void;
}

export const Complete = ({ onClose, href }: CompleteProps) => (
  <div className="flex flex-col items-center h-full">
    <div className="flex-1 flex flex-col items-center text-black-300">
      <Icon.Checkmark color="blue-300" className="mb-8" size={40} />
      <div>Export complete.</div>
      <div>
        Don’t see your file?{" "}
        <Link
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          data-e2e="manual-download-link"
        >
          Try downloading it again.
        </Link>
      </div>
      <div>
        <strong>Note:</strong> This link will expire in 5 minutes.
      </div>
    </div>
    <Button
      onClick={onClose}
      className="text-center"
      color="primary"
      size="medium"
    >
      Close
    </Button>
  </div>
);
