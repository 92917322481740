import { TypePolicies } from "@apollo/client";

import Job from "./Job";
import SegmentImport from "./SegmentImport";

const typePolicies: TypePolicies = {
  SegmentImport,
  Job,
  SegmentJob: {
    keyFields: ["SegmentId"],
  },
  Segment: {
    keyFields: ["SegmentId"],
  },
  Video: {
    keyFields: ["VideoId"],
  },
};

export default typePolicies;
