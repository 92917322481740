import React from "react";

import { Button, Icon } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

export interface CodeBlockProps {
  className?: any;
  children?: string;
  onCopy?: () => void | Promise<void>;
}

export const CodeBlock = ({ children, onCopy, className }: CodeBlockProps) => (
  <pre
    className={classnames(
      className,
      "text-black-300",
      "text-bodySm",
      "relative",
      "overflow-scroll",
      "border",
      "border-black-100",
      "bg-lightGrey-200",
      "p-3",
      "min-h-40",
      "max-h-[800px]" as any
    )}
  >
    {onCopy && (
      <Button
        color="clear"
        variant="text"
        onClick={onCopy}
        className="text-coolGrey-300 absolute right-0 top-0 mt-2 mr-3"
      >
        <Icon.Copy />
      </Button>
    )}
    {children}
  </pre>
);
