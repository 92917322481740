import React, {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import _, { isNull, snakeCase, toUpper } from "lodash";

import { ContentModerationLabels } from "@components/molecules/Segment/Condition/ContentModeration/labels";
import {
  CelebrityEqualsVariables,
  ContentModerationEqualsVariables,
  GenericSportsDimensionVariables,
  GenericSportsMetricVariables,
  LabelEqualsVariables,
  MetadataContainsVariables,
  MinOccurrenceCountVariables,
  NestedSegmentIdEqualsVariables,
  Query,
  QueryCondition,
  QueryConditionType,
  SentimentEqualsVariables,
  SportsBooleanDimensionVariables,
  SportsBooleanWithToleranceVariables,
  SportsStringDimensionVariables,
  TextInVideoContainsVariables,
  TranscriptContainsVariables,
  VideoDurationEqualsVariables,
  VideoTitleContainsVariables,
} from "@components/types";
import { ConvertToUpperSnakeCase } from "@utils/casing";
import * as typing from "@utils/typing";

import {
  CelebrityEqualsFilter,
  ContentModerationFilter,
  FilterType,
  GenericSportsDimensionFilter,
  GenericSportsMetricFilter,
  LabelFilter,
  MetadataContainsFilter,
  MinOccurrenceCountFilter,
  NestedSegmentFilter,
  SentimentFilter,
  SportsDimensionBooleanFilter,
  SportsDimensionBooleanWithToleranceFilter,
  SportsDimensionStringFilter,
  TextInVideoContainsFilter,
  TranscriptContainsFilter,
  VideoDurationFilter,
  VideoTitleContainsFilter,
} from "./types";

const FilterContext = createContext<FilterContextProps | null>(null);

export type OccupiedLabel = {
  ownerId: string;
  value: string;
  parentKey: string | undefined;
};
type LabelActionContext = {
  value: string;
  parentKey: string | undefined;
  checkedStatus: LabelAction;
  ownerId: string;
};

export interface FilterContextProps {
  anyOfTheFollowing: IndexedFilters;
  allOfTheFollowing: IndexedFilters;
  excludes: IndexedFilters;
  query?: Query;
  length: number;
  hasCondition: (filterType: FilterType) => {
    anyOfTheFollowing: boolean;
    allOfTheFollowing: boolean;
    excludes: boolean;
  };
  append: (location: Location, type: FilterType, filter?: any) => void;
  insertBetween: (
    location: Location,
    type: FilterType,
    id: string,
    above: boolean,
    filter?: any
  ) => void;
  moveBetween: (
    destination: Location,
    movedId: string,
    movingId: string,
    above: boolean
  ) => void;
  move: (destination: Location, id: string) => void;
  update: (id: string, value: any) => void;
  remove: (id: string) => void;
  setSegmentTitle: (value: string) => void;
  segmentTitle: string;
  setSegmentId: (value: string) => void;
  segmentId: string;
  setSegmentDescription: (value: string) => void;
  segmentDescription: string;
  version?: number;
  contentModerationCtx: {
    state: Array<OccupiedLabel>;
    dispatch: React.Dispatch<LabelActionContext>;
  };
}

export enum Location {
  ALL,
  ANY,
  EXCLUDE,
}

type FilterPayloadTable = {
  [FilterType.CELEBRITY__EQUALS]: CelebrityEqualsFilter;
  [FilterType.LABEL__CONTAINS]: LabelFilter;
  [FilterType.VIDEO_TITLE__CONTAINS]: VideoTitleContainsFilter;
  [FilterType.TRANSCRIPT__CONTAINS]: TranscriptContainsFilter;
  [FilterType.CONTENT_MODERATION__EQUALS]: ContentModerationFilter;
  [FilterType.VIDEO_DURATION__EQUALS]: VideoDurationFilter;
  [FilterType.SENTIMENT__EQUALS]: SentimentFilter;
  [FilterType.NESTED_SEGMENT_ID__EQUALS]: NestedSegmentFilter;
  [FilterType.TEXT_IN_VIDEO__CONTAINS]: TextInVideoContainsFilter;
  [FilterType.METADATA__CONTAINS]: MetadataContainsFilter;
  [FilterType.MIN_OCCURRENCE__COUNT]: MinOccurrenceCountFilter;

  [FilterType.RUN_DIFFERENTIAL__COMPARE]: GenericSportsMetricFilter;
  [FilterType.INNING__COMPARE]: GenericSportsMetricFilter;
  [FilterType.EVENT_LEVERAGE_INDEX__COMPARE]: GenericSportsMetricFilter;
  [FilterType.EVENT_RUNS_SCORED__COMPARE]: GenericSportsMetricFilter;

  [FilterType.CLOSE_GAME__EQUALS]: SportsDimensionBooleanWithToleranceFilter;
  [FilterType.DELAYED_GAME__EQUALS]: SportsDimensionBooleanFilter;
  [FilterType.DOUBLE_HEADER__EQUALS]: SportsDimensionBooleanFilter;
  [FilterType.NIGHT_GAME__EQUALS]: SportsDimensionBooleanFilter;

  [FilterType.VENUE__EQUALS]: SportsDimensionStringFilter;
  [FilterType.PRE_ALL_STAR__EQUALS]: SportsDimensionStringFilter;
  [FilterType.POST_ALL_STAR__EQUALS]: SportsDimensionStringFilter;
  [FilterType.SEASON_TYPE__EQUALS]: SportsDimensionStringFilter;
  [FilterType.HOME_TEAM__EQUALS]: SportsDimensionStringFilter;
  [FilterType.AWAY_TEAM__EQUALS]: SportsDimensionStringFilter;
  [FilterType.DIVISION__EQUALS]: SportsDimensionStringFilter;
  [FilterType.LEAGUE__EQUALS]: SportsDimensionStringFilter;
  [FilterType.PLAYER__EQUALS]: GenericSportsDimensionFilter;
  [FilterType.WINNING_TEAM__EQUALS]: SportsDimensionStringFilter;

  [FilterType.EXTRA_INNINGS__EQUALS]: GenericSportsDimensionFilter;
  [FilterType.SERIES_TYPE__EQUALS]: SportsDimensionStringFilter;
  [FilterType.INNING_HALF__EQUALS]: GenericSportsDimensionFilter;
  [FilterType.EVENT_TYPE__EQUALS]: GenericSportsDimensionFilter;
  [FilterType.EVENT_DESCRIPTION__CONTAINS]: GenericSportsDimensionFilter;
  [FilterType.LINEUP_CHANGE__EQUALS]: SportsDimensionBooleanWithToleranceFilter;
  [FilterType.STOLEN_BASE__EQUALS]: GenericSportsDimensionFilter;
};

type LocationAwareTag<T> = {
  [K in keyof T]: { location: Location } & T[K];
};

type IndexedTag<T> = {
  [K in keyof T]: { id: string } & T[K];
};

type FilterTable = typing.TagWithKey<
  "type",
  {
    [K in keyof FilterPayloadTable]: {
      filter: FilterPayloadTable[K] | null;
    };
  }
>;

export type Filter = typing.Unionise<FilterTable>;
export type Filters = Filter[];

export type LocationAwareFilter = typing.Unionise<
  IndexedTag<LocationAwareTag<FilterTable>>
>;
export type LocationAwareFilters = LocationAwareFilter[];

export type IndexedFilter = typing.Unionise<IndexedTag<FilterTable>>;
export type IndexedFilters = IndexedFilter[];

export interface FiltersProviderProps {
  query?: Query;
  segmentTitle?: string;
  segmentDescription?: string;
  version?: number;
  children?: ReactNode;
}

export enum LabelAction {
  Checked,
  UnChecked,
}

const labelReducer = (
  state: Array<OccupiedLabel>,
  action: LabelActionContext
): Array<OccupiedLabel> => {
  switch (action.checkedStatus) {
    case LabelAction.Checked: {
      const occupiedLabels: Array<OccupiedLabel> = [
        ...state,
        {
          value: action.value,
          ownerId: action.ownerId,
          parentKey: action.parentKey,
        },
      ];

      if (!action.parentKey) {
        ContentModerationLabels.forEach((topLevel) => {
          if (ConvertToUpperSnakeCase(topLevel.value) === action.value) {
            topLevel.secondLevelCategories?.forEach((secondLevelCategory) => {
              !state.some(
                (label) =>
                  label.value ===
                  ConvertToUpperSnakeCase(secondLevelCategory.value)
              ) &&
                occupiedLabels.push({
                  value: ConvertToUpperSnakeCase(secondLevelCategory.value),
                  ownerId: action.ownerId,
                  parentKey: action.value,
                });
            });
          }
        });
      }
      return occupiedLabels;
    }
    case LabelAction.UnChecked: {
      let occupiedLabels: Array<OccupiedLabel> = state;
      if (!action.parentKey) {
        occupiedLabels = occupiedLabels.filter(
          (label) => label.ownerId !== action.ownerId
        );
      }
      occupiedLabels = occupiedLabels.filter(
        (label) => label.value !== action.value
      );
      return occupiedLabels;
    }
    default:
      return state;
  }
};

export const FiltersProvider = ({
  query: defaultQuery,
  segmentTitle: defaultSegmentTitle,
  segmentDescription: defaultSegmentDescription,
  version,
  children,
}: FiltersProviderProps) => {
  const [state, dispatch] = useReducer(labelReducer, []);
  const [anyOfTheFollowing, setAnyOfTheFollowing] = useState<IndexedFilters>(
    []
  );
  const [allOfTheFollowing, setAllOfTheFollowing] = useState<IndexedFilters>(
    []
  );
  const [excludes, setExcludes] = useState<IndexedFilters>([]);
  const [query, setQuery] = useState<Query>();
  const [segmentTitle, setSegmentTitle] = useState<string>(
    defaultSegmentTitle || ""
  );
  const [segmentDescription, setSegmentDescription] = useState<string>(
    defaultSegmentDescription || ""
  );
  const [segmentId, setSegmentId] = useState<string>("");
  const [conditionsState, setConditionsState] = useState<LocationAwareFilters>(
    () => (defaultQuery ? conditionsToFilter(defaultQuery, dispatch) : [])
  );

  useEffect(() => {
    setAnyOfTheFollowing([
      ...conditionsState.filter(({ location }) => location === Location.ANY),
    ]);
    setAllOfTheFollowing([
      ...conditionsState.filter(({ location }) => location === Location.ALL),
    ]);
    setExcludes([
      ...conditionsState.filter(
        ({ location }) => location === Location.EXCLUDE
      ),
    ]);
  }, [conditionsState]);

  const hasCondition = useCallback(
    (filterType: FilterType) => {
      const byType = ({ type }: IndexedFilter) => type === filterType;

      return {
        anyOfTheFollowing: !!anyOfTheFollowing.filter(byType).length,
        allOfTheFollowing: !!allOfTheFollowing.filter(byType).length,
        excludes: !!excludes.filter(byType).length,
      };
    },
    [anyOfTheFollowing, allOfTheFollowing, excludes]
  );

  const append = useCallback(
    (location: Location, type: FilterType, filter: any = null) =>
      setConditionsState((previous) => [
        ...previous,
        {
          id: _.uniqueId("segmentation-filter-"),
          location,
          type,
          filter,
        } as LocationAwareFilter,
      ]),
    [setConditionsState]
  );

  const insertBetween = useCallback(
    (
      location: Location,
      type: FilterType,
      id: string,
      above: boolean,
      filter?: any
    ) =>
      setConditionsState((previous) => {
        const update = [...previous];
        const idx = update.findIndex((element) => element.id === id);
        const newLocation = {
          id: _.uniqueId("segmentation-filter-"),
          location,
          type,
          filter: filter || null,
        } as LocationAwareFilter;
        update.splice(above ? idx : idx + 1, 0, newLocation);
        return update;
      }),
    [setConditionsState]
  );

  const moveBetween = useCallback(
    (location: Location, movedId: string, movingId: string, above: boolean) =>
      setConditionsState((previous) => {
        if (movedId === movingId) {
          return previous;
        }
        const update = [...previous];
        const idx = update.findIndex((element) => element.id === movedId);

        const [movingItem] = update.splice(idx, 1);
        movingItem.location = location;
        const movingIdx = update.findIndex(
          (element) => element.id === movingId
        );
        update.splice(above ? movingIdx : movingIdx + 1, 0, movingItem);

        return update;
      }),
    [setConditionsState]
  );

  const move = useCallback(
    (location: Location, id: string) =>
      setConditionsState((previous) => {
        const update = [...previous];
        const idx = update.findIndex((element) => element.id === id);
        update[idx] = { ...update[idx], location };
        update.push(update.splice(idx, 1)[0]);
        return update;
      }),
    [setConditionsState]
  );

  const update = useCallback(
    (id: string, value: any) =>
      setConditionsState((previous) => {
        const update = [...previous];
        const idx = update.findIndex((element) => element.id === id);
        update[idx] = { ...update[idx], filter: value };
        return update;
      }),
    [setConditionsState]
  );

  const remove = useCallback(
    (id: string) =>
      setConditionsState((previous) => {
        const update = [...previous];
        const idx = update.findIndex((element) => element.id === id);
        update.splice(idx, 1);
        return update;
      }),
    [setConditionsState]
  );

  useEffect(() => {
    const allOfTheFollowingQuery = filterToQueryConditions(allOfTheFollowing);
    const anyOfTheFollowingQuery = filterToQueryConditions(anyOfTheFollowing);
    const excludesQuery = filterToQueryConditions(excludes);

    let query: Query = {};

    if (allOfTheFollowingQuery.length) {
      query.allOfTheFollowing = allOfTheFollowingQuery;
    }

    if (anyOfTheFollowingQuery.length) {
      query.anyOfTheFollowing = anyOfTheFollowingQuery;
    }

    if (excludesQuery.length) {
      query.exclude = excludesQuery;
    }

    setQuery(query);
  }, [allOfTheFollowing, anyOfTheFollowing, excludes]);

  return (
    <FilterContext.Provider
      value={{
        anyOfTheFollowing,
        allOfTheFollowing,
        contentModerationCtx: { state, dispatch },
        excludes,
        length: conditionsState.length,
        hasCondition,
        query,
        remove,
        append,
        insertBetween,
        moveBetween,
        move,
        update,
        version,

        segmentTitle,
        setSegmentTitle,
        segmentId,
        setSegmentId,
        segmentDescription,
        setSegmentDescription,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilters = (): FilterContextProps => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error(
      "useFilters should be used inside a FiltersProvider component"
    );
  }

  return context;
};

const filterToConfidenceCondition = (filter: { confidence?: number }) =>
  filter.confidence ? { confidence: { gte: filter.confidence } } : {};
const filterToWithinCondition = (filter: { within?: number }) =>
  filter.within
    ? {
        tolerance: {
          lead: Math.floor(filter.within / 2),
          lag: Math.floor(filter.within / 2),
        },
      }
    : {
        tolerance: {
          lead: 30000,
          lag: 30000,
        },
      };

export const contentModerationEqualsQueryToState = (
  ownerId: string,
  values: ContentModerationEqualsVariables["values"],
  contentModerationCtxDispatch: React.Dispatch<LabelActionContext>
) => {
  values.forEach((value) => {
    const topLevelLabel = ContentModerationLabels.find(
      (label) => ConvertToUpperSnakeCase(label.value) === value
    );

    if (topLevelLabel) {
      contentModerationCtxDispatch({
        value,
        parentKey: undefined,
        checkedStatus: LabelAction.Checked,
        ownerId,
      });
    } else {
      ContentModerationLabels.forEach((label) => {
        const secondLevelLabel = label.secondLevelCategories?.find(
          (secondLevelLabel) =>
            ConvertToUpperSnakeCase(secondLevelLabel.value) === value
        );

        if (secondLevelLabel) {
          contentModerationCtxDispatch({
            value,
            parentKey: ConvertToUpperSnakeCase(secondLevelLabel.parentKey),
            checkedStatus: LabelAction.Checked,
            ownerId,
          });
        }
      });
    }
  });
};

const createFilter = (
  filter: Filter,
  location: Location,
  contentModerationCtxDispatch: React.Dispatch<LabelActionContext>
) => {
  const id = _.uniqueId("segmentation-filter-");

  if (
    filter.type === FilterType.CONTENT_MODERATION__EQUALS &&
    filter.filter?.values
  ) {
    contentModerationEqualsQueryToState(
      id,
      filter.filter.values,
      contentModerationCtxDispatch
    );
  }

  return {
    ...filter,
    id,
    location,
  };
};

const conditionsToFilter = (
  query: Query,
  contentModerationCtxDispatch: React.Dispatch<LabelActionContext>
): LocationAwareFilters => [
  ...(query.anyOfTheFollowing
    ? queryConditionsToVariables(query.anyOfTheFollowing)
    : []
  ).map((v) => createFilter(v, Location.ANY, contentModerationCtxDispatch)),
  ...(query.allOfTheFollowing
    ? queryConditionsToVariables(query.allOfTheFollowing)
    : []
  ).map((v) => createFilter(v, Location.ALL, contentModerationCtxDispatch)),
  ...(query.exclude ? queryConditionsToVariables(query.exclude) : []).map((v) =>
    createFilter(v, Location.EXCLUDE, contentModerationCtxDispatch)
  ),
];

const queryConditionsToVariables = (v: QueryCondition[]): Filters => {
  return v
    .flatMap((v: QueryCondition) => {
      let f: Filter | Filters | null = null;

      if (QueryConditionType.MIN_OCCURRENCE__COUNT in v) {
        const condition = v[QueryConditionType.MIN_OCCURRENCE__COUNT];
        f = [
          {
            type: FilterType.MIN_OCCURRENCE__COUNT,
            filter: {
              value: condition?.minCount || 2,
              within:
                (condition?.tolerance?.lag || 0) +
                  (condition?.tolerance?.lead || 0) || undefined,
            },
          },
          ...queryConditionsToVariables(condition.conditions),
        ];
      } else if (QueryConditionType.LABEL__EQUALS in v) {
        const condition = v[QueryConditionType.LABEL__EQUALS];
        f = {
          type: FilterType.LABEL__CONTAINS,
          filter: {
            value: condition?.value,
            confidence: condition?.confidence?.gte,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
          },
        };
      } else if (QueryConditionType.CELEBRITY__EQUALS in v) {
        const condition = v[QueryConditionType.CELEBRITY__EQUALS];
        f = {
          type: FilterType.CELEBRITY__EQUALS,
          filter: {
            value: condition?.value,
            confidence: condition?.confidence?.gte,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
          },
        };
      } else if (QueryConditionType.TRANSCRIPT__CONTAINS in v) {
        const condition = v[QueryConditionType.TRANSCRIPT__CONTAINS];
        f = {
          type: FilterType.TRANSCRIPT__CONTAINS,
          filter: {
            value: condition?.value,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
          },
        };
      } else if (QueryConditionType.SENTIMENT__EQUALS in v) {
        const condition = v[QueryConditionType.SENTIMENT__EQUALS];

        f = {
          type: FilterType.SENTIMENT__EQUALS,
          filter: {
            value: condition.value,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
          },
        };
      } else if (QueryConditionType.VIDEO_DURATION__EQUALS in v) {
        const condition = v[QueryConditionType.VIDEO_DURATION__EQUALS];

        f = {
          type: FilterType.VIDEO_DURATION__EQUALS,
          filter: {
            minDuration: condition.minDuration,
            maxDuration: condition.maxDuration,
          },
        };
      } else if (QueryConditionType.VIDEO_TITLE__CONTAINS in v) {
        const condition = v[QueryConditionType.VIDEO_TITLE__CONTAINS];

        f = {
          type: FilterType.VIDEO_TITLE__CONTAINS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.METADATA__CONTAINS in v) {
        const condition = v[QueryConditionType.METADATA__CONTAINS];

        f = {
          type: FilterType.METADATA__CONTAINS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.CONTENT_MODERATION__EQUALS in v) {
        const condition = v[QueryConditionType.CONTENT_MODERATION__EQUALS];

        f = {
          type: FilterType.CONTENT_MODERATION__EQUALS,
          filter: {
            values: condition?.values,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
          },
        };
      } else if (QueryConditionType.NESTED_SEGMENT_ID__EQUALS in v) {
        const condition = v[QueryConditionType.NESTED_SEGMENT_ID__EQUALS];

        f = {
          type: FilterType.NESTED_SEGMENT_ID__EQUALS,
          filter: {
            value: condition?.value,
            version: condition?.version,
          },
        };
      } else if (QueryConditionType.TEXT_IN_VIDEO__CONTAINS in v) {
        const condition = v[QueryConditionType.TEXT_IN_VIDEO__CONTAINS];

        f = {
          type: FilterType.TEXT_IN_VIDEO__CONTAINS,
          filter: {
            value: condition?.value,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
          },
        };
      } else if (QueryConditionType.RUN_DIFFERENTIAL__COMPARE in v) {
        const condition = v[QueryConditionType.RUN_DIFFERENTIAL__COMPARE];

        f = {
          type: FilterType.RUN_DIFFERENTIAL__COMPARE,
          filter: {
            value: condition?.value,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
            comparator: condition?.comparator,
          },
        };
      } else if (QueryConditionType.INNING__COMPARE in v) {
        const condition = v[QueryConditionType.INNING__COMPARE];

        f = {
          type: FilterType.INNING__COMPARE,
          filter: {
            value: condition?.value,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
            comparator: condition?.comparator,
          },
        };
      } else if (QueryConditionType.EVENT_LEVERAGE_INDEX__COMPARE in v) {
        const condition = v[QueryConditionType.EVENT_LEVERAGE_INDEX__COMPARE];

        f = {
          type: FilterType.EVENT_LEVERAGE_INDEX__COMPARE,
          filter: {
            value: condition?.value,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
            comparator: condition?.comparator,
          },
        };
      } else if (QueryConditionType.EVENT_RUNS_SCORED__COMPARE in v) {
        const condition = v[QueryConditionType.EVENT_RUNS_SCORED__COMPARE];

        f = {
          type: FilterType.EVENT_RUNS_SCORED__COMPARE,
          filter: {
            value: condition?.value,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
            comparator: condition?.comparator,
          },
        };
      } else if (QueryConditionType.CLOSE_GAME__EQUALS in v) {
        const condition = v[QueryConditionType.CLOSE_GAME__EQUALS];

        f = {
          type: FilterType.CLOSE_GAME__EQUALS,
          filter: {
            value: condition?.value || false,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
          },
        };
      } else if (QueryConditionType.DELAYED_GAME__EQUALS in v) {
        const condition = v[QueryConditionType.DELAYED_GAME__EQUALS];

        f = {
          type: FilterType.DELAYED_GAME__EQUALS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.DOUBLE_HEADER__EQUALS in v) {
        const condition = v[QueryConditionType.DOUBLE_HEADER__EQUALS];

        f = {
          type: FilterType.DOUBLE_HEADER__EQUALS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.NIGHT_GAME__EQUALS in v) {
        const condition = v[QueryConditionType.NIGHT_GAME__EQUALS];

        f = {
          type: FilterType.NIGHT_GAME__EQUALS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.VENUE__EQUALS in v) {
        const condition = v[QueryConditionType.VENUE__EQUALS];

        f = {
          type: FilterType.VENUE__EQUALS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.PRE_ALL_STAR__EQUALS in v) {
        const condition = v[QueryConditionType.PRE_ALL_STAR__EQUALS];

        f = {
          type: FilterType.PRE_ALL_STAR__EQUALS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.POST_ALL_STAR__EQUALS in v) {
        const condition = v[QueryConditionType.POST_ALL_STAR__EQUALS];

        f = {
          type: FilterType.POST_ALL_STAR__EQUALS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.SEASON_TYPE__EQUALS in v) {
        const condition = v[QueryConditionType.SEASON_TYPE__EQUALS];

        f = {
          type: FilterType.SEASON_TYPE__EQUALS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.HOME_TEAM__EQUALS in v) {
        const condition = v[QueryConditionType.HOME_TEAM__EQUALS];

        f = {
          type: FilterType.HOME_TEAM__EQUALS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.AWAY_TEAM__EQUALS in v) {
        const condition = v[QueryConditionType.AWAY_TEAM__EQUALS];

        f = {
          type: FilterType.AWAY_TEAM__EQUALS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.DIVISION__EQUALS in v) {
        const condition = v[QueryConditionType.DIVISION__EQUALS];

        f = {
          type: FilterType.DIVISION__EQUALS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.LEAGUE__EQUALS in v) {
        const condition = v[QueryConditionType.LEAGUE__EQUALS];

        f = {
          type: FilterType.LEAGUE__EQUALS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.PLAYER__EQUALS in v) {
        const condition = v[QueryConditionType.PLAYER__EQUALS];

        f = {
          type: FilterType.PLAYER__EQUALS,
          filter: {
            value: condition?.value,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
          },
        };
      } else if (QueryConditionType.WINNING_TEAM__EQUALS in v) {
        const condition = v[QueryConditionType.WINNING_TEAM__EQUALS];

        f = {
          type: FilterType.WINNING_TEAM__EQUALS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.EXTRA_INNINGS__EQUALS in v) {
        const condition = v[QueryConditionType.EXTRA_INNINGS__EQUALS];

        f = {
          type: FilterType.EXTRA_INNINGS__EQUALS,
          filter: {
            value: condition?.value,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
          },
        };
      } else if (QueryConditionType.SERIES_TYPE__EQUALS in v) {
        const condition = v[QueryConditionType.SERIES_TYPE__EQUALS];

        f = {
          type: FilterType.SERIES_TYPE__EQUALS,
          filter: {
            value: condition?.value,
          },
        };
      } else if (QueryConditionType.INNING_HALF__EQUALS in v) {
        const condition = v[QueryConditionType.INNING_HALF__EQUALS];

        f = {
          type: FilterType.INNING_HALF__EQUALS,
          filter: {
            value: condition?.value,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
          },
        };
      } else if (QueryConditionType.EVENT_TYPE__EQUALS in v) {
        const condition = v[QueryConditionType.EVENT_TYPE__EQUALS];

        f = {
          type: FilterType.EVENT_TYPE__EQUALS,
          filter: {
            value: condition?.value,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
          },
        };
      } else if (QueryConditionType.EVENT_DESCRIPTION__CONTAINS in v) {
        const condition = v[QueryConditionType.EVENT_DESCRIPTION__CONTAINS];

        f = {
          type: FilterType.EVENT_DESCRIPTION__CONTAINS,
          filter: {
            value: condition?.value,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
          },
        };
      } else if (QueryConditionType.LINEUP_CHANGE__EQUALS in v) {
        const condition = v[QueryConditionType.LINEUP_CHANGE__EQUALS];

        f = {
          type: FilterType.LINEUP_CHANGE__EQUALS,
          filter: {
            value: condition?.value || false,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
          },
        };
      } else if (QueryConditionType.STOLEN_BASE__EQUALS in v) {
        const condition = v[QueryConditionType.STOLEN_BASE__EQUALS];

        f = {
          type: FilterType.STOLEN_BASE__EQUALS,
          filter: {
            value: condition?.value,
            within:
              (condition?.tolerance?.lag || 0) +
                (condition?.tolerance?.lead || 0) || undefined,
          },
        };
      }
      return f;
    })
    .filter(typing.notEmpty);
};

const filterToCelebrityEqualsCondition = (
  filter: CelebrityEqualsFilter
): CelebrityEqualsVariables => {
  return {
    value: toUpper(snakeCase(filter?.value)),
    ...filterToConfidenceCondition(filter),
    ...filterToWithinCondition(filter),
  };
};

const filterToLabelEqualsCondition = (
  filter: LabelFilter
): LabelEqualsVariables => {
  return {
    value: toUpper(snakeCase(filter?.value)),
    ...filterToConfidenceCondition(filter),
    ...filterToWithinCondition(filter),
  };
};

const filterToTextInVideoContainsCondition = (
  filter: TextInVideoContainsFilter
): TextInVideoContainsVariables => {
  return {
    value: filter?.value || "",
    ...filterToWithinCondition(filter),
  };
};

const filterToTranscriptContainsCondition = (
  filter: TranscriptContainsFilter
): TranscriptContainsVariables => {
  return {
    value: filter?.value || "",
    ...filterToWithinCondition(filter),
  };
};

const filterToSentimentCondition = (
  filter: SentimentFilter
): SentimentEqualsVariables => {
  return {
    value: toUpper(snakeCase(filter?.value)),
    ...filterToWithinCondition(filter),
  };
};

const filterToVideoTitleContainsCondition = (
  filter: VideoTitleContainsFilter
): VideoTitleContainsVariables => {
  return {
    value: filter?.value || "",
  };
};

const filterToMetadataContainsCondition = (
  filter: MetadataContainsFilter
): MetadataContainsVariables => {
  return {
    value: filter?.value || "",
  };
};

const filterToContentModerationEquals = (
  filter: ContentModerationFilter
): ContentModerationEqualsVariables => {
  return {
    values: filter?.values ? filter?.values : [],
    ...filterToWithinCondition(filter),
  };
};

const filterToNestedSegmentIdEquals = (
  filter: NestedSegmentFilter
): NestedSegmentIdEqualsVariables => {
  return {
    value: filter?.value || "",
    version: filter?.version || 1,
  };
};

const filterToVideoDurationEqualsCondition = (
  filter: VideoDurationFilter
): VideoDurationEqualsVariables => {
  return {
    minDuration: Math.floor(filter?.minDuration ? filter.minDuration : 0),
    maxDuration: Math.floor(filter?.maxDuration ? filter.maxDuration : 0),
  };
};

const filterToSportsMetricCondition = (
  filter: GenericSportsMetricFilter
): GenericSportsMetricVariables => ({
  value: filter?.value || 0,
  comparator: filter?.comparator || "",
  ...filterToWithinCondition(filter),
});

const filterToSportsDimensionCondition = (
  filter: GenericSportsDimensionFilter
): GenericSportsDimensionVariables => ({
  value: filter?.value || "",
  ...filterToWithinCondition(filter),
});

const filterToBooleanCondition = (
  filter: SportsDimensionBooleanFilter
): SportsBooleanDimensionVariables => ({
  value: filter?.value || false,
});

const filterToStringCondition = (
  filter: GenericSportsDimensionFilter
): SportsStringDimensionVariables => ({
  value: filter?.value || "",
});

const filterToBooleanWithToleranceCondition = (
  filter: SportsDimensionBooleanWithToleranceFilter
): SportsBooleanWithToleranceVariables => {
  return {
    value: filter?.value || false,
    ...filterToWithinCondition(filter),
  };
};

const filterToMinOccurenceCountCondition = (
  filter: MinOccurrenceCountFilter
): MinOccurrenceCountVariables => {
  return {
    conditions: [],
    minCount: filter?.value || 2,
    ...filterToWithinCondition(filter),
  };
};

const filterToQueryConditions = (
  conditions: IndexedFilters,
  queryConditions: QueryCondition[] = []
): QueryCondition[] => {
  // @NOTICE investigate why we need a copy here...
  const conditionsCopy = conditions.concat([]);
  if (!conditionsCopy.length) {
    return queryConditions;
  }

  const currentCondition = conditionsCopy.shift();
  if (!currentCondition) {
    return filterToQueryConditions(conditions, queryConditions);
  }

  const queryCondition = mapFilterToQueryCondition(currentCondition);
  if (
    queryCondition &&
    currentCondition.type === FilterType.MIN_OCCURRENCE__COUNT
  ) {
    // @ts-ignore
    queryCondition[QueryConditionType.MIN_OCCURRENCE__COUNT].conditions =
      filterToQueryConditions(conditionsCopy, []);

    queryConditions.push(queryCondition);
    return queryConditions;
  }

  if (typing.notEmpty(queryCondition)) {
    queryConditions.push(queryCondition);
  }

  return filterToQueryConditions(conditionsCopy, queryConditions);
};

const mapFilterToQueryCondition = ({
  filter,
  type,
}: IndexedFilter): QueryCondition | null => {
  switch (type) {
    case FilterType.MIN_OCCURRENCE__COUNT:
      if (filter?.value) {
        return {
          MIN_OCCURRENCE__COUNT: filterToMinOccurenceCountCondition(filter),
        };
      }
      break;
    case FilterType.CELEBRITY__EQUALS:
      if (filter?.value) {
        return {
          CELEBRITY__EQUALS: filterToCelebrityEqualsCondition(filter),
        };
      }
      break;
    case FilterType.LABEL__CONTAINS:
      if (filter?.value) {
        return {
          LABEL__EQUALS: filterToLabelEqualsCondition(filter),
        };
      }
      break;
    case FilterType.TRANSCRIPT__CONTAINS:
      if (filter?.value) {
        return {
          TRANSCRIPT__CONTAINS: filterToTranscriptContainsCondition(filter),
        };
      }
      break;
    case FilterType.SENTIMENT__EQUALS:
      if (filter?.value) {
        return {
          SENTIMENT__EQUALS: filterToSentimentCondition(filter),
        };
      }
      break;
    case FilterType.VIDEO_DURATION__EQUALS:
      if (filter?.minDuration || filter?.maxDuration) {
        return {
          VIDEO_DURATION__EQUALS: filterToVideoDurationEqualsCondition(filter),
        };
      }
      break;
    case FilterType.VIDEO_TITLE__CONTAINS:
      if (filter?.value) {
        return {
          VIDEO_TITLE__CONTAINS: filterToVideoTitleContainsCondition(filter),
        };
      }
      break;
    case FilterType.CONTENT_MODERATION__EQUALS:
      if (filter?.values) {
        return {
          CONTENT_MODERATION__EQUALS: filterToContentModerationEquals(filter),
        };
      }
      break;
    case FilterType.NESTED_SEGMENT_ID__EQUALS:
      if (filter?.value) {
        return {
          NESTED_SEGMENT_ID__EQUALS: filterToNestedSegmentIdEquals(filter),
        };
      }
      break;
    case FilterType.TEXT_IN_VIDEO__CONTAINS:
      if (filter?.value) {
        return {
          TEXT_IN_VIDEO__CONTAINS: filterToTextInVideoContainsCondition(filter),
        };
      }
      break;

    case FilterType.METADATA__CONTAINS:
      if (filter?.value) {
        return {
          METADATA__CONTAINS: filterToMetadataContainsCondition(filter),
        };
      }
      break;
    case FilterType.RUN_DIFFERENTIAL__COMPARE:
      if (!isNull(filter)) {
        return {
          RUN_DIFFERENTIAL__COMPARE: filterToSportsMetricCondition(filter),
        };
      }
      break;
    case FilterType.INNING__COMPARE:
      if (!isNull(filter)) {
        return {
          INNING__COMPARE: filterToSportsMetricCondition(filter),
        };
      }
      break;
    case FilterType.EVENT_RUNS_SCORED__COMPARE:
      if (!isNull(filter)) {
        return {
          EVENT_RUNS_SCORED__COMPARE: filterToSportsMetricCondition(filter),
        };
      }
      break;
    case FilterType.EVENT_LEVERAGE_INDEX__COMPARE:
      if (!isNull(filter)) {
        return {
          EVENT_LEVERAGE_INDEX__COMPARE: filterToSportsMetricCondition(filter),
        };
      }
      break;
    case FilterType.CLOSE_GAME__EQUALS:
      if (!isNull(filter)) {
        return {
          CLOSE_GAME__EQUALS: filterToBooleanWithToleranceCondition(filter),
        };
      }
      break;
    case FilterType.DELAYED_GAME__EQUALS:
      if (!isNull(filter)) {
        return {
          DELAYED_GAME__EQUALS: filterToBooleanCondition(filter),
        };
      }
      break;
    case FilterType.DOUBLE_HEADER__EQUALS:
      if (!isNull(filter)) {
        return {
          DOUBLE_HEADER__EQUALS: filterToBooleanCondition(filter),
        };
      }
      break;
    case FilterType.NIGHT_GAME__EQUALS:
      if (!isNull(filter)) {
        return {
          NIGHT_GAME__EQUALS: filterToBooleanCondition(filter),
        };
      }
      break;
    case FilterType.VENUE__EQUALS:
      if (filter?.value) {
        return {
          VENUE__EQUALS: filterToStringCondition(filter),
        };
      }
      break;
    case FilterType.PRE_ALL_STAR__EQUALS:
      if (filter?.value) {
        return {
          PRE_ALL_STAR__EQUALS: filterToStringCondition(filter),
        };
      }
      break;
    case FilterType.POST_ALL_STAR__EQUALS:
      if (filter?.value) {
        return {
          POST_ALL_STAR__EQUALS: filterToStringCondition(filter),
        };
      }
      break;
    case FilterType.SEASON_TYPE__EQUALS:
      if (filter?.value) {
        return {
          SEASON_TYPE__EQUALS: filterToStringCondition(filter),
        };
      }
      break;
    case FilterType.HOME_TEAM__EQUALS:
      if (filter?.value) {
        return {
          HOME_TEAM__EQUALS: filterToStringCondition(filter),
        };
      }
      break;
    case FilterType.AWAY_TEAM__EQUALS:
      if (filter?.value) {
        return {
          AWAY_TEAM__EQUALS: filterToStringCondition(filter),
        };
      }
      break;
    case FilterType.DIVISION__EQUALS:
      if (filter?.value) {
        return {
          DIVISION__EQUALS: filterToStringCondition(filter),
        };
      }
      break;
    case FilterType.LEAGUE__EQUALS:
      if (filter?.value) {
        return {
          LEAGUE__EQUALS: filterToStringCondition(filter),
        };
      }
      break;
    case FilterType.PLAYER__EQUALS:
      if (filter?.value) {
        return {
          PLAYER__EQUALS: filterToSportsDimensionCondition(filter),
        };
      }
      break;
    case FilterType.WINNING_TEAM__EQUALS:
      if (filter?.value) {
        return {
          WINNING_TEAM__EQUALS: filterToStringCondition(filter),
        };
      }
      break;
    case FilterType.EXTRA_INNINGS__EQUALS:
      if (filter?.value) {
        return {
          EXTRA_INNINGS__EQUALS: filterToSportsDimensionCondition(filter),
        };
      }
      break;
    case FilterType.SERIES_TYPE__EQUALS:
      if (filter?.value) {
        return {
          SERIES_TYPE__EQUALS: filterToStringCondition(filter),
        };
      }
      break;
    case FilterType.INNING_HALF__EQUALS:
      if (filter?.value) {
        return {
          INNING_HALF__EQUALS: filterToSportsDimensionCondition(filter),
        };
      }
      break;
    case FilterType.EVENT_TYPE__EQUALS:
      if (filter?.value) {
        return {
          EVENT_TYPE__EQUALS: filterToSportsDimensionCondition(filter),
        };
      }
      break;
    case FilterType.EVENT_DESCRIPTION__CONTAINS:
      if (filter?.value) {
        return {
          EVENT_DESCRIPTION__CONTAINS: filterToSportsDimensionCondition(filter),
        };
      }
      break;
    case FilterType.LINEUP_CHANGE__EQUALS:
      if (!isNull(filter)) {
        return {
          LINEUP_CHANGE__EQUALS: filterToBooleanWithToleranceCondition(filter),
        };
      }
      break;
    case FilterType.STOLEN_BASE__EQUALS:
      if (filter?.value) {
        return {
          STOLEN_BASE__EQUALS: filterToSportsDimensionCondition(filter),
        };
      }
      break;
    default:
      const _exhaustiveCheck: null = null;
      return _exhaustiveCheck;
  }
  return null;
};
