import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Double = createSvgIcon({
  content: (
    <>
      <path
        d="M4.6665 3.33333C4.6665 2.97971 4.80698 2.64057 5.05703 2.39052C5.30708 2.14048 5.64622 2 5.99984 2H12.6665C13.0201 2 13.3593 2.14048 13.6093 2.39052C13.8594 2.64057 13.9998 2.97971 13.9998 3.33333V10C13.9998 10.3536 13.8594 10.6928 13.6093 10.9428C13.3593 11.1929 13.0201 11.3333 12.6665 11.3333H5.99984C5.64622 11.3333 5.30708 11.1929 5.05703 10.9428C4.80698 10.6928 4.6665 10.3536 4.6665 10V3.33333Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3333 11.3334V12.6667C11.3333 13.0203 11.1929 13.3594 10.9428 13.6095C10.6928 13.8595 10.3536 14 10 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V6.00002C2 5.6464 2.14048 5.30726 2.39052 5.05721C2.64057 4.80716 2.97971 4.66669 3.33333 4.66669H4.66667"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 5.33333C8 4.97971 8.14048 4.64057 8.39052 4.39052C8.64057 4.14048 8.97971 4 9.33333 4C9.68696 4 10.0261 4.14048 10.2761 4.39052C10.5262 4.64057 10.6667 4.97971 10.6667 5.33333C10.6667 5.72733 10.3887 6.212 10.1227 6.572L8 9.334H10.6667"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
});
