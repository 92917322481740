import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Running = createSvgIcon({
  content: (
    <>
      <path
        d="M8 2.66667C8 2.84348 8.07024 3.01305 8.19526 3.13807C8.32029 3.2631 8.48986 3.33333 8.66667 3.33333C8.84348 3.33333 9.01305 3.2631 9.13807 3.13807C9.2631 3.01305 9.33333 2.84348 9.33333 2.66667C9.33333 2.48986 9.2631 2.32029 9.13807 2.19526C9.01305 2.07024 8.84348 2 8.66667 2C8.48986 2 8.32029 2.07024 8.19526 2.19526C8.07024 2.32029 8 2.48986 8 2.66667Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.6665 11.3333L5.99984 12L6.49984 11"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0002 14V11.3333L7.3335 9.33333L8.00016 5.33333"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.6665 7.99999V5.99999L7.99984 5.33333L9.99984 7.33333L11.9998 7.99999"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
  defaultColor: "navy-100",
});
