export enum FilterType {
  TITLE__LIKE = "TITLE__LIKE",
  LABEL__CONTAINS = "LABEL__CONTAINS",
  CELEBRITY__EQUALS = "CELEBRITY__EQUALS",
  CELEBRITY__LIKE = "CELEBRITY__LIKE",
  SPOKEN_NOUN__CONTAINS = "SPOKEN_NOUN__CONTAINS",
  DURATION__MIN_MAX = "DURATION__MIN_MAX",
  SENTIMENT__EQUALS = "SENTIMENT__EQUALS",
  CONTENT_MODERATION_CONTAINS = "CONTENT_MODERATION_CONTAINS",
  CONTENT_MODERATION_EXCLUDES = "CONTENT_MODERATION_EXCLUDES",
  BUSINESS_UNIT = "BUSINESS_UNIT",
}

export interface TitleLikeFilter {
  title: string;
  filterType: FilterType.TITLE__LIKE;
}

export interface LabelContainsFilter {
  values: string[];
  filterType: FilterType.LABEL__CONTAINS;
}

export interface CelebrityEqualsFilter {
  values: string[];
  filterType: FilterType.CELEBRITY__EQUALS;
}

export interface CelebrityLikeFilter {
  values: string[];
  filterType: FilterType.CELEBRITY__LIKE;
}

export interface SentimentEqualsFilter {
  values: Array<"POSITIVE" | "NEGATIVE" | "NEUTRAL" | "MIXED">;
  filterType: FilterType.SENTIMENT__EQUALS;
}

export interface SpokenNounContainsFilter {
  values: string[];
  filterType: FilterType.SPOKEN_NOUN__CONTAINS;
}

export interface DurationFilter {
  min?: number;
  max?: number;
  filterType: FilterType.DURATION__MIN_MAX;
}

export interface ContentModerationContainsFilter {
  filterType: FilterType.CONTENT_MODERATION_CONTAINS;
  values: string[];
}

export interface ContentModerationExcludesFilter {
  filterType: FilterType.CONTENT_MODERATION_EXCLUDES;
  values: string[];
}

export interface BusinessUnitFilter {
  values: string[];
  filterType: FilterType.BUSINESS_UNIT;
}

export type Filter =
  | TitleLikeFilter
  | LabelContainsFilter
  | CelebrityEqualsFilter
  | CelebrityLikeFilter
  | SpokenNounContainsFilter
  | DurationFilter
  | SentimentEqualsFilter
  | ContentModerationContainsFilter
  | ContentModerationExcludesFilter
  | BusinessUnitFilter;
