import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { ChartOptions } from "chart.js";

import { DialogBox, Icon } from "@components/atoms";
import * as truncate from "@utils/truncate";

const options: ChartOptions<"doughnut"> = {
  responsive: false,
  events: [],
  plugins: {
    legend: {
      display: false,
    },
  },
};

export interface ChartBodyProps {
  className?: any;
  totalVideos: number;
  totalOccurrences?: number;
  totalIsolateVideos?: number;
}

export const ChartBody = ({
  totalVideos,
  totalIsolateVideos,
  totalOccurrences,
  ...rest
}: ChartBodyProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <div {...rest}>
      <div className="flex flex-row">
        <div>
          <Doughnut
            height="56px"
            width="56px"
            data={{
              labels: ["active", "inactive"],
              datasets: [
                {
                  data: [
                    totalIsolateVideos === undefined
                      ? totalVideos
                      : totalIsolateVideos,
                    totalIsolateVideos !== undefined
                      ? totalVideos - totalIsolateVideos
                      : 0,
                  ],
                  backgroundColor: ["#454DE2", "#E9EDF5"],
                  borderColor: ["#454DE2", "#E9EDF5"],
                  borderWidth: 1,
                },
              ],
            }}
            options={options}
          />
        </div>
        <div className="ml-4">
          <h2>
            {totalIsolateVideos !== undefined
              ? totalIsolateVideos
                ? truncate.number(totalIsolateVideos)
                : "0"
              : truncate.number(totalVideos)}
          </h2>
          <div>
            Videos (
            {totalIsolateVideos !== undefined
              ? formatToPct(totalIsolateVideos, totalVideos)
              : "100%"}
            )
          </div>
        </div>
      </div>
      {totalOccurrences !== undefined && (
        <div data-testid="occurrences" className="mt-6 flex flex-row">
          <div className="h-14 w-14 flex justify-center shrink-0">
            <Icon.BarChart />
          </div>
          <div className="ml-4">
            <h2>{totalOccurrences ? truncate.number(totalOccurrences) : 0}</h2>
            <div className="flex">
              Occurrences
              <Icon.Info
                className="ml-1 cursor-pointer"
                size={11}
                onClick={() => setIsDialogOpen(true)}
              />
            </div>
          </div>
          <DialogBox.Container
            isDialogOpen={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
          >
            <DialogBox.Card onClose={() => setIsDialogOpen(false)}>
              <DialogBox.Title>Occurences</DialogBox.Title>
              <p className="text-sm">
                Segment conditions may be met multiple times within a single
                video. The occurence count reflects the total number of times a
                Segment’s conditions are met across all videos within the
                Segment.
              </p>
            </DialogBox.Card>
          </DialogBox.Container>
        </div>
      )}
    </div>
  );
};

const formatToPct = (numeriator?: number, denominator?: number) => {
  if (!numeriator || !denominator) {
    return "0%";
  }
  return `${((numeriator / denominator) * 100).toFixed(1)}%`;
};
