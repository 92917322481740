import React, { useEffect } from "react";
import { Listbox } from "@headlessui/react";

import { Option, Options, OptionsTransition } from "@components/atoms";

interface ListboxContentProps {
  open: boolean;
  onFocus: () => void | Promise<void>;
  onFocusLost: () => void | Promise<void>;
  values: Record<string, string>;
  children: string | number;
}

export const ListboxContent = ({
  open,
  onFocus,
  onFocusLost,
  values,
  children,
}: ListboxContentProps) => {
  useEffect(() => {
    if (open) {
      onFocus();
    } else {
      onFocusLost();
    }
  }, [open, onFocus, onFocusLost]);

  const numberOfKeys = Object.keys(values).length;

  return (
    <>
      <Listbox.Button
        style={{ fontWeight: "inherit" }}
        className="w-full min-h-full bg-transparent focus:outline-none text-left relative z-10"
      >
        {children || <>&nbsp;</>}
      </Listbox.Button>
      <OptionsTransition>
        <Options className="mt-3" direction="down" fixedWidth={true}>
          {Object.entries(values).map(([key, value], idx) => (
            <Option
              key={key}
              index={idx}
              value={key}
              lastItem={idx === numberOfKeys - 1}
            >
              {value}
            </Option>
          ))}
        </Options>
      </OptionsTransition>
    </>
  );
};
