import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const LayoutLeft = createSvgIcon({
  content: (
    <>
      <path d="M14 2H2V14H14V2Z" stroke="#303030" stroke-linecap="square" />
      <path
        d="M6 5.33331V14M2 5.33331H14H2Z"
        stroke="#303030"
        stroke-linecap="square"
      />
      <line
        x1="7.3335"
        y1="7.83337"
        x2="12.6668"
        y2="7.83337"
        stroke="#303030"
      />
      <line x1="7.3335" y1="9.5" x2="12.6668" y2="9.5" stroke="#303030" />
      <line
        x1="7.3335"
        y1="11.1666"
        x2="12.6668"
        y2="11.1666"
        stroke="#303030"
      />
    </>
  ),
  width: 16,
  height: 16,
});
