import React, { useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { LazyQueryExecFunction, useLazyQuery, useQuery } from "@apollo/client";

import { Loading, NoContent, SlideOver, Tabs } from "@components/atoms";
import { VodStatus } from "@components/molecules";
import {
  VodSlideOver_JobDetailsDocument,
  VodSlideOver_JobDetailsQueryVariables,
  VodSlideOver_SegmentsDocument,
  VodSlideOver_SegmentsQuery,
  VodSlideOver_SegmentsQueryVariables,
} from "@graphql/operations";

import { VodSlideOverBody, VodSliderOverTab } from "./VodSlideOverBody";

export const VOD_SLIDE_OVER_TAB_QUERY_PARAM = "tab";

type SegmentItems = NonNullable<
  NonNullable<VodSlideOver_SegmentsQuery["segments"]>["atlas_custom_items"]
>;

async function getAllSegments(
  fetchData: LazyQueryExecFunction<
    VodSlideOver_SegmentsQuery,
    VodSlideOver_SegmentsQueryVariables
  >,
  variables: VodSlideOver_SegmentsQueryVariables,
  segments: SegmentItems
): Promise<SegmentItems> {
  const { data } = await fetchData({ variables });
  const nextToken = data?.segments?.nextToken;

  segments.push(...(data?.segments?.atlas_custom_items || []));
  segments.push(...(data?.segments?.fw_contextual_items || []));
  if (nextToken) {
    return getAllSegments(
      fetchData,
      {
        ...variables,
        nextToken,
      },
      segments
    );
  }

  return segments;
}

const useGetAllSegments = (videoId?: string) => {
  const [segments, setSegments] = useState<SegmentItems>([]);
  const [fetchData, { loading }] = useLazyQuery(VodSlideOver_SegmentsDocument);

  useEffect(() => {
    if (videoId) {
      getAllSegments(
        fetchData,
        {
          filter: {
            videoId: {
              eq: videoId || "",
            },
          },
        },
        []
      ).then((data) => {
        setSegments(data);
      });
    }
  }, [videoId, fetchData]);

  return useMemo(
    () => ({
      loading,
      segments,
    }),
    [loading, segments]
  );
};

export interface VodSlideOverProps {
  videoId?: string;
  open: boolean;
  onClose: () => void;
  onRestartClicked?: (
    videoId: string,
    href?: string,
    thumbnail?: string
  ) => Promise<void> | void;
  showViewInsightsButton?: boolean;
}

export const VodSlideOver = ({
  open,
  videoId,
  onClose,
  onRestartClicked,
  showViewInsightsButton,
}: VodSlideOverProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = (searchParams.get(VOD_SLIDE_OVER_TAB_QUERY_PARAM) ||
    "job") as VodSliderOverTab;

  let variables: VodSlideOver_JobDetailsQueryVariables = {
    listVideoFilterInput: {
      videoId: {
        eq: videoId || "",
      },
    },
    jobFilter: {
      videoId: {
        eq: videoId || "",
      },
    },
  };

  const { loading, data } = useQuery(VodSlideOver_JobDetailsDocument, {
    skip: !videoId,
    variables,
  });

  const job = data?.job;
  const video = data?.listVideo?.items && data?.listVideo?.items[0];

  const { segments, loading: segmentsLoading } = useGetAllSegments(videoId);

  let body = null;

  if (loading || segmentsLoading) {
    body = <Loading.Shimmer data-testid="Shimmer" />;
  } else if (!job && open) {
    body = <NoContent />;
  } else if (tab === "error" && job && VodStatus.isSuccessful(job)) {
    setSearchParams(
      {
        details: "job",
      },
      {
        replace: true,
      }
    );
    return null;
  } else if (job) {
    const standardSegments = segments?.filter(
      (segment) => !segment.segmentID.startsWith("$")
    );
    const segmentsDisabled = !standardSegments || standardSegments.length === 0;

    const segmentsTab = (
      <Tabs.Tab
        underlineVerticalSpacing="medium"
        variant="slim"
        active={tab === "segments"}
        disabled={segmentsDisabled}
      >
        Segments ({standardSegments?.length || 0})
      </Tabs.Tab>
    );

    const inverseSegments = segments?.filter((segment) =>
      segment.segmentID.startsWith("$")
    );
    const inverseSegmentsDisabled =
      !inverseSegments || inverseSegments.length === 0;

    const inverseSegmentsTab = (
      <Tabs.Tab
        underlineVerticalSpacing="medium"
        variant="slim"
        active={tab === "inverse-segments"}
        disabled={inverseSegmentsDisabled}
      >
        Inverse segments ({inverseSegments?.length || 0})
      </Tabs.Tab>
    );

    body = (
      <>
        <SlideOver.Header onClose={onClose}>
          <div className="flex flex-col h-full justify-between">
            <p className="text-navy-300">{videoId}</p>
            <div className="flex gap-3">
              <Link
                to={{
                  search: `${VOD_SLIDE_OVER_TAB_QUERY_PARAM}=job`,
                }}
              >
                <Tabs.Tab
                  underlineVerticalSpacing="medium"
                  variant="slim"
                  active={tab === "job"}
                >
                  Job details
                </Tabs.Tab>
              </Link>
              {VodStatus.isFailed(job) && (
                <Link
                  to={{
                    search: `${VOD_SLIDE_OVER_TAB_QUERY_PARAM}=error`,
                  }}
                >
                  <Tabs.Tab
                    underlineVerticalSpacing="medium"
                    variant="slim"
                    active={tab === "error"}
                  >
                    Error details
                  </Tabs.Tab>
                </Link>
              )}
              {VodStatus.isSuccessful(job) ? (
                <>
                  {segmentsDisabled ? (
                    <div>{segmentsTab}</div>
                  ) : (
                    <Link
                      to={{
                        search: `${VOD_SLIDE_OVER_TAB_QUERY_PARAM}=segments`,
                      }}
                    >
                      {segmentsTab}
                    </Link>
                  )}
                  {inverseSegmentsDisabled ? (
                    <div>{inverseSegmentsTab}</div>
                  ) : (
                    <Link
                      to={{
                        search: `${VOD_SLIDE_OVER_TAB_QUERY_PARAM}=inverse-segments`,
                      }}
                    >
                      {inverseSegmentsTab}
                    </Link>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </SlideOver.Header>
        <VodSlideOverBody
          onRestartClicked={onRestartClicked}
          job={job}
          video={video}
          tab={tab}
          segmentIds={segments?.map((segment) => segment.segmentID)}
          showViewInsightsButton={showViewInsightsButton}
        />
      </>
    );
  }

  return (
    <SlideOver.SlideOver
      data-testid="vod-slide-over"
      open={open}
      onClose={onClose}
    >
      {body}
    </SlideOver.SlideOver>
  );
};
