import React, { type ReactNode } from "react";

import { classnames } from "@external/tailwindcss-classnames";

interface ItemTitleProps {
  className?: any;
  children: ReactNode;
}

export const ItemTitle = ({ children, className }: ItemTitleProps) => (
  <div
    className={classnames(
      className,
      "font-semibold",
      "text-bodyXs",
      "text-black-300"
    )}
  >
    {children}
  </div>
);
