import { TypePolicy } from "@apollo/client/cache/inmemory/policies";

export const Job: TypePolicy = {
  keyFields: ["videoId"],
  fields: {
    // Never cache these properties, independently of the fetchPolicy used
    thumbnailHref: {
      merge() {},
    },
    mediaHref: {
      merge() {},
    },
  },
};

export default Job;
