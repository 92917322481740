import React from "react";

import { classnames } from "@external/tailwindcss-classnames";

type LinkProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

export const Link = ({ className, children, ...rest }: LinkProps) => (
  <a
    className={classnames(
      className as any,
      "hover:underline",
      "active:text-blue-400",
      "text-blue-300"
    )}
    {...rest}
  >
    {children}
  </a>
);
