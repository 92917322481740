import React, { useState } from "react";

import { Button, Time } from "@components/atoms";
import { Table } from "@components/molecules";
import { classnames } from "@external/tailwindcss-classnames";
import * as truncate from "@utils/truncate";

import { InsightsTable, InsightsTableProps } from "../InsightsTables";

import { SentimentTooltip } from "./SentimentToolTip";

type Sentiment = {
  Sentence: string;
  Sentiment: string;
  SentimentScoreMixed: number;
  SentimentScoreNegative: number;
  SentimentScoreNeutral: number;
  SentimentScorePositive: number;
  Timestamp: string;
};

export type SentimentsTableProps = {
  sentiments?: Array<Sentiment>;
} & Pick<InsightsTableProps, "loading" | "loadingRows">;

const SentimentLabel = ({ sentiment }: { sentiment: Sentiment }) => {
  const dotClassName = classnames(
    "rounded",
    {
      "bg-red-300": sentiment.Sentiment === "NEGATIVE",
      "bg-navy-200": sentiment.Sentiment === "POSITIVE",
      "bg-orange-300": sentiment.Sentiment === "MIXED",
      "bg-blue-300": sentiment.Sentiment === "NEUTRAL",
    },
    "w-1.5",
    "h-1.5"
  );

  return (
    <div className="relative">
      <SentimentTooltip sentiment={sentiment}>
        <div className="flex flex-row items-center cursor-pointer">
          <div className={dotClassName} />
          <div className="ml-2 font-semibold">{sentiment.Sentiment}</div>
        </div>
      </SentimentTooltip>
    </div>
  );
};

const SentenceCell = ({
  sentence,
  isExpanded,
  onExpandedStateChange,
}: {
  sentence: string;
  isExpanded: boolean;
  onExpandedStateChange: (expand: boolean) => void;
}) => {
  const [isTruncated, truncatedSentence] = truncate.head(sentence, 110, "...");

  return (
    <Table.Cell>
      <span className="text-xs font-semibold">
        {!isTruncated || isExpanded ? sentence : truncatedSentence}
      </span>
      {isTruncated &&
        (!isExpanded ? (
          <Button
            className="inline-block py-0 px-1font-semibold"
            variant="text"
            onClick={() => onExpandedStateChange(true)}
          >
            <span className="text-blue-300 text-xs">Show more</span>
          </Button>
        ) : (
          <Button
            className="inline-block py-0 px-1 text-xs text-blue-300 font-semibold"
            variant="text"
            onClick={() => onExpandedStateChange(false)}
          >
            <span className="text-blue-300 text-xs">Show less</span>
          </Button>
        ))}
    </Table.Cell>
  );
};

// this component renders a table of sentiments
export const SentimentsTable = ({
  sentiments = [],
  loading,
  loadingRows,
}: SentimentsTableProps) => {
  const [expandedSentiment, setExpandedSentiment] = useState<Sentiment>();

  return (
    <InsightsTable
      columns={[
        { title: "Sentiment", width: "w-3/12" },
        { title: "Timestamp", width: "w-3/12" },
        { title: "Sentence", width: "w-6/12" },
      ]}
      loading={loading}
      loadingRows={loadingRows}
      loadingRowsHeight="h-12"
    >
      {sentiments.length > 0
        ? sentiments.map((sentiment) => (
            <Table.Row key={`${sentiment.Sentiment}_${sentiment.Timestamp}`}>
              <Table.Cell>
                <SentimentLabel sentiment={sentiment} />
              </Table.Cell>
              <Table.Cell>
                <Time>{sentiment.Timestamp}</Time>
              </Table.Cell>
              <SentenceCell
                sentence={sentiment.Sentence}
                isExpanded={expandedSentiment === sentiment}
                onExpandedStateChange={(expand) =>
                  setExpandedSentiment(expand ? sentiment : undefined)
                }
              />
            </Table.Row>
          ))
        : null}
    </InsightsTable>
  );
};
