import React, { ChangeEvent, useRef, useState } from "react";

import { Checkbox } from "@components/atoms";
import { ChevronDown } from "@components/atoms/Icon/ChevronDown";
import { OccupiedLabel } from "@hooks/segments";
import { ConvertToUpperSnakeCase } from "@utils/casing";

import { Label } from "./labels";

type Props = {
  occupiedLabels: Array<OccupiedLabel>;
  planeId: string;
  labels: Label;
  handleClickEvent: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const LabelGroup = ({
  occupiedLabels,
  planeId,
  labels: { value, secondLevelCategories },
  handleClickEvent,
}: Props) => {
  const [isExpanded, setExpandedState] = useState(false);
  const ref = useRef<any>();

  const labelOwnershipMetaData = occupiedLabels.reduce(
    (acc: Record<string, boolean | string>, elem) => {
      acc[elem.ownerId] = true;
      acc[elem.value] = elem.value;
      return acc;
    },
    {}
  );
  const numberOfTotalLabels =
    secondLevelCategories && secondLevelCategories.length + 1;
  const numberOfSecondLevelCategories = secondLevelCategories?.length;
  const numberOfTaken = occupiedLabels.length;

  if (ref.current) {
    if (
      labelOwnershipMetaData.hasOwnProperty(planeId) &&
      labelOwnershipMetaData.hasOwnProperty(ConvertToUpperSnakeCase(value))
    ) {
      if (numberOfTaken === numberOfSecondLevelCategories) {
        ref.current.indeterminate = false;
      } else {
        ref.current.indeterminate = true;
      }
    }
  }

  if (
    !labelOwnershipMetaData.hasOwnProperty(planeId) &&
    labelOwnershipMetaData.hasOwnProperty(ConvertToUpperSnakeCase(value))
  ) {
    if (numberOfTaken === numberOfTotalLabels) {
      return null;
    }
  }

  return (
    <div className="mb-5">
      <div
        data-plane-id={planeId}
        data-label-value={ConvertToUpperSnakeCase(value)}
        className="flex items-center leading-6 "
      >
        <Checkbox
          ref={ref}
          className="text-sm font-medium"
          onChange={(e) => handleClickEvent(e)}
          value={ConvertToUpperSnakeCase(value)}
        >
          {value}
        </Checkbox>
        <ChevronDown
          className="ml-3 cursor-pointer"
          onClick={() => setExpandedState(!isExpanded)}
        />
      </div>
      <div className={`flex flex-col ml-6 ${!isExpanded ? "hidden" : ""}`}>
        {secondLevelCategories &&
          secondLevelCategories.map((secondLevelCategory) => {
            const occupiedLabel = occupiedLabels.find(
              (label) =>
                label.value ===
                ConvertToUpperSnakeCase(secondLevelCategory.value)
            );
            if (occupiedLabel && occupiedLabel.ownerId !== planeId) {
              return null;
            }
            return (
              <Checkbox
                key={ConvertToUpperSnakeCase(secondLevelCategory.value)}
                className="text-sm font-medium mt-5"
                data-plane-id={planeId}
                data-parent={ConvertToUpperSnakeCase(value)}
                data-label-value={ConvertToUpperSnakeCase(
                  secondLevelCategory.value
                )}
                onChange={(e) => handleClickEvent(e)}
                value={ConvertToUpperSnakeCase(secondLevelCategory.value)}
                checked={
                  labelOwnershipMetaData.hasOwnProperty(planeId) &&
                  labelOwnershipMetaData.hasOwnProperty(
                    ConvertToUpperSnakeCase(secondLevelCategory.value)
                  )
                }
              >
                {secondLevelCategory.value}
              </Checkbox>
            );
          })}
      </div>
    </div>
  );
};
