import React, {
  ForwardRefExoticComponent,
  HTMLAttributes,
  RefAttributes,
  useRef,
} from "react";

import { Button, Icon, Portal } from "@components/atoms";
import { classnames, TArg } from "@external/tailwindcss-classnames";

export type Ref = HTMLDivElement;

export interface CardProps extends HTMLAttributes<Ref> {
  onClose?: () => void;
  onOpened?: () => void;
  className?: any;
}

export const Card: ForwardRefExoticComponent<CardProps & RefAttributes<Ref>> =
  React.forwardRef<Ref, CardProps>(({ children, onClose, className }, ref) => {
    const titlePortalRef = useRef<HTMLDivElement | null>(null);
    const descriptionPortalRef = useRef<HTMLDivElement | null>(null);

    return (
      <Portal.PortalProvider
        targets={{
          title: titlePortalRef,
          description: descriptionPortalRef,
        }}
      >
        <div
          ref={ref}
          className={classnames(
            className,
            "m-4",
            "flex",
            "flex-col",
            "max-w-xl",
            "w-[28rem]" as TArg,
            "transform" as TArg,
            "bg-white",
            "shadow-4dp",
            "rounded-lg"
          )}
        >
          <Button
            className="self-end mr-[1.7rem] mt-[1.7rem] hover:text-coolGrey-400 text-coolGrey-300 focus:outline-none"
            color="clear"
            onClick={onClose}
          >
            <Icon.Close />
          </Button>
          <div className="ml-8">
            <div ref={titlePortalRef} />
          </div>
          <div className="mx-8 mb-14 mt-4">{children}</div>
        </div>
      </Portal.PortalProvider>
    );
  });
