import React, { type ReactNode } from "react";
import { Dialog } from "@headlessui/react";

import { Teleport } from "@components/atoms/Portal";
import { classnames } from "@external/tailwindcss-classnames";

export interface TitleProps {
  className?: any;
  children: ReactNode;
}

export const Title = ({ className, children }: TitleProps) => (
  <Teleport refName="title">
    <Dialog.Title
      as="p"
      className={classnames(
        className,
        "text-navy-400",
        "text-left",
        "font-semibold"
      )}
    >
      {children}
    </Dialog.Title>
  </Teleport>
);
