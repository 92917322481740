import React from "react";
import { formatInTimeZone } from "date-fns-tz";

import { GenericLabel } from "@components/atoms";
import { JobThumbnail, Table } from "@components/molecules";
import { VideoRow_SegmentJobVideoAssociationFragment } from "@graphql/operations";
import { getLocale, getTimezone } from "@utils/i18n";

export type VideoRowProps = {
  onClick: () => void;
  index: number;
  segmentVideoAssociationItem: VideoRow_SegmentJobVideoAssociationFragment;
};

export const VideoRow = ({
  onClick,
  index,
  segmentVideoAssociationItem,
  ...rest
}: VideoRowProps) => {
  const video = segmentVideoAssociationItem.IngestionJobConnection;

  return (
    <Table.Row {...rest} key={video.videoId} onClick={onClick} height="h-18">
      <Table.Cell className="flex items-center">
        <div className="mr-6 shrink-0">
          <JobThumbnail
            size="small"
            className="aspect-video h-14"
            src={video.thumbnailHref}
          />
        </div>
        <div className="font-semibold">
          <div className="line-clamp-2">{video.title || "-"}</div>
        </div>
      </Table.Cell>
      <Table.Cell>
        {video.businessUnit && (
          <GenericLabel>{video.businessUnit.toLowerCase()}</GenericLabel>
        )}
      </Table.Cell>
      <Table.Cell>
        {video?.createdAt
          ? formatInTimeZone(new Date(video?.createdAt), getTimezone(), "P p", {
              locale: getLocale(),
            })
          : "-"}
      </Table.Cell>
      <Table.Cell>{segmentVideoAssociationItem.TotalSegmentCount}</Table.Cell>
    </Table.Row>
  );
};
