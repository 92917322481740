import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Exclamation = createSvgIcon({
  content: (
    <>
      <path
        d="M20 2.22222C29.8056 2.22222 37.7778 10.1944 37.7778 20C37.7778 29.8056 29.8056 37.7778 20 37.7778C10.1944 37.7778 2.22222 29.8056 2.22222 20C2.22222 10.1944 10.1944 2.22222 20 2.22222ZM20 0C8.94444 0 0 8.94444 0 20C0 31.0556 8.94444 40 20 40C31.0556 40 40 31.0556 40 20C40 8.94444 31.0556 0 20 0Z"
        className="fill-current"
      />
      <path
        d="M19.9459 31.1667C19.4181 31.1667 18.9459 30.9722 18.557 30.5833C18.1681 30.1944 17.9736 29.75 17.9736 29.1944C17.9736 28.6667 18.1681 28.1944 18.557 27.8056C18.9459 27.4167 19.3903 27.2222 19.9459 27.2222C20.4736 27.2222 20.9459 27.4167 21.3347 27.8056C21.7236 28.1944 21.9181 28.6389 21.9181 29.1944C21.9181 29.5556 21.8347 29.8889 21.6403 30.1667C21.4736 30.4722 21.2236 30.6944 20.9459 30.8889C20.6403 31.0833 20.307 31.1667 19.9459 31.1667ZM21.4459 8.75L21.2236 24.75H18.6959L18.4736 8.75H21.4459Z"
        className="fill-current"
      />
    </>
  ),
  width: 40,
  height: 40,
});
