import React from "react";

import { AtlasLogo, Button, FoxLogo, OktaLogo } from "@components/atoms";
import { useAuth } from "@hooks/useAuth";

import { ArtWork } from "./Artwork";

export interface LoginProps {}

export const Login = () => {
  const { signIn } = useAuth();

  return (
    <div className="flex h-screen flex-row">
      <div className="flex flex-1 flex-col h-screen items-center">
        <div className="flex flex-col items-center justify-center h-full">
          <AtlasLogo />
          <h5 className="mt-22 text-navy-300 font-semibold">
            Log in to your account
          </h5>
          <Button
            color="primary"
            variant="contained"
            className="mt-16 w-92 h-10 "
            onClick={() => signIn()}
          >
            <div className="flex flex-row h-full items-center">
              <OktaLogo />
              <div className="h-full border-l border-blue-200" />
              <p className="ml-11">Log in with Okta</p>
            </div>
          </Button>
        </div>

        <div className="w-full flex justify-between items-center px-10 h-20">
          <FoxLogo />
          <p className="text-black-100 text-sm select-none">
            &#169;FOX Corporation
          </p>
        </div>
      </div>
      <div className="flex-1 border">
        <ArtWork />
      </div>
    </div>
  );
};
