import React, { useMemo } from "react";

const regex = /^(?<h>\d\d):(?<m>\d\d):(?<s>\d\d)(.(?<ms>\d\d\d))?$/;

export const formatTime = (time: string) => {
  const match = regex.exec(time);

  if (!match?.groups) {
    return null;
  }

  const { h, m, s } = match.groups;
  const timeParts = [h, m, s].map((timePart) => parseInt(timePart));

  if (timeParts[0] === 0) {
    timeParts.shift();
  }

  return timeParts
    .map((timePart) => timePart.toString().padStart(2, "0"))
    .join(":");
};

export interface TimeProps {
  children: string;
}

export const Time = ({ children }: TimeProps) => {
  const formattedTime = useMemo(() => formatTime(children), [children]);

  return <>{formattedTime}</>;
};
