import React, { memo } from "react";
import { isEqual } from "lodash";

import { FilterType } from "@hooks/segments";

import {
  BooleanCondition,
  BooleanConditionProps,
} from "./Sports/BooleanCondition";
import {
  BooleanWithTolerance,
  BooleanWithToleranceProps,
} from "./Sports/BooleanWithTolerance";
import {
  GenericDimensionEquals,
  GenericDimensionEqualsProps,
} from "./Sports/GenericDimensionEquals";
import {
  GenericMetricCompare,
  GenericMetricCompareProps,
} from "./Sports/GenericMetricCompare";
import { CelebrityEquals, type CelebrityEqualsProps } from "./CelebrityEquals";
import {
  ContentModerationEquals,
  type ContentModerationEqualsProps,
} from "./ContentModeration";
import { LabelEquals, type LabelEqualsProps } from "./LabelEquals";
import { MetadataContains, MetadataContainsProps } from "./MetadataContains";
import {
  NestedSegmentIdEquals,
  type NestedSegmentIdEqualsProps,
} from "./NestedSegmentIdEquals";
import { Occurences, type OccurencesProps } from "./Occurences";
import { SentimentEquals, type SentimentEqualsProps } from "./SentimentEquals";
import {
  TextInVideoContains,
  type TextInVideoContainsProps,
} from "./TextInVideoContains";
import {
  TranscriptContains,
  type TranscriptContainsProps,
} from "./TranscriptContains";
import {
  VideoDurationEquals,
  type VideoDurationEqualsProps,
} from "./VideoDurationEquals";
import {
  VideoTitleContains,
  type VideoTitleContainsProps,
} from "./VideoTitleContains";

type TagWithKey<T> = {
  [K in keyof T]: { type: K } & T[K];
};

type ConditionPropsPayloadTable = {
  [FilterType.CELEBRITY__EQUALS]: CelebrityEqualsProps;
  [FilterType.LABEL__CONTAINS]: LabelEqualsProps;
  [FilterType.TRANSCRIPT__CONTAINS]: TranscriptContainsProps;
  [FilterType.SENTIMENT__EQUALS]: SentimentEqualsProps;
  [FilterType.CONTENT_MODERATION__EQUALS]: ContentModerationEqualsProps;
  [FilterType.VIDEO_DURATION__EQUALS]: VideoDurationEqualsProps;
  [FilterType.VIDEO_TITLE__CONTAINS]: VideoTitleContainsProps;
  [FilterType.NESTED_SEGMENT_ID__EQUALS]: NestedSegmentIdEqualsProps;
  [FilterType.TEXT_IN_VIDEO__CONTAINS]: TextInVideoContainsProps;
  [FilterType.METADATA__CONTAINS]: MetadataContainsProps;
  [FilterType.MIN_OCCURRENCE__COUNT]: OccurencesProps;
  [FilterType.INNING__COMPARE]: GenericMetricCompareProps;
  [FilterType.RUN_DIFFERENTIAL__COMPARE]: GenericMetricCompareProps;
  [FilterType.EVENT_RUNS_SCORED__COMPARE]: GenericMetricCompareProps;
  [FilterType.EVENT_LEVERAGE_INDEX__COMPARE]: GenericMetricCompareProps;
  [FilterType.CLOSE_GAME__EQUALS]: BooleanWithToleranceProps;
  [FilterType.DELAYED_GAME__EQUALS]: BooleanConditionProps;
  [FilterType.DOUBLE_HEADER__EQUALS]: BooleanConditionProps;
  [FilterType.NIGHT_GAME__EQUALS]: BooleanConditionProps;
  [FilterType.VENUE__EQUALS]: GenericDimensionEqualsProps;
  [FilterType.PRE_ALL_STAR__EQUALS]: GenericDimensionEqualsProps;
  [FilterType.POST_ALL_STAR__EQUALS]: GenericDimensionEqualsProps;
  [FilterType.SEASON_TYPE__EQUALS]: GenericDimensionEqualsProps;
  [FilterType.HOME_TEAM__EQUALS]: GenericDimensionEqualsProps;
  [FilterType.AWAY_TEAM__EQUALS]: GenericDimensionEqualsProps;
  [FilterType.DIVISION__EQUALS]: GenericDimensionEqualsProps;
  [FilterType.LEAGUE__EQUALS]: GenericDimensionEqualsProps;
  [FilterType.PLAYER__EQUALS]: GenericDimensionEqualsProps;
  [FilterType.WINNING_TEAM__EQUALS]: GenericDimensionEqualsProps;
  [FilterType.EXTRA_INNINGS__EQUALS]: GenericDimensionEqualsProps;
  [FilterType.SERIES_TYPE__EQUALS]: GenericDimensionEqualsProps;
  [FilterType.INNING_HALF__EQUALS]: GenericDimensionEqualsProps;
  [FilterType.EVENT_TYPE__EQUALS]: GenericDimensionEqualsProps;
  [FilterType.EVENT_DESCRIPTION__CONTAINS]: GenericDimensionEqualsProps;
  [FilterType.LINEUP_CHANGE__EQUALS]: BooleanWithToleranceProps;
  [FilterType.STOLEN_BASE__EQUALS]: GenericDimensionEqualsProps;
};

type ConditionPropsTable = TagWithKey<ConditionPropsPayloadTable>;

export type ConditionProps = ConditionPropsTable[keyof ConditionPropsTable];

export const Condition = memo(
  (p: ConditionProps) => {
    switch (p.type) {
      case FilterType.CELEBRITY__EQUALS:
        return <CelebrityEquals {...p} />;
      case FilterType.LABEL__CONTAINS:
        return <LabelEquals {...p} />;
      case FilterType.VIDEO_TITLE__CONTAINS:
        return <VideoTitleContains {...p} />;
      case FilterType.TRANSCRIPT__CONTAINS:
        return <TranscriptContains {...p} />;
      case FilterType.SENTIMENT__EQUALS:
        return <SentimentEquals {...p} />;
      case FilterType.CONTENT_MODERATION__EQUALS:
        return <ContentModerationEquals {...p} />;
      case FilterType.VIDEO_DURATION__EQUALS:
        return <VideoDurationEquals {...p} />;
      case FilterType.MIN_OCCURRENCE__COUNT:
        return <Occurences {...p} />;
      case FilterType.NESTED_SEGMENT_ID__EQUALS:
        return <NestedSegmentIdEquals {...p} />;
      case FilterType.TEXT_IN_VIDEO__CONTAINS:
        return <TextInVideoContains {...p} />;
      case FilterType.METADATA__CONTAINS:
        return <MetadataContains {...p} />;
      case FilterType.INNING__COMPARE:
      case FilterType.RUN_DIFFERENTIAL__COMPARE:
      case FilterType.EVENT_RUNS_SCORED__COMPARE:
      case FilterType.EVENT_LEVERAGE_INDEX__COMPARE:
        return <GenericMetricCompare {...p} />;
      case FilterType.LINEUP_CHANGE__EQUALS:
      case FilterType.CLOSE_GAME__EQUALS:
        return <BooleanWithTolerance {...p} />;
      case FilterType.DELAYED_GAME__EQUALS:
      case FilterType.DOUBLE_HEADER__EQUALS:
      case FilterType.NIGHT_GAME__EQUALS:
        return <BooleanCondition {...p} />;
      case FilterType.VENUE__EQUALS:
      case FilterType.PRE_ALL_STAR__EQUALS:
      case FilterType.POST_ALL_STAR__EQUALS:
      case FilterType.SEASON_TYPE__EQUALS:
      case FilterType.HOME_TEAM__EQUALS:
      case FilterType.AWAY_TEAM__EQUALS:
      case FilterType.DIVISION__EQUALS:
      case FilterType.LEAGUE__EQUALS:
      case FilterType.SERIES_TYPE__EQUALS:
        return <GenericDimensionEquals {...p} showWithin={false} />;
      case FilterType.PLAYER__EQUALS:
      case FilterType.WINNING_TEAM__EQUALS:
      case FilterType.EXTRA_INNINGS__EQUALS:
      case FilterType.INNING_HALF__EQUALS:
      case FilterType.EVENT_TYPE__EQUALS:
      case FilterType.EVENT_DESCRIPTION__CONTAINS:
      case FilterType.STOLEN_BASE__EQUALS:
        return <GenericDimensionEquals {...p} showWithin={true} />;
      default:
        const _exhaustiveCheck: never = p;
        return _exhaustiveCheck;
    }
  },
  (
    {
      onDrop: prevOnDrop,
      onRemove: prevOnRemove,
      onChange: prevOnChange,
      ...prevProps
    },
    {
      onDrop: nextOnDrop,
      onRemove: nextOnRemove,
      onChange: nextOnChange,
      ...nextProps
    }
  ) => isEqual(prevProps, nextProps)
);
