import { useCallback, useEffect, useRef, useState } from "react";

export const limitOptions = {
  "10": "10",
  "50": "50",
  "100": "100",
};

export function usePaging<T>(
  defaults?: Partial<{
    tokenStack: T[];
    currentPage: number;
    totalPages?: number;
  }>
) {
  const [tokenStack, setTokenStack] = useState<T[]>(defaults?.tokenStack || []);
  const [currentPage, setPage] = useState<number>(defaults?.currentPage || 1);
  const [totalPages, setTotal] = useState<number | undefined>(
    defaults?.totalPages
  );

  const ref = useRef({ tokenStack, currentPage, totalPages, defaults });

  useEffect(() => {
    if (ref.current.currentPage !== defaults?.currentPage) {
      setPage((ref.current.currentPage = defaults?.currentPage as number));
    }
  }, [defaults?.currentPage]);

  useEffect(() => {
    if (ref.current.totalPages !== defaults?.totalPages) {
      setPage((ref.current.totalPages = defaults?.totalPages as number));
    }
  }, [defaults?.totalPages]);

  const setCurrentPage = useCallback((pageNumber: number) => {
    setPage((ref.current.currentPage = pageNumber));
  }, []);

  const getCurrentPage = useCallback(() => {
    return ref.current.currentPage;
  }, []);

  const setTotalPages = useCallback((pages: number) => {
    setTotal((ref.current.totalPages = pages));
  }, []);

  const getTotalPages = useCallback(() => ref.current.totalPages, []);

  const push = useCallback((token: T) => {
    setTokenStack(
      (ref.current.tokenStack = [...ref.current.tokenStack, token])
    );
  }, []);

  const clear = useCallback(() => {
    setTokenStack(
      (ref.current.tokenStack = ref.current.defaults?.tokenStack || [])
    );
    setPage((ref.current.currentPage = ref.current.defaults?.currentPage || 1));
    setTotal((ref.current.totalPages = ref.current.defaults?.totalPages));
  }, []);

  const discardStackTo = useCallback(
    (index: number) => {
      if (index > 0) {
        setTokenStack(
          (ref.current.tokenStack = ref.current.tokenStack.slice(0, index))
        );
      } else {
        clear();
      }
    },
    [clear]
  );

  const peek = useCallback(() => {
    return ref.current.tokenStack[ref.current.tokenStack.length - 1];
  }, []);

  const getStackLength = useCallback(() => {
    return ref.current.tokenStack.length;
  }, []);

  return {
    push,
    peek,
    clear,
    discardStackTo,
    getStackLength,
    setCurrentPage,
    getCurrentPage,
    setTotalPages,
    getTotalPages,
  };
}
