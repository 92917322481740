import React from "react";
import { Link } from "react-router-dom";

import { Button } from "@components/atoms";
import { Images } from "@components/molecules";

export const NotFound = () => (
  <article className="flex-auto flex flex-col items-center justify-center">
    <Images.NotFound className="mb-10" />
    <h2 className="mb-12">Page Not Found</h2>
    <Link to="/">
      <Button color="primary" size="large">
        Back to Home
      </Button>
    </Link>
  </article>
);
