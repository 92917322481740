import { Dropdown } from "./Dropdown";
import { ChatAltIcon, StarIcon, TagIcon } from "./Iconography";
import { Item } from "./Item";
import { Items } from "./Items";
import { TitleItem } from "./TitleItem";

export default Object.assign(Dropdown, {
  Item,
  Items,
  TitleItem,
  ChatAltIcon,
  StarIcon,
  TagIcon,
});
