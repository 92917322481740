import React from "react";

import { useTreeViewModel } from "../hooks";
import { TreeViewItem } from "../TreeViewItem";

export const Tree = ({ className }: { className?: any }) => {
  const context = useTreeViewModel();

  return (
    <div className={className} data-e2e="treeview">
      {context.tree.nodes
        .filter((node) => node.isVisible && !node.isVirtuallyRemoved)
        .map((node) => (
          <TreeViewItem
            key={node.id}
            node={node}
            data-e2e={`treeview-node-${node.id}`}
          />
        ))}
    </div>
  );
};
