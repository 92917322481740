const ContentModerationConditions = [
  "Explicit Nudity",
  "Nudity",
  "Graphic Male Nudity",
  "Graphic Female Nudity",
  "Sexual Activity",
  "Illustrated Explicit Nudity",
  "Adult Toys",
  "Suggestive",
  "Female Swimwear Or Underwear",
  "Male Swimwear Or Underwear",
  "Partial Nudity",
  "Barechested Male",
  "Revealing Clothes",
  "Sexual Situations",
  "Violence",
  "Graphic Violence Or Gore",
  "Physical Violence",
  "Weapon Violence",
  "Weapons",
  "Self Injury",
  "Visually Disturbing",
  "Emaciated Bodies",
  "Corpses",
  "Hanging",
  "Air Crash",
  "Explosions And Blasts",
  "Rude Gestures",
  "Middle Finger",
  "Drugs",
  "Drug Products",
  "Drug Use",
  "Pills",
  "Drug Paraphernalia",
  "Tobacco",
  "Tobacco Products",
  "Smoking",
  "Alcohol",
  "Drinking",
  "Alcoholic Beverages",
  "Gambling",
  "Hate Symbols",
  "Nazi Party",
  "White Supremacy",
  "Extremist",
];

const SentimentConditions = ["Positive", "Negative", "Naturel", "Mixed"];

type Condition<T extends string, V> = {
  "Condition Type*": T;
  "Condition Value*": V;
  "Description (Skip during upload)"?: string;
  "Operator (optional)"?: "All" | "Any" | "Exclude";
};

type ConditionPositionFilter = {
  "Confidence Score (optional)"?: number;
  "Within (optional)"?: string;
};

export type ExampleCsvType =
  | (Condition<
      "Celebrity" | "Spoken Noun" | "Object or scene" | "Spoken word or phrase",
      string
    > &
      ConditionPositionFilter)
  | (Condition<"Flagged Content", typeof ContentModerationConditions[number]> &
      ConditionPositionFilter)
  | Condition<"Video Title Contains", string>
  | Condition<"Video Duration", string>
  | Condition<"Sentiment", typeof SentimentConditions[number]>;

export const ExampleCsv: ExampleCsvType[] = [
  {
    "Condition Type*": "Object or scene",
    "Condition Value*": "Tuxedo",
    "Confidence Score (optional)": 83,
    "Within (optional)": "20s",
    "Operator (optional)": "Any",
    "Description (Skip during upload)":
      "A free text search field with no restrictions for searching for objects on screen",
  },
  {
    "Condition Type*": "Spoken Noun",
    "Condition Value*": "Table",
    "Description (Skip during upload)":
      "A free text search field with no restrictions for searching the transcript for spoken nouns",
  },
  {
    "Condition Type*": "Sentiment",
    "Condition Value*": "Table",
    "Description (Skip during upload)":
      "A restrictive list of sentiment occurrences. Possible values: Positive, Negative, Neutral, Mixed",
  },
  {
    "Condition Type*": "Celebrity",
    "Condition Value*": "Tom Hanks",
    "Description (Skip during upload)":
      "A free text search field with no restrictions for searching the screen for celebrities",
  },
  {
    "Condition Type*": "Spoken word or phrase",
    "Condition Value*": "Copyright Infringement",
    "Description (Skip during upload)":
      "A free text search field with no restrictions for searching the for spoken word or phrases",
  },
  {
    "Condition Type*": "Flagged Content",
    "Condition Value*": "Explicit Nudity",
    "Description (Skip during upload)": `"A restrictive list of flagged content values. Possible values are listed bellow

EXPLICIT NUDITY
-Nudity
-Graphic Male Nudity
-Graphic Female Nudity
-Sexual Activity
-Illustrated Explicit Nudity
-Adult Toys

SUGGESTIVE
-Female Swimwear Or Underwear
-Male Swimwear Or Underwear
-Partial Nudity
-Barechested Male
-Revealing Clothes
-Sexual Situations

VIOLENCE
-Graphic Violence or Gore
-Physical Violence
-Weapon Violence
-Weapons
-Self Injury

VISUALLY DISTURBING -Emaciated Bodies
-Corpses
-Hanging
-Air Crash
-Explosions And Blasts

RUDE GESTURES
-Middle Finger

DRUGS
-Drug Products
-Drug Use
-Pills
-Drug Paraphernalia

TOBACCO
-Tobacco Products
-Smoking

ALCOHOL
-Drinking
-Alcoholic Beverages

GAMBLING
-Gambling

HATE SYMBOLS
-Nazi Party
-White Supremacy
-Extremist"`,
  },
  {
    "Condition Type*": "Video Duration",
    "Condition Value*": "30s < x",
    "Description (Skip during upload)": `"Examples:
-Setting the video to be greater than 30 seconds: 30s < x
-Setting the video duration less than 10 minutes: x < 10m
-Setting the video duration to be greater than 1 minutes but less than 5 minutes: 1m < x < 5m"`,
  },
  {
    "Condition Type*": "Video Title Contains",
    "Condition Value*": "Fox News Flash",
    "Description (Skip during upload)": "",
  },
];
