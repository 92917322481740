import React from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface EmptyRowsProps {
  maxRowCount: number;
  itemsLength: number;
  columns: number;
  className: any;
}

export const EmptyRows = ({
  maxRowCount,
  itemsLength,
  columns,
  className,
}: EmptyRowsProps) => (
  <>
    {maxRowCount > itemsLength &&
      itemsLength > 0 &&
      Array.from(Array(maxRowCount - itemsLength).keys()).map((idx) => (
        <tr key={idx} className={classnames(className, "border-b-0")}>
          <td colSpan={columns} />
        </tr>
      ))}
  </>
);
