import React, { useState } from "react";

import { Slider, SliderProps } from "@components/atoms";

interface Props {
  confidence?: number;
  onAfterChange: (value: number) => void | Promise<void>;
}

interface Style {
  [prop: string]: string | number;
}

interface Mark {
  label?: string;
  style?: Style;
}

interface Marks {
  [mark: string]: Mark;
}

export type ConfidenceThresholdProps = Props & Omit<SliderProps, "onChange">;

const defaultMarks: Marks = {
  "100": {},
  "90": {},
  "91": {},
  "92": {},
  "93": {},
  "94": {},
  "95": {},
  "96": {},
  "97": {},
  "98": {},
  "99": {},
  "80": {},
  "81": {},
  "82": {},
  "83": {},
  "84": {},
  "85": {},
  "86": {},
  "87": {},
  "88": {},
  "89": {},
  "70": {},
  "71": {},
  "72": {},
  "73": {},
  "74": {},
  "75": {},
  "76": {},
  "77": {},
  "78": {},
  "79": {},
  "60": {},
  "61": {},
  "62": {},
  "63": {},
  "64": {},
  "65": {},
  "66": {},
  "67": {},
  "68": {},
  "69": {},
  "50": {},
  "51": {},
  "52": {},
  "53": {},
  "54": {},
  "55": {},
  "56": {},
  "57": {},
  "58": {},
  "59": {},
};

const defineMarks = (currentMark: number) => {
  return Object.keys(defaultMarks).reduce((acc: Marks, value: string) => {
    const confidence = 150 - parseInt(value);
    const style: Style = {};

    if (![50, 100, currentMark].includes(confidence)) {
      style.visibility = "hidden";
    }

    if (currentMark > 90 && confidence > 90 && confidence !== currentMark) {
      style.visibility = "hidden";
    }

    if (currentMark < 60 && confidence < 60 && confidence !== currentMark) {
      style.visibility = "hidden";
    }

    acc[confidence] = {
      style,
      label: `${value}%`,
    };

    return acc;
  }, {});
};

export const ConfidenceThreshold = ({
  onAfterChange,
  confidence,
  ...rest
}: ConfidenceThresholdProps) => {
  const defaultValue = confidence ? 150 - confidence : 75;
  const [marks, setMarkers] = useState(defineMarks(defaultValue));
  return (
    <div>
      <label className="mb-2 font-semibold text-xs text-navy-400">
        Confidence threshold
      </label>
      <Slider
        reverse
        min={50}
        max={100}
        marks={marks}
        defaultValue={defaultValue}
        onChange={(v) => {
          const confidence = v as number;
          setMarkers(defineMarks(confidence));
          onAfterChange(150 - confidence);
        }}
        {...rest}
      />
    </div>
  );
};
