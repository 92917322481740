import React, { HTMLAttributes } from "react";

import { Button } from "@components/atoms";

import * as Icon from "../Icon";

export interface ErrorRetryProps extends HTMLAttributes<HTMLDivElement> {
  className?: any;
  onRetry: () => void;
}

export const ErrorRetry = ({ onRetry }: ErrorRetryProps) => (
  <div className="flex flex-col h-full items-center justify-center">
    <Icon.Exclamation color="coolGrey-200" />
    <h6 className="text-center mt-6 font-semibold text-coolGrey-300">
      AN ERROR OCCURRED
    </h6>
    <Button
      onClick={onRetry}
      variant="contained"
      size="small"
      className="mt-6 font-semibold text-black-300"
    >
      Retry
    </Button>
  </div>
);
