import React from "react";
import { useLazyQuery } from "@apollo/client";
import { merge } from "lodash";

import {
  Card,
  CardProps,
} from "@components/organisms/GenericExportDialog/Card";
import {
  selectableFields,
  selectedNodesId,
} from "@components/organisms/SegmentJobsExportDialog";
import {
  SegmentJobsExportDialog_SegmentJobUnloadDocument,
  SegmentJobsExportDialog_SegmentJobUnloadQueryVariables,
} from "@graphql/operations";

export type ExportDialogProps = Pick<CardProps, "title"> & {
  inverse?: boolean;
  segmentId: string;
  segmentVersion: number;
  onClose: () => void;
};

export const ExportDialog = React.forwardRef<HTMLDivElement, ExportDialogProps>(
  ({ inverse, segmentId, segmentVersion, title, onClose }, ref) => {
    const [unloadSegmentJob, { loading, error }] = useLazyQuery(
      SegmentJobsExportDialog_SegmentJobUnloadDocument,
      {
        fetchPolicy: "no-cache",
      }
    );

    return (
      <Card
        ref={ref}
        title={title}
        selectableFields={selectableFields}
        initialSelectedNodeIds={selectedNodesId}
        onExportSubmission={async (formData: string[]) => {
          let variables: SegmentJobsExportDialog_SegmentJobUnloadQueryVariables =
            {
              filter: {
                segmentId: {
                  eq: segmentId,
                },
                segmentVersion: {
                  eq: segmentVersion,
                },
              },
            };

          if ((formData.length || 0) >= 1) {
            variables = merge(variables, {
              unload: {
                columns: formData,
              },
            });
          }

          if (inverse) {
            variables = merge(variables, {
              unload: {
                inverse: true,
              },
            });
          }

          const { data } = await unloadSegmentJob({ variables });

          return (
            data?.segmentJob?.VideoAssociationConnection?.unload?.href ||
            undefined
          );
        }}
        loading={loading}
        error={error?.message}
        onClose={onClose}
      />
    );
  }
);
