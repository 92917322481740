import { ImportColumnType } from "./importColumnType";

export const toColumnTypeIndex = (
  values: ImportColumnType[]
): { [Key in ImportColumnType]?: number } => {
  const table: { [Key in ImportColumnType]?: number } = {};
  for (let i = 0; i < values.length; i++) {
    table[values[i]] = i;
  }
  return table;
};
