import React, { ChangeEvent, useEffect } from "react";

import { Checkbox, RadioButton, TextWithStatusPoint } from "@components/atoms";
import { AdvancedSearchQueryIntrinsicsCombinators } from "@graphql/operations";

import { FilterType, SentimentEqualsFilter, useFilters } from "../../hooks";

export const Sentiments = () => {
  const context = useFilters();

  const sentimentFilter = context.getFilter(FilterType.SENTIMENT__EQUALS);

  const selectedSentimentsCount = sentimentFilter?.values?.length || 0;

  useEffect(() => {
    // This UI might switch from a multi-select OR condition to a single-select AND condition
    // In such case we should just select the first selected option
    if (
      context.combinator === AdvancedSearchQueryIntrinsicsCombinators.And &&
      selectedSentimentsCount > 1
    ) {
      const filter: SentimentEqualsFilter = {
        filterType: FilterType.SENTIMENT__EQUALS,
        values: sentimentFilter ? [sentimentFilter?.values[0]] : [],
      };
      context.addFilter(filter);
    }
  }, [context, context.combinator, selectedSentimentsCount, sentimentFilter]);

  const onSelectionChange = (
    e: ChangeEvent<HTMLInputElement>,
    sentiment: null | "POSITIVE" | "NEGATIVE" | "NEUTRAL" | "MIXED"
  ) => {
    if (!sentiment) {
      context.removeFilter(FilterType.SENTIMENT__EQUALS);
    } else if (
      context.combinator === AdvancedSearchQueryIntrinsicsCombinators.And
    ) {
      // In case of AND condition we should remove all other selected options
      const filter: SentimentEqualsFilter = {
        filterType: FilterType.SENTIMENT__EQUALS,
        values: [sentiment],
      };

      if (e.target.checked) {
        context.addFilter(filter);
      } else {
        context.removeFilter(FilterType.SENTIMENT__EQUALS);
      }
    } else {
      // In case of OR condition we should add/remove the selected option
      const previousSentiments = sentimentFilter?.values || [];
      const filter: SentimentEqualsFilter = {
        filterType: FilterType.SENTIMENT__EQUALS,
        values: [...previousSentiments, sentiment],
      };

      if (e.target.checked) {
        context.addFilter(filter);
      } else if (selectedSentimentsCount > 1) {
        context.addFilter({
          filterType: FilterType.SENTIMENT__EQUALS,
          values: previousSentiments.filter((s) => s !== sentiment),
        });
      } else {
        context.removeFilter(FilterType.SENTIMENT__EQUALS);
      }
    }
  };

  const Choice =
    context.combinator === AdvancedSearchQueryIntrinsicsCombinators.And
      ? RadioButton
      : Checkbox;

  return (
    <div className="space-y-7">
      {context.combinator === AdvancedSearchQueryIntrinsicsCombinators.And && (
        <Choice
          data-testid="sentiment-any"
          onChange={(e) => e.target.value && onSelectionChange(e, null)}
          checked={!sentimentFilter?.values.length}
        >
          <div className="flex items-center text-black-300 font-semibold text-h7">
            Any Sentiment
          </div>
        </Choice>
      )}
      <Choice
        data-testid="sentiment-positive"
        onChange={(e) => e.target.value && onSelectionChange(e, "POSITIVE")}
        checked={!!sentimentFilter?.values.includes("POSITIVE")}
      >
        <TextWithStatusPoint pointColor="blue">Positive</TextWithStatusPoint>
      </Choice>
      <Choice
        data-testid="sentiment-negative"
        onChange={(e) => e.target.value && onSelectionChange(e, "NEGATIVE")}
        checked={!!sentimentFilter?.values.includes("NEGATIVE")}
      >
        <TextWithStatusPoint pointColor="red">Negative</TextWithStatusPoint>
      </Choice>
      <Choice
        data-testid="sentiment-neutral"
        onChange={(e) => e.target.value && onSelectionChange(e, "NEUTRAL")}
        checked={!!sentimentFilter?.values.includes("NEUTRAL")}
      >
        <TextWithStatusPoint pointColor="orange">Neutral</TextWithStatusPoint>
      </Choice>
      <Choice
        data-testid="sentiment-mixed"
        onChange={(e) => e.target.value && onSelectionChange(e, "MIXED")}
        checked={!!sentimentFilter?.values.includes("MIXED")}
      >
        <TextWithStatusPoint pointColor="teal">Mixed</TextWithStatusPoint>
      </Choice>
    </div>
  );
};
