import React, { ChangeEvent, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { formatInTimeZone } from "date-fns-tz";

import { Button, GenericLabel, SlideOver, TextInput } from "@components/atoms";
import { JobThumbnail, VodStatus } from "@components/molecules";
import {
  VodSlideOverBody_JobFragment,
  VodSlideOverBody_VideoFragment,
} from "@graphql/operations";
import * as clipboard from "@utils/clipboard";
import { getLocale, getTimezone } from "@utils/i18n";
import * as temporal from "@utils/temporal";
import * as truncate from "@utils/truncate";

import * as Information from "./Information";
import { SegmentsTabBody } from "./SegmentsTabBody";

export enum VodSliderOverTab {
  Job = "job",
  Error = "error",
  Segments = "segments",
  InverseSegments = "inverse-segments",
}

export interface VodSlideOverBodyProps {
  tab: VodSliderOverTab;
  job: VodSlideOverBody_JobFragment;
  video?: VodSlideOverBody_VideoFragment | null;
  segmentIds?: string[];
  onRestartClicked?: (
    videoId: string,
    href?: string,
    thumbnail?: string
  ) => Promise<void> | void;
  showViewInsightsButton?: boolean;
}

interface ErrorJsonProps {
  data: any;
}

const ErrorJson = ({ data }: ErrorJsonProps) => {
  let error = data;

  try {
    error = JSON.stringify(JSON.parse(error), null, 2);
  } catch (err) {}

  return (
    <div className="bg-lightGrey-200 rounded-lg mt-2 p-3 mb-6 overflow-scroll min-h-40 max-h-313 w-121">
      <div className="font-code text-xs whitespace-pre">{error}</div>
    </div>
  );
};

export const VodSlideOverBody = ({
  onRestartClicked,
  job,
  video,
  tab,
  segmentIds = [],
  showViewInsightsButton = true,
}: VodSlideOverBodyProps) => {
  const [vodData, setVodData] = useState<{ href: string; thumbnail: string }>({
    href: job.href || "",
    thumbnail: job.thumbnail || "",
  });
  const [, setSearchParams] = useSearchParams();

  let body;

  const handleTextInput = (e: ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setVodData({
      ...vodData,
      [name]: value,
    });
  };

  switch (tab) {
    case "error": {
      body = (
        <div className="flex-row flex-auto m-4 p-6 bg-white">
          <section>
            <VodStatus.Label
              mieStatus={job.mieStatus}
              ingestionStatus={job.ingestionStatus}
            />
            <ErrorJson data={job.error} />
            <label className="font-semibold text-xs text-navy-400">
              Video URL
            </label>
            <TextInput
              onChange={(e) => handleTextInput(e)}
              name="href"
              className="mb-6"
              value={vodData.href}
            />
            <label className="font-semibold text-xs text-navy-400">
              Video thumbnail URL - Optional
            </label>
            <TextInput
              onChange={(e) => handleTextInput(e)}
              name="thumbnail"
              value={vodData.thumbnail}
            />
          </section>
          {onRestartClicked && (
            <section className="mt-12 flex justify-end">
              <Button
                onClick={() => {
                  onRestartClicked(
                    job.videoId,
                    vodData.href,
                    vodData.thumbnail
                  );
                  setSearchParams(
                    {
                      details: "job",
                    },
                    {
                      replace: true,
                    }
                  );
                }}
                size="small"
                variant="contained"
                color="primary"
              >
                Retry
              </Button>
            </section>
          )}
        </div>
      );
      break;
    }

    case "segments": {
      body = (
        <SegmentsTabBody
          key={"segments"}
          segmentIds={segmentIds?.filter(
            (segmentId) => !segmentId.startsWith("$")
          )}
        />
      );
      break;
    }

    case "inverse-segments": {
      body = (
        <SegmentsTabBody
          key={"inverse-segments"}
          segmentIds={segmentIds?.filter((segmentId) =>
            segmentId.startsWith("$")
          )}
        />
      );
      break;
    }

    default: {
      body = (
        <div className="flex-row flex-auto m-4 p-6 bg-white">
          <div className="self-center mb-8">
            <JobThumbnail
              size="medium"
              className="min-w-full"
              src={job.thumbnailHref}
            />
          </div>
          <h6 className="text-navy-400 font-semibold mb-2">
            {job?.title || "-"}
          </h6>
          <div className="text-h7 text-black-100 mb-8">
            {video && temporal.formatMilliseconds(video.DurationMillis)}
          </div>
          <section className="divide-y divide-coolGrey-200">
            <Information.Container>
              <Information.Key className="text-black-200 uppercase">
                Information
              </Information.Key>
            </Information.Container>
            <Information.Container>
              <Information.Key>Video ID</Information.Key>
              <Information.Value>
                {truncate.middle(job.videoId, 30, "[...]")}
              </Information.Value>
              <Information.Copy
                onClick={clipboard.copyToHandler({
                  value: job.videoId,
                  onComplete: clipboard.toastSuccess({
                    message: "Video ID copied to clipboard",
                  }),
                })}
              />
            </Information.Container>
            <Information.Container>
              <Information.Key>Url</Information.Key>
              <Information.Value className="underline">
                {truncate.url(job.href, 30, "[...]")}
              </Information.Value>
              <Information.Copy
                onClick={clipboard.copyToHandler({
                  value: job.href,
                  onComplete: clipboard.toastSuccess({
                    message: "URL copied to clipboard",
                  }),
                })}
              />
            </Information.Container>
            <Information.Container>
              <Information.Key>Workflow ID</Information.Key>
              <Information.Value>
                {job.workflowExecutionId
                  ? truncate.tail(job.workflowExecutionId, 20, "...")
                  : "-"}
              </Information.Value>
              {job.workflowExecutionId && (
                <Information.Copy
                  onClick={clipboard.copyToHandler({
                    value: job.workflowExecutionId,
                    onComplete: clipboard.toastSuccess({
                      message: "Workflow ID copied to clipboard",
                    }),
                  })}
                />
              )}
            </Information.Container>
            <Information.Container>
              <Information.Key>Asset ID</Information.Key>
              <Information.Value>
                {job.assetId ? truncate.tail(job.assetId, 20, "...") : "-"}
              </Information.Value>
              {job.assetId && (
                <Information.Copy
                  onClick={clipboard.copyToHandler({
                    value: job.assetId,
                    onComplete: clipboard.toastSuccess({
                      message: "Asset ID copied to clipboard",
                    }),
                  })}
                />
              )}
            </Information.Container>
            <Information.Container>
              <Information.Key>Started</Information.Key>
              <Information.Value>
                {formatInTimeZone(
                  new Date(job.createdAt || ""),
                  getTimezone(),
                  "P 'at' p",
                  { locale: getLocale() }
                )}
              </Information.Value>
            </Information.Container>
            <Information.Container>
              <Information.Key>Type</Information.Key>
              <Information.Value>VOD</Information.Value>
            </Information.Container>
            <Information.Container>
              <Information.Key>Status</Information.Key>
              <Information.Value>
                {VodStatus.isFailed(job) ? (
                  <Link
                    to={`/videos/${job.videoId}?tab=${VodSliderOverTab.Error}`}
                  >
                    <VodStatus.Label
                      mieStatus={job.mieStatus}
                      ingestionStatus={job.ingestionStatus}
                    />
                  </Link>
                ) : (
                  <VodStatus.Label
                    mieStatus={job.mieStatus}
                    ingestionStatus={job.ingestionStatus}
                  />
                )}
              </Information.Value>
            </Information.Container>
            <Information.Container>
              <Information.Key>Business Unit</Information.Key>
              <Information.Value>
                {job.businessUnit ? (
                  <GenericLabel>{job.businessUnit.toLowerCase()}</GenericLabel>
                ) : (
                  "-"
                )}
              </Information.Value>
            </Information.Container>
          </section>
          {showViewInsightsButton && (
            <section className="mt-11 flex justify-end">
              {VodStatus.isSuccessful(job) && (
                <Link to={`/insights/${job.videoId}/overview`}>
                  <Button size="small" variant="contained">
                    View insights
                  </Button>
                </Link>
              )}
            </section>
          )}
        </div>
      );
      break;
    }
  }

  return <SlideOver.Body className="flex flex-col">{body}</SlideOver.Body>;
};
