import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Upload = createSvgIcon({
  content: (
    <>
      <path
        d="M10 3.33203V14.1654"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M5.83398 6.66667L10.0007 2.5L14.1673 6.66667"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M16.6673 17.5H3.33398"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </>
  ),
  width: 20,
  height: 20,
});
