import { tail } from "./tail";

export function email(input: string, maxLength: number, separator: string) {
  if (input.indexOf("@") !== -1) {
    const [left, right] = input.split("@");
    const leftMaxLength = maxLength - right.length - 1;
    const trimmedLeft = tail(left, leftMaxLength, separator);
    return `${trimmedLeft}@${right}`;
  }

  return input;
}
