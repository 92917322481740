import React, { type ReactNode } from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface HeaderProps {
  className?: any;
  children: ReactNode;
}

export const Header = ({ children, className }: HeaderProps) => (
  <div
    className={classnames(
      className,
      "text-h7",
      "normal-case",
      "font-semibold",
      "tracking-base",
      "leading-h7",
      "text-navy-400",
      "font-semibold",
      "text-center",
      "mt-8"
    )}
  >
    {children}
  </div>
);
