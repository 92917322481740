import React, { useState } from "react";

import {
  FilterGroup as FilterGroupComponent,
  FilterGroupProps as FilterGroupComponentProps,
} from "@components/molecules";

interface FilterGroupProps
  extends Pick<FilterGroupComponentProps, "title" | "children"> {}

export const FilterGroup = (props: FilterGroupProps) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <FilterGroupComponent
      {...props}
      onClick={() => setIsOpen((s) => !s)}
      isOpen={isOpen}
    />
  );
};
