import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Export = createSvgIcon({
  content: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.59268 12.021C3.33233 11.7607 3.33233 11.3386 3.59268 11.0782L12.078 2.59294C12.3383 2.33259 12.7604 2.33259 13.0208 2.59294C13.2811 2.85329 13.2811 3.2754 13.0208 3.53574L4.53549 12.021C4.27514 12.2814 3.85303 12.2814 3.59268 12.021Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.64024 3.06417C7.64024 2.69598 7.93871 2.3975 8.3069 2.3975L12.5495 2.3975C12.9177 2.3975 13.2162 2.69598 13.2162 3.06417C13.2162 3.43236 12.9177 3.73083 12.5495 3.73083L8.3069 3.73083C7.93871 3.73083 7.64024 3.43236 7.64024 3.06417Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5848 8.05601C12.2166 8.05601 11.9182 7.75753 11.9182 7.38934L11.9182 3.1467C11.9182 2.77851 12.2166 2.48004 12.5848 2.48004C12.953 2.48004 13.2515 2.77852 13.2515 3.14671L13.2515 7.38934C13.2515 7.75753 12.953 8.05601 12.5848 8.05601Z"
        className="fill-current"
      />
    </>
  ),
  width: 16,
  height: 16,
});
