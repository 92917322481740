import React from "react";

export interface AtlasIconLogoProps {
  className?: any;
  size?: number;
}

export const AtlasIconLogo = ({
  className,
  size = 217,
  ...rest
}: AtlasIconLogoProps) => (
  <svg
    width={size}
    className={className}
    viewBox="0 0 217 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M108.498 71.9998L62.3306 180H0.774902L77.7203 0L108.498 71.9998Z"
      fill="url(#paint0_linear_4815_127385)"
    />
    <path
      d="M139.274 0L108.496 71.9998L77.7178 0H139.274Z"
      fill="url(#paint1_linear_4815_127385)"
    />
    <path
      d="M216.225 179.996H154.668L57.9966 77.1434L154.668 125.996L216.225 179.996Z"
      fill="url(#paint2_linear_4815_127385)"
    />
    <path
      d="M216.22 180H154.664L131.581 126L108.497 71.9998L139.276 0L216.22 180Z"
      fill="url(#paint3_linear_4815_127385)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4815_127385"
        x1="32.6939"
        y1="180.773"
        x2="94.3736"
        y2="26.8099"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#454DE2" />
        <stop offset="0.8012" stopColor="#0E337A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4815_127385"
        x1="108.496"
        y1="71.9999"
        x2="108.496"
        y2="0.415777"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#454DE2" />
        <stop offset="0.114" stopColor="#5A75E5" />
        <stop offset="0.2552" stopColor="#709FE8" />
        <stop offset="0.3992" stopColor="#83C2EB" />
        <stop offset="0.5446" stopColor="#91DCED" />
        <stop offset="0.6919" stopColor="#9BF0EF" />
        <stop offset="0.8422" stopColor="#A1FBF0" />
        <stop offset="1" stopColor="#A3FFF0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4815_127385"
        x1="57.9965"
        y1="128.57"
        x2="216.225"
        y2="128.57"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#09204C" />
        <stop offset="0.8766" stopColor="#0E337A" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4815_127385"
        x1="108.497"
        y1="90"
        x2="216.22"
        y2="90"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#454DE2" />
        <stop offset="1" stopColor="#0E337A" />
      </linearGradient>
    </defs>
  </svg>
);
