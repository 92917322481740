import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const DocumentOutline = createSvgIcon({
  content: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.53125 1.12218C1.53125 0.876719 1.73023 0.677734 1.97569 0.677734H9.69792C9.81599 0.677734 9.92921 0.724718 10.0126 0.808317L14.2904 5.09721C14.3735 5.18052 14.4201 5.29339 14.4201 5.41107V14.8444C14.4201 15.0899 14.2212 15.2888 13.9757 15.2888H1.97569C1.73023 15.2888 1.53125 15.0899 1.53125 14.8444V1.12218ZM2.42014 1.56662V14.4H13.5312V5.59482L9.51348 1.56662H2.42014Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53244 0.711469C9.69866 0.642745 9.88991 0.680981 10.0169 0.80833L14.2947 5.09722C14.4216 5.22442 14.4594 5.41549 14.3905 5.58143C14.3217 5.74736 14.1597 5.85553 13.98 5.85553H9.70226C9.4568 5.85553 9.25781 5.65654 9.25781 5.41108V1.12219C9.25781 0.942326 9.36622 0.780193 9.53244 0.711469ZM10.1467 2.19715V4.96664H12.909L10.1467 2.19715Z"
        className="fill-current"
      />
      <line
        x1="4.5"
        y1="7.5"
        x2="10.5"
        y2="7.5"
        className="stroke-current"
        strokeLinecap="round"
      />
      <line
        x1="4.5"
        y1="9.5"
        x2="7.5"
        y2="9.5"
        className="stroke-current"
        strokeLinecap="round"
      />
    </>
  ),
  width: 16,
  height: 16,
});
