import React, {
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  RefAttributes,
} from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface ToggleSwitchProps
  extends InputHTMLAttributes<HTMLInputElement> {
  className?: any;
  displayChildren: "before" | "after";
  checked?: boolean;
  value?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const ToggleSwitch: ForwardRefExoticComponent<
  ToggleSwitchProps & RefAttributes<HTMLInputElement>
> = React.forwardRef<HTMLInputElement, ToggleSwitchProps>(
  (
    { displayChildren, children, className, checked, value, onChange, ...rest },
    ref
  ) => (
    <label
      className={classnames(
        className,
        "flex",
        "items-center",
        "cursor-pointer"
      )}
    >
      {displayChildren === "before" && children}
      <div className="relative" {...rest}>
        <input
          ref={ref}
          checked={checked}
          value={value}
          type="checkbox"
          className="sr-only peer"
          onChange={onChange}
        />
        <div className="block border border-coolGrey-300 bg-white peer-checked:border-blue-300 peer-checked:bg-blue-300 w-[50px] h-[24px] rounded-full" />
        <div className="shadow-1dp border border-coolGrey-300 peer-checked:border-white peer-checked:bg-white peer-checked:translate-x-[25.5px] absolute left-[4.5px] top-[3.75px] bg-white w-[16.5px] h-[16.5px] rounded-full transition" />
      </div>
      {displayChildren === "after" && children}
    </label>
  )
);
