import React from "react";

import { Checkbox } from "@components/atoms";
import { Status } from "@components/molecules/Segment";

export enum SegmentJobStatusValue {
  Active = "active",
  Draft = "draft",
  Failed = "failed",
  Processing = "processing",
}

const options: {
  [K in SegmentJobStatusValue]: {
    display: React.ReactNode;
    value: K;
  };
} = {
  active: {
    display: <Status.LabelActive />,
    value: SegmentJobStatusValue.Active,
  },
  draft: {
    display: <Status.LabelDraft />,
    value: SegmentJobStatusValue.Draft,
  },
  failed: {
    display: <Status.LabelFailed />,
    value: SegmentJobStatusValue.Failed,
  },
  processing: {
    display: <Status.LabelProcessing />,
    value: SegmentJobStatusValue.Processing,
  },
};

const name = "segment-job-status";

interface SegmentJobStatusProps {
  value?: SegmentJobStatusValue[];
  onChange: (value: SegmentJobStatusValue[]) => void;
}

export const SegmentJobStatus = ({
  value,
  onChange,
}: SegmentJobStatusProps) => (
  <div className="flex flex-col">
    {Object.values(options).map((filter) => (
      <Checkbox
        key={filter.value}
        className="py-2"
        name={name}
        checked={value?.includes(filter.value)}
        value={filter.value}
        onChange={(e) =>
          e.target.checked
            ? onChange([...(value || []), filter.value])
            : onChange(value?.filter((v) => v !== filter.value) || [])
        }
      >
        {filter.display}
      </Checkbox>
    ))}
  </div>
);
