import { Button, Icon } from "@components/atoms";
import { clipboard } from "@utils/index";

export type SegmentJobIdProps = {
  id: string;
};

export const SegmentJobId = ({ id }: SegmentJobIdProps) => (
  <div
    data-e2e="segment-id"
    className="flex flex-nowrap items-center justify-between outline-none leading-input w-full py-1 px-smx cursor-not-allowed bg-lightGrey-200 border border-coolGrey-300 text-black-100 rounded-lg"
  >
    <span>{id}</span>
    <Button
      className="ml-2 text-coolGrey-300"
      variant="contained"
      color="clear"
      icon={<Icon.Copy size={16} />}
      onClick={(e) => {
        e.stopPropagation();

        clipboard.copyToHandler({
          value: id,
          onComplete: clipboard.toastSuccess({
            message: "Copied to clipboard",
          }),
        })();
      }}
    />
  </div>
);
