import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import { Button, Loading, TextInput } from "@components/atoms";
import * as Icon from "@components/atoms/Icon";
import { Segment } from "@components/molecules";
import { NoResults } from "@components/molecules/Table";
import { Sidebar_ListSegmentJobsDocument } from "@graphql/operations";
import { FilterType, NestedSegmentFilter } from "@hooks/segments";
import { useDebounce } from "@hooks/useDebounce";

import { Section } from "./Section";

export const SegmentsSection = () => {
  const [showAllSegments, setShowAllSegments] = useState(false);
  const [searchValue, setSearchValue] = useState<string>();
  const debouncedSearchValue = useDebounce(searchValue, 300);
  const { data, previousData, loading } = useQuery(
    Sidebar_ListSegmentJobsDocument,
    {
      variables: {
        limit: showAllSegments
          ? undefined
          : !debouncedSearchValue
          ? 5
          : undefined,
        filter: {
          IdOrTitle: {
            contains: debouncedSearchValue,
          },
        },
      },
    }
  );

  const items =
    loading && (previousData?.listSegmentJobs?.items?.length || 0) > 0
      ? previousData?.listSegmentJobs?.items
      : data?.listSegmentJobs?.items;

  return (
    <Section
      title={
        <>
          Segments
          {loading && <Loading.Slide className="shrink-0" />}
        </>
      }
    >
      <div className="px-6 py-2 bg-white">
        <TextInput
          icon={<Icon.Search size={16} className="ml-2 text-black-100" />}
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue || ""}
          placeholder="Search Segments"
        />
      </div>

      {items ? (
        <div className="border-t border-coolGrey-200">
          {items?.map((item) => (
            <Segment.MinimisedCondition<NestedSegmentFilter>
              key={item.SegmentId}
              icon={<Icon.Segment size={16} />}
              type={FilterType.NESTED_SEGMENT_ID__EQUALS}
              filter={{ value: item.SegmentId, version: item.Version }}
            >
              {item.Title}
            </Segment.MinimisedCondition>
          ))}
        </div>
      ) : (
        <div className="bg-white py-12">
          <NoResults />
        </div>
      )}

      {!debouncedSearchValue && (
        <div className="bg-white px-6 py-4">
          <Button variant="text" onClick={() => setShowAllSegments((s) => !s)}>
            {showAllSegments ? "Show less" : "View all"}
          </Button>
        </div>
      )}
    </Section>
  );
};
