import React, { type ReactNode } from "react";

import { Button } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

export interface IconButtonProps {
  className?: any;
  children: ReactNode;
}

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ className, children, ...rest }: IconButtonProps, ref) => (
    <Button
      ref={ref}
      variant="contained"
      color="clear"
      className={classnames(
        className,
        "bg-opacity-100",
        "rounded-lg",
        "hover:bg-opacity-40",
        "active:bg-opacity-64",
        "p-2"
      )}
      {...rest}
    >
      {children}
    </Button>
  )
);
