import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Tag = createSvgIcon({
  content: (
    <path
      d="M5.33203 12L1.33203 8L5.33203 4H13.332C13.6857 4 14.0248 4.14048 14.2748 4.39052C14.5249 4.64057 14.6654 4.97971 14.6654 5.33333V10.6667C14.6654 11.0203 14.5249 11.3594 14.2748 11.6095C14.0248 11.8595 13.6857 12 13.332 12H5.33203Z"
      className="stroke-current"
      strokeLinecap="square"
    />
  ),
  width: 16,
  height: 16,
});
