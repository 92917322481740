import React from "react";

import { Icon } from "@components/atoms";

export interface ErrorProps {
  message?: string;
}

export const Error = ({ message = "An error occurred" }: ErrorProps) => (
  <div className="flex flex-col items-center justify-center">
    <Icon.Exclamation className="text-coolGrey-200" />
    <h6 className="text-center mt-6 font-semibold uppercase text-coolGrey-300 tracking-widest">
      {message}
    </h6>
  </div>
);
