import { Amplify, Auth } from "aws-amplify";

import { IProvider, UserSession } from "./useAuth";

function provider(): IProvider {
  const {
    REACT_APP_COGNITO_REGION,
    REACT_APP_COGNITO_POOL_ID,
    REACT_APP_COGNITO_CLIENT_ID,
    REACT_APP_COGNITO_DOMAIN,
  } = process.env;

  if (!REACT_APP_COGNITO_REGION) {
    throw new Error("No AWS Cognito region provided");
  }
  if (!REACT_APP_COGNITO_POOL_ID) {
    throw new Error("No AWS Cognito user pool id provided");
  }
  if (!REACT_APP_COGNITO_CLIENT_ID) {
    throw new Error("No AWS Cognito client id provided");
  }
  if (!REACT_APP_COGNITO_DOMAIN) {
    throw new Error("No AWS Cognito domain provided");
  }

  Amplify.configure({
    Auth: {
      region: REACT_APP_COGNITO_REGION,
      userPoolId: REACT_APP_COGNITO_POOL_ID,
      userPoolWebClientId: REACT_APP_COGNITO_CLIENT_ID,
      oauth: {
        domain: REACT_APP_COGNITO_DOMAIN,
        scope: ["email", "openid"],
        redirectSignIn: window.location.origin + "/login/callback",
        redirectSignOut: window.location.origin + "/login/callback",
        responseType: "code",
      },
    },
  });

  return {
    signIn: () => Auth.federatedSignIn({ customProvider: "Okta" }),
    signOut: () => Auth.signOut(),
    getSession: async (): Promise<UserSession | null> => {
      try {
        return await Auth.currentSession();
      } catch (err) {
        return null;
      }
    },
  };
}

export default provider;
