import React from "react";

import {
  ConditionTemplate,
  ConditionTemplateProps,
} from "@components/atoms/Segment";
import { FilterType, TitleFilter } from "@hooks/segments";

import { MultilineShortcut } from "./MultilineShortcut";

interface Props {
  onChange: (value: TitleFilter) => void | Promise<void>;
}

export type VideoTitleContainsProps = Props &
  TitleFilter &
  Omit<ConditionTemplateProps, "type">;

export const VideoTitleContains = ({
  onChange,
  value,
  location,
  ...rest
}: VideoTitleContainsProps) => (
  <ConditionTemplate
    type={FilterType.VIDEO_TITLE__CONTAINS}
    location={location}
    {...rest}
  >
    <label className="mb-2 font-semibold text-xs text-navy-400">
      Video Title
    </label>
    <MultilineShortcut
      conditionId={rest.id}
      label="Video Title Contains"
      value={value || ""}
      onChange={(value) => onChange({ value })}
      placeholderTextName="video title"
      location={location}
      filterType={FilterType.VIDEO_TITLE__CONTAINS}
    />
  </ConditionTemplate>
);
