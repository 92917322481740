export interface Query {
  allOfTheFollowing?: QueryCondition[] | undefined;
  anyOfTheFollowing?: QueryCondition[] | undefined;
  exclude?: QueryCondition[] | undefined;
}

export enum QueryConditionType {
  MIN_OCCURRENCE__COUNT = "MIN_OCCURRENCE__COUNT",
  LABEL__EQUALS = "LABEL__EQUALS",
  CELEBRITY__EQUALS = "CELEBRITY__EQUALS",
  CONTENT_MODERATION__EQUALS = "CONTENT_MODERATION__EQUALS",
  TRANSCRIPT__CONTAINS = "TRANSCRIPT__CONTAINS",
  SENTIMENT__EQUALS = "SENTIMENT__EQUALS",
  VIDEO_TITLE__CONTAINS = "VIDEO_TITLE__CONTAINS",
  METADATA__CONTAINS = "METADATA__CONTAINS",
  VIDEO_DURATION__EQUALS = "VIDEO_DURATION__EQUALS",
  NESTED_SEGMENT_ID__EQUALS = "NESTED_SEGMENT_ID__EQUALS",
  TEXT_IN_VIDEO__CONTAINS = "TEXT_IN_VIDEO__CONTAINS",

  RUN_DIFFERENTIAL__COMPARE = "RUN_DIFFERENTIAL__COMPARE",
  INNING__COMPARE = "INNING__COMPARE",
  EVENT_RUNS_SCORED__COMPARE = "EVENT_RUNS_SCORED__COMPARE",
  EVENT_LEVERAGE_INDEX__COMPARE = "EVENT_LEVERAGE_INDEX__COMPARE",

  CLOSE_GAME__EQUALS = "CLOSE_GAME__EQUALS",
  DELAYED_GAME__EQUALS = "DELAYED_GAME__EQUALS",
  DOUBLE_HEADER__EQUALS = "DOUBLE_HEADER__EQUALS",
  NIGHT_GAME__EQUALS = "NIGHT_GAME__EQUALS",
  VENUE__EQUALS = "VENUE__EQUALS",
  PRE_ALL_STAR__EQUALS = "PRE_ALL_STAR__EQUALS",
  POST_ALL_STAR__EQUALS = "POST_ALL_STAR__EQUALS",
  SEASON_TYPE__EQUALS = "SEASON_TYPE__EQUALS",
  HOME_TEAM__EQUALS = "HOME_TEAM__EQUALS",
  AWAY_TEAM__EQUALS = "AWAY_TEAM__EQUALS",
  DIVISION__EQUALS = "DIVISION__EQUALS",
  LEAGUE__EQUALS = "LEAGUE__EQUALS",
  PLAYER__EQUALS = "PLAYER__EQUALS",
  WINNING_TEAM__EQUALS = "WINNING_TEAM__EQUALS",
  EXTRA_INNINGS__EQUALS = "EXTRA_INNINGS__EQUALS",
  SERIES_TYPE__EQUALS = "SERIES_TYPE__EQUALS",
  INNING_HALF__EQUALS = "INNING_HALF__EQUALS",
  EVENT_TYPE__EQUALS = "EVENT_TYPE__EQUALS",
  EVENT_DESCRIPTION__CONTAINS = "EVENT_DESCRIPTION__CONTAINS",
  LINEUP_CHANGE__EQUALS = "LINEUP_CHANGE__EQUALS",
  STOLEN_BASE__EQUALS = "STOLEN_BASE__EQUALS",
}

export type QueryCondition =
  | { [QueryConditionType.MIN_OCCURRENCE__COUNT]: MinOccurrenceCountVariables }
  | { [QueryConditionType.LABEL__EQUALS]: LabelEqualsVariables }
  | { [QueryConditionType.CELEBRITY__EQUALS]: CelebrityEqualsVariables }
  | {
      [QueryConditionType.CONTENT_MODERATION__EQUALS]: ContentModerationEqualsVariables;
    }
  | { [QueryConditionType.TRANSCRIPT__CONTAINS]: TranscriptContainsVariables }
  | { [QueryConditionType.SENTIMENT__EQUALS]: SentimentEqualsVariables }
  | { [QueryConditionType.VIDEO_TITLE__CONTAINS]: VideoTitleContainsVariables }
  | {
      [QueryConditionType.VIDEO_DURATION__EQUALS]: VideoDurationEqualsVariables;
    }
  | {
      [QueryConditionType.NESTED_SEGMENT_ID__EQUALS]: NestedSegmentIdEqualsVariables;
    }
  | {
      [QueryConditionType.TEXT_IN_VIDEO__CONTAINS]: TextInVideoContainsVariables;
    }
  | { [QueryConditionType.METADATA__CONTAINS]: MetadataContainsVariables }
  | {
      [QueryConditionType.RUN_DIFFERENTIAL__COMPARE]: GenericSportsMetricVariables;
    }
  | { [QueryConditionType.INNING__COMPARE]: GenericSportsMetricVariables }
  | {
      [QueryConditionType.EVENT_RUNS_SCORED__COMPARE]: GenericSportsMetricVariables;
    }
  | {
      [QueryConditionType.EVENT_LEVERAGE_INDEX__COMPARE]: GenericSportsMetricVariables;
    }
  | {
      [QueryConditionType.CLOSE_GAME__EQUALS]: SportsBooleanWithToleranceVariables;
    }
  | {
      [QueryConditionType.DELAYED_GAME__EQUALS]: SportsBooleanDimensionVariables;
    }
  | {
      [QueryConditionType.DOUBLE_HEADER__EQUALS]: SportsBooleanDimensionVariables;
    }
  | { [QueryConditionType.NIGHT_GAME__EQUALS]: SportsBooleanDimensionVariables }
  | { [QueryConditionType.VENUE__EQUALS]: SportsStringDimensionVariables }
  | {
      [QueryConditionType.PRE_ALL_STAR__EQUALS]: SportsStringDimensionVariables;
    }
  | {
      [QueryConditionType.POST_ALL_STAR__EQUALS]: SportsStringDimensionVariables;
    }
  | {
      [QueryConditionType.SEASON_TYPE__EQUALS]: SportsStringDimensionVariables;
    }
  | { [QueryConditionType.HOME_TEAM__EQUALS]: SportsStringDimensionVariables }
  | { [QueryConditionType.AWAY_TEAM__EQUALS]: SportsStringDimensionVariables }
  | { [QueryConditionType.DIVISION__EQUALS]: SportsStringDimensionVariables }
  | { [QueryConditionType.LEAGUE__EQUALS]: SportsStringDimensionVariables }
  | { [QueryConditionType.PLAYER__EQUALS]: GenericSportsDimensionVariables }
  | {
      [QueryConditionType.WINNING_TEAM__EQUALS]: SportsStringDimensionVariables;
    }
  | {
      [QueryConditionType.EXTRA_INNINGS__EQUALS]: GenericSportsDimensionVariables;
    }
  | {
      [QueryConditionType.SERIES_TYPE__EQUALS]: SportsStringDimensionVariables;
    }
  | {
      [QueryConditionType.INNING_HALF__EQUALS]: GenericSportsDimensionVariables;
    }
  | { [QueryConditionType.EVENT_TYPE__EQUALS]: GenericSportsDimensionVariables }
  | { [QueryConditionType.EVENT_TYPE__EQUALS]: GenericSportsDimensionVariables }
  | {
      [QueryConditionType.EVENT_DESCRIPTION__CONTAINS]: GenericSportsDimensionVariables;
    }
  | {
      [QueryConditionType.LINEUP_CHANGE__EQUALS]: SportsBooleanWithToleranceVariables;
    }
  | {
      [QueryConditionType.STOLEN_BASE__EQUALS]: GenericSportsDimensionVariables;
    };

export type QueryConditionTuple =
  | [QueryConditionType.LABEL__EQUALS, LabelEqualsVariables]
  | [QueryConditionType.CELEBRITY__EQUALS, CelebrityEqualsVariables]
  | [
      QueryConditionType.CONTENT_MODERATION__EQUALS,
      ContentModerationEqualsVariables
    ]
  | [QueryConditionType.TRANSCRIPT__CONTAINS, TranscriptContainsVariables]
  | [QueryConditionType.SENTIMENT__EQUALS, SentimentEqualsVariables]
  | [QueryConditionType.VIDEO_TITLE__CONTAINS, VideoTitleContainsVariables]
  | [QueryConditionType.METADATA__CONTAINS, MetadataContainsVariables]
  | [QueryConditionType.VIDEO_DURATION__EQUALS, VideoDurationEqualsVariables]
  | [
      QueryConditionType.NESTED_SEGMENT_ID__EQUALS,
      NestedSegmentIdEqualsVariables
    ]
  | [QueryConditionType.TEXT_IN_VIDEO__CONTAINS, TextInVideoContainsVariables]
  | [QueryConditionType.MIN_OCCURRENCE__COUNT, MinOccurrenceCountVariables]
  | [QueryConditionType.RUN_DIFFERENTIAL__COMPARE, GenericSportsMetricVariables]
  | [QueryConditionType.INNING__COMPARE, GenericSportsMetricVariables]
  | [
      QueryConditionType.EVENT_RUNS_SCORED__COMPARE,
      GenericSportsMetricVariables
    ]
  | [
      QueryConditionType.EVENT_LEVERAGE_INDEX__COMPARE,
      GenericSportsMetricVariables
    ]
  | [QueryConditionType.CLOSE_GAME__EQUALS, SportsBooleanWithToleranceVariables]
  | [QueryConditionType.DELAYED_GAME__EQUALS, SportsBooleanDimensionVariables]
  | [QueryConditionType.DOUBLE_HEADER__EQUALS, SportsBooleanDimensionVariables]
  | [QueryConditionType.NIGHT_GAME__EQUALS, SportsBooleanDimensionVariables]
  | [QueryConditionType.VENUE__EQUALS, SportsStringDimensionVariables]
  | [QueryConditionType.PRE_ALL_STAR__EQUALS, SportsStringDimensionVariables]
  | [QueryConditionType.POST_ALL_STAR__EQUALS, SportsStringDimensionVariables]
  | [QueryConditionType.SEASON_TYPE__EQUALS, SportsStringDimensionVariables]
  | [QueryConditionType.HOME_TEAM__EQUALS, SportsStringDimensionVariables]
  | [QueryConditionType.AWAY_TEAM__EQUALS, SportsStringDimensionVariables]
  | [QueryConditionType.DIVISION__EQUALS, SportsStringDimensionVariables]
  | [QueryConditionType.LEAGUE__EQUALS, SportsStringDimensionVariables]
  | [QueryConditionType.PLAYER__EQUALS, GenericSportsDimensionVariables]
  | [QueryConditionType.WINNING_TEAM__EQUALS, SportsStringDimensionVariables]
  | [QueryConditionType.EXTRA_INNINGS__EQUALS, GenericSportsDimensionVariables]
  | [QueryConditionType.SERIES_TYPE__EQUALS, SportsStringDimensionVariables]
  | [QueryConditionType.INNING_HALF__EQUALS, GenericSportsDimensionVariables]
  | [QueryConditionType.EVENT_TYPE__EQUALS, GenericSportsDimensionVariables]
  | [
      QueryConditionType.EVENT_DESCRIPTION__CONTAINS,
      GenericSportsDimensionVariables
    ]
  | [
      QueryConditionType.LINEUP_CHANGE__EQUALS,
      SportsBooleanWithToleranceVariables
    ]
  | [QueryConditionType.STOLEN_BASE__EQUALS, GenericSportsDimensionVariables];

export interface LabelEqualsVariables {
  value: string;
  tolerance: {
    lead: number;
    lag: number;
  };
  confidence?: {
    gte: number;
  };
}

export interface CelebrityEqualsVariables {
  value: string;
  tolerance: {
    lead: number;
    lag: number;
  };
  confidence?: {
    gte: number;
  };
}

export interface ContentModerationEqualsVariables {
  values: Array<string>;
  tolerance: {
    lead: number;
    lag: number;
  };
  confidence?: {
    gte: number;
  };
}

export interface TextInVideoContainsVariables {
  value: string;
  tolerance: {
    lead: number;
    lag: number;
  };
}

export interface MetadataContainsVariables {
  value: string;
}

export interface MinOccurrenceCountVariables {
  conditions: QueryCondition[];
  minCount: number;
  tolerance: {
    lead: number;
    lag: number;
  };
}

export interface TranscriptContainsVariables {
  value: string;
  tolerance: {
    lead: number;
    lag: number;
  };
}

export interface VideoTitleContainsVariables {
  value: string;
}

export interface NestedSegmentIdEqualsVariables {
  value: string;
  version: number;
}

export interface SentimentEqualsVariables {
  value: string;
  tolerance: {
    lead: number;
    lag: number;
  };
}

export interface VideoDurationEqualsVariables {
  minDuration: number;
  maxDuration: number;
}

export interface GenericSportsMetricVariables {
  value: number;
  tolerance: {
    lead: number;
    lag: number;
  };
  comparator: string;
}

export interface GenericSportsDimensionVariables {
  value: string;
  tolerance: {
    lead: number;
    lag: number;
  };
}

export interface SportsBooleanWithToleranceVariables {
  value: boolean;
  tolerance: {
    lead: number;
    lag: number;
  };
}

export interface SportsBooleanDimensionVariables {
  value: boolean;
}

export interface SportsStringDimensionVariables {
  value: string;
}

export type QueryVariables =
  | LabelEqualsVariables
  | CelebrityEqualsVariables
  | ContentModerationEqualsVariables
  | TextInVideoContainsVariables
  | MinOccurrenceCountVariables
  | TranscriptContainsVariables
  | VideoTitleContainsVariables
  | NestedSegmentIdEqualsVariables
  | SentimentEqualsVariables
  | VideoDurationEqualsVariables
  | GenericSportsMetricVariables
  | GenericSportsDimensionVariables
  | SportsBooleanWithToleranceVariables
  | SportsBooleanDimensionVariables
  | SportsStringDimensionVariables;
