import React, { CSSProperties } from "react";

import { classnames } from "@external/tailwindcss-classnames";

interface ShimmerProps {
  className?: any;
  style?: CSSProperties;
}

const base = {
  background:
    "linear-gradient(90deg, rgba(241,254,252,1) 0%, rgba(233,234,252,1) 100%)",
  backgroundSize: "300% 100%",
};

export const Shimmer = ({ className, style, ...rest }: ShimmerProps) => (
  <div
    {...rest}
    style={Object.assign(base, style)}
    className={classnames(className, "h-3", "animate-shine", "rounded")}
  />
);
