import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";
import { Auth } from "aws-amplify";

import { typeDefs } from "./schema";
import typePolicies from "./typePolicies";

export const apolloClient = async (
  graphqlEndpoint = process.env.REACT_APP_GRAPHQL_URL
) => {
  const REACT_APP_GRAPHQL_URL = graphqlEndpoint;

  if (!REACT_APP_GRAPHQL_URL) {
    throw new Error("No GraphQL endpoint provided");
  }

  const cache = new InMemoryCache({
    typePolicies,
    resultCaching: false,
  });

  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
    trigger: "write",
  });

  const authLink = setContext(async (_, { headers }) => {
    const user = await Auth.currentSession();

    return {
      headers: {
        ...headers,
        Authorization: user.getIdToken().getJwtToken(),
      },
    };
  });

  const httpLink = createHttpLink({
    uri: REACT_APP_GRAPHQL_URL,
  });

  return new ApolloClient({
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        initialFetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
      },
    },
    link: authLink.concat(httpLink),
    typeDefs,
    cache,
  });
};
