import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const InverseSegment = createSvgIcon({
  content: (
    <path
      d="M15.6667 5C14.3353 5 13.0778 5.35927 12 5.99328C10.9222 5.35927 9.66475 5 8.33333 5C4.28626 5 1 8.28626 1 12.3333C1 16.3804 4.28626 19.6667 8.33333 19.6667C9.66475 19.6667 10.9222 19.3074 12 18.6734C13.0778 19.3074 14.3353 19.6667 15.6667 19.6667C19.7137 19.6667 23 16.3804 23 12.3333C23 8.28626 19.7137 5 15.6667 5ZM9.91835 12.3333C9.91835 10.5581 10.732 8.96254 12 7.91643C13.268 8.96254 14.0817 10.5581 14.0817 12.3333C14.0817 14.1085 13.268 15.7041 12 16.7502C10.732 15.7041 9.91835 14.1085 9.91835 12.3333Z"
      className="fill-current"
    />
  ),
  width: 24,
  height: 24,
});
