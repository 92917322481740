import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Stop = createSvgIcon({
  content: (
    <path
      d="M13.0417 3.33325H3.70833V12.6666H13.0417V3.33325Z"
      stroke="#303030"
      stroke-linecap="square"
    />
  ),
  width: 17,
  height: 16,
});
