import React from "react";

import { Node, Tree, TreeView } from "@components/molecules";

import { FilterType, useFilters } from "../../hooks";

import { nodes } from "./nodes";

interface ContentModerationProps {
  mode: "contains" | "excludes";
}

// Only children can be used as filters / queries
const getChildrenByName = (nodes: Node[], name: string): Node | undefined => {
  const children = nodes.flatMap((node) => node.children || []);
  for (const child of children) {
    if (child.name === name) {
      return child;
    }
  }
};

export const ContentModeration = ({ mode }: ContentModerationProps) => {
  const context = useFilters();
  const containsFilter = context.getFilter(
    FilterType.CONTENT_MODERATION_CONTAINS
  );
  const excludesFilter = context.getFilter(
    FilterType.CONTENT_MODERATION_EXCLUDES
  );

  const excludedNodes = (excludesFilter?.values || []).map(
    (name) => getChildrenByName(nodes, name)!
  );
  const containsNodes = (containsFilter?.values || []).map(
    (name) => getChildrenByName(nodes, name)!
  );

  const onTreeChange = (tree: Tree) => {
    const selectedNodes = tree.leaves
      .filter((node) => node.selectionState === "on")
      .map((node) => node.name);

    if (mode === "contains") {
      if (selectedNodes.length > 0) {
        context.addFilter({
          values: selectedNodes,
          filterType: FilterType.CONTENT_MODERATION_CONTAINS,
        });
      } else {
        context.removeFilter(FilterType.CONTENT_MODERATION_CONTAINS);
      }
    }

    if (mode === "excludes") {
      if (selectedNodes.length > 0) {
        context.addFilter({
          values: selectedNodes,
          filterType: FilterType.CONTENT_MODERATION_EXCLUDES,
        });
      } else {
        context.removeFilter(FilterType.CONTENT_MODERATION_EXCLUDES);
      }
    }
  };

  const selectedNodeIds =
    mode === "contains"
      ? containsNodes.map((node) => node.id)
      : excludedNodes.map((node) => node.id);

  const hiddenNodeIds =
    mode === "contains"
      ? excludedNodes.map((node) => node.id)
      : containsNodes.map((node) => node.id);

  return (
    <div>
      <TreeView
        nodes={nodes}
        selectedNodeIds={selectedNodeIds}
        hiddenNodeIds={hiddenNodeIds}
        onTreeChange={onTreeChange}
      />
    </div>
  );
};
