import React, { type ReactNode } from "react";

import { Button } from "../Button";
import * as Icon from "../Icon";

export interface HeaderProps {
  onClose: () => void;
  children: ReactNode;
}

export const Header = ({ onClose, children }: HeaderProps) => (
  <div className="min-h-24 p-6 pb-0 border-coolGrey-200 border-b flex flex-row items-start justify-between">
    {children}
    <Button
      className="text-coolGrey-300 focus:outline-none"
      color="clear"
      onClick={onClose}
    >
      <Icon.Close />
    </Button>
  </div>
);
