import React from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import { isEmpty } from "lodash";

import { Button, Icon } from "@components/atoms";
import { Segment } from "@components/molecules";
import {
  ConnectedSegmentPreview_JobsAggregateDocument,
  ConnectedSegmentPreview_SegmentPreviewDocument,
  SegmentQuery,
} from "@graphql/operations";
import { useFilters } from "@hooks/segments";
import { useDebounce } from "@hooks/useDebounce";

interface ConnectedSegmentPreviewProps {
  loadingQuery?: boolean;
}

export const ConnectedSegmentPreview = ({
  loadingQuery,
}: ConnectedSegmentPreviewProps) => {
  const { query } = useFilters();
  const debouncedQuery = useDebounce(query, loadingQuery ? 0 : 500);

  const hasQueries =
    debouncedQuery?.exclude ||
    debouncedQuery?.anyOfTheFollowing ||
    debouncedQuery?.allOfTheFollowing;

  const {
    data: totalJobsData,
    error: totalJobsError,
    refetch: totalJobsRefetch,
    networkStatus: totalJobsNetworkStatus,
    loading: totalJobsLoadingIndicator,
  } = useQuery(ConnectedSegmentPreview_JobsAggregateDocument, {
    notifyOnNetworkStatusChange: true,
  });

  const totalJobsLoading =
    totalJobsLoadingIndicator ||
    totalJobsNetworkStatus === NetworkStatus.refetch;

  const {
    data: segmentPreviewData,
    error: segmentPreviewError,
    refetch: segmentPreviewQueryRefetch,
    networkStatus: segmentPreviewNetworkStatus,
    loading: segmentPreviewLoadingIndicator,
  } = useQuery(ConnectedSegmentPreview_SegmentPreviewDocument, {
    variables: {
      filter: {
        query: debouncedQuery as SegmentQuery,
      },
    },
    skip: isEmpty(debouncedQuery),
    notifyOnNetworkStatusChange: true,
  });

  const loading =
    totalJobsLoading ||
    segmentPreviewLoadingIndicator ||
    segmentPreviewNetworkStatus === NetworkStatus.refetch;

  return (
    <div className="relative">
      <Button
        className="absolute top-4 right-4 p-2 text-coolGrey-300"
        type="button"
        variant="contained"
        size="tight"
        disabled={!hasQueries}
        onClick={async () =>
          await Promise.all([segmentPreviewQueryRefetch(), totalJobsRefetch()])
        }
      >
        <Icon.Refresh size={16} />
      </Button>
      <Segment.Preview
        totalOccurrences={
          segmentPreviewData?.segmentPreview?.occurrenceCount || undefined
        }
        totalIsolateVideos={
          segmentPreviewData?.segmentPreview?.videoCount || undefined
        }
        totalVideos={totalJobsData?.jobs?.aggregate?.Total}
        query={debouncedQuery}
        loadingQuery={loadingQuery}
        loading={loading}
        error={!!segmentPreviewError || !!totalJobsError}
      />
    </div>
  );
};
