import React, { HTMLAttributes } from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface BodyProps extends HTMLAttributes<HTMLDivElement> {
  className?: any;
}

export const Body = ({ children, className, ...rest }: BodyProps) => (
  <div className={classnames(className, "m-6", "h-full")} {...rest}>
    {children}
  </div>
);
