import React, { useState } from "react";

import { DialogBox, Segment } from "@components/atoms";
import { FilterType, LabelFilter } from "@hooks/segments";

import { MultilineShortcut } from "./MultilineShortcut";
import { ShowAdvanced } from "./ShowAdvanced";

interface Props {
  onChange: (value: LabelFilter) => void | Promise<void>;
}

export type LabelEqualsProps = Props &
  LabelFilter &
  Omit<Segment.ConditionTemplateProps, "type">;

export const LabelEquals = ({
  onChange,
  confidence,
  value,
  within,
  location,
  ...rest
}: LabelEqualsProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);

  return (
    <>
      <Segment.ConditionTemplate
        type={FilterType.LABEL__CONTAINS}
        location={location}
        {...rest}
      >
        <label className="mb-2 font-semibold text-xs text-navy-400">
          Object Or Scene
        </label>
        <MultilineShortcut
          conditionId={rest.id}
          label="Object Or Scene"
          value={value || ""}
          onChange={(value) => {
            onChange({ value, confidence, within });
          }}
          placeholderTextName="object or scene"
          location={location}
          filterType={FilterType.LABEL__CONTAINS}
          appendFilterProps={{ within, confidence }}
        />
        <ShowAdvanced
          setIsAdvancedOpen={setIsAdvancedOpen}
          isAdvancedOpen={isAdvancedOpen}
        >
          <div className="grid grid-cols-2 gap-x-4">
            <Segment.ConfidenceThreshold
              onAfterChange={(confidence) =>
                onChange({ value, confidence, within })
              }
            />
            <Segment.DurationInput
              value={within}
              onChange={(within) => onChange({ value, confidence, within })}
              onInfoClick={() => setIsDialogOpen(true)}
            />
          </div>
        </ShowAdvanced>
      </Segment.ConditionTemplate>
      <DialogBox.Container
        isDialogOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogBox.Card onClose={() => setIsDialogOpen(false)}>
          <DialogBox.Title>Tolerance</DialogBox.Title>
          <p className="text-sm">
            Specify a buffer of time around the occurence.
            <br />
            E.g., Ginger Rogers must appear in the frame{" "}
            <span className="font-bold">within 5 minutes</span> of a given point
            in the video for that point to meet segment condititions.
          </p>
        </DialogBox.Card>
      </DialogBox.Container>
    </>
  );
};
