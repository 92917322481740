import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Exit = createSvgIcon({
  content: (
    <>
      <path
        d="M12 10L14 8L12 6"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M7.66675 8H13.3334"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M13.9999 8H13.3333"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.0001 2.66666V13.3333H2.66675V2.66666H10.0001Z"
        stroke="#4D6CA3"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </>
  ),
  width: 16,
  height: 16,
});
