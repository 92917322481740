import React, { useMemo, useState } from "react";
import { formatInTimeZone } from "date-fns-tz";

import { Button } from "@components/atoms";
import * as Icon from "@components/atoms/Icon";
import { CustomDialog, Segment, Table } from "@components/molecules";
import { SegmentJobId } from "@components/pages/SegmentJobDetails/SegmentJobId";
import { classnames } from "@external/tailwindcss-classnames";
import {
  SegmentJobRedshiftQueryStatus,
  SegmentJobStatus,
} from "@graphql/operations";
import { useDebug } from "@hooks/useDebug";
import { getLocale, getLocaleCode, getTimezone } from "@utils/i18n";
import * as truncate from "@utils/truncate";

import { ActionsMenu } from "./ActionsMenu";
import { ExportDialog } from "./ExportDialog";

const localeCode = getLocaleCode();

export interface SegmentRowProps {
  inverse?: boolean;
  segmentId: string;
  version: number;
  title?: string;
  description?: string;
  videoCount?: number;
  totalVideoPercentage: string;
  createdAt: string;
  redshiftQueryStatus?: SegmentJobRedshiftQueryStatus;
  status: SegmentJobStatus;
  segmentCount?: number;
  onDuplicateClick?: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onCopyLinkClick?: () => void;
  onClick?: (segmentId: string, version: number) => void;
  onRetryClick?: () => void;
  onReindexClick?: () => void | Promise<void>;
}

export const SegmentRow = ({
  inverse,
  segmentId,
  version,
  title,
  description,
  videoCount,
  createdAt,
  totalVideoPercentage,
  redshiftQueryStatus,
  segmentCount,
  onDeleteClick,
  onDuplicateClick,
  onEditClick,
  onRetryClick,
  status,
  onClick,
  onReindexClick,
  onCopyLinkClick,
}: SegmentRowProps) => {
  const { debugEnabled } = useDebug();

  const showActionsMenu = useMemo(
    () =>
      onDuplicateClick ||
      onEditClick ||
      onDeleteClick ||
      onCopyLinkClick ||
      inverse,
    [onDuplicateClick, onEditClick, onDeleteClick, onCopyLinkClick, inverse]
  );
  const formattedSegmentId = useMemo(
    () => (inverse ? `$${segmentId}` : segmentId),
    [segmentId, inverse]
  );
  const [exportDialogIsOpen, setExportDialogIsOpen] = useState(false);

  return (
    <>
      <Table.Row
        data-testid={`segment-row${inverse ? "-inverse" : ""}`}
        data-e2e="segment"
        onClick={
          onClick ? () => onClick(formattedSegmentId, version) : undefined
        }
        height="h-24"
      >
        <Table.Cell className="flex items-start gap-2">
          {inverse ? (
            <Icon.InverseSegment
              data-testid="icon"
              size={16}
              color="red-300"
              className={classnames("mt-1", "shrink-0" as any)}
            />
          ) : (
            <Icon.Segment
              data-testid="icon"
              size={16}
              color="blue-300"
              className={classnames("mt-1", "shrink-0" as any)}
            />
          )}

          <div className="space-y-2">
            <div className="font-medium flex items-center">
              <span>{title || "-"}</span>
            </div>

            <div className="text-black-100 text-xs">
              {truncate.tail(description || "-", 46, "...")}
            </div>
          </div>
        </Table.Cell>
        <Table.Cell>
          <SegmentJobId id={formattedSegmentId} />
        </Table.Cell>
        <Table.Cell>
          <span className="font-semibold" data-e2e="videos-total-count">
            {videoCount?.toLocaleString(localeCode) || "-"} videos
          </span>{" "}
          ({totalVideoPercentage})
          {!inverse && (
            <div className="text-black-100 text-xs">
              {segmentCount?.toLocaleString(localeCode) || "-"} occurrences
            </div>
          )}
        </Table.Cell>
        <Table.Cell>
          {formatInTimeZone(createdAt, getTimezone(), "P", {
            locale: getLocale(),
          })}
        </Table.Cell>
        <Table.Cell>
          <div className="flex flex-row items-center justify-between">
            <Segment.Status.Label
              redshiftQueryStatus={redshiftQueryStatus}
              status={status}
            />
            {Segment.Status.isFailed({
              redshiftQueryStatus: redshiftQueryStatus,
              status: status,
            }) &&
              onRetryClick && (
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  className="float-right"
                  onClick={(e) => {
                    e.stopPropagation();
                    onRetryClick();
                  }}
                >
                  Retry
                </Button>
              )}
          </div>
        </Table.Cell>
        <Table.Cell>
          {showActionsMenu && (
            <ActionsMenu
              relative={false}
              onDuplicateClick={onDuplicateClick}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              onExportClick={
                inverse ? () => setExportDialogIsOpen(true) : undefined
              }
              onCopyLinkClick={onCopyLinkClick}
              onReindexClick={debugEnabled ? onReindexClick : undefined}
            />
          )}
        </Table.Cell>
      </Table.Row>

      <CustomDialog.Container
        isDialogOpen={exportDialogIsOpen}
        onClose={() => setExportDialogIsOpen(false)}
      >
        <ExportDialog
          segmentId={segmentId}
          segmentVersion={version}
          title={`Export Segment ${formattedSegmentId}`}
          onClose={() => setExportDialogIsOpen(false)}
          inverse
        />
      </CustomDialog.Container>
    </>
  );
};
