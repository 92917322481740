import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Stars = createSvgIcon({
  content: (
    <>
      <path
        d="M6.17085 11.2915L2.77149 13L3.42085 9.3813L0.666992 6.81878L4.46731 6.29227L6.16699 3L7.86667 6.29227L11.667 6.81878L8.91314 9.3813L9.5625 13L6.17085 11.2915Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 3.33333C12.3536 3.33333 12.6928 3.47381 12.9428 3.72386C13.1929 3.97391 13.3333 4.31304 13.3333 4.66667C13.3333 4.31304 13.4738 3.97391 13.7239 3.72386C13.9739 3.47381 14.313 3.33333 14.6667 3.33333C14.313 3.33333 13.9739 3.19286 13.7239 2.94281C13.4738 2.69276 13.3333 2.35362 13.3333 2C13.3333 2.35362 13.1929 2.69276 12.9428 2.94281C12.6928 3.19286 12.3536 3.33333 12 3.33333Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
});
