import React from "react";
import { Disclosure, Transition } from "@headlessui/react";

import { Icon, Link } from "@components/atoms";
import { NestedSegment } from "@graphql/operations";

import { SquaredLabel } from "./SquaredLabel";

export type CollapsibleNestedSegmentsProps = {
  segments?: NestedSegment[] | null;
};

export const CollapsibleNestedSegments = ({
  segments,
}: CollapsibleNestedSegmentsProps) =>
  segments?.length ? (
    <Disclosure defaultOpen={!!segments.length}>
      {({ open }) => (
        <>
          <Disclosure.Button className="py-2">
            <div className="flex flex-row">
              <p className="text-left text-h8 font-bold uppercase text-black-200">
                Nested Segments{" "}
                <span className="text-black-100">({segments.length})</span>
              </p>
              <Icon.ChevronDown
                className={`mt-1 ml-2 transition ${
                  open ? "transform rotate-180" : ""
                }`}
              />
            </div>
          </Disclosure.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="text-gray-500">
              {segments.map((segment) => (
                <SquaredLabel
                  key={`${segment.SegmentId}-${segment.Connection.Version}`}
                >
                  <Link
                    href={`/segments/${segment.SegmentId}/version/${segment.Connection.Version}`}
                  >
                    <span className="text-black-300 font-semibold text-sm">
                      {segment.SegmentId}
                    </span>
                  </Link>
                </SquaredLabel>
              ))}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  ) : null;
