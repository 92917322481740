import React, { useEffect, useState } from "react";

import { DialogBox, ToggleSwitch } from "@components/atoms";
import {
  ConditionTemplate,
  ConditionTemplateProps,
  DurationInput,
} from "@components/atoms/Segment";
import {
  FilterType,
  SportsDimensionBooleanWithToleranceFilter,
} from "@hooks/segments";

interface Props {
  onChange: (
    value: SportsDimensionBooleanWithToleranceFilter
  ) => void | Promise<void>;
}

export type BooleanWithToleranceProps = Props &
  SportsDimensionBooleanWithToleranceFilter &
  ConditionTemplateProps;

const LabelTypeMap: Partial<Record<FilterType, string>> = {
  [FilterType.CLOSE_GAME__EQUALS]: "Close Game",
  [FilterType.LINEUP_CHANGE__EQUALS]: "Lineup Change",
};

export const BooleanWithTolerance = ({
  onChange,
  value = false,
  location,
  within,
  type,
  ...rest
}: BooleanWithToleranceProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    onChange({ value, within });
  }, [value, within, onChange]);

  return (
    <>
      <ConditionTemplate type={type} location={location} {...rest}>
        <ToggleSwitch
          onChange={({ target }) => onChange({ value: target.checked, within })}
          displayChildren="before"
          checked={!!value}
          className="flex w-2/3"
        >
          <span className="mr-4 font-semibold text-xs text-navy-400">
            {LabelTypeMap[type]}
          </span>
        </ToggleSwitch>

        <div className="inline-block mt-2 w-1/3">
          <DurationInput
            data-testid="within"
            value={within}
            onChange={(within) => onChange({ value, within })}
            onInfoClick={() => setIsDialogOpen(true)}
          />
        </div>
      </ConditionTemplate>

      <DialogBox.Container
        isDialogOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogBox.Card onClose={() => setIsDialogOpen(false)}>
          <DialogBox.Title>Tolerance</DialogBox.Title>
          <p className="text-sm">
            Specify a buffer of time around the occurence.
            <br />
            E.g., Ginger Rogers must appear in the frame{" "}
            <span className="font-bold">within 5 minutes</span> of a given point
            in the video for that point to meet segment condititions.
          </p>
        </DialogBox.Card>
      </DialogBox.Container>
    </>
  );
};
