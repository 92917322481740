import React, { useState } from "react";

import { Tree, type TreeNodeProp, TreeView } from "@components/molecules";

export type TreeColumnsSelection = string[];

export interface TreeViewColumnsSelectorProps {
  selectedNodesId?: string[];
  selectableFields: TreeNodeProp;
  onChange: (value: TreeColumnsSelection) => Promise<void> | void;
}

type State = { [key: string]: boolean };

export const TreeViewColumnsSelector = ({
  selectedNodesId = [],
  selectableFields,
  onChange,
}: TreeViewColumnsSelectorProps) => {
  const [state, setState] = useState<State>(
    selectedNodesId.reduce((acc, id) => ({ ...acc, [id]: true }), {})
  );

  const onTreeChange = (tree: Tree) => {
    const update: State = tree.leaves
      .filter(({ selectionState }) => selectionState === "on")
      .map(({ id }) => id)
      .reduce((acc, node) => ({ ...acc, [node]: true }), {});

    setState(update);

    onChange(
      Object.entries(update)
        .filter(([_, v]) => v)
        .map(([k]) => k)
    );
  };

  return (
    <>
      <TreeView
        searchable={false}
        nodes={selectableFields}
        selectedNodeIds={Object.keys(state)}
        onTreeChange={onTreeChange}
      />
    </>
  );
};
