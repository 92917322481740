import React from "react";

import { Button, Dropdown } from "@components/atoms";
import { DropdownProps } from "@components/atoms/Dropdown";
import * as Icon from "@components/atoms/Icon";
import { classnames } from "@external/tailwindcss-classnames";

export interface ActionsMenuProps
  extends Pick<DropdownProps, "position" | "relative"> {
  onEditClick?: () => void | Promise<void>;
  onExportClick?: () => void | Promise<void>;
  onDuplicateClick?: () => void | Promise<void>;
  onDeleteClick?: () => void | Promise<void>;
  onReindexClick?: () => void | Promise<void>;
  onCopyLinkClick?: () => void | Promise<void>;
}

export const ActionsMenu = ({
  position = "below",
  relative,
  onEditClick,
  onExportClick,
  onDuplicateClick,
  onReindexClick,
  onDeleteClick,
  onCopyLinkClick,
}: ActionsMenuProps) => (
  <Dropdown.Container
    position={position}
    relative={relative}
    data-testid="actions-menu"
    data-e2e="actions-menu"
    button={
      <Button
        type="button"
        className="p-2 -m-2"
        variant="text"
        color="clear"
        data-e2e="action-button"
      >
        Actions
        <Icon.ChevronUp className="inline ml-1" />
      </Button>
    }
  >
    <Dropdown.Items
      className={classnames(
        "z-20",
        "w-30",
        "text-black-300",
        "border",
        "border-coolGrey-300",
        "shadow-2dp",
        {
          [classnames("top-auto", "bottom-full", "mb-2")]: position === "above",
        }
      )}
      data-e2e="actions-menu-items"
    >
      {onEditClick && (
        <Dropdown.Item
          className="text-bodySm font-semibold"
          data-e2e="actions-menu-edit"
          onClick={(e) => {
            e.stopPropagation();
            onEditClick();
          }}
        >
          Edit
        </Dropdown.Item>
      )}
      {onExportClick && (
        <Dropdown.Item
          className="text-bodySm font-semibold"
          data-e2e="actions-menu-export"
          onClick={(e) => {
            e.stopPropagation();
            onExportClick();
          }}
        >
          Export
        </Dropdown.Item>
      )}
      {onCopyLinkClick && (
        <Dropdown.Item
          className="text-bodySm font-semibold"
          data-e2e="actions-menu-copy-link"
          onClick={(e) => {
            e.stopPropagation();
            onCopyLinkClick();
          }}
        >
          Copy link
        </Dropdown.Item>
      )}
      {onDuplicateClick && (
        <Dropdown.Item
          className="text-bodySm font-semibold"
          data-e2e="actions-menu-duplicate"
          onClick={(e) => {
            e.stopPropagation();
            onDuplicateClick();
          }}
        >
          Duplicate
        </Dropdown.Item>
      )}
      {onReindexClick && (
        <Dropdown.Item
          className="text-bodySm font-semibold"
          data-e2e="actions-menu-reindex"
          onClick={(e) => {
            e.stopPropagation();
            onReindexClick();
          }}
        >
          Reindex
        </Dropdown.Item>
      )}
      {onDeleteClick && (
        <Dropdown.Item
          className="text-bodySm font-semibold"
          data-e2e="actions-menu-delete"
          onClick={(e) => {
            e.stopPropagation();
            onDeleteClick();
          }}
        >
          Delete
        </Dropdown.Item>
      )}
    </Dropdown.Items>
  </Dropdown.Container>
);
