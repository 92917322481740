import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Button, RadioButton } from "@components/atoms";
import { CustomDialog, Exporting } from "@components/molecules";

export enum ExportDialogFormDataTableRows {
  Current = "Current",
  All = "All",
}

export type ExportDialogFormData = {
  tableRows: ExportDialogFormDataTableRows;
  selectedColumns?: string[];
};

export interface ExportDialogProps {
  isDialogOpen: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmission: (v: ExportDialogFormData) => void;
  selectableFields: { [key: string]: string };
}

const schema = yup.object({
  tableRows: yup.string().required(),
  selectedColumns: yup.array().test((value) => {
    if (!value) {
      return true;
    }
    return (
      (value[0] === Exporting.TableColumns.Custom && value.length > 1) ||
      value[0] === Exporting.TableColumns.All
    );
  }),
});

export const ExportDialog = ({
  isDialogOpen = false,
  onClose,
  onSubmission,
  loading,
  selectableFields,
}: ExportDialogProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<ExportDialogFormData>({
    defaultValues: {
      tableRows: ExportDialogFormDataTableRows.Current,
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const submitHandler = (data: ExportDialogFormData) =>
    onSubmission({
      ...data,
      selectedColumns: data.selectedColumns?.slice(1),
    });

  return (
    <CustomDialog.Container isDialogOpen={isDialogOpen} onClose={onClose}>
      <CustomDialog.Card onClose={onClose}>
        <CustomDialog.Title>Export</CustomDialog.Title>
        <form onSubmit={handleSubmit(submitHandler)}>
          <label className="font-semibold text-xs text-navy-400 mb-2">
            Table rows
          </label>
          <RadioButton
            {...register("tableRows")}
            value={ExportDialogFormDataTableRows.Current}
          >
            Export with the currently displayed rows
          </RadioButton>
          <RadioButton
            {...register("tableRows")}
            value={ExportDialogFormDataTableRows.All}
          >
            Export with all rows
          </RadioButton>

          <Controller
            control={control}
            name="selectedColumns"
            render={({ field: { onChange } }) => (
              <Exporting.TableColumnsSelector
                onChange={onChange}
                selectableFields={selectableFields}
              />
            )}
          />

          <div className="flex flex-row justify-between mt-12">
            <Button type="button" variant="text" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading || !isValid}
            >
              Export data
            </Button>
          </div>
        </form>
      </CustomDialog.Card>
    </CustomDialog.Container>
  );
};
