import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { gql, useApolloClient, useQuery } from "@apollo/client";

import { Loading, TextInputWithMetadata } from "@components/atoms";
import {
  ConditionTemplate,
  ConditionTemplateProps,
} from "@components/atoms/Segment";
import { classnames } from "@external/tailwindcss-classnames";
import {
  NestedSegmentIdEquals_SegmentJobDocument,
  NestedSegmentIdEquals_SegmentJobQuery,
} from "@graphql/operations";
import { FilterType, LabelFilter, NestedSegmentFilter } from "@hooks/segments";

const CACHED_SEGMENT_ERROR_QUERY = gql`
  query NestedSegmentError {
    nestedSegmentError @client {
      id
      circularDependencyChain
      circularDependencyDetected
    }
  }
`;

type NestedSegmentError = {
  nestedSegmentError?: {
    id: string;
    circularDependencyChain: Array<string>;
    circularDependencyDetected: boolean;
  };
};

interface Props {
  onChange: (value: NestedSegmentFilter) => void | Promise<void>;
}

export type NestedSegmentIdEqualsProps = Props &
  LabelFilter &
  Omit<ConditionTemplateProps, "type">;

export const NestedSegmentIdEquals = ({
  onChange,
  confidence,
  value,
  within,
  ...rest
}: NestedSegmentIdEqualsProps) => {
  const [shouldTypeaheadBePresent, setTypeaheadBePresent] = useState(false);
  const [videoId, setVideoId] = useState(value || "");
  const [videoTitle, setVideoTitle] = useState("");
  const [hasCircularDep, setHasCircularDep] = useState(false);
  const location = useLocation();
  const client = useApolloClient();
  const segmentId = useMemo(() => location.pathname.split("/")[2], [location]);

  const { data: circularDepErrData } = useQuery<NestedSegmentError>(
    CACHED_SEGMENT_ERROR_QUERY,
    {
      skip: shouldTypeaheadBePresent,
    }
  );

  const { data, loading } = useQuery(NestedSegmentIdEquals_SegmentJobDocument, {
    skip: !!value ? false : !shouldTypeaheadBePresent,
    variables: {
      filter: {
        segmentId: {
          eq: videoId,
        },
        segmentVersion: {},
      },
    },
  });

  useEffect(() => {
    if (circularDepErrData) {
      const { nestedSegmentError } = circularDepErrData;
      if (nestedSegmentError?.circularDependencyChain?.includes(videoId)) {
        setHasCircularDep(true);
      }
    }
  }, [circularDepErrData, videoId]);

  useEffect(() => {
    if (!!value) {
      setVideoTitle(data?.segmentJob?.Title || "");
    }
  }, [value, data?.segmentJob?.Title]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (circularDepErrData) {
      client.cache.evict({
        id: `NestedSegmentError:${segmentId}`,
      });
      setHasCircularDep(false);
    }
    setVideoId(e.target.value);
    setVideoTitle(data?.segmentJob?.Title || "");
    setTypeaheadBePresent(true);
  };

  const handleSelect = (data?: NestedSegmentIdEquals_SegmentJobQuery) => {
    if (data?.segmentJob) {
      onChange({
        value: data.segmentJob.SegmentId,
        version: data.segmentJob.Version,
      });

      setVideoTitle(data?.segmentJob?.Title || "");
      setTypeaheadBePresent(false);
    }
  };

  return (
    <ConditionTemplate type={FilterType.NESTED_SEGMENT_ID__EQUALS} {...rest}>
      <label className="mb-2 font-semibold text-xs text-navy-400">
        Segment
      </label>
      <div className={classnames("relative")}>
        <TextInputWithMetadata
          onChange={(e) => handleSearch(e)}
          value={videoId}
          placeholder="Search by Segment ID"
          className="mb-6"
          error={hasCircularDep}
          metadata={videoTitle ? ` - ${videoTitle}` : undefined}
        />

        {!shouldTypeaheadBePresent && hasCircularDep && (
          <div className="absolute top-12">
            <p className="text-red-400 text-xs">
              Current Segment is already nested in this Segment
            </p>
          </div>
        )}
        {videoId.length > 2 && shouldTypeaheadBePresent && (
          <div className="flex h-14 w-full py-2 px-2 bg-white top-[42px] absolute border border-navy-100 shadow-navy-300 rounded-lg text-black-300">
            {loading ? (
              <Loading.Slide
                className="mx-auto my-auto"
                data-testid="loading-slide"
                size={2}
              />
            ) : !data ? (
              <div className="cursor-pointer text-base flex items-center py-2">
                <p className="text-black-100 px-2">No results found</p>
              </div>
            ) : (
              <div
                onClick={() => handleSelect(data)}
                className="cursor-pointer text-base flex items-center py-2 w-full px-2 hover:rounded-lg hover:bg-navy-300 hover:bg-opacity-4 transition duration-200"
              >
                <p className="text-black-300">{data?.segmentJob?.SegmentId}</p>
                <p className="ml-1 text-coolGrey-300 line-clamp-1">
                  - {data?.segmentJob?.Title}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </ConditionTemplate>
  );
};
