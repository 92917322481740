import React from "react";
import * as ReactDOMClient from "react-dom/client";

import App from "./App";

import "./index.css";

const container = document.getElementById("root");

if (!container) {
  throw new Error(
    "Could not find the container element to mount the application (#root)"
  );
}

const root = ReactDOMClient.createRoot(container);
root.render(<App />);
