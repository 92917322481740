import { ImportColumnType } from "../useImportState/importColumnType";

import * as ConditionTypeValidator from "./ConditionTypes";
import * as ConditionValue from "./ConditionValue";
import * as ConfidenceScore from "./ConfidenceScore";
import * as Operator from "./Operator";
import { join, pass, Result } from "./result";
import * as Tolerance from "./Tolerance";

export const failedOnColumnType =
  (columnType: ImportColumnType) =>
  (...err: string[]): Result => {
    return {
      success: false,
      error: {
        [columnType]: err,
      },
    };
  };

export function validate(
  input: Partial<{ [key in ImportColumnType]: string }>
): Result {
  const validators = [
    ConditionTypeValidator.validate,
    ConditionValue.validate,
    ConfidenceScore.validate,
    Tolerance.validate,
    Operator.validate,
  ];

  return validators.reduce((previous, elem) => {
    return join(previous, elem(input));
  }, pass());
}
