import React from "react";

import { Button, TextInput } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

import { FilterType, useFilters } from "../../hooks";

export const VideoContains = () => {
  const context = useFilters();
  const filter = context.getFilter(FilterType.LABEL__CONTAINS);
  const labelFilter = filter || {
    filterType: FilterType.LABEL__CONTAINS,
    values: [""],
  };

  const onTextChange = (index: number, e: any) => {
    labelFilter.values[index] = e.target.value;
    context.addFilter(labelFilter);
  };

  const onAddAnother = () => {
    labelFilter.values.push("");
    context.addFilter(labelFilter);
  };

  const onClearClicked = (index: number) => {
    if (labelFilter.values.length > 1) {
      labelFilter.values.splice(index, 1);
      context.addFilter(labelFilter);
    } else {
      context.removeFilter(FilterType.LABEL__CONTAINS);
    }
  };

  const isAnotherButtonVisible =
    labelFilter.values.length > 0 &&
    labelFilter.values[labelFilter.values.length - 1] !== "";

  return (
    <div>
      <div className="flex flex-col">
        {labelFilter.values.map((value, index) => (
          <TextInput
            className={classnames({
              "mb-3": labelFilter.values.length > 1 || isAnotherButtonVisible,
            })}
            inputClassName="w-52"
            key={index}
            placeholder="Search labels"
            onChange={(e) => onTextChange(index, e)}
            value={value}
            endAdornment={
              ((index === 0 && value.length > 0) || index > 0) && (
                <span
                  className="text-sm font-semibold cursor-pointer mr-4 text-black-300"
                  onClick={() => onClearClicked(index)}
                >
                  Clear
                </span>
              )
            }
          />
        ))}
      </div>
      {isAnotherButtonVisible ? (
        <Button size="small" onClick={onAddAnother}>
          + Another
        </Button>
      ) : null}
    </div>
  );
};
