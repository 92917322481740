import React from "react";

import { Time } from "@components/atoms";
import { Table } from "@components/molecules";
import { ContentLabelsTable_ContentLabelOccurenceFragment } from "@graphql/operations";

import { InsightsTable, InsightsTableProps } from "../InsightsTables";

export type ContentLabelsTableProps = {
  labels?: ContentLabelsTable_ContentLabelOccurenceFragment[];
} & Pick<InsightsTableProps, "loading" | "loadingRows">;

const getParentValues = (values?: string[]) => {
  if (!values) {
    return "-";
  }

  return values.join(", ");
};

// this component renders a table of labels
export const ContentLabelsTable = ({
  labels = [],
  loading,
  loadingRows,
}: ContentLabelsTableProps) => (
  <InsightsTable
    columns={[
      { title: "Name", width: "w-3/12" },
      { title: "Parent name", width: "w-5/12" },
      { title: "Timestamp", width: "w-2/12" },
      { title: "Confidence", width: "w-2/12" },
    ]}
    loading={loading}
    loadingRows={loadingRows}
    loadingRowsHeight="h-10"
  >
    {labels.length > 0
      ? labels.map((label) => (
          <Table.Row key={`${label.Name}_${label.Timestamp}`} height="h-10">
            <Table.Cell className="font-bold">{label.Name}</Table.Cell>
            <Table.Cell>
              {getParentValues(label.ParentNames || undefined)}
            </Table.Cell>
            <Table.Cell>
              <Time>{label.Timestamp}</Time>
            </Table.Cell>
            <Table.Cell>{label.Confidence}</Table.Cell>
          </Table.Row>
        ))
      : null}
  </InsightsTable>
);
