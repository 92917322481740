import React from "react";

import { Table } from "@components/molecules";
import { classnames } from "@external/tailwindcss-classnames";

const shimmerRows = 3;

export interface TableLoadingShimmerProps {
  className?: any;
  maxRowCount: number;
  columns?: number;
  rows?: number;
}

export const TableLoadingShimmer = ({
  className,
  maxRowCount,
  columns = 6,
  rows = shimmerRows,
}: TableLoadingShimmerProps) => (
  <>
    <Table.Shimmer
      data-e2e="table-loading"
      data-testid="table-loading"
      className={classnames("h-20", "align-middle", className)}
      col={columns}
      padRight={0}
      rows={rows}
    />
    {maxRowCount
      ? Array.from(Array(maxRowCount - shimmerRows).keys()).map((idx) => (
          <tr key={idx} className={classnames(className, "border-b-0")}>
            <td colSpan={columns} />
          </tr>
        ))
      : null}
  </>
);
