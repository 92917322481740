import React, { useState } from "react";

import { DurationInput as CDurationInput, Icon, Unit } from "@components/atoms";
import { Label } from "@components/atoms/Label";
import { classnames } from "@external/tailwindcss-classnames";

export interface DurationInputProps {
  labelValue?: string;
  value?: number;
  onChange: (value?: number) => void | Promise<void>;
  onInfoClick?: () => void | Promise<void>;
  hasError?: boolean;
}

export const DurationInput = ({
  value,
  onChange,
  onInfoClick,
  labelValue,
  hasError,
  ...rest
}: DurationInputProps) => {
  const [unit, setUnit] = useState<Unit>(findUnit(value));
  const [isPristine, setPristine] = useState<boolean>(true);
  const [isZero, setIsZero] = useState<boolean>(value === 0);

  return (
    <div {...rest}>
      <label className="flex mb-2 font-semibold text-xs text-navy-400">
        {labelValue ? labelValue : "Within"}
        <Icon.Info
          className={classnames("ml-1", "cursor-pointer", {
            hidden: !onInfoClick,
          })}
          size={11}
          onClick={() => onInfoClick && onInfoClick()}
        />
      </label>
      <CDurationInput
        hasError={hasError || isZero}
        value={
          // If the user has not eddited the input,
          // and there's no value, default to 1 minute
          isPristine && value === undefined ? 1 : convertToUnit(value, unit)
        }
        unit={unit}
        onChange={(unit, duration) => {
          setPristine(false);

          if (duration === 0 || duration === undefined) {
            setIsZero(true);
            return onChange();
          }

          setIsZero(false);
          setUnit(unit);
          onChange(convertToMilliSeconds(duration, unit));
        }}
      />
      {isZero && (
        <Label className="text-red-400 text-bodySm">
          {labelValue ? labelValue : "Within"} value must be greater than 0
        </Label>
      )}
    </div>
  );
};

const findUnit = (value: number | undefined) => {
  if (value === undefined) {
    return "min";
  }

  const valSeconds = value / 1000;
  if (valSeconds < 60) {
    return "sec";
  }
  return "min";
};

const convertToUnit = (value: number | undefined, unit: Unit) => {
  if (value === undefined) {
    return;
  }
  if (unit === "sec") {
    return value ? value / 1000 : undefined;
  } else {
    return value ? value / (60 * 1000) : undefined;
  }
};

const convertToMilliSeconds = (value: number, unit: Unit) => {
  if (unit === "sec") {
    return value * 1000;
  } else {
    return value * 60 * 1000;
  }
};
