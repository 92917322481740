import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Time = createSvgIcon({
  content: (
    <>
      <path
        d="M8.0026 14.6673C11.6845 14.6673 14.6693 11.6825 14.6693 8.00065C14.6693 4.31875 11.6845 1.33398 8.0026 1.33398C4.32071 1.33398 1.33594 4.31875 1.33594 8.00065C1.33594 11.6825 4.32071 14.6673 8.0026 14.6673Z"
        className="stroke-current"
        strokeLinecap="square"
      />
      <path
        d="M8 3.33398V8.00065L10.6667 10.6673"
        className="stroke-current"
        strokeLinecap="square"
      />
    </>
  ),
  width: 16,
  height: 16,
});
