import React from "react";
import type { SliderProps as RcSliderProps } from "rc-slider";
import RcSlider from "rc-slider";

import "rc-slider/assets/index.css";
import "./Slider.css";

interface Props {
  onAfterChange?: (value: number) => void;
}

export type SliderProps = Props & Omit<RcSliderProps, "onAfterChange">;

export const Slider = ({ onAfterChange, ...rest }: SliderProps) => (
  <RcSlider
    onAfterChange={
      onAfterChange && ((v) => !Array.isArray(v) && onAfterChange(v))
    }
    {...rest}
  />
);
