import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Play = createSvgIcon({
  content: (
    <path
      d="M13.8333 8L3.83334 14V2L13.8333 8Z"
      className="stroke-current"
      strokeLinecap="square"
    />
  ),
  width: 17,
  height: 16,
});
