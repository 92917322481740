import React, { HTMLAttributes } from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface BodyProps extends HTMLAttributes<HTMLDivElement> {
  className?: any;
}

export const Body = ({ children, className, ...rest }: BodyProps) => (
  <div
    {...rest}
    className={classnames(className, "flex", "flex-auto", "bg-lightGrey-100")}
  >
    {children}
  </div>
);
