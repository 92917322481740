import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Separator = createSvgIcon({
  content: (
    <>
      <path
        d="M2 8V8.00667"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.6665 8H11.3332"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 8V8.00667"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
  defaultColor: "navy-100",
});
