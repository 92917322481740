import React, { ChangeEvent, useState } from "react";

import {
  ColorfulBullet,
  ColorLabels,
  DialogBox,
  RadioButton,
  Segment,
} from "@components/atoms";
import { FilterType, SentimentFilter } from "@hooks/segments";

enum SentimentFilterTypes {
  Positive = "POSITIVE",
  Negative = "NEGATIVE",
  Neutral = "NEUTRAL",
  Mixed = "MIXED",
}

const ColorMapping: { [key in SentimentFilterTypes]: ColorLabels } = {
  [SentimentFilterTypes.Positive]: ColorLabels.Blue,
  [SentimentFilterTypes.Negative]: ColorLabels.Red,
  [SentimentFilterTypes.Neutral]: ColorLabels.Orange,
  [SentimentFilterTypes.Mixed]: ColorLabels.Teal,
};

interface Props {
  onChange: (value: SentimentFilter) => void | Promise<void>;
}

export type SentimentEqualsProps = Props &
  SentimentFilter &
  Omit<Segment.ConditionTemplateProps, "type">;

export const SentimentEquals = ({
  onChange,
  value,
  within,
  ...rest
}: SentimentEqualsProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedSentiment, setSelectedSentiment] = useState<string>(
    value ? value : ""
  );

  const handleSelectSentimentEvent = (e: ChangeEvent<HTMLInputElement>) => {
    const chosenSentiment = e.target.value;
    setSelectedSentiment(chosenSentiment);
    onChange({ value: chosenSentiment, within });
  };

  return (
    <>
      <Segment.ConditionTemplate type={FilterType.SENTIMENT__EQUALS} {...rest}>
        <label className="mb-2 font-semibold text-xs text-navy-400">
          Sentiment
        </label>
        <div className="grid grid-cols-4 gap-x-4">
          {Object.entries(SentimentFilterTypes).map((filter) => (
            <div key={filter[0]} className="flex items-center h-10">
              <RadioButton
                data-testid={filter[1]}
                name={filter[0]}
                onChange={(e) => handleSelectSentimentEvent(e)}
                value={filter[1]}
                checked={selectedSentiment === filter[1]}
              />
              <ColorfulBullet color={ColorMapping[filter[1]]} />
              <label className="font-semibold text-xs text-navy-400">
                {filter[0]}
              </label>
            </div>
          ))}
          <div className="w-50">
            <Segment.DurationInput
              value={within}
              onChange={(within) => onChange({ value, within })}
              onInfoClick={() => setIsDialogOpen(true)}
            />
          </div>
        </div>
      </Segment.ConditionTemplate>
      <DialogBox.Container
        isDialogOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogBox.Card onClose={() => setIsDialogOpen(false)}>
          <DialogBox.Title>Tolerance</DialogBox.Title>
          <p className="text-sm">
            Specify a buffer of time around the occurence.
            <br />
            E.g., Ginger Rogers must appear in the frame{" "}
            <span className="font-bold">within 5 minutes</span> of a given point
            in the video for that point to meet segment condititions.
          </p>
        </DialogBox.Card>
      </DialogBox.Container>
    </>
  );
};
