const abbrev = ["k", "m"];

export function number(number: number): string {
  for (let i = abbrev.length - 1; i >= 0; i--) {
    let size = Math.pow(10, (i + 1) * 3);

    if (size <= number) {
      number = Math.round(number / size);

      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      return `${number}${abbrev[i]}`;
    }
  }

  return `${number}`;
}
