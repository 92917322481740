import React, { HTMLAttributes, ReactElement, useState } from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface ImageProps extends HTMLAttributes<HTMLImageElement> {
  alt: string;
  src: string;
  className?: any;
  loading: ReactElement;
}

export const Image = ({
  src,
  alt,
  className,
  loading,
  ...rest
}: ImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const thePlaceholder = React.cloneElement(loading, {
    ...loading.props,
    className: classnames(loading.props["className"], className, {
      hidden: isLoaded,
    }),
  });

  return (
    <>
      {thePlaceholder}
      <img
        onLoad={() => setIsLoaded(true)}
        className={classnames(className, {
          hidden: !isLoaded,
        })}
        alt={alt}
        src={src}
        {...rest}
      />
    </>
  );
};
