import React from "react";
import { Doughnut } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import _ from "lodash";

import "chart.js/auto";

export interface DoughnutValues {
  name: string;
  value: number;
  color: string;
}

export interface DoughnutChartProps {
  data: DoughnutValues[];
  title: string;
  height: number;
}

const options: ChartOptions<"doughnut"> = {
  animation: false,
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        boxWidth: 5,
        font: {
          size: 14,
        },
        textAlign: "center",
        padding: 20,
      },
    },
    title: {
      display: true,
      text: "Current Title",
      font: {
        size: 12,
        weight: "bold",
      },
    },
  },
};

export const DoughnutChart = ({ data, title, height }: DoughnutChartProps) => {
  const doughnutChartOptions = _.merge(options, {
    plugins: {
      title: {
        text: title,
      },
    },
  });

  const chartData: ChartData<"doughnut", number[], string> = {
    labels: data.map((item) => item.name),
    datasets: [
      {
        label: "Current Sentiment",
        data: data.map((item) => item.value),
        animation: false,
        backgroundColor: data.map((item) => item.color),
        borderColor: data.map((item) => item.color),
        borderWidth: 1,
      },
    ],
  };

  return (
    <Doughnut height={height} data={chartData} options={doughnutChartOptions} />
  );
};
