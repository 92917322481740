import React, { useState } from "react";
import { uniqueId } from "lodash";

import { TextInput, TextInputProps } from "@components/atoms";

interface Props {
  label: string;
  placeholder: string;
  fieldName: string;
  errors?: any;
  className?: any;
  register?: any;
}

export type FormInputProps = Props & TextInputProps;

export const FormInput = ({
  label,
  errors = {},
  fieldName,
  placeholder,
  className,
  register = () => [],
  ...rest
}: FormInputProps) => {
  const [id] = useState(uniqueId("form-input-"));
  const hasError = Object.keys(errors[fieldName] || {}).length > 0;

  return (
    <div className={className}>
      <label htmlFor={id} className="font-semibold text-xs text-navy-400 mb-2">
        {label}
      </label>
      <TextInput
        id={id}
        error={hasError}
        placeholder={placeholder}
        {...register()}
        {...rest}
      />
      {hasError && (
        <p className="mt-2 text-red-300 font-semibold text-xs">
          {label} is {errors[fieldName].type}
        </p>
      )}
    </div>
  );
};
