import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const ListOutline = createSvgIcon({
  content: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.631579C0 0.282768 0.282768 0 0.631579 0H23.3684C23.7172 0 24 0.282768 24 0.631579V23.3684C24 23.7172 23.7172 24 23.3684 24H0.631579C0.282768 24 0 23.7172 0 23.3684V0.631579ZM1.26316 1.26316V22.7368H22.7368V1.26316H1.26316Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.2627 4.83092C4.2627 4.48211 4.54546 4.19934 4.89427 4.19934H7.73638C8.08519 4.19934 8.36796 4.48211 8.36796 4.83092V7.67302C8.36796 8.02184 8.08519 8.3046 7.73638 8.3046H4.89427C4.54546 8.3046 4.2627 8.02184 4.2627 7.67302V4.83092ZM5.52585 5.4625V7.04145H7.1048V5.4625H5.52585Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.18457 10.6257C4.18457 10.2768 4.46734 9.99408 4.81615 9.99408H7.65826C8.00707 9.99408 8.28983 10.2768 8.28983 10.6257V13.4678C8.28983 13.8166 8.00707 14.0993 7.65826 14.0993H4.81615C4.46734 14.0993 4.18457 13.8166 4.18457 13.4678V10.6257ZM5.44773 11.2572V12.8362H7.02668V11.2572H5.44773Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.21582 16.2781C4.21582 15.9293 4.49859 15.6465 4.8474 15.6465H7.68951C8.03832 15.6465 8.32108 15.9293 8.32108 16.2781V19.1202C8.32108 19.469 8.03832 19.7518 7.68951 19.7518H4.8474C4.49859 19.7518 4.21582 19.469 4.21582 19.1202V16.2781ZM5.47898 16.9097V18.4886H7.05793V16.9097H5.47898Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.94727 4.89549C9.94727 4.54668 10.23 4.26392 10.5788 4.26392H19.1052C19.454 4.26392 19.7367 4.54668 19.7367 4.89549C19.7367 5.24431 19.454 5.52707 19.1052 5.52707H10.5788C10.23 5.52707 9.94727 5.24431 9.94727 4.89549Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9165 7.7531C9.9165 7.40429 10.1993 7.12152 10.5481 7.12152H16.2639C16.6127 7.12152 16.8955 7.40429 16.8955 7.7531C16.8955 8.10191 16.6127 8.38468 16.2639 8.38468H10.5481C10.1993 8.38468 9.9165 8.10191 9.9165 7.7531Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9165 10.6434C9.9165 10.2945 10.1993 10.0118 10.5481 10.0118H19.0744C19.4232 10.0118 19.706 10.2945 19.706 10.6434C19.706 10.9922 19.4232 11.2749 19.0744 11.2749H10.5481C10.1993 11.2749 9.9165 10.9922 9.9165 10.6434Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.86816 13.5142C9.86816 13.1654 10.1509 12.8826 10.4997 12.8826H16.2313C16.5801 12.8826 16.8629 13.1654 16.8629 13.5142C16.8629 13.863 16.5801 14.1458 16.2313 14.1458H10.4997C10.1509 14.1458 9.86816 13.863 9.86816 13.5142Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0903 16.2004C10.0903 15.8516 10.3731 15.5688 10.7219 15.5688H19.2482C19.597 15.5688 19.8798 15.8516 19.8798 16.2004C19.8798 16.5492 19.597 16.8319 19.2482 16.8319H10.7219C10.3731 16.8319 10.0903 16.5492 10.0903 16.2004Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0586 19.0569C10.0586 18.7081 10.3414 18.4254 10.6902 18.4254H16.406C16.7548 18.4254 17.0375 18.7081 17.0375 19.0569C17.0375 19.4057 16.7548 19.6885 16.406 19.6885H10.6902C10.3414 19.6885 10.0586 19.4057 10.0586 19.0569Z"
        className="fill-current"
      />
    </>
  ),
  width: 24,
  height: 24,
});
