import {
  SegmentJobRedshiftQueryStatus,
  SegmentJobStatus,
} from "@graphql/operations";

export interface SatisfiabilityProps {
  redshiftQueryStatus?: string | null;
  status: string | null;
}

export function isDraft({ status }: SatisfiabilityProps): boolean {
  return status === SegmentJobStatus.Draft;
}

export function isFailed({
  redshiftQueryStatus,
}: SatisfiabilityProps): boolean {
  return (
    redshiftQueryStatus === SegmentJobRedshiftQueryStatus.Failed ||
    redshiftQueryStatus === SegmentJobRedshiftQueryStatus.Aborted
  );
}

export function isProcessing({ redshiftQueryStatus }: SatisfiabilityProps) {
  return (
    redshiftQueryStatus === SegmentJobRedshiftQueryStatus.Started ||
    redshiftQueryStatus === SegmentJobRedshiftQueryStatus.Submitted ||
    redshiftQueryStatus === SegmentJobRedshiftQueryStatus.Picked
  );
}

export function isSuccessful({
  redshiftQueryStatus,
}: SatisfiabilityProps): boolean {
  return redshiftQueryStatus === SegmentJobRedshiftQueryStatus.Finished;
}
