import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const ChevronUp = createSvgIcon({
  content: (
    <path
      d="M10 5.33334L6 1.33334L2 5.33334"
      className="stroke-current"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  ),
  width: 12,
  height: 7,
  "data-testid": "chevron-up",
});
