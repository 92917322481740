import _ from "lodash";

import { ImportColumnType } from "../useImportState/importColumnType";

export type Result =
  | { success: true }
  | { success: false; error: { [key in ImportColumnType]?: string[] } };

export function pass(): Result {
  return { success: true };
}

export function join(r1: Result, r2: Result) {
  if (!r1.success && r2.success) {
    return r1;
  }

  if (r1.success && !r2.success) {
    return r2;
  }

  if (!r1.success && !r2.success) {
    const errors: { [key in ImportColumnType]?: string[] } = _.clone(r1.error);

    Object.entries(r2.error).forEach((entry) => {
      const key = entry[0] as ImportColumnType;
      const values = entry[1];
      errors[key] = [...(errors[key] || []), ...values];
    });

    return {
      success: false,
      error: errors,
    };
  }

  return pass();
}

export function pushError(
  previous: Result,
  columnType: ImportColumnType,
  ...errors: string[]
): Result {
  if (previous.success) {
    return {
      success: false,
      error: {
        [columnType]: errors,
      },
    };
  }

  const result = _.clone(previous);

  result.error[columnType] = [...(previous.error[columnType] || []), ...errors];

  return result;
}
