import React from "react";

import { TreeViewModelProvider } from "./hooks";
import { Node, Tree } from "./model";
import { SearchInput } from "./SearchInput";
import { Tree as TreeComponent } from "./Tree";

export type TreeNodeProp = Node[];

export type TreeViewProps = {
  nodes: Node[];
  selectedNodeIds?: string[];
  hiddenNodeIds?: string[];
  searchable?: boolean;
  onTreeChange?: (tree: Tree) => void;
};

export const TreeView = ({
  nodes,
  selectedNodeIds = [],
  hiddenNodeIds = [],
  searchable = true,
  onTreeChange,
}: TreeViewProps) => (
  <TreeViewModelProvider
    nodes={nodes}
    selectedNodeIds={selectedNodeIds}
    hiddenNodeIds={hiddenNodeIds}
    onTreeChange={onTreeChange}
  >
    {searchable && <SearchInput />}
    <TreeComponent className="mt-6" />
  </TreeViewModelProvider>
);
