import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const BusinessUnit = createSvgIcon({
  content: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0417 10.4167C11.1442 10.4167 10.4167 11.1442 10.4167 12.0417C10.4167 12.9391 11.1442 13.6667 12.0417 13.6667C12.9391 13.6667 13.6667 12.9391 13.6667 12.0417C13.6667 11.1442 12.9391 10.4167 12.0417 10.4167ZM9.41667 12.0417C9.41667 10.5919 10.5919 9.41667 12.0417 9.41667C13.4914 9.41667 14.6667 10.5919 14.6667 12.0417C14.6667 13.4914 13.4914 14.6667 12.0417 14.6667C10.5919 14.6667 9.41667 13.4914 9.41667 12.0417Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33 9.42H7.58V14.67H2.33V9.42ZM3.33 10.42V13.67H6.58V10.42H3.33Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95833 3.33333C4.06087 3.33333 3.33333 4.06087 3.33333 4.95833C3.33333 5.8558 4.06087 6.58333 4.95833 6.58333C5.8558 6.58333 6.58333 5.8558 6.58333 4.95833C6.58333 4.06087 5.8558 3.33333 4.95833 3.33333ZM2.33333 4.95833C2.33333 3.50859 3.50859 2.33333 4.95833 2.33333C6.40808 2.33333 7.58333 3.50859 7.58333 4.95833C7.58333 6.40808 6.40808 7.58333 4.95833 7.58333C3.50859 7.58333 2.33333 6.40808 2.33333 4.95833Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42 2.33H14.67V7.58H9.42V2.33ZM10.42 3.33V6.58H13.67V3.33H10.42Z"
        className="fill-current"
      />
    </>
  ),
});
