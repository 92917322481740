import React, { useState } from "react";

import { Button, Link } from "@components/atoms";
import { Link as LinkIcon } from "@components/atoms/Icon/Link";

type UrlDisplayHandlerProps = {
  urls: Array<string>;
};

const getDomainFromUrl = (url: string): RegExpMatchArray | null => {
  return url.match(/\w+\..{2,3}(?:\..{2})?(?:$|(?=\/))/g);
};

export const UrlDisplayHandler = ({ urls }: UrlDisplayHandlerProps) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="w-full flex flex-col gap-2">
      {urls.slice(0, expanded ? urls.length : 2).map((url) => {
        const domain = getDomainFromUrl(url);
        const urlWithProtocol = url.match(/(http|https)/)
          ? url
          : "https://" + url;
        return (
          <div key={url}>
            {!domain ? (
              "-"
            ) : (
              <Link
                className="flex items-center"
                href={urlWithProtocol}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="text-black-400 font-semibold mr-2 hover:underline">
                  {domain[0]}
                </p>
                <LinkIcon className={"text-blue-300"} size={16} />
              </Link>
            )}
          </div>
        );
      })}
      {urls.length > 2 && (
        <Button
          type="button"
          variant="text"
          onClick={() => setExpanded((prev) => !prev)}
          className="font-semibold text-blue-300 w-full text-justify"
        >
          Show {expanded ? "less" : urls.slice(2).length + " more"}
        </Button>
      )}
    </div>
  );
};
