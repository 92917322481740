import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Remove = createSvgIcon({
  content: (
    <>
      <path
        d="M17 12H7"
        stroke="#C6D1E5"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </>
  ),
  width: 24,
  height: 24,
});
