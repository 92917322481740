import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Checkmark = createSvgIcon({
  content: (
    <>
      <path
        d="M0.5 7.99466C0.5 3.85664 3.85664 0.5 7.99466 0.5C12.1429 0.5 15.4893 3.8562 15.4893 7.99466C15.4893 12.1327 12.1327 15.4893 7.99466 15.4893C3.85664 15.4893 0.5 12.1327 0.5 7.99466Z"
        className="stroke-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1659 4.80614C12.3329 4.9731 12.3329 5.24379 12.1659 5.41075L6.39438 11.1823C6.22742 11.3493 5.95673 11.3493 5.78977 11.1823C5.62281 11.0153 5.62281 10.7446 5.78977 10.5777L11.5613 4.80614C11.7283 4.63918 11.999 4.63918 12.1659 4.80614Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33176 8.11944C3.49872 7.95248 3.76941 7.95248 3.93637 8.11944L6.34118 10.5242C6.50814 10.6912 6.50814 10.9619 6.34118 11.1289C6.17422 11.2958 5.90353 11.2958 5.73657 11.1289L3.33176 8.72404C3.1648 8.55708 3.1648 8.28639 3.33176 8.11944Z"
        className="fill-current"
      />
    </>
  ),
});
