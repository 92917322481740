import React from "react";

import { classnames } from "@external/tailwindcss-classnames";

import { ConditionColor, ConditionColorMapping } from "../ConditionColours";

export interface ColourLineProps {
  colour: ConditionColor;
}

export const ColourLine = ({ colour }: ColourLineProps) => (
  <div className={classnames("w-1", ...ConditionColorMapping[colour])} />
);
