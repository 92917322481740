import React from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface SlideProps {
  show?: boolean;
  className?: any;
  size?: number;
}

export const Slide = ({ className, size = 2, show, ...rest }: SlideProps) => {
  return (
    <div
      data-testid="loading-slide"
      className={classnames(
        className,
        "inline-block",
        "relative",
        `w-${size * 5}` as any,
        `h-${size}` as any
      )}
      {...rest}
    >
      {show === false ? (
        <div className="w-full" />
      ) : (
        <>
          <div
            className={`absolute left-0 w-${size} h-${size} rounded-full bg-coolGrey-300 animate-grow`}
          />
          <div
            className={`absolute left-0 w-${size} h-${size} rounded-full bg-coolGrey-300 animate-slide-right-${
              size * 2
            }`}
          />
          <div
            className={`absolute left-${
              2 * size
            } w-${size} h-${size} rounded-full bg-coolGrey-300 animate-slide-right-${
              size * 2
            }`}
          />
          <div
            className={`absolute left-${
              4 * size
            } w-${size} h-${size} rounded-full bg-coolGrey-300 animate-shrink`}
          />
        </>
      )}
    </div>
  );
};
