import React from "react";

import { NoContent } from "@components/atoms";
import { Table } from "@components/molecules";

export interface InsightsTableProps
  extends Required<
      Pick<Table.TableProps, "loading" | "loadingRows" | "columns" | "children">
    >,
    Pick<Table.TableProps, "loadingRowsHeight"> {}

export const InsightsTable = ({
  columns,
  loading,
  loadingRows,
  loadingRowsHeight = "h-10",
  children,
}: InsightsTableProps) => (
  <Table.Table
    columns={columns}
    emptyComponent={<NoContent />}
    loading={loading}
    loadingRows={loadingRows}
    loadingRowsHeight={loadingRowsHeight}
    rounded
    className="shadow-1dp"
  >
    {children}
  </Table.Table>
);
