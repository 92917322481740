import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";

import { CustomDialog } from "@components/molecules";
import { CreateJobForm } from "@components/organisms";
import {
  CreateJobDialog_BeginIngestionJobDocument,
  CreateJobDialog_CreateIngestionJobDocument,
  Jobs_JobsDocument,
} from "@graphql/operations";

type CreateIngestionJobData = {
  href: string;
  videoId: string;
  title?: string;
  thumbnail?: string;
};

type BeginIngestionJobData = {
  videoId: string;
};

export interface CreateJobDialogProps {
  isDialogOpen: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSubmission: () => void;
}

export const CreateJobDialog = ({
  isDialogOpen = false,
  onCancel,
  onClose,
  onSubmission,
}: CreateJobDialogProps) => {
  const [createIngestionJob, { data: dataCreate, error: errorCreate }] =
    useMutation(CreateJobDialog_CreateIngestionJobDocument, {
      refetchQueries: [Jobs_JobsDocument],
    });

  const [beginIngestionJob, { data: dataBegin, error: errorBegin }] =
    useMutation(CreateJobDialog_BeginIngestionJobDocument, {
      refetchQueries: [Jobs_JobsDocument],
    });

  useEffect(() => {
    if (dataCreate && dataBegin) {
      toast.success("Job created", {
        id: "job-created",
      });
    }

    if (errorCreate || errorBegin) {
      const errMessage = errorCreate?.message || errorBegin?.message || "";

      try {
        const jsonError = JSON.parse(errMessage);

        if (jsonError.message) {
          toast.error(jsonError.message, {
            id: "job-create-error",
          });
        } else {
          toast.error("An unknown error happened", {
            id: "job-create-error",
          });
        }
      } catch {
        toast.error(errMessage, {
          id: "job-create-error",
        });
      }
    }
  }, [dataCreate, errorCreate, dataBegin, errorBegin]);

  useEffect(() => {
    if (dataCreate && dataBegin) {
      toast.success("Job created", {
        id: "job-created",
      });
    }

    if (errorCreate || errorBegin) {
      const errMessage = errorCreate?.message || errorBegin?.message || "";
      try {
        const jsonError = JSON.parse(errMessage);

        if (jsonError.message) {
          toast.error(jsonError.message, {
            id: "job-create-error",
          });
        } else {
          toast.error("An unknown error happened", {
            id: "job-create-error",
          });
        }
      } catch {
        toast.error(errMessage, {
          id: "job-create-error",
        });
      }
    }
  }, [dataCreate, errorCreate, dataBegin, errorBegin]);

  const handleCreateIngestionJobSubmission = async (
    data: CreateIngestionJobData
  ) => {
    try {
      return await createIngestionJob({
        variables: {
          input: {
            ...data,
          },
        },
      });
    } catch (err) {
      return err;
    }
  };

  const handleBeginIngestionJobSubmission = async (
    data: BeginIngestionJobData
  ) => {
    try {
      return await beginIngestionJob({
        variables: {
          input: {
            ...data,
          },
        },
      });
    } catch (err) {
      return err;
    }
  };

  const handleJobSubmission = async (data: CreateIngestionJobData) => {
    try {
      await handleCreateIngestionJobSubmission(data);
      await handleBeginIngestionJobSubmission({ videoId: data.videoId });
      onSubmission();
    } catch (err) {
      return err;
    }
  };

  return (
    <CustomDialog.Container isDialogOpen={isDialogOpen} onClose={onClose}>
      <CustomDialog.Card onClose={onClose}>
        <CustomDialog.Title>Ingest a video</CustomDialog.Title>
        <CreateJobForm onSubmit={handleJobSubmission} onCancel={onCancel} />
      </CustomDialog.Card>
    </CustomDialog.Container>
  );
};
