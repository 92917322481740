import React, { useEffect, useState } from "react";
import { ApolloClient, ApolloProvider } from "@apollo/client";

import { ThemedToaster } from "@components/atoms";
import { SessionLoader } from "@components/pages";
import amplifyClientProvider from "@hooks/amplify-client";
import apolloClient from "@hooks/apollo";
import { AuthProvider } from "@hooks/useAuth";

import CustomRoutes from "./CustomRoutes";

const amplifyClient = amplifyClientProvider();

function App() {
  const [graphqlClient, setGraphqlClient] = useState<ApolloClient<{}>>();

  useEffect(() => {
    apolloClient().then((client) => {
      setGraphqlClient(client);
    });
  }, []);

  if (!graphqlClient) {
    return <SessionLoader />;
  }

  return (
    <AuthProvider provider={amplifyClient}>
      <ApolloProvider client={graphqlClient}>
        <CustomRoutes />
        <ThemedToaster />
      </ApolloProvider>
    </AuthProvider>
  );
}

export default App;
