import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Bell = createSvgIcon({
  content: (
    <>
      <path d="M12.3684 1.12281C14.0807 1.12281 15.6947 1.79649 16.9298 3.01754C18.1509 4.25263 18.8246 5.86667 18.8246 7.57895C18.8246 12.4351 19.386 16 20.4947 18.1895L20.8035 18.807H21.4912H21.8421C22.2632 18.807 22.614 19.1579 22.614 19.5789C22.614 20 22.2632 20.3509 21.8421 20.3509H2.89474C2.47368 20.3509 2.12281 20 2.12281 19.5789C2.12281 19.1579 2.47368 18.807 2.89474 18.807H3.24561H3.93333L4.24211 18.1895C5.35088 16 5.91228 12.4351 5.91228 7.57895C5.91228 5.86667 6.58597 4.25263 7.80702 3.01754C9.04211 1.79649 10.6561 1.12281 12.3684 1.12281ZM12.3684 0C8.21404 0 4.78947 3.42456 4.78947 7.57895C4.78947 13.3895 3.94737 16.2807 3.24561 17.6842H2.89474C1.84211 17.6842 1 18.5263 1 19.5789C1 20.6316 1.84211 21.4737 2.89474 21.4737H21.8421C22.8947 21.4737 23.7368 20.6316 23.7368 19.5789C23.7368 18.5263 22.8947 17.6842 21.8421 17.6842H21.4912C20.7754 16.2807 19.9474 13.3895 19.9474 7.57895C19.9474 3.42456 16.5228 0 12.3684 0Z" />
      <path d="M15.4977 22.1052H12.3398H9.18188C9.18188 23.1438 10.6135 24 12.3398 24C14.0801 24 15.4977 23.1438 15.4977 22.1052Z" />
    </>
  ),
  width: 24,
  height: 24,
  defaultClassName: "fill-current",
});
