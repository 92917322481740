import React, {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";

export interface UserSession {
  getIdToken(): CognitoIdToken;

  getRefreshToken(): CognitoRefreshToken;

  getAccessToken(): CognitoAccessToken;

  isValid(): boolean;
}

export interface IProvider {
  signIn(): void;

  signOut(): Promise<void>;

  getSession(): Promise<UserSession | void | null | undefined>;
}

export interface IAuthContext extends IProvider {
  initializing: boolean;
  isAuthenticated: boolean;
}

export interface AuthProviderProps {
  provider: IProvider;
  children?: ReactNode;
}

const AuthState = (): IAuthContext => ({
  initializing: true,
  isAuthenticated: false,
  signIn: () => {
    throw new Error("Missing Provider signIn method");
  },
  signOut: () => {
    throw new Error("Missing Provider signOut method");
  },
  getSession: () => {
    throw new Error("Missing Provider getSession method");
  },
});

const AuthContext = createContext(AuthState());

export const useAuth = (): IAuthContext => useContext(AuthContext);

export const AuthProvider = ({ children, provider }: AuthProviderProps) => {
  const [initializing, setInitializing] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const { signIn, signOut, getSession } = provider;

  useEffect(() => {
    getSession()
      .then((session) => {
        setIsAuthenticated(session?.isValid() || false);
      })
      .catch(() => {
        setIsAuthenticated(false);
      })
      .finally(() => {
        setInitializing(false);
      });
  }, [getSession]);

  return (
    <AuthContext.Provider
      value={{
        initializing,
        isAuthenticated,
        signIn,
        signOut,
        getSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
