import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const BaseballBall = createSvgIcon({
  content: (
    <>
      <path
        d="M3.75737 12.2427C4.31452 12.7998 4.97596 13.2418 5.70392 13.5433C6.43188 13.8448 7.2121 14 8.00004 14C8.78797 14 9.56819 13.8448 10.2962 13.5433C11.0241 13.2418 11.6855 12.7998 12.2427 12.2427C12.7999 11.6855 13.2418 11.0241 13.5433 10.2961C13.8449 9.56816 14.0001 8.78794 14.0001 8.00001C14.0001 7.21207 13.8449 6.43185 13.5433 5.70389C13.2418 4.97593 12.7999 4.31449 12.2427 3.75734C11.1175 2.63211 9.59135 1.99997 8.00004 1.99997C6.40873 1.99997 4.8826 2.63211 3.75737 3.75734C2.63214 4.88256 2 6.4087 2 8.00001C2 9.59131 2.63214 11.1174 3.75737 12.2427Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.32985 2.01334C8.37557 2.85487 8.2435 3.69659 7.94223 4.48368C7.64096 5.27076 7.17726 5.98555 6.58133 6.58148C5.9854 7.17741 5.27061 7.64111 4.48352 7.94238C3.69644 8.24365 2.85471 8.37572 2.01318 8.33"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.9866 7.67C13.1451 7.62428 12.3034 7.75635 11.5163 8.05762C10.7292 8.35889 10.0144 8.82259 9.41849 9.41852C8.82256 10.0145 8.35886 10.7292 8.05759 11.5163C7.75632 12.3034 7.62425 13.1451 7.66997 13.9867"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 6L7.33333 7.33333"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.6665 8.66667L9.99984 10"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.3335 4.66667L8.66683 5.33334"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.6665 7.33333L5.33317 8.66666"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6665 7.33333L11.3332 8.66666"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.3335 10.6667L8.66683 11.3333"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
    </>
  ),
  width: 16,
  height: 16,
  defaultColor: "navy-100",
});
