import React, { useState } from "react";

import { Image, Loading } from "@components/atoms";
import { Images } from "@components/molecules";
import { classnames } from "@external/tailwindcss-classnames";

export interface ThumbnailProps {
  className?: any;
  src?: string | null;
}

export const Thumbnail = ({ className, src }: ThumbnailProps) => {
  const [error, setError] = useState(false);

  if (error) {
    return (
      <Images.ErrorLoadingImage
        data-testid="ErrorLoadingImage"
        size="small"
        className={classnames(className, "w-24", "h-14")}
      />
    );
  }

  if (!src) {
    return (
      <Images.NoImageAvailable
        data-testid="NoImageAvailable"
        size="small"
        className={classnames(className, "w-24", "h-14")}
      />
    );
  }

  return (
    <Image
      data-testid="Image"
      className={className}
      alt="thumbnail"
      src={src}
      loading={<Loading.Shimmer data-testid="Shimmer" className="w-24 h-14" />}
      onError={() => setError(true)}
    />
  );
};
