import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const SpokenNoun = createSvgIcon({
  content: (
    <path
      d="M8.66667 11.3333L4.66667 14V11.3333H2V2.66663H14V11.3333H8.66667Z"
      className="stroke-current"
      strokeLinecap="square"
    />
  ),
  width: 16,
  height: 16,
});
