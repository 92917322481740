import React from "react";

import { Icon } from "@components/atoms";
import { Tooltip } from "@components/organisms/Tooltip";
import { classnames } from "@external/tailwindcss-classnames";

export interface SegmentPillProps {
  id: string;
  title?: string;
}

export const SegmentPill = ({ id, title }: SegmentPillProps) => {
  const inverse = id.startsWith("$");

  return (
    <div className="full rounded-md px-4 inline-flex items-center bg-lightGrey-100 text-xs font-medium gap-2 py-1">
      {inverse ? (
        <Icon.Segment
          size={16}
          color="red-300"
          className={classnames("shrink-0" as any)}
        />
      ) : (
        <Icon.InverseSegment
          size={16}
          color="blue-300"
          className={classnames("shrink-0" as any)}
        />
      )}
      {title && (
        <Tooltip horizontalAlign="left" tip={title}>
          <div className="line-clamp-2">{title}</div>
        </Tooltip>
      )}
      <div className="text-black-100">{id}</div>
    </div>
  );
};
