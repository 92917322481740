import React from "react";

import { classnames } from "@external/tailwindcss-classnames";
import { getLocaleCode } from "@utils/i18n";

interface ResponseItem {
  value: string;
  occurrences: number;
}

export interface ItemProps {
  responseItem: ResponseItem;
  caser?: (string: string) => string;
  icon: React.ReactNode;
  className?: any;
  onClick?: (item: ResponseItem) => Promise<void> | void;
}

export const Item = ({
  responseItem,
  icon,
  className,
  onClick,
  caser,
  ...rest
}: ItemProps) => (
  <div
    className={classnames(
      className,
      "flex",
      "flex-row",
      "cursor-pointer",

      "rounded",

      "m-2",

      "hover:bg-navy-300",
      "hover:bg-opacity-4",
      "active:bg-blue-300",
      "active:bg-opacity-8"
    )}
    onClick={
      onClick
        ? () =>
            onClick({
              ...responseItem,
              value: caser ? caser(responseItem.value) : responseItem.value,
            })
        : undefined
    }
    {...rest}
  >
    <div className="mr-3">{icon}</div>
    <div className="flex-1 text-black-300">
      {caser ? caser(responseItem.value) : responseItem.value}
    </div>
    <div className="justify-self-end text-bodySm text-black-100 mr-2">
      {responseItem.occurrences.toLocaleString(getLocaleCode())}
    </div>
  </div>
);
