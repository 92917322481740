import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const UserGroup = createSvgIcon({
  content: (
    <>
      <path
        d="M6.6665 8.66665C6.6665 9.02027 6.80698 9.35941 7.05703 9.60946C7.30708 9.8595 7.64622 9.99998 7.99984 9.99998C8.35346 9.99998 8.6926 9.8595 8.94265 9.60946C9.19269 9.35941 9.33317 9.02027 9.33317 8.66665C9.33317 8.31302 9.19269 7.97389 8.94265 7.72384C8.6926 7.47379 8.35346 7.33331 7.99984 7.33331C7.64622 7.33331 7.30708 7.47379 7.05703 7.72384C6.80698 7.97389 6.6665 8.31302 6.6665 8.66665Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.3335 14V13.3333C5.3335 12.9797 5.47397 12.6406 5.72402 12.3905C5.97407 12.1405 6.31321 12 6.66683 12H9.3335C9.68712 12 10.0263 12.1405 10.2763 12.3905C10.5264 12.6406 10.6668 12.9797 10.6668 13.3333V14"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 3.33333C10 3.68696 10.1405 4.02609 10.3905 4.27614C10.6406 4.52619 10.9797 4.66667 11.3333 4.66667C11.687 4.66667 12.0261 4.52619 12.2761 4.27614C12.5262 4.02609 12.6667 3.68696 12.6667 3.33333C12.6667 2.97971 12.5262 2.64057 12.2761 2.39052C12.0261 2.14048 11.687 2 11.3333 2C10.9797 2 10.6406 2.14048 10.3905 2.39052C10.1405 2.64057 10 2.97971 10 3.33333Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3335 6.66669H12.6668C13.0205 6.66669 13.3596 6.80716 13.6096 7.05721C13.8597 7.30726 14.0002 7.6464 14.0002 8.00002V8.66669"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.3335 3.33333C3.3335 3.68696 3.47397 4.02609 3.72402 4.27614C3.97407 4.52619 4.31321 4.66667 4.66683 4.66667C5.02045 4.66667 5.35959 4.52619 5.60964 4.27614C5.85969 4.02609 6.00016 3.68696 6.00016 3.33333C6.00016 2.97971 5.85969 2.64057 5.60964 2.39052C5.35959 2.14048 5.02045 2 4.66683 2C4.31321 2 3.97407 2.14048 3.72402 2.39052C3.47397 2.64057 3.3335 2.97971 3.3335 3.33333Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 8.66669V8.00002C2 7.6464 2.14048 7.30726 2.39052 7.05721C2.64057 6.80716 2.97971 6.66669 3.33333 6.66669H4.66667"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
});
