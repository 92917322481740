import React, { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

import { Segment } from "@components/atoms";
import { SegmentBuilder } from "@components/templates";
import {
  converters,
  FiltersProvider,
  ImportColumnType,
  useSegmentImport,
} from "@hooks/segments";

const QUERY = gql`
  query GetSegmentImport {
    segmentImport @client {
      data
    }
  }
`;

export const SegmentsNew = () => {
  const { deleteSegmentImport } = useSegmentImport();
  const { data, loading } = useQuery<{
    segmentImport?: {
      data: { [key in ImportColumnType]: string }[];
    };
  }>(QUERY, {
    returnPartialData: true,
  });

  const query = converters.SegmentImportDataToQuery(
    data?.segmentImport?.data || []
  );

  useEffect(() => {
    const hasData = data?.segmentImport?.data;
    if (hasData && !loading) {
      deleteSegmentImport();
    }
  }, [deleteSegmentImport, data, loading]);

  return (
    <FiltersProvider query={query}>
      <SegmentBuilder
        loading={false}
        operation={Segment.OperationOptions.Create}
      />
    </FiltersProvider>
  );
};
