import React from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface SentimentIndicatorProps {
  className?: any;
  sentiment: {
    SentimentScoreMixed: number;
    SentimentScoreNegative: number;
    SentimentScoreNeutral: number;
    SentimentScorePositive: number;
  };
}

export const SentimentIndicator = ({
  sentiment,
  className,
}: SentimentIndicatorProps) => {
  return (
    <div
      className={classnames(
        className,
        "w-full",
        "h-2",
        "mt-2",
        "flex",
        "flex-row"
      )}
    >
      <div
        className="bg-orange-300 rounded-l-sm"
        style={{ flex: sentiment.SentimentScoreMixed }}
      ></div>
      <div
        className="bg-red-300"
        style={{ flex: sentiment.SentimentScoreNegative }}
      ></div>
      <div
        className="bg-navy-200"
        style={{ flex: sentiment.SentimentScorePositive }}
      ></div>
      <div
        className="bg-blue-300 rounded-r-sm"
        style={{ flex: sentiment.SentimentScoreNeutral }}
      ></div>
    </div>
  );
};
