import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const ChevronLeft = createSvgIcon({
  content: (
    <path
      d="M9.3335 12L5.3335 8L9.3335 4"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  ),
  width: 16,
  height: 16,
  defaultClassName: "stroke-current",
});
