import { type ReactNode, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { PortalContext } from "./Group";

export interface TeleportProps {
  refName?: string;
  children: ReactNode;
}

export const Teleport = ({ refName, children }: TeleportProps) => {
  const [location, setLocation] = useState<HTMLElement>();
  const { targets } = useContext(PortalContext) || { targets: {} };
  const target = targets[refName || "default"];

  useEffect(() => {
    if (!target || !target.current) {
      return;
    }

    setLocation(target.current);
  }, [target]);

  if (!location) {
    return null;
  }

  return createPortal(children, location);
};
