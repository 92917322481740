import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FetchResult, MutationHookOptions, useMutation } from "@apollo/client";
import { isEmpty } from "lodash";

import { Button, Segment as SegmentAtoms } from "@components/atoms";
import { Segment } from "@components/molecules";
import {
  BeginSegmentButton_BeginSegmentJobDocument,
  CreateSegmentButton_CreateSegmentJobDocument,
  CreateSegmentButton_CreateSegmentJobMutation,
  CreateSegmentButton_CreateSegmentJobMutationVariables,
  NestedSegmentIdEquals_SegmentJobDocument,
  SegmentJobDetails_SegmentJobDocument,
  SegmentJobTable_ListSegmentJobsDocument,
  SegmentQuery,
  SegmentsJobDuplicate_SegmentJobDocument,
  SegmentsJobEdit_SegmentJobDocument,
  SegmentsOverview_ListSegmentJobs_AggregateDocument,
} from "@graphql/operations";
import { useFilters } from "@hooks/segments";
import * as errors from "@utils/errors";

import { beginSegmentJobDefaultOptions } from "../BeginSegmentButton";

export const createSegmentJobDefaultOptions: MutationHookOptions<
  CreateSegmentButton_CreateSegmentJobMutation,
  CreateSegmentButton_CreateSegmentJobMutationVariables
> = {
  refetchQueries: [
    SegmentsJobDuplicate_SegmentJobDocument,
    SegmentsJobEdit_SegmentJobDocument,
    NestedSegmentIdEquals_SegmentJobDocument,
    SegmentJobDetails_SegmentJobDocument,
    SegmentJobTable_ListSegmentJobsDocument,
    SegmentsOverview_ListSegmentJobs_AggregateDocument,
  ],
};

export const CreateSegmentButton = () => {
  const [retryPayload, setRetryPayload] = useState<{
    segmentId: string;
    version: number;
  }>({
    segmentId: "",
    version: 0,
  });
  const { query, segmentTitle, segmentDescription, segmentId } = useFilters();
  const [
    createSegmentJob,
    {
      data: createSegmentJobData,
      loading: createSegmentJobLoading,
      error: createSegmentJobError,
      reset: createSegmentJobReset,
    },
  ] = useMutation(
    CreateSegmentButton_CreateSegmentJobDocument,
    createSegmentJobDefaultOptions
  );
  const [
    beginSegmentJob,
    {
      loading: beginSegmentJobLoading,
      error: beginSegmentJobError,
      reset: beginSegmentJobReset,
    },
  ] = useMutation(
    BeginSegmentButton_BeginSegmentJobDocument,
    beginSegmentJobDefaultOptions
  );
  const [isDialogueOpen, setIsDialogueOpen] = useState(false);
  const navigate = useNavigate();

  const variables: CreateSegmentButton_CreateSegmentJobMutationVariables = {
    input: {
      title: segmentTitle,
      ...(segmentId ? { segmentId: segmentId } : {}),
      ...(segmentDescription ? { description: segmentDescription } : {}),
      query: (query || {}) as SegmentQuery,
    },
  };

  const createAndStartJob = useCallback(
    async (
      variables: CreateSegmentButton_CreateSegmentJobMutationVariables
    ) => {
      let result: FetchResult<CreateSegmentButton_CreateSegmentJobMutation> =
        {};
      if (retryPayload.segmentId === "" && retryPayload.version === 0) {
        result = await createSegmentJob({
          variables,
        });
        if (
          result.data?.createSegmentJob?.segmentId &&
          result.data?.createSegmentJob?.version
        ) {
          setRetryPayload({
            segmentId: result.data?.createSegmentJob?.segmentId,
            version: result.data.createSegmentJob.version,
          });
        }
      }

      if (retryPayload.segmentId === "" && retryPayload.version === 0) {
        if (
          result.data?.createSegmentJob?.segmentId &&
          result.data?.createSegmentJob?.version
        ) {
          await beginSegmentJob({
            variables: {
              input: {
                segmentId: result.data.createSegmentJob.segmentId,
                version: result.data.createSegmentJob.version,
              },
            },
          });
        } else {
          await beginSegmentJob({
            variables: {
              input: {
                segmentId: retryPayload.segmentId,
                version: retryPayload.version,
              },
            },
          });
        }
      }
    },
    [createSegmentJob, beginSegmentJob, retryPayload]
  );

  return (
    <>
      <Button
        type="button"
        size="medium"
        color="primary"
        disabled={isEmpty(segmentTitle) || isEmpty(query)}
        data-e2e="button-create-segment"
        onClick={async () => {
          setIsDialogueOpen(true);
          await createAndStartJob(variables);
        }}
      >
        Create Segment
      </Button>
      <Segment.OperationDialogue
        operation={SegmentAtoms.OperationOptions.Create}
        loading={createSegmentJobLoading || beginSegmentJobLoading}
        segmentId={createSegmentJobData?.createSegmentJob?.segmentId || ""}
        isDialogOpen={isDialogueOpen}
        onClose={() => setIsDialogueOpen(false)}
        onConfirmButtonClicked={() => navigate("/segments")}
        error={errors.isApolloErrorPresent(
          createSegmentJobError,
          beginSegmentJobError
        )}
        onTryAgain={async () => {
          createSegmentJobReset();
          beginSegmentJobReset();
          await createAndStartJob(variables);
        }}
      />
    </>
  );
};
