import React, { HTMLAttributes } from "react";

import { classnames, TArg, THeight } from "@external/tailwindcss-classnames";

export interface RowProps
  extends Omit<HTMLAttributes<HTMLTableRowElement>, "className"> {
  children: React.ReactNode;
  className?: string;
  hover?: boolean;
  rowSpan?: number;
  height?: THeight;
}

export const Row = ({
  hover = true,
  rowSpan,
  height,
  children,
  className,
  ...rest
}: RowProps) => (
  <tr
    {...rest}
    className={classnames(
      "border-coolGrey-200",
      "border-b",
      "text-bodySm",
      "text-black-300",
      "transition-colors",
      "duration-100",
      "last:border-b-0" as any,
      {
        ["hover:first:box-shadow-border-t-coolGrey-200" as any]: hover,
        "hover:bg-navy-300": hover,
        "hover:bg-opacity-4": hover,
        "cursor-pointer": !!rest.onClick,
      },
      height,
      className as TArg
    )}
  >
    {children}
  </tr>
);
