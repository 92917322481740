import { Locale } from "date-fns";
import * as Locales from "date-fns/locale";

const defaultLocale = "enGB";
const fallbackLocale = Locales[defaultLocale];

const locale =
  Locales[navigator.language.replace("-", "") as keyof typeof Locales] ||
  Locales[navigator.language.split("-")[0] as keyof typeof Locales] ||
  fallbackLocale;

export const getLocale = (): Locale => locale;
export const getLocaleCode = (): string | undefined => locale.code;
export const getTimezone = (): string =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;
