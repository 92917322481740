import { isEmpty } from "lodash";

export const getSearchParams = <T extends object>(
  searchParams: URLSearchParams,
  keys: string[]
) => {
  const filters = Array.from(searchParams.entries())
    .filter(([key]) => keys.includes(key))
    .reduce((acc, item) => {
      let value;

      try {
        value = JSON.parse(item[1]);
      } catch (_) {
        value = item[1];
      }

      return {
        ...acc,
        [item[0]]: value,
      };
    }, {} as T);

  return filters;
};

export const removeSearchParams = (
  searchParams: URLSearchParams,
  keys: string[]
) => {
  keys.forEach((type) => {
    searchParams.delete(type);
  });

  return searchParams;
};

export const updateSearchParams = <T extends object>(
  newSearchParams: T,
  searchParams: URLSearchParams
) => {
  Object.entries(newSearchParams).forEach(([key, value]) => {
    const isObject = typeof value === "object";

    if (isObject && isEmpty(value)) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, isObject ? JSON.stringify(value) : value);
    }
  });

  return searchParams;
};
