import snakeCase from "lodash/snakeCase";
import toUpper from "lodash/toUpper";

import {
  AdvancedSearchQuery,
  AdvancedSearchQueryIntrinsicsCombinators,
  AdvancedSearchQuerySentimentEqualsVariablesSentiment,
} from "@graphql/operations";

import {
  CelebrityEqualsFilter,
  CelebrityLikeFilter,
  ContentModerationContainsFilter,
  ContentModerationExcludesFilter,
  DurationFilter,
  Filter,
  FilterType,
  LabelContainsFilter,
  SentimentEqualsFilter,
  SpokenNounContainsFilter,
  TitleLikeFilter,
} from "./types";

const addIntrinsicCombinator = (
  query: AdvancedSearchQuery,
  combinator: AdvancedSearchQueryIntrinsicsCombinators
): AdvancedSearchQuery => {
  query.INTRINSICS__COMBINATOR = {
    combinator:
      combinator === AdvancedSearchQueryIntrinsicsCombinators.And
        ? AdvancedSearchQueryIntrinsicsCombinators.And
        : AdvancedSearchQueryIntrinsicsCombinators.Or,
    conditions: [],
  };

  return query;
};

function getFilter<T>(
  filters: Filter[],
  filterType: FilterType
): T | undefined {
  const labelFilter = filters.find(
    (filter) => filter.filterType === filterType
  );

  return labelFilter as T | undefined;
}

const addToIntrinsicCombinator = (
  query: AdvancedSearchQuery,
  condition: Partial<AdvancedSearchQuery>
) => {
  query.INTRINSICS__COMBINATOR!.conditions?.push(condition);
};

const addToQuery = (
  query: AdvancedSearchQuery,
  condition: Partial<AdvancedSearchQuery>
) => {
  Object.assign(query, condition);
};

const addTitleLikeCondition = (
  query: AdvancedSearchQuery,
  filters: Filter[]
): AdvancedSearchQuery => {
  const titleFilter = getFilter<TitleLikeFilter>(
    filters,
    FilterType.TITLE__LIKE
  );

  if (titleFilter && titleFilter.title.trim() !== "") {
    const filter = {
      TITLE__LIKE: {
        value: titleFilter.title,
      },
    };

    if (filters.length === 1) {
      addToQuery(query, filter);
    } else {
      addToIntrinsicCombinator(query, filter);
    }
  }

  return query;
};

const addLabelContainsCondition = (
  query: AdvancedSearchQuery,
  filters: Filter[],
  topLevelCombinator: AdvancedSearchQueryIntrinsicsCombinators
): AdvancedSearchQuery => {
  const labelFilter = getFilter<LabelContainsFilter>(
    filters,
    FilterType.LABEL__CONTAINS
  );

  if (!labelFilter || labelFilter.values.length < 1) {
    return query;
  }

  const append = filters.length > 1 ? addToIntrinsicCombinator : addToQuery;

  if (labelFilter.values.length > 1) {
    append(query, {
      INTRINSICS__COMBINATOR: {
        combinator:
          topLevelCombinator === AdvancedSearchQueryIntrinsicsCombinators.And
            ? AdvancedSearchQueryIntrinsicsCombinators.And
            : AdvancedSearchQueryIntrinsicsCombinators.Or,
        conditions: labelFilter.values.map((value) => ({
          LABEL__CONTAINS: {
            value: toUpper(snakeCase(value)),
          },
        })),
      },
    });
  } else {
    append(query, {
      LABEL__CONTAINS: {
        value: toUpper(snakeCase(labelFilter.values[0])),
      },
    });
  }

  return query;
};

const addCelebrityEqualsCondition = (
  query: AdvancedSearchQuery,
  filters: Filter[],
  topLevelCombinator: AdvancedSearchQueryIntrinsicsCombinators
): AdvancedSearchQuery => {
  const celebrityFilter = getFilter<CelebrityEqualsFilter>(
    filters,
    FilterType.CELEBRITY__EQUALS
  );

  if (!celebrityFilter || celebrityFilter.values.length < 1) {
    return query;
  }

  const append = filters.length > 1 ? addToIntrinsicCombinator : addToQuery;

  if (celebrityFilter.values.length > 1) {
    append(query, {
      INTRINSICS__COMBINATOR: {
        combinator:
          topLevelCombinator === AdvancedSearchQueryIntrinsicsCombinators.And
            ? AdvancedSearchQueryIntrinsicsCombinators.And
            : AdvancedSearchQueryIntrinsicsCombinators.Or,
        conditions: celebrityFilter.values.map((value) => ({
          CELEBRITY__EQUALS: {
            value: toUpper(snakeCase(value)),
          },
        })),
      },
    });
  } else {
    append(query, {
      CELEBRITY__EQUALS: {
        value: toUpper(snakeCase(celebrityFilter.values[0])),
      },
    });
  }

  return query;
};

const addCelebrityLikeCondition = (
  query: AdvancedSearchQuery,
  filters: Filter[],
  topLevelCombinator: AdvancedSearchQueryIntrinsicsCombinators
): AdvancedSearchQuery => {
  const celebrityFilter = getFilter<CelebrityLikeFilter>(
    filters,
    FilterType.CELEBRITY__LIKE
  );

  if (!celebrityFilter || celebrityFilter.values.length < 1) {
    return query;
  }

  const append = filters.length > 1 ? addToIntrinsicCombinator : addToQuery;

  if (celebrityFilter.values.length > 1) {
    append(query, {
      INTRINSICS__COMBINATOR: {
        combinator:
          topLevelCombinator === AdvancedSearchQueryIntrinsicsCombinators.And
            ? AdvancedSearchQueryIntrinsicsCombinators.And
            : AdvancedSearchQueryIntrinsicsCombinators.Or,
        conditions: celebrityFilter.values.map((value) => ({
          CELEBRITY__LIKE: {
            value: toUpper(snakeCase(value)),
          },
        })),
      },
    });
  } else {
    append(query, {
      CELEBRITY__LIKE: {
        value: toUpper(snakeCase(celebrityFilter.values[0])),
      },
    });
  }

  return query;
};

const addSpokenNounContainsCondition = (
  query: AdvancedSearchQuery,
  filters: Filter[],
  topLevelCombinator: AdvancedSearchQueryIntrinsicsCombinators
): AdvancedSearchQuery => {
  const spokenNounFilter = getFilter<SpokenNounContainsFilter>(
    filters,
    FilterType.SPOKEN_NOUN__CONTAINS
  );

  if (!spokenNounFilter || spokenNounFilter.values.length < 1) {
    return query;
  }

  const append = filters.length > 1 ? addToIntrinsicCombinator : addToQuery;

  if (spokenNounFilter.values.length > 1) {
    append(query, {
      INTRINSICS__COMBINATOR: {
        combinator:
          topLevelCombinator === AdvancedSearchQueryIntrinsicsCombinators.And
            ? AdvancedSearchQueryIntrinsicsCombinators.And
            : AdvancedSearchQueryIntrinsicsCombinators.Or,
        conditions: spokenNounFilter.values.map((value) => ({
          SPOKEN_NOUN__CONTAINS: {
            value: toUpper(snakeCase(value)),
          },
        })),
      },
    });
  } else {
    append(query, {
      SPOKEN_NOUN__CONTAINS: {
        value: toUpper(snakeCase(spokenNounFilter.values[0])),
      },
    });
  }

  return query;
};

const addDurationCondition = (
  query: AdvancedSearchQuery,
  filters: Filter[]
): AdvancedSearchQuery => {
  const durationFilter = getFilter<DurationFilter>(
    filters,
    FilterType.DURATION__MIN_MAX
  );

  const append = filters.length > 1 ? addToIntrinsicCombinator : addToQuery;

  const min = durationFilter?.min;
  const max = durationFilter?.max;

  if (min && max) {
    append(query, {
      INTRINSICS__COMBINATOR: {
        combinator: AdvancedSearchQueryIntrinsicsCombinators.And,
        conditions: [
          {
            DURATION__LESS_THAN_OR_EQUAL: {
              value: max,
            },
          },
          {
            DURATION__GREATER_THAN_OR_EQUAL: {
              value: min,
            },
          },
        ],
      },
    });
  } else if (min) {
    append(query, {
      DURATION__GREATER_THAN_OR_EQUAL: {
        value: min,
      },
    });
  } else if (max) {
    append(query, {
      DURATION__LESS_THAN_OR_EQUAL: {
        value: max,
      },
    });
  }

  return query;
};

const addSentimentEqualsCondition = (
  query: AdvancedSearchQuery,
  filters: Filter[]
): AdvancedSearchQuery => {
  const sentimentFilter = getFilter<SentimentEqualsFilter>(
    filters,
    FilterType.SENTIMENT__EQUALS
  );

  if (!sentimentFilter || sentimentFilter.values.length < 1) {
    return query;
  }

  const append = filters.length > 1 ? addToIntrinsicCombinator : addToQuery;

  if (sentimentFilter.values.length > 1) {
    append(query, {
      INTRINSICS__COMBINATOR: {
        combinator: AdvancedSearchQueryIntrinsicsCombinators.Or,
        conditions: sentimentFilter.values.map((value) => ({
          SENTIMENT__EQUALS: {
            value:
              value as AdvancedSearchQuerySentimentEqualsVariablesSentiment,
          },
        })),
      },
    });
  } else {
    append(query, {
      SENTIMENT__EQUALS: {
        value: sentimentFilter
          .values[0] as AdvancedSearchQuerySentimentEqualsVariablesSentiment,
      },
    });
  }

  return query;
};

const addContentModerationContainsCondition = (
  query: AdvancedSearchQuery,
  filters: Filter[],
  topLevelCombinator: AdvancedSearchQueryIntrinsicsCombinators
): AdvancedSearchQuery => {
  const containsFilter = getFilter<ContentModerationContainsFilter>(
    filters,
    FilterType.CONTENT_MODERATION_CONTAINS
  );

  if (!containsFilter || containsFilter.values.length < 1) {
    return query;
  }

  const append = filters.length > 1 ? addToIntrinsicCombinator : addToQuery;

  if (containsFilter.values.length > 1) {
    append(query, {
      INTRINSICS__COMBINATOR: {
        combinator:
          topLevelCombinator === AdvancedSearchQueryIntrinsicsCombinators.And
            ? AdvancedSearchQueryIntrinsicsCombinators.And
            : AdvancedSearchQueryIntrinsicsCombinators.Or,
        conditions: containsFilter.values.map((value) => ({
          CONTENT_MODERATION__CONTAINS: {
            value: toUpper(snakeCase(value)),
          },
        })),
      },
    });
  } else {
    append(query, {
      CONTENT_MODERATION__CONTAINS: {
        value: toUpper(snakeCase(containsFilter.values[0])),
      },
    });
  }

  return query;
};

const addContentModerationExcludesCondition = (
  query: AdvancedSearchQuery,
  filters: Filter[],
  topLevelCombinator: AdvancedSearchQueryIntrinsicsCombinators
): AdvancedSearchQuery => {
  const excludesFilter = getFilter<ContentModerationExcludesFilter>(
    filters,
    FilterType.CONTENT_MODERATION_EXCLUDES
  );

  if (!excludesFilter || excludesFilter.values.length < 1) {
    return query;
  }

  const append = filters.length > 1 ? addToIntrinsicCombinator : addToQuery;

  if (excludesFilter.values.length > 1) {
    append(query, {
      INTRINSICS__NOT: {
        condition: {
          INTRINSICS__COMBINATOR: {
            combinator:
              topLevelCombinator ===
              AdvancedSearchQueryIntrinsicsCombinators.And
                ? AdvancedSearchQueryIntrinsicsCombinators.And
                : AdvancedSearchQueryIntrinsicsCombinators.Or,
            conditions: excludesFilter.values.map((value) => ({
              CONTENT_MODERATION__CONTAINS: {
                value: toUpper(snakeCase(value)),
              },
            })),
          },
        },
      },
    });
  } else {
    append(query, {
      INTRINSICS__NOT: {
        condition: {
          CONTENT_MODERATION__CONTAINS: {
            value: toUpper(snakeCase(excludesFilter.values[0])),
          },
        },
      },
    });
  }

  return query;
};

export const buildQueryFromFilters = (
  combinator: AdvancedSearchQueryIntrinsicsCombinators,
  filters: Filter[]
): AdvancedSearchQuery => {
  let query: AdvancedSearchQuery = {};

  if (filters.length === 0) {
    return query;
  }

  if (filters.length > 1) {
    addIntrinsicCombinator(query, combinator);
  }

  addTitleLikeCondition(query, filters);
  addLabelContainsCondition(query, filters, combinator);
  addCelebrityEqualsCondition(query, filters, combinator);
  addCelebrityLikeCondition(query, filters, combinator);
  addSpokenNounContainsCondition(query, filters, combinator);
  addDurationCondition(query, filters);
  addSentimentEqualsCondition(query, filters);
  addContentModerationContainsCondition(query, filters, combinator);
  addContentModerationExcludesCondition(query, filters, combinator);

  return query;
};
