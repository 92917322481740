import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { Segment } from "@components/atoms";
import { SegmentBuilder } from "@components/templates";
import { SegmentsJobDuplicate_SegmentJobDocument } from "@graphql/operations";
import { FiltersProvider } from "@hooks/segments";

export const SegmentsJobDuplicate = () => {
  const { id, version = "1" } = useParams();

  const { data, loading } = useQuery(SegmentsJobDuplicate_SegmentJobDocument, {
    variables: {
      filter: {
        segmentId: {
          eq: id,
        },
        segmentVersion: {
          eq: parseInt(version, 10),
        },
      },
    },
  });

  return (
    <FiltersProvider
      segmentDescription={data?.segmentJob?.Description || undefined}
      query={data?.segmentJob?.Query && JSON.parse(data?.segmentJob.Query)}
    >
      <SegmentBuilder
        operation={Segment.OperationOptions.Create}
        loading={loading}
      />
    </FiltersProvider>
  );
};
