import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Flag = createSvgIcon({
  content: (
    <path
      d="M3 13.6667V10.3333C3.88867 9.44467 5 9 6.33333 9C8.33333 9 9 10.3333 10.3333 10.3333C11 10.3333 11.6667 10.1113 12.3333 9.66667V3.66667C11.6667 4.11133 11 4.33333 10.3333 4.33333C9 4.33333 8.33333 3 6.33333 3C5 3 3.88867 3.44467 3 4.33333V10.3333"
      className="stroke-current"
      strokeLinecap="square"
    />
  ),
  width: 16,
  height: 16,
});
