import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const ChevronRight = createSvgIcon({
  content: (
    <path
      d="M6.66669 4L10.6667 8L6.66669 12"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  ),
  width: 16,
  height: 16,
  defaultClassName: "stroke-current",
});
