import React, { HTMLAttributes } from "react";

import { createSvgIcon } from "./createSvgIcon";

export interface FilterProps extends HTMLAttributes<HTMLOrSVGElement> {
  className?: any;
  size?: number;
}

export const Filter = createSvgIcon({
  content: (
    <>
      <title>Filter</title>
      <path
        d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z"
        className="stroke-current"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  width: 24,
  height: 24,
});
