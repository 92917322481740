import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { NetworkStatus, useMutation, useQuery } from "@apollo/client";
import _ from "lodash";

import { Pager } from "@components/molecules";
import {
  CreateJobDialog,
  JobsTable,
  VOD_SLIDE_OVER_TAB_QUERY_PARAM,
  VodSlideOver,
} from "@components/organisms";
import { VodSliderOverTab } from "@components/organisms/VodSlideOver/VodSlideOverBody";
import {
  IngestionStatus,
  Jobs_JobsDocument,
  Jobs_JobsQueryVariables,
  Jobs_RestartJobDocument,
  JobsFilterStatusInput,
  MieStatus,
  StatusType,
} from "@graphql/operations";
import { usePaging } from "@hooks/usePaging";

const limit = 13;

type QueryParams = {
  id: string;
};

export interface JobsProps {
  searchTerm: string;
  filterTerms?: {
    waiting: boolean;
    queued: boolean;
    inProgress: boolean;
    success: boolean;
    failedIngestion: boolean;
    failedAnalytics: boolean;
  };
}

export const Jobs = ({ searchTerm, filterTerms }: JobsProps) => {
  const navigate = useNavigate();

  const statusFilter: JobsFilterStatusInput[] = useMemo(
    () =>
      _.uniq([
        ...(filterTerms?.waiting
          ? [
              {
                source: StatusType.Ingestion,
                value: IngestionStatus.Created,
              },
            ]
          : []),
        ...(filterTerms?.failedIngestion
          ? [
              {
                source: StatusType.Ingestion,
                value: IngestionStatus.Error,
              },
            ]
          : []),
        ...(filterTerms?.failedAnalytics
          ? [
              {
                source: StatusType.Mie,
                value: MieStatus.Error,
              },
            ]
          : []),
        ...(filterTerms?.inProgress
          ? [
              {
                source: StatusType.Ingestion,
                value: IngestionStatus.Started,
              },
              {
                source: StatusType.Mie,
                value: MieStatus.Started,
              },
              {
                source: StatusType.Mie,
                value: MieStatus.Resumed,
              },
            ]
          : []),
        ...(filterTerms?.queued
          ? [
              {
                source: StatusType.Mie,
                value: MieStatus.Queued,
              },
              {
                source: StatusType.Mie,
                value: MieStatus.Waiting,
              },
            ]
          : []),
        ...(filterTerms?.success
          ? [
              {
                source: StatusType.Mie,
                value: MieStatus.Complete,
              },
            ]
          : []),
      ]),
    [filterTerms]
  );

  const [isCreateJobDialogOpen, setIsCreateJobDialogOpen] = useState(false);

  const { discardStackTo, push, peek, getCurrentPage, setCurrentPage, clear } =
    usePaging({
      tokenStack: [""],
      currentPage: 1,
    });
  const nextToken = peek();

  let variables: Jobs_JobsQueryVariables = {
    limit,
  };

  if (searchTerm) {
    variables = _.merge(variables, {
      filter: {
        videoId: {
          beginsWith: searchTerm,
        },
      },
    });
  }

  if (nextToken) {
    variables = _.merge(variables, {
      nextToken,
    });
  }

  if (statusFilter.length > 0) {
    variables = _.merge(variables, {
      filter: {
        status: statusFilter,
      },
    });
  }

  const {
    loading: isJobsLoading,
    error: jobsError,
    data: jobsData,
    previousData,
    networkStatus,
  } = useQuery(Jobs_JobsDocument, {
    variables: variables,
  });

  useEffect(() => {
    if (!isJobsLoading && !jobsData?.jobs?.items) {
      setCurrentPage(1);
    }
  }, [isJobsLoading, previousData, jobsData, setCurrentPage]);

  const [restartJob, { data: restartJobsData, error: restartJobError }] =
    useMutation(Jobs_RestartJobDocument);
  const hasNextPage = !!jobsData?.jobs?.nextToken;
  const showPager =
    networkStatus !== NetworkStatus.loading &&
    (hasNextPage || getCurrentPage() > 1);
  const { id: selectedVideoId } = useParams<QueryParams>();
  const [searchParams] = useSearchParams();
  const vodSliderOverOpen = !!searchParams.get(VOD_SLIDE_OVER_TAB_QUERY_PARAM);

  useEffect(() => {
    if (restartJobError) {
      toast.error("Failed to restart job", {
        id: "restart-job-error",
      });
    }

    if (restartJobsData) {
      toast.success("Job has been successfully restarted", {
        id: "restart-job-success",
      });
    }
  }, [restartJobsData, restartJobError]);

  useEffect(() => {
    clear();
  }, [clear, searchTerm, statusFilter]);

  const openSlideForJob = (
    idx: number,
    details: VodSliderOverTab = VodSliderOverTab.Job
  ) => {
    const job = jobsData?.jobs?.items && jobsData?.jobs?.items[idx];

    if (job) {
      navigate(`/videos/${job.videoId}?tab=${details}`, { replace: true });
    }
  };

  const onRestartClicked = (
    videoId: string,
    href?: string,
    thumbnail?: string
  ) => {
    restartJob({
      variables: {
        input: {
          videoId,
          href,
          thumbnail,
        },
      },
    });
  };

  const onPagedChanged = (pageNumber: number) => {
    if (pageNumber > getCurrentPage()) {
      push(jobsData?.jobs?.nextToken || "");
    } else {
      discardStackTo(pageNumber);
    }
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil((jobsData?.jobs?.aggregate?.Total || 0) / limit);

  return (
    <div className="py-md space-y-4">
      <div className="rounded-md shadow-1dp overflow-clip">
        <JobsTable
          loading={isJobsLoading}
          error={!!jobsError}
          errorMessage={jobsError?.message}
          canHaveResults={(jobsData?.jobs?.aggregate?.Total || 0) > 0}
          jobs={jobsData?.jobs?.items || []}
          loadingRows={limit}
          onRestartClicked={onRestartClicked}
          onNewJobClicked={() => setIsCreateJobDialogOpen(true)}
          onJobClicked={(idx, details) => openSlideForJob(idx, details)}
        />
      </div>
      {showPager && (
        <Pager
          className="mr-0"
          data-testid="pager"
          currentPage={getCurrentPage()}
          nextButtonEnabled={hasNextPage}
          onPageChanged={onPagedChanged}
          totalPages={totalPages}
        />
      )}
      <CreateJobDialog
        isDialogOpen={isCreateJobDialogOpen}
        onCancel={() => setIsCreateJobDialogOpen(false)}
        onClose={() => setIsCreateJobDialogOpen(false)}
        onSubmission={() => setIsCreateJobDialogOpen(false)}
      />
      <VodSlideOver
        open={vodSliderOverOpen}
        videoId={selectedVideoId}
        onRestartClicked={onRestartClicked}
        onClose={() => {
          navigate("/videos", { replace: true });
        }}
      />
    </div>
  );
};
