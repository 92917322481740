import React, {
  BaseSyntheticEvent,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import Hls, {
  ErrorData,
  Events,
  FragChangedData,
  FragLoadedData,
  InitPTSFoundData,
} from "hls.js";

import { classnames } from "@external/tailwindcss-classnames";
import { useVideo } from "@hooks/useVideo";
import { secondsToTimeRange } from "@utils/temporal";

export interface VideoProps extends HTMLAttributes<HTMLVideoElement> {
  classNames?: any;
  url?: string;
  streamId?: string;
  debug?: boolean;
  autoPlay?: boolean;
  controls?: boolean;
  muted?: boolean;
  xhrSetup?: (xhr: XMLHttpRequest, url: string) => void;
  onInitPtsFound?: (e: InitPTSFoundData) => void;
  onFragLoaded?: (e: FragLoadedData) => void;
  onFragChanged?: (e: FragChangedData) => void;
  onErrorData?: (e: ErrorData) => void;
}

export const Video = ({
  classNames,
  url,
  streamId,
  onInitPtsFound,
  onFragLoaded,
  onFragChanged,
  onErrorData,
  autoPlay = false,
  controls = true,
  muted = true,
  debug,
  xhrSetup,
}: VideoProps) => {
  const [hls, setHls] = useState<Hls>();
  const [lastUpdated, setLastUpdated] = useState(0);
  const playerRef = useRef(null);
  const videoCtx = useVideo();

  const handleLoadedMetadata = (evt: BaseSyntheticEvent) => {
    videoCtx.setVideoLoadingState(false);

    if (url && videoCtx.currentTimestamp) {
      evt.target.currentTime = videoCtx.currentTimestamp / 1000;
    }
  };

  const handleTimeUpdate = (evt: BaseSyntheticEvent) => {
    const { currentTime } = evt.target;
    const timeRange = secondsToTimeRange(currentTime);

    if (Math.abs(currentTime - lastUpdated) > 1) {
      videoCtx.setCurrentTimeRange(timeRange);
      videoCtx.setCurrentTimestamp(currentTime * 1000);
      setLastUpdated(currentTime);
    }
  };

  const handleError = (evt: BaseSyntheticEvent) => {
    videoCtx.setVideoLoadingState(false);
    videoCtx.setVideoError(evt.target.error?.message || "Unknown Error");
  };

  useEffect(() => {
    if (hls && onErrorData) {
      const f = (v: Events.ERROR, e: ErrorData) => onErrorData(e);
      hls.on(Hls.Events.ERROR, f);
      return ((hls: Hls) => {
        return () => hls.off(Hls.Events.ERROR, f);
      })(hls);
    }
  }, [hls, onErrorData]);

  useEffect(() => {
    if (hls && onFragChanged) {
      const f = (v: Events.FRAG_CHANGED, e: FragChangedData) =>
        onFragChanged(e);
      hls.on(Hls.Events.FRAG_CHANGED, f);
      return ((hls: Hls) => {
        return () => hls.off(Hls.Events.FRAG_CHANGED, f);
      })(hls);
    }
  }, [hls, onFragChanged]);

  useEffect(() => {
    if (hls && onInitPtsFound) {
      const f = (v: Events.INIT_PTS_FOUND, e: InitPTSFoundData) =>
        onInitPtsFound(e);
      hls.on(Hls.Events.INIT_PTS_FOUND, f);
      return ((hls: Hls) => {
        return () => hls.off(Hls.Events.INIT_PTS_FOUND, f);
      })(hls);
    }
  }, [hls, onInitPtsFound]);

  useEffect(() => {
    if (hls && onFragLoaded) {
      const f = (v: Events.FRAG_LOADED, e: FragLoadedData) => onFragLoaded(e);
      hls.on(Hls.Events.FRAG_LOADED, f);
      return ((hls: Hls) => {
        return () => hls.off(Hls.Events.FRAG_LOADED, f);
      })(hls);
    }
  }, [hls, onFragLoaded]);

  useEffect(() => {
    if (hls && streamId) {
      hls.loadSource(streamId);
    }
  }, [hls, streamId]);

  useEffect(() => {
    const videoElement = playerRef.current;

    if (hls && videoElement) {
      hls.attachMedia(videoElement);
    }
  }, [hls, playerRef]);

  useEffect(() => {
    if (!streamId) {
      return;
    }

    const theHls = new Hls({
      debug,
      xhrSetup,
    });

    setHls(theHls);

    return () => {
      theHls.destroy();
    };
  }, [debug, xhrSetup, streamId]);

  return (
    <video
      ref={playerRef}
      controls={controls}
      muted={muted}
      autoPlay={autoPlay}
      preload="auto"
      onLoadedMetadata={handleLoadedMetadata}
      onTimeUpdate={handleTimeUpdate}
      onError={handleError}
      className={classnames(classNames, {
        hidden: videoCtx.videoLoading || !!videoCtx.videoError,
        [classnames("w-full", "h-full")]: !(
          videoCtx.videoLoading || !!videoCtx.videoError
        ),
      })}
    >
      {url && <source src={url} type="video/mp4" />}
    </video>
  );
};
