import React, { useState } from "react";

import { DialogBox, TextInput } from "@components/atoms";
import {
  ConditionTemplate,
  ConditionTemplateProps,
  DurationInput,
} from "@components/atoms/Segment";
import { FilterType, GenericSportsDimensionFilter } from "@hooks/segments";

interface Props {
  onChange: (value: GenericSportsDimensionFilter) => void | Promise<void>;
  showWithin: boolean;
}

export type GenericDimensionEqualsProps = Props &
  GenericSportsDimensionFilter &
  ConditionTemplateProps;

const LabelTypeMap: Partial<Record<FilterType, string>> = {
  [FilterType.VENUE__EQUALS]: "Venue",
  [FilterType.PRE_ALL_STAR__EQUALS]: "Pre All-Star",
  [FilterType.POST_ALL_STAR__EQUALS]: "Post All-Star",
  [FilterType.SEASON_TYPE__EQUALS]: "Season Type",
  [FilterType.HOME_TEAM__EQUALS]: "Home Team",
  [FilterType.AWAY_TEAM__EQUALS]: "Away Team",
  [FilterType.DIVISION__EQUALS]: "Division",
  [FilterType.LEAGUE__EQUALS]: "League",
  [FilterType.PLAYER__EQUALS]: "Player",
  [FilterType.WINNING_TEAM__EQUALS]: "Winning Team",
  [FilterType.EXTRA_INNINGS__EQUALS]: "Extra Innings",
  [FilterType.SERIES_TYPE__EQUALS]: "Series Type",
  [FilterType.INNING_HALF__EQUALS]: "Inning Half",
  [FilterType.EVENT_TYPE__EQUALS]: "Event Type",
  [FilterType.EVENT_DESCRIPTION__CONTAINS]: "Event Description",
  [FilterType.STOLEN_BASE__EQUALS]: "Stolen Base",
};

export const GenericDimensionEquals = ({
  onChange,
  value,
  within,
  location,
  type,
  showWithin,
  ...rest
}: GenericDimensionEqualsProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <ConditionTemplate type={type} location={location} {...rest}>
        <div className={`inline-block ${showWithin ? "w-2/3" : "w-full"}`}>
          <label className="mb-2 font-semibold text-xs text-navy-400">
            {LabelTypeMap[type]}
          </label>
          <div className="flex mt-2 mb-4 mr-4">
            <TextInput
              className={"w-full"}
              value={value}
              onChange={({ target }) =>
                onChange({ value: target.value, within })
              }
            />
          </div>
        </div>

        {showWithin && (
          <div className="inline-block mt-2 w-1/3">
            <DurationInput
              data-testid="within"
              value={within}
              onChange={(within) => onChange({ value, within })}
              onInfoClick={() => setIsDialogOpen(true)}
            />
          </div>
        )}
      </ConditionTemplate>

      <DialogBox.Container
        isDialogOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogBox.Card onClose={() => setIsDialogOpen(false)}>
          <DialogBox.Title>Tolerance</DialogBox.Title>
          <p className="text-sm">
            Specify a buffer of time around the occurence.
            <br />
            E.g., Ginger Rogers must appear in the frame{" "}
            <span className="font-bold">within 5 minutes</span> of a given point
            in the video for that point to meet segment condititions.
          </p>
        </DialogBox.Card>
      </DialogBox.Container>
    </>
  );
};
