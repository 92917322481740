import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Map = createSvgIcon({
  content: (
    <>
      <path
        d="M6 12.6667L2 14V3.33333L6 2L10 3.33333L14 2V12.3333L10 14L6 12.6667Z"
        stroke="#303030"
        stroke-linecap="square"
      />
      <path
        d="M10 3.33333V14M6 2V12.6667"
        stroke="#303030"
        stroke-linecap="round"
      />{" "}
    </>
  ),
  width: 16,
  height: 16,
  defaultColor: "navy-100",
});
