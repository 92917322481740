import React, { useRef, useState } from "react";
import { useDebounce } from "react-use";

import { Segment } from "@components/atoms";
import { ConditionTemplate } from "@components/atoms/Segment";
import { FilterType, VideoDurationFilter } from "@hooks/segments";

const errorMessage = (
  <p className="text-red-300 text-xs font-medium mt-2">
    Max value must exceed min value
  </p>
);

interface Props {
  onChange: ({
    maxDuration,
    minDuration,
  }: VideoDurationFilter) => void | Promise<void>;
}

export type VideoDurationEqualsProps = Props &
  VideoDurationFilter &
  Omit<Segment.ConditionTemplateProps, "type">;

type DurationType = number | undefined;

export const VideoDurationEquals = ({
  minDuration,
  maxDuration,
  onChange,
  ...rest
}: VideoDurationEqualsProps) => {
  const [hasInvalidRange, setHasInvalidRange] = useState(false);
  const last = useRef<"min" | "max" | undefined>("max");

  const handleDurationInput = (min: DurationType, max: DurationType) => {
    setHasInvalidRange(false);
    onChange({ maxDuration: max, minDuration: min });
  };

  useDebounce(
    () => {
      if (minDuration && maxDuration && minDuration > maxDuration) {
        setHasInvalidRange(true);
        onChange({
          maxDuration: last.current === "min" ? maxDuration : undefined,
          minDuration: last.current === "min" ? undefined : minDuration,
        });
      }
    },
    500,
    [minDuration, maxDuration]
  );

  return (
    <ConditionTemplate type={FilterType.VIDEO_DURATION__EQUALS} {...rest}>
      <div className="flex">
        <div className="w-50 mr-6">
          <Segment.DurationInput
            labelValue="Min"
            value={minDuration}
            hasError={last.current === "min" && hasInvalidRange}
            onChange={(minDuration) => {
              last.current = "min";
              handleDurationInput(minDuration, maxDuration);
            }}
          />
          {last.current === "min" && hasInvalidRange && errorMessage}
        </div>
        <div className="flex flex-col w-50">
          <Segment.DurationInput
            labelValue="Max"
            value={maxDuration}
            hasError={last.current === "max" && hasInvalidRange}
            onChange={(maxDuration) => {
              last.current = "max";
              handleDurationInput(minDuration, maxDuration);
            }}
          />
          {last.current === "max" && hasInvalidRange && errorMessage}
        </div>
      </div>
    </ConditionTemplate>
  );
};
