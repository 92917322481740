import React from "react";

import { Icon } from "@components/atoms";

export interface NoResultsProps {}

export const NoResults = (props: NoResultsProps) => (
  <div {...props} className="flex flex-col items-center justify-center">
    <Icon.Search size={40} className="mb-6 text-navy-100" />
    <h4>No results found</h4>
  </div>
);
