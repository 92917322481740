import React from "react";
import capitalize from "lodash/capitalize";

import { BarChart, colorPalette } from "@components/atoms";

export enum RollupType {
  CumulativeCount = "CumulativeCount",
  Rolling60SecondCount = "Rolling60SecondCount",
  TfIdfCount = "TfIdfCount",
}

export type NameWithCount = {
  Name: string;
  Count: number;
};

export type RollupData = {
  VideoId: string;
  Timestamp: string;
  CumulativeCount: Array<NameWithCount>;
  TfIdfCount: Array<NameWithCount>;
  Rolling60SecondCount: Array<NameWithCount>;
};

export interface RollupChartProps {
  rollupData?: RollupData;
  rollupType: RollupType;
  color: keyof typeof colorPalette;
}

export const RollupChart = ({
  rollupData,
  rollupType,
  color,
}: RollupChartProps) => {
  const nameWithCounts = rollupData?.[rollupType] || [];
  const names = nameWithCounts
    .map((count) => capitalize(count.Name))
    .slice(0, 10);
  const counts = nameWithCounts.map((count) => count.Count).slice(0, 10);

  const data = {
    labels: names,
    datasets: [
      {
        barThickness: 12,
        borderSkipped: "start",
        borderRadius: 2,
        data: counts,
        backgroundColor: nameWithCounts.map((_, idx) =>
          idx % 2 === 0
            ? colorPalette[color].default
            : colorPalette[color].alternate
        ),
      },
    ],
  };

  return <BarChart height={110} data={data} title="Occurrences" />;
};
