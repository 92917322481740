import { createSvgIcon } from "./createSvgIcon";

export const Segment = createSvgIcon({
  content: (
    <>
      <path
        d="M8.33335 6.58502C11.5034 6.58502 14.0817 9.16332 14.0817 12.3334C14.0817 15.5034 11.5034 18.0817 8.33335 18.0817C5.16332 18.0817 2.58502 15.5034 2.58502 12.3334C2.58502 9.16332 5.16332 6.58502 8.33335 6.58502ZM8.33335 5C4.28627 5 1 8.28627 1 12.3334C1 16.3804 4.28627 19.6667 8.33335 19.6667C12.3804 19.6667 15.6667 16.3804 15.6667 12.3334C15.6667 8.28627 12.3804 5 8.33335 5Z"
        className="fill-current"
      />
      <path
        d="M15.6664 6.58502C18.8364 6.58502 21.4147 9.16332 21.4147 12.3334C21.4147 15.5034 18.8364 18.0817 15.6664 18.0817C12.4963 18.0817 9.91803 15.5034 9.91803 12.3334C9.91803 9.16332 12.4963 6.58502 15.6664 6.58502ZM15.6664 5C11.6193 5 8.33301 8.28627 8.33301 12.3334C8.33301 16.3804 11.6193 19.6667 15.6664 19.6667C19.7134 19.6667 22.9997 16.3804 22.9997 12.3334C22.9997 8.28627 19.724 5 15.6664 5Z"
        className="fill-current"
      />
      <path
        d="M13.4264 7.04936C13.0037 6.63725 12.5282 6.27798 12.0104 5.99268C11.4927 6.27798 11.0171 6.63725 10.5945 7.04936C9.21023 8.38077 8.34375 10.2617 8.34375 12.3328C8.34375 14.4038 9.21023 16.2847 10.5945 17.6161C11.0171 18.0283 11.4927 18.3875 12.0104 18.6728C12.5282 18.3875 13.0037 18.0283 13.4264 17.6161C14.8106 16.2847 15.6771 14.4038 15.6771 12.3328C15.6771 10.2617 14.8106 8.38077 13.4264 7.04936Z"
        className="fill-current"
      />
    </>
  ),
  width: 24,
  height: 24,
  defaultColor: "blue-300",
});
