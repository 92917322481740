import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Question = createSvgIcon({
  content: (
    <>
      <path
        d="M12 1.33333C17.8833 1.33333 22.6667 6.11667 22.6667 12C22.6667 17.8833 17.8833 22.6667 12 22.6667C6.11667 22.6667 1.33333 17.8833 1.33333 12C1.33333 6.11667 6.11667 1.33333 12 1.33333ZM12 0C5.36667 0 0 5.36667 0 12C0 18.6333 5.36667 24 12 24C18.6333 24 24 18.6333 24 12C24 5.36667 18.6333 0 12 0Z"
        className="fill-current"
      />
      <path
        d="M10.8334 14.8987V14.8153C10.8334 13.9987 10.9334 13.332 11.0834 12.8487C11.25 12.3653 11.4834 11.9653 11.7834 11.6653C12.0834 11.3653 12.45 11.082 12.8667 10.832C13.1167 10.682 13.35 10.4987 13.55 10.282C13.75 10.0653 13.9167 9.81534 14.0334 9.54867C14.15 9.26534 14.2167 8.96534 14.2167 8.61534C14.2167 8.19867 14.1167 7.83201 13.9167 7.51534C13.7167 7.19867 13.45 6.96534 13.1167 6.79867C12.7834 6.63201 12.4167 6.54867 12.0167 6.54867C11.6667 6.54867 11.3167 6.61534 11 6.76534C10.6834 6.91534 10.4 7.14867 10.1834 7.44867C9.96669 7.76534 9.83335 8.16534 9.80002 8.68201H8.16669C8.20002 7.94867 8.38335 7.33201 8.73335 6.79867C9.08335 6.26534 9.53335 5.88201 10.1 5.59867C10.6667 5.31534 11.3 5.18201 12 5.18201C12.75 5.18201 13.4167 5.33201 13.9834 5.63201C14.55 5.93201 14.9834 6.34867 15.2834 6.86534C15.5834 7.38201 15.75 7.98201 15.75 8.66534C15.75 9.13201 15.6834 9.56534 15.5334 9.94867C15.3834 10.332 15.1834 10.6653 14.9167 10.982C14.65 11.282 14.3167 11.5487 13.95 11.782C13.5667 12.0153 13.2667 12.2653 13.0334 12.532C12.8 12.7987 12.6334 13.0987 12.5334 13.4653C12.4334 13.8153 12.3834 14.2653 12.3667 14.7987V14.882H10.8334V14.8987ZM11.6667 18.7153C11.35 18.7153 11.0834 18.5987 10.85 18.382C10.6167 18.1487 10.5167 17.882 10.5167 17.5653C10.5167 17.2487 10.6334 16.982 10.85 16.7487C11.0834 16.5153 11.35 16.4153 11.6667 16.4153C11.9834 16.4153 12.25 16.532 12.4834 16.7487C12.7167 16.982 12.8334 17.2487 12.8334 17.5653C12.8334 17.782 12.7834 17.9653 12.6834 18.1487C12.5834 18.332 12.4334 18.4653 12.2667 18.5653C12.0667 18.6653 11.8834 18.7153 11.6667 18.7153Z"
        className="fill-current"
      />
    </>
  ),
  width: 24,
  height: 24,
});
