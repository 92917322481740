import React from "react";

export interface LabelProps {
  Name: string;
  VideoId: string;
  Confidence: number;
  Timestamp: string;
  ParentTypes: string[];
  ParentNames: string[];
  Type: string;
}

export const Label = (props: LabelProps) => {
  return (
    <tr>
      <td>{props.Name}</td>
      <td>{props.Confidence}</td>
      <td>{props.Timestamp}</td>
      <td>{props.ParentTypes.join(", ")}</td>
      <td>{props.ParentNames.join(", ")}</td>
      <td>{props.Type}</td>
    </tr>
  );
};
