import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { formatInTimeZone } from "date-fns-tz";

import { Button, GenericLabel } from "@components/atoms";
import { Table, VodStatus } from "@components/molecules";
import {
  TableContentElement_JobDocument,
  TableContentElement_JobFragmentFragment,
  TableContentElement_JobQueryVariables,
} from "@graphql/operations";
import { getLocale, getTimezone } from "@utils/i18n";
import * as truncate from "@utils/truncate";

import { VodSliderOverTab } from "../VodSlideOver/VodSlideOverBody";

interface TableContentElementProps {
  onJobClicked?: (
    idx: number,
    details?: VodSliderOverTab
  ) => Promise<void> | void;
  onRestartClicked: (
    videoId: string,
    href?: string,
    thumbnail?: string
  ) => Promise<void> | void;
  className?: any;
  job: TableContentElement_JobFragmentFragment;
  idx: number;
  enableHover: boolean;
  setEnableHover: (enabled: boolean) => void;
}

const POLLING_INTERVAL = 10000;

export const TableContentElement = ({
  job,
  onRestartClicked,
  enableHover,
  setEnableHover,
  onJobClicked,
  idx,
}: TableContentElementProps) => {
  let variables: TableContentElement_JobQueryVariables = {
    filter: {
      videoId: {
        eq: job.videoId,
      },
    },
  };
  const { data, startPolling, stopPolling } = useQuery(
    TableContentElement_JobDocument,
    {
      variables,
    }
  );

  useEffect(() => {
    if (data?.job) {
      if (VodStatus.isFailed(data.job) || VodStatus.isSuccessful(data.job)) {
        stopPolling();
      } else {
        startPolling(POLLING_INTERVAL);
      }
    }
  }, [data, job, startPolling, stopPolling]);

  return (
    <Table.Row
      onClick={
        onJobClicked
          ? () => {
              onJobClicked(idx);
            }
          : undefined
      }
      hover={enableHover}
      height="h-12"
    >
      <Table.Cell className="truncate">
        {truncate.tail(job.videoId, 13, "...")}
      </Table.Cell>
      <Table.Cell className="font-semibold">{job.title || "-"}</Table.Cell>
      <Table.Cell>
        {job.businessUnit ? (
          <GenericLabel>{job.businessUnit.toLowerCase()}</GenericLabel>
        ) : (
          "-"
        )}
      </Table.Cell>
      <Table.Cell>
        {formatInTimeZone(new Date(job.createdAt), getTimezone(), "P p", {
          locale: getLocale(),
        })}
      </Table.Cell>
      <Table.Cell
        onClick={(e) => {
          if (!VodStatus.isFailed(job)) {
            return;
          }
          e.stopPropagation();
          onJobClicked && onJobClicked(idx, VodSliderOverTab.Error);
        }}
      >
        <VodStatus.Label
          mieStatus={job.mieStatus}
          ingestionStatus={job.ingestionStatus}
        />
      </Table.Cell>

      <Table.Cell>
        {VodStatus.isFailed(job) && (
          <Button
            size="small"
            color="primary"
            variant="contained"
            className="float-right"
            onClick={(e) => {
              e.stopPropagation();
              startPolling(POLLING_INTERVAL);
              onRestartClicked(
                job.videoId,
                job.href,
                job.thumbnail || undefined
              );
            }}
            onMouseEnter={() => setEnableHover(false)}
            onMouseLeave={() => setEnableHover(true)}
          >
            Retry
          </Button>
        )}
      </Table.Cell>
    </Table.Row>
  );
};
