import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { Button, Card, Player, Portal, Select } from "@components/atoms";
import { RollupType } from "@components/organisms";
import {
  VOD_SLIDE_OVER_TAB_QUERY_PARAM,
  VodSlideOver,
} from "@components/organisms/VodSlideOver";
import { VodSliderOverTab } from "@components/organisms/VodSlideOver/VodSlideOverBody";
import { Overview_JobDocument } from "@graphql/operations";

import { CelebritiesCard } from "./CelebritiesCard";
import { ContentModerationCard } from "./ContentModerationCard";
import { RollupLabelDetectionCard } from "./RollupLabelDetectionCard";
import { RollupSpokenNounsCard } from "./RollupSpokenNounsCard";
import { SentimentCard } from "./SentimentCard";
import { TranscriptionCard } from "./TranscriptionCard";

export interface OverviewProps {
  videoId: string;
}

const rollupTypeOptions = {
  [RollupType.CumulativeCount.toString()]: "Cumulative Count",
  [RollupType.Rolling60SecondCount.toString()]: "Rolling 60 Second Count",
  [RollupType.TfIdfCount.toString()]: "Tf-Idf Count",
};

export const Overview = ({ videoId: id }: OverviewProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const vodSliderOverOpen = !!searchParams.get(VOD_SLIDE_OVER_TAB_QUERY_PARAM);
  const [selectedRollupType, setSelectedRollupType] = useState(
    RollupType.CumulativeCount.toString()
  );

  const { data } = useQuery(Overview_JobDocument, {
    variables: {
      filter: {
        videoId: {
          eq: id,
        },
      },
    },
  });

  const videoUrl = data?.job?.mediaHref || "";

  return (
    <>
      <Portal.Teleport>
        <div className="flex flex-col items-end relative">
          <Link to={{ search: `?tab=${VodSliderOverTab.Job}` }} replace>
            <Button
              className="absolute bottom-full right-0 mb-2"
              size="small"
              color="default"
            >
              Job Details
            </Button>
          </Link>
          <Select
            className="h-8"
            options={rollupTypeOptions}
            value={selectedRollupType}
            onChange={(item) => setSelectedRollupType(item)}
          />
        </div>
      </Portal.Teleport>
      <section className="grid grid-cols-2 gap-4">
        <Card.Container className="flex items-center justify-center">
          <Player className="flex-1" url={videoUrl} />
        </Card.Container>
        <section className="grid grid-cols-2 gap-4">
          <SentimentCard videoId={id} />
          <CelebritiesCard videoId={id} />
        </section>
        <RollupLabelDetectionCard
          videoId={id}
          rollupType={selectedRollupType as RollupType}
        />
        <RollupSpokenNounsCard
          videoId={id}
          rollupType={selectedRollupType as RollupType}
        />
        <TranscriptionCard videoId={id} />
        <section className="grid grid-cols-2 gap-4">
          <ContentModerationCard videoId={id} />
        </section>
      </section>
      <VodSlideOver
        open={vodSliderOverOpen}
        videoId={id}
        onClose={() =>
          setSearchParams(
            {},
            {
              replace: true,
            }
          )
        }
        showViewInsightsButton={false}
      />
    </>
  );
};
