import React, { useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";

import { DialogBox, Icon, Segment, Tooltip } from "@components/atoms";
import { Condition, ConditionContainer } from "@components/molecules/Segment";
import { RemoveConditionsGroupDialog } from "@components/organisms/Segment/RemoveConditionsDialog";
import { classnames } from "@external/tailwindcss-classnames";
import { FilterContextProps, FilterType, Location } from "@hooks/segments";

export interface AnyOfTheFollowingProps {
  className?: any;
  filters: FilterContextProps;
}

export const AnyOfTheFollowing = ({
  className,
  filters,
  ...rest
}: AnyOfTheFollowingProps) => {
  const {
    anyOfTheFollowing,
    hasCondition,
    append,
    move,
    insertBetween,
    moveBetween,
    update,
    remove,
  } = filters;
  const [isRemoveDialogOpen, setIsRemoveIsDialogOpen] = useState(false);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const isEmpty = anyOfTheFollowing.length <= 0;
  const { anyOfTheFollowing: hasMinCondition } = hasCondition(
    FilterType.MIN_OCCURRENCE__COUNT
  );

  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => (
        <div
          {...rest}
          className={classnames(
            className,
            "border",
            "border-coolGrey-200",
            "bg-lightGrey-100"
          )}
        >
          <Disclosure.Button className="w-full">
            <Segment.Header classNames="flex justify-between items-center cursor-pointer">
              <span className="flex items-center">
                Any of the following
                {!isEmpty && (
                  <p className="ml-2 text-black-100">
                    ({anyOfTheFollowing.length})
                  </p>
                )}
                <Tooltip
                  tip={
                    <span className="normal-case font-semibold">
                      Only one condition here must be true. E.g., you’re
                      targeting assets containing alcohol OR positive sentiment,
                      without the need for a single asset to contain both. ANY
                      broadens your search by accepting any of the conditions
                      added here.
                    </span>
                  }
                >
                  <Icon.Info size={14} className="ml-2" />
                </Tooltip>
              </span>
              <div className="flex items-center">
                {!isEmpty && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsRemoveIsDialogOpen(!isRemoveDialogOpen);
                    }}
                  >
                    Clear
                  </span>
                )}
                <Icon.ChevronUp
                  data-testid="icon"
                  className={`mx-2 transition ${
                    open ? "transform rotate-180" : ""
                  }`}
                />
              </div>
            </Segment.Header>
          </Disclosure.Button>
          <Transition
            enter="transition duration-200 ease-in-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-200 ease-in-out"
            leaveTo="transform scale-95 opacity-0"
            leaveFrom="transform scale-100 opacity-100"
          >
            <Disclosure.Panel>
              <ConditionContainer
                empty={isEmpty}
                onDrop={(item) => {
                  if (item.action === "NEW") {
                    append(Location.ANY, item.type, item.filter);
                  }
                  if (item.action === "MOVE") {
                    move(Location.ANY, item.id!);
                  }
                }}
              >
                <div className="bg-lightGrey-100">
                  {anyOfTheFollowing?.map(({ id, type, filter, ...rest }) => (
                    <Condition
                      location={Location.ANY}
                      onDrop={(item, id, above) => {
                        if (item.action === "NEW") {
                          if (
                            item.type === FilterType.MIN_OCCURRENCE__COUNT &&
                            hasMinCondition
                          ) {
                            return setIsWarningDialogOpen(true);
                          }

                          insertBetween(
                            Location.ANY,
                            item.type,
                            id!,
                            above,
                            item.filter
                          );
                        } else if (item.action === "MOVE") {
                          moveBetween(Location.ANY, item.id!, id, above);
                        }
                      }}
                      onRemove={() => remove(id)}
                      onChange={(v: any) => update(id, v)}
                      {...rest}
                      key={id}
                      type={type}
                      id={id}
                      {...(filter as any)}
                    />
                  ))}
                </div>
              </ConditionContainer>
            </Disclosure.Panel>
          </Transition>
          <RemoveConditionsGroupDialog
            isDialogOpen={isRemoveDialogOpen}
            setIsDialogOpen={(isDialogOpen) =>
              setIsRemoveIsDialogOpen(isDialogOpen)
            }
            indexedFilter={anyOfTheFollowing}
            remove={remove}
          />

          <DialogBox.Container
            isDialogOpen={isWarningDialogOpen}
            onClose={() => setIsWarningDialogOpen(false)}
          >
            <DialogBox.Card onClose={() => setIsWarningDialogOpen(false)}>
              <DialogBox.Title>
                Occurrence Number Condition Flow
              </DialogBox.Title>
              <p className="text-sm">
                This Condition group already contains an Occurrences Condition.
                It is not possible to set multiple minimum Occurrences for a
                single group.
              </p>
            </DialogBox.Card>
          </DialogBox.Container>
        </div>
      )}
    </Disclosure>
  );
};
