import React from "react";

import { TrappedInput } from "./TrappedInput";

interface InputContentProps {
  children: string | number;
  onFocus: () => void | Promise<void>;
  onChange: (value: string | number) => void | Promise<void>;
  onBlur: () => void | Promise<void>;
}

export const InputContent = ({
  children,
  onFocus,
  onChange,
  onBlur,
}: InputContentProps) => {
  const [focused, setFocused] = React.useState(false);

  return (
    <div className="flex-1 flex items-center">
      {!focused && (
        <div
          data-testid="cell-click-plane"
          className="absolute inset-0 z-10"
          onClick={() => {
            setFocused(true);
            onFocus();
          }}
        />
      )}
      {focused ? (
        <TrappedInput
          onFocus={() => {
            setFocused(true);
            onFocus();
          }}
          onBlur={() => {
            setFocused(false);
            onBlur();
          }}
          onChange={onChange}
        >
          {children}
        </TrappedInput>
      ) : (
        children
      )}
    </div>
  );
};
