import React from "react";

import {
  StatusLabel,
  StatusLabelColor,
  StatusLabelProps,
} from "../../StatusLabel";

type LabelDraftProps = Omit<StatusLabelProps, "color">;

export const LabelDraft = (props: LabelDraftProps) => (
  <StatusLabel color={StatusLabelColor.CoolGrey} {...props}>
    Draft
  </StatusLabel>
);
