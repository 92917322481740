import React, { HTMLAttributes } from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface ValueProps extends HTMLAttributes<HTMLDivElement> {
  className?: any;
}

export const Value = ({ children, className, ...rest }: ValueProps) => (
  <div {...rest} className={classnames(className, "text-h7", "text-black-300")}>
    {children}
  </div>
);
