import React from "react";

import { FixupCell } from "@components/molecules";
import { ImportColumnType, useSegmentImport } from "@hooks/segments";
import { ConvertToUpperSnakeCase } from "@utils/casing";

export interface ReviewErrorsCellProps {
  rowIndex: number;
  type: ImportColumnType;
  errors: { [key in ImportColumnType]?: string[] };
  originalRow: { [key in ImportColumnType]: string };
  updatedRow: { [key in ImportColumnType]: string };
}

export const ReviewErrorsCell = ({
  rowIndex,
  errors,
  type,
  updatedRow,
  originalRow,
}: ReviewErrorsCellProps) => {
  const { setRawDataCellByColumnType } = useSegmentImport();

  const updated = updatedRow[type] !== originalRow[type];
  const hasError = (errors[type]?.length || 0) > 0;
  const { type: resolvedType, options: resolvedOptions } = fixupCellType(
    type,
    updatedRow
  );

  return (
    <FixupCell
      className="text-bodySm font-semibold"
      error={hasError}
      type={resolvedType}
      options={(resolvedOptions || []).reduce<Record<string, string>>(
        (acc, elem) => ({ ...acc, [elem]: elem }),
        {}
      )}
      editable={hasError || updated}
      onChange={(value) => setRawDataCellByColumnType(rowIndex, type, value)}
      success={!hasError && updated}
    >
      {updatedRow[type]}
    </FixupCell>
  );
};

function fixupCellType(
  type: ImportColumnType,
  cell: { [key in ImportColumnType]: string }
): { type: "select" | "input"; options?: string[] } {
  if (type === ImportColumnType.conditionType) {
    return {
      type: "select",
      options: [
        "Video title contains",
        "Spoken word or phrase",
        "Object or scene",
        "Spoken noun",
        "Sentiment",
        "Celebrity",
        "Flagged content",
        "Video duration",
        "Text in video",
      ],
    };
  }

  if (type === ImportColumnType.conditionValue) {
    if (
      ConvertToUpperSnakeCase(cell[ImportColumnType.conditionType]) ===
      "SENTIMENT"
    ) {
      return {
        type: "select",
        options: ["Positive", "Negative", "Mixed", "Neutral"],
      };
    }

    if (
      ConvertToUpperSnakeCase(cell[ImportColumnType.conditionType]) ===
      "FLAGGED_CONTENT"
    ) {
      return {
        type: "select",
        options: [
          "Explicit Nudity",
          "Nudity",
          "Graphic Male Nudity",
          "Graphic Female Nudity",
          "Sexual Activity",
          "Illustrated Explicit Nudity",
          "Adult Toys",
          "Suggestive",
          "Female Swimwear Or Underwear",
          "Male Swimwear Or Underwear",
          "Partial Nudity",
          "Barechested Male",
          "Revealing Clothes",
          "Sexual Situations",
          "Violence",
          "Graphic Violence Or Gore",
          "Physical Violence",
          "Weapon Violence",
          "Weapons",
          "Self Injury",
          "Visually Disturbing",
          "Emaciated Bodies",
          "Corpses",
          "Hanging",
          "Air Crash",
          "Explosions And Blasts",
          "Rude Gestures",
          "Middle Finger",
          "Drugs",
          "Drug Products",
          "Drug Use",
          "Pills",
          "Drug Paraphernalia",
          "Tobacco",
          "Tobacco Products",
          "Smoking",
          "Alcohol",
          "Drinking",
          "Alcoholic Beverages",
          "Gambling",
          "Hate Symbols",
          "Nazi Party",
          "White Supremacy",
          "Extremist",
        ],
      };
    }
  }

  return { type: "input" };
}
