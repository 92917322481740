import React from "react";

import { AtlasLogo } from "@components/atoms";

import { ArtWork } from "./Artwork";

export const SessionLoader = () => (
  <div className="flex h-screen flex-row">
    <div className="flex flex-1 flex-col h-screen items-center">
      <div className="flex flex-col items-center justify-center h-full">
        <AtlasLogo />
        <h5 className="mt-22 text-navy-300 font-semibold">
          Validating session...
        </h5>
      </div>

      <p className="text-coolGrey-400 text-sm select-none mb-7">
        &#169;FOX Corporation
      </p>
    </div>
    <div className="flex-1 border">
      <ArtWork />
    </div>
  </div>
);
