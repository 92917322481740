import React, { useEffect, useRef, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  camelCase,
  lowerCase,
  merge,
  snakeCase,
  startCase,
  toUpper,
  upperFirst,
} from "lodash";

import { Icon, Loading, TextInput } from "@components/atoms";
import { useDebounce } from "@hooks/useDebounce";

import { FilterType, useFilters } from "../hooks";

import Dropdown from "./Dropdown";

export const query = gql`
  query Query($filter: AdvancedSearchTermsInput) {
    advancedSearchTerms(filter: $filter) {
      items {
        value
        occurrences
        type
      }
    }
  }
`;

interface Variables {
  filter: {
    search: {
      contains: string;
    };
  };
}

interface Response {
  advancedSearchTerms: {
    items: ResponseItem[];
  };
}

interface ResponseItem {
  value: string;
  occurrences: number;
  type: "TITLE" | "CELEBRITY" | "LABEL" | "SPOKEN_NOUN";
}

export const TypeAhead = () => {
  const { addFilter, applyFilters, getFilter, subscribeToReset } = useFilters();

  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const debouncedValue = useDebounce(value, 200);

  useEffect(() => {
    const unsubscribe = subscribeToReset(() => {
      setValue("");
    });
    return () => {
      unsubscribe();
    };
  }, [subscribeToReset]);

  const visible = open && !!value;

  let variables;

  if (value) {
    variables = merge(variables || {}, {
      filter: {
        search: {
          contains: toUpper(snakeCase(debouncedValue)),
        },
      },
    });
  }

  const { data, loading } = useQuery<Response, Variables>(query, {
    skip: !value,
    variables,
  });

  const labels = data?.advancedSearchTerms?.items
    ?.filter((v) => v.type === "LABEL")
    .sort((o1, o2) => o2.occurrences - o1.occurrences);

  const spokenNouns = data?.advancedSearchTerms?.items
    ?.filter((v) => v.type === "SPOKEN_NOUN")
    .sort((o1, o2) => o2.occurrences - o1.occurrences);

  const celebrities = data?.advancedSearchTerms?.items
    ?.filter((v) => v.type === "CELEBRITY")
    .sort((o1, o2) => o2.occurrences - o1.occurrences);

  const onNValueCallback =
    (
      filterType:
        | FilterType.SPOKEN_NOUN__CONTAINS
        | FilterType.LABEL__CONTAINS
        | FilterType.CELEBRITY__EQUALS
    ) =>
    (v: { value: string; occurrences: number }) => {
      const filter = getFilter(filterType);
      addFilter({
        ...filter,
        filterType: filterType,
        values: [...(filter?.values || []), v.value],
      });
      applyFilters();
      setValue(v.value);
      closeDropdown();
    };
  const onLabelClicked = onNValueCallback(FilterType.LABEL__CONTAINS);
  const onCelebrityClicked = onNValueCallback(FilterType.CELEBRITY__EQUALS);
  const onSpokenNounClicked = onNValueCallback(
    FilterType.SPOKEN_NOUN__CONTAINS
  );

  const onTitleChanged = (title: string) => {
    if (title) {
      const filter = getFilter(FilterType.TITLE__LIKE);
      addFilter({
        ...filter,
        filterType: FilterType.TITLE__LIKE,
        title: title,
      });
      applyFilters();
      setValue(title);
      closeDropdown();
    }
  };

  const closeDropdown = () => {
    setOpen(false);
    inputRef.current?.blur();
  };

  return (
    <div className="relative">
      <TextInput
        ref={inputRef}
        onFocus={() => setOpen(true)}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(event: any) => {
          if (event.key === "Enter") {
            onTitleChanged(value);
          }
        }}
        value={value}
        placeholder="Search"
        icon={<Icon.Search size={16} className="ml-2 text-black-100" />}
      />
      <Dropdown
        data-testid="dropdown"
        className="absolute mt-2 z-10"
        open={visible}
        onClose={closeDropdown}
      >
        <>
          <Dropdown.TitleItem onClick={onTitleChanged} value={value} />
          {(() => {
            if (loading) {
              return (
                <div className="p-4 h-18 flex flex-col justify-center items-center">
                  <Loading.Slide />
                </div>
              );
            }
            return (
              <div className="divide-y divide-coolGrey-200">
                <div hidden={!labels?.length}>
                  <Dropdown.Items
                    caser={(v) => upperFirst(lowerCase(v))}
                    icon={<Dropdown.TagIcon />}
                    title="Object or scene"
                    terms={labels}
                    onClick={onLabelClicked}
                  />
                </div>
                <div hidden={!spokenNouns?.length}>
                  <Dropdown.Items
                    caser={(v) => startCase(camelCase(v))}
                    icon={<Dropdown.ChatAltIcon />}
                    title="Spoken noun"
                    terms={spokenNouns}
                    onClick={onSpokenNounClicked}
                  />
                </div>
                <div hidden={!celebrities?.length}>
                  <Dropdown.Items
                    caser={(v) => startCase(camelCase(v))}
                    icon={<Dropdown.StarIcon />}
                    title="Celebrity"
                    terms={celebrities}
                    onClick={onCelebrityClicked}
                  />
                </div>
              </div>
            );
          })()}
        </>
      </Dropdown>
    </div>
  );
};
