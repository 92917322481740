import React, { Fragment, type ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";

export interface SlideOverProps {
  open: boolean;
  onClose: (value: boolean) => void;
  children: ReactNode;
}

export const SlideOver = ({
  onClose,
  open,
  children,
  ...rest
}: SlideOverProps) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog
      {...rest}
      as="div"
      className="fixed inset-0 overflow-hidden z-20"
      onClose={onClose}
    >
      <div className="absolute inset-0 overflow-hidden">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="absolute inset-0 bg-navy-300 bg-opacity-40 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-out duration-200"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in duration-100"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="relative w-screen max-w-130">
              <div className="h-full flex flex-col bg-white shadow-4dp overflow-y-scroll">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);
