import * as temporal from "@utils/temporal/index";

const durationRegexp = /^(?<value>[0-9]+\.?[0-9]*|\.[0-9]+)(?<unit>[hms])$/;

const durationRangeRegexp =
  /^(?:(?<min>[\w.]*\s?)<)?\s?x\s?(?:<(?<max>\s?[\w.]*))?$/;

export interface Duration {
  value: string;
  unit: "h" | "m" | "s";
}

export interface DurationRange {
  min?: Duration;
  max?: Duration;
}

export function parseDurationRange(input: string): DurationRange | undefined {
  const projection = input?.match(durationRangeRegexp);

  if (!projection) {
    return;
  }

  const { groups } = projection;

  if (!groups) {
    return;
  }

  const { min, max } = groups;

  const minDuration = min && temporal.parseDuration(min.trim());
  const maxDuration = max && temporal.parseDuration(max.trim());

  return {
    ...(minDuration ? { min: minDuration } : {}),
    ...(maxDuration ? { max: maxDuration } : {}),
  };
}

export function parseDuration(input: string): Duration | undefined {
  if (!input) {
    return;
  }

  const result = input.match(durationRegexp);
  if (!result) {
    return;
  }

  const { groups } = result;
  if (!groups) {
    return;
  }

  const { value, unit } = groups as {
    [key: string]: string;
    unit: "h" | "m" | "s";
  };

  return { value, unit };
}

export function durationToMs({ value, unit }: Duration): number {
  if (unit === "h") {
    return parseInt(value) * 3600000;
  } else if (unit === "m") {
    return parseInt(value) * 60000;
  } else {
    return parseInt(value) * 1000;
  }
}
