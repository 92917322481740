import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const ArrowsCross = createSvgIcon({
  content: (
    <>
      <path
        d="M10.6665 2.66663H13.3332V5.33329"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 5.99996L13.3333 2.66663"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.6665 13.3333L5.99984 10"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6665 13.3333H13.3332V10.6666"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.6665 2.66663L13.3332 13.3333"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
});
