import { useSearchParams } from "react-router-dom";

type Environment = "development" | "production" | "test" | "stage";

const environment =
  (process.env.REACT_APP_ENVIRONMENT as Environment | undefined) ||
  "production";

export const useDebug = (): { debugEnabled: boolean } => {
  const [searchParams] = useSearchParams();
  const debugParam = searchParams.get("debug");
  let debugEnabled = false;

  if (debugParam?.toLowerCase() === "true") {
    debugEnabled = true;
  } else if (environment === "development") {
    debugEnabled = true;
  }

  return {
    debugEnabled,
  };
};
