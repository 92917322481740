import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const ArrowLeftCircle = createSvgIcon({
  content: (
    <>
      <path
        d="M10.625 15L7.625 12L10.625 9"
        stroke="#303030"
        stroke-width="1.5"
        stroke-linecap="square"
      />
      <path
        d="M16.625 12H9.125"
        stroke="#303030"
        stroke-width="1.5"
        stroke-linecap="square"
      />
      <path
        d="M7.625 12H9.125"
        stroke="#303030"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M12.125 22C17.6478 22 22.125 17.5228 22.125 12C22.125 6.47715 17.6478 2 12.125 2C6.60215 2 2.125 6.47715 2.125 12C2.125 17.5228 6.60215 22 12.125 22Z"
        stroke="#303030"
        stroke-width="1.5"
        stroke-linecap="square"
      />
    </>
  ),
  width: 24,
  height: 24,
});
