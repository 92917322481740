import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const StolenBase = createSvgIcon({
  content: (
    <>
      <path d="M2.28467 6.83325L8.28682 2.00003" stroke="#303030" />
      <line x1="2.5" y1="6.57336" x2="2.5" y2="14.0001" stroke="#303030" />
      <line x1="2.03955" y1="13.5" x2="13.5022" y2="13.5" stroke="#303030" />
      <path d="M13.7153 6.83325L7.71318 2.00003" stroke="#303030" />
      <line
        y1="-0.5"
        x2="7.42669"
        y2="-0.5"
        transform="matrix(-4.65284e-08 1 1 4.1065e-08 14 6.57336)"
        stroke="#303030"
      />
    </>
  ),
  width: 16,
  height: 16,
});
