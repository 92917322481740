import React from "react";
import { Bar } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import _ from "lodash";

import "chart.js/auto";

const options: ChartOptions<"bar"> = {
  indexAxis: "y",
  plugins: {
    title: {
      display: true,
      align: "center",
      position: "bottom",
      text: "Occurrences",
      font: {
        size: 12,
        weight: "bold",
      },
    },
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        color: "#E9EDF5",
        lineWidth: 1,
      },
      ticks: {
        color: "#4D6CA3",
      },
    },
    y: {
      grid: {
        color: "#E9EDF5",
        lineWidth: 1,
      },
      ticks: {
        font: {
          weight: "600",
        },
      },
    },
  },
  animation: false,
  responsive: true,
};

export interface ChartProps {
  data: any;
  height: number;
  title: string;
}

export const BarChart = ({ height, data, title }: ChartProps) => {
  const barChartOptions = _.merge(options, {
    plugins: {
      title: {
        text: title,
      },
    },
  });

  return <Bar height={height} data={data} options={barChartOptions} />;
};
