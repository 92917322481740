import React from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { merge } from "lodash";

import { Card, Player, Portal } from "@components/atoms";
import { GenericExportDialog } from "@components/organisms/GenericExportDialog";
import {
  ContentModeration_JobDocument,
  ContentModeration_ListContentModerationOccurrenceUnloadDocument,
  ContentModeration_ListContentModerationOccurrenceUnloadQueryVariables,
} from "@graphql/operations";

import { ContentModerationTable } from "./ContentModerationTable";

enum Category {
  CONDITION_DATA = "Condition data",
}

const conditionSelectableFields = {
  Confidence: "Confidence",
  Name: "Name",
  ParentName: "Parent Name",
  ParentType: "Parent Type",
  Timestamp: "Timestamp",
  TimestampMillis: "Timestamp Millis",
  Type: "Type",
  VideoId: "Video Id",
};

const selectableFields = [
  {
    id: Category.CONDITION_DATA,
    name: Category.CONDITION_DATA,
    children: Object.entries(conditionSelectableFields).map(([k, v]) => ({
      id: k,
      name: v,
    })),
  },
];

const selectedNodesId = [...Object.keys(conditionSelectableFields)];

export interface SentimentsProps {
  videoId: string;
}

export const ContentModeration = ({ videoId }: SentimentsProps) => {
  const [
    unloadListContentModerationOccurrence,
    { loading: unloadLoading, error: unloadError },
  ] = useLazyQuery(
    ContentModeration_ListContentModerationOccurrenceUnloadDocument,
    {
      fetchPolicy: "no-cache",
    }
  );

  const { data } = useQuery(ContentModeration_JobDocument, {
    skip: !videoId,
    variables: {
      filter: {
        videoId: {
          eq: videoId,
        },
      },
    },
  });

  const videoUrl = data?.job?.mediaHref || "";

  return (
    <>
      <Portal.Teleport>
        <div className="flex flex-col items-end">
          <GenericExportDialog
            title="Export"
            selectableFields={selectableFields}
            initialSelectedNodeIds={selectedNodesId}
            onExportSubmission={async (formData: string[]) => {
              let variables: ContentModeration_ListContentModerationOccurrenceUnloadQueryVariables =
                {
                  filter: {
                    videoId: {
                      eq: videoId,
                    },
                  },
                };

              if (formData.length > 0) {
                variables = merge(variables, {
                  unload: {
                    columns: formData,
                  },
                });
              }

              const { data: unloadData } =
                await unloadListContentModerationOccurrence({
                  variables,
                });

              return (
                unloadData?.listContentModerationOccurrence?.unload?.href ||
                undefined
              );
            }}
            loading={unloadLoading}
            error={unloadError?.message}
          />
        </div>
      </Portal.Teleport>
      <section className="flex flex-row flex-1">
        <Card.Container className="w-2/5 self-start">
          <Player url={videoUrl} />
        </Card.Container>
        <section className="flex-1 ml-6">
          <ContentModerationTable videoId={videoId} />
        </section>
      </section>
    </>
  );
};
