import React, { useEffect } from "react";

import { ToggleSwitch } from "@components/atoms";
import {
  ConditionTemplate,
  ConditionTemplateProps,
} from "@components/atoms/Segment";
import { FilterType, SportsDimensionBooleanFilter } from "@hooks/segments";

interface Props {
  onChange: (value: SportsDimensionBooleanFilter) => void | Promise<void>;
}

export type BooleanConditionProps = Props &
  SportsDimensionBooleanFilter &
  ConditionTemplateProps;

const LabelTypeMap: Partial<Record<FilterType, string>> = {
  [FilterType.DELAYED_GAME__EQUALS]: "Delayed Game",
  [FilterType.DOUBLE_HEADER__EQUALS]: "Double Header",
  [FilterType.NIGHT_GAME__EQUALS]: "Night Game",
};

export const BooleanCondition = ({
  onChange,
  value = false,
  location,
  type,
  ...rest
}: BooleanConditionProps) => {
  useEffect(() => {
    onChange({ value });
  }, [value, onChange]);

  return (
    <ConditionTemplate type={type} location={location} {...rest}>
      <ToggleSwitch
        onChange={({ target }) => onChange({ value: target.checked })}
        displayChildren="before"
        checked={!!value}
      >
        <span className="mr-4 font-semibold text-xs text-navy-400">
          {LabelTypeMap[type]}
        </span>
      </ToggleSwitch>
    </ConditionTemplate>
  );
};
