import React from "react";

import {
  classnames,
  TArg,
  TBackgroundColor,
} from "@external/tailwindcss-classnames";

import { CombinationMark, CombinationMarkProps } from "./CombinationMark";
import * as IconBar from "./IconBar";
import * as Nav from "./Nav";

type Environment = "development" | "production" | "test" | "stage";

const config: Record<
  Environment,
  {
    combinationMarkType: Required<CombinationMarkProps["type"]>;
    backgroundColor: TBackgroundColor;
    combinationMarkClassName: TArg[];
    navLinkClassName: TArg[];
    avatarClassName: TArg[];
  }
> = {
  development: {
    combinationMarkType: "production",
    backgroundColor: "bg-navy-300",
    combinationMarkClassName: ["text-white"],
    navLinkClassName: ["text-white", "bg-blue-300"],
    avatarClassName: [
      "text-white",
      "bg-navy-300",
      "hover:bg-blue-400",
      "active:bg-blue-400",
    ],
  },
  test: {
    combinationMarkType: "test",
    backgroundColor: "bg-red-300",
    combinationMarkClassName: ["text-white"],
    navLinkClassName: ["text-white", "bg-red-200"],
    avatarClassName: [
      "text-white",
      "bg-red-300",
      "hover:bg-red-200",
      "active:bg-red-200",
    ],
  },
  stage: {
    combinationMarkType: "stage",
    backgroundColor: "bg-coolGrey-400",
    combinationMarkClassName: ["text-white"],
    navLinkClassName: ["text-white", "bg-coolGrey-300"],
    avatarClassName: [
      "text-white",
      "bg-coolGrey-400",
      "hover:bg-coolGrey-300",
      "active:bg-coolGrey-300",
    ],
  },
  production: {
    combinationMarkType: "production",
    backgroundColor: "bg-navy-300",
    combinationMarkClassName: ["text-white"],
    navLinkClassName: ["text-white", "bg-blue-300"],
    avatarClassName: [
      "text-white",
      "bg-navy-300",
      "hover:bg-blue-400",
      "active:bg-blue-400",
    ],
  },
};

const environment =
  (process.env.REACT_APP_ENVIRONMENT as Environment | undefined) ||
  "production";

export const Header = () => (
  <nav
    className={`grid grid-cols-3 ${config[environment].backgroundColor} px-10 h-18 shadow-1dp z-10`}
    data-e2e="navbar"
  >
    <CombinationMark
      type={config[environment].combinationMarkType}
      className={config[environment].combinationMarkClassName?.join(" ")}
    />
    <Nav.Container>
      <Nav.Link
        to="/segments"
        className={classnames("mr-1", ...config[environment].navLinkClassName)}
        data-e2e="nav-segments"
      >
        Segments
      </Nav.Link>
      <Nav.Link
        to="/insights"
        className={classnames("mx-1", ...config[environment].navLinkClassName)}
        data-e2e="nav-insights"
      >
        Insights
      </Nav.Link>
      <Nav.Link
        to="/videos"
        className={classnames("ml-1", ...config[environment].navLinkClassName)}
        data-e2e="nav-jobs"
      >
        Videos
      </Nav.Link>
    </Nav.Container>
    <IconBar.Container>
      <IconBar.Avatar className={config[environment].avatarClassName} />
    </IconBar.Container>
  </nav>
);
