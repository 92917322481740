import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { Card, Icon, NoContent } from "@components/atoms";
import { RollupChart, RollupType } from "@components/organisms";
import { RollupLabelDetectionCard_CumulativeLabelsDocument } from "@graphql/operations";
import { useVideo } from "@hooks/useVideo";

export interface RollupLabelDetectionCardProps {
  videoId: string;
  rollupType: RollupType;
}

export const RollupLabelDetectionCard = ({
  videoId,
  rollupType,
}: RollupLabelDetectionCardProps) => {
  const { currentTimeRange } = useVideo();
  const timestamp = currentTimeRange.end;

  const { data, previousData, error, refetch } = useQuery(
    RollupLabelDetectionCard_CumulativeLabelsDocument,
    {
      variables: {
        filter: {
          videoId: {
            eq: videoId,
          },
          timestamp: {
            lt: timestamp,
          },
        },
      },
    }
  );

  const errorBody = error ? (
    <Card.ErrorRetry onRetry={() => refetch()} />
  ) : null;

  let noDataBody;

  const cumulativeLabels =
    data?.cumulativeLabels || previousData?.cumulativeLabels;

  if (!cumulativeLabels) {
    noDataBody = <NoContent />;
  }

  return (
    <Card.Container>
      <Card.Header>Rollup Label Detection</Card.Header>
      <Card.Body>
        {errorBody || noDataBody || (
          <RollupChart
            rollupData={cumulativeLabels || undefined}
            rollupType={rollupType}
            color="blue"
          />
        )}
      </Card.Body>
      <Card.Footer className="text-right flex justify-end items-center">
        <Link to={`/insights/${videoId}/labels`}>
          <span className="hover:underline">Overview </span>
          <Icon.ChevronRight color="black-200" className="inline" />
        </Link>
      </Card.Footer>
    </Card.Container>
  );
};
