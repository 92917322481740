import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const HomeStar = createSvgIcon({
  content: (
    <>
      <path
        d="M12.8387 6.83867L8 2L2 8H3.33333V12.6667C3.33333 13.0203 3.47381 13.3594 3.72386 13.6095C3.97391 13.8595 4.31304 14 4.66667 14H7.33333"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 14V10C6 9.6464 6.14048 9.30726 6.39052 9.05721C6.64057 8.80716 6.97971 8.66669 7.33333 8.66669H8.33333"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.8668 13.878L10.4188 14.6367C10.3759 14.659 10.3275 14.669 10.2792 14.6655C10.2309 14.662 10.1845 14.6451 10.1452 14.6167C10.1059 14.5884 10.0753 14.5497 10.0568 14.5049C10.0382 14.4602 10.0325 14.4111 10.0402 14.3633L10.3168 12.756L9.14549 11.618C9.11053 11.5842 9.08577 11.5412 9.07405 11.494C9.06233 11.4468 9.06412 11.3973 9.0792 11.3511C9.09429 11.3049 9.12207 11.2638 9.15937 11.2326C9.19667 11.2014 9.24199 11.1813 9.29016 11.1746L10.9088 10.94L11.6328 9.47798C11.6545 9.43444 11.6879 9.3978 11.7292 9.37219C11.7705 9.34658 11.8182 9.33301 11.8668 9.33301C11.9155 9.33301 11.9631 9.34658 12.0045 9.37219C12.0458 9.3978 12.0792 9.43444 12.1008 9.47798L12.8248 10.94L14.4435 11.1746C14.4915 11.1816 14.5367 11.2018 14.5738 11.233C14.6109 11.2642 14.6386 11.3052 14.6536 11.3513C14.6687 11.3975 14.6705 11.4469 14.659 11.494C14.6474 11.5411 14.6229 11.5841 14.5882 11.618L13.4168 12.756L13.6928 14.3627C13.7011 14.4106 13.6958 14.4598 13.6775 14.5049C13.6592 14.5499 13.6286 14.5889 13.5892 14.6175C13.5499 14.646 13.5033 14.6629 13.4548 14.6663C13.4063 14.6697 13.3578 14.6594 13.3148 14.6367L11.8668 13.878Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
});
