import React, { useState } from "react";

import { Icon, TextInput } from "@components/atoms";
import { VodStatus } from "@components/molecules";
import { FilterPopover } from "@components/organisms/FilterPopover";
import { IngestionJobsExportDialog } from "@components/organisms/IngestionJobsExportDialog";
import { useDebug } from "@hooks/useDebug";

import { Jobs } from "./Jobs";
import { NewMediaButton } from "./NewMediaButton";

interface FormData {
  waiting: boolean;
  queued: boolean;
  inProgress: boolean;
  success: boolean;
  failedIngestion: boolean;
  failedAnalytics: boolean;
}

export const JobsOverview = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterTerms, setFilterTerms] = useState<FormData>({
    waiting: false,
    queued: false,
    inProgress: false,
    success: false,
    failedIngestion: false,
    failedAnalytics: false,
  });

  const { debugEnabled } = useDebug();

  return (
    <div className="px-8 py-8 bg-lightGrey-100 flex-auto">
      <div className="flex px-14">
        <div className="flex-1"></div>
        <div className="flex justify-center">
          <TextInput
            className="w-96"
            placeholder="Search video ID"
            onChange={(e) => setSearchTerm(e.target.value)}
            icon={<Icon.Search size={16} className="ml-2 text-black-100" />}
          />
          <FilterPopover<FormData>
            buttonText="Status"
            filters={{
              waiting: {
                display: <VodStatus.LabelWaiting />,
                name: "waiting",
              },
              queued: {
                display: <VodStatus.LabelQueued />,
                name: "queued",
              },
              inProgress: {
                display: <VodStatus.LabelProcessing />,
                name: "inProgress",
              },
              success: {
                display: <VodStatus.LabelSuccess />,
                name: "success",
              },
              failedIngestion: {
                display: <VodStatus.LabelFailedIngestion />,
                name: "failedIngestion",
              },
              failedAnalytics: {
                display: <VodStatus.LabelFailedAnalytics />,
                name: "failedAnalytics",
              },
            }}
            defaultValues={filterTerms}
            onSubmit={setFilterTerms}
          />
        </div>
        <div className="flex-1 flex justify-end gap-2">
          <IngestionJobsExportDialog size="medium" title="Export" />
          {debugEnabled && <NewMediaButton />}
        </div>
      </div>
      <Jobs filterTerms={filterTerms} searchTerm={searchTerm} />
    </div>
  );
};
