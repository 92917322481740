import React from "react";

import { Card, Loading, NoContent } from "@components/atoms";
import { Query } from "@components/types";
import { classnames } from "@external/tailwindcss-classnames";

import { ChartBody } from "./ChartBody";
import { QuerySummary } from "./QuerySummary";

interface SegmentPreviewProps {
  className?: any;
  loading?: boolean;
  totalVideos?: number;
  totalOccurrences?: number;
  totalIsolateVideos?: number;
  query?: Query;
  loadingQuery?: boolean;
  error?: boolean;
}

export const Preview = ({
  className,
  loading,
  totalVideos,
  totalOccurrences,
  totalIsolateVideos,
  query,
  loadingQuery,
  error,
  ...rest
}: SegmentPreviewProps) => {
  const hasQueries =
    query?.exclude || query?.anyOfTheFollowing || query?.allOfTheFollowing;
  const numberOfQueries =
    (query?.allOfTheFollowing?.length || 0) +
    (query?.anyOfTheFollowing?.length || 0) +
    (query?.exclude?.length || 0);

  return (
    <Card.Container className={classnames(className, "p-10", "mb-6")} {...rest}>
      <div className="text-base text-navy-300 mb-6 font-semibold">
        Segment Preview
      </div>

      <div className="relative">
        {error ? (
          <NoContent message="An error occurred" iconType="Exclamation" />
        ) : (
          <>
            {loading && (
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <Loading.Slide size={5} />
              </div>
            )}
            <ChartBody
              data-testid="chart-body"
              totalVideos={totalVideos || 0}
              totalOccurrences={
                hasQueries ? totalOccurrences || 0 : totalOccurrences
              }
              totalIsolateVideos={
                hasQueries ? totalIsolateVideos || 0 : totalIsolateVideos
              }
              className={classnames({
                invisible: loading,
              })}
            />
          </>
        )}
      </div>

      <hr className="my-10 border-coolGrey-200" />

      <div className="text-base text-navy-300 mb-6 font-semibold">
        Segment Conditions{" "}
        <span className="text-black-100">({numberOfQueries})</span>
      </div>

      <div className="relative">
        {loadingQuery && (
          <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
            <Loading.Slide size={5} />
          </div>
        )}

        <div
          className={classnames("flex", "flex-col", "gap-5", {
            invisible: loadingQuery,
            "min-h-24": loadingQuery,
          })}
        >
          {!hasQueries && (
            <div className="text-black-300 text-bodySm">
              Add one or more conditions
            </div>
          )}
          {query?.allOfTheFollowing && (
            <QuerySummary
              data-testid="all-of-the-following-query-summary"
              title="Includes ALL of the following"
              queryConditions={query?.allOfTheFollowing}
            />
          )}
          {query?.anyOfTheFollowing && (
            <QuerySummary
              title="Any of the following"
              queryConditions={query?.anyOfTheFollowing}
            />
          )}
          {query?.exclude && (
            <QuerySummary title="Excludes" queryConditions={query?.exclude} />
          )}
        </div>
      </div>
    </Card.Container>
  );
};
