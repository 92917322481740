import React, { useState } from "react";

import { DialogBox, Select, TextInput } from "@components/atoms";
import {
  ConditionTemplate,
  ConditionTemplateProps,
  DurationInput,
} from "@components/atoms/Segment";
import { FilterType, GenericSportsMetricFilter } from "@hooks/segments";

import { ShowAdvanced } from "../ShowAdvanced";

interface Props {
  onChange: (value: GenericSportsMetricFilter) => void | Promise<void>;
}

export type GenericMetricCompareProps = Props &
  GenericSportsMetricFilter &
  ConditionTemplateProps;

enum Options {
  gt = "Greater than",
  gte = "Greater than or equal to",
  lt = "Less than",
  lte = "Less than or equal to",
  eq = "Equals",
  not = "Does not equal",
}

const LabelTypeMap: Partial<Record<FilterType, string>> = {
  [FilterType.RUN_DIFFERENTIAL__COMPARE]: "Run Differential",
  [FilterType.INNING__COMPARE]: "Inning",
  [FilterType.EVENT_LEVERAGE_INDEX__COMPARE]: "Event Leverage Index",
  [FilterType.EVENT_RUNS_SCORED__COMPARE]: "Event Runs Scored",
};

export const GenericMetricCompare = ({
  onChange,
  value,
  within,
  comparator = "gt",
  location,
  type,
  ...rest
}: GenericMetricCompareProps) => {
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <ConditionTemplate type={type} location={location} {...rest}>
        <label className="mb-2 font-semibold text-xs text-navy-400">
          {LabelTypeMap[type]}
        </label>
        <div className="flex mt-2 mb-4">
          <div className="w-1/2 mr-4">
            <Select
              options={Options}
              placeholder={comparator}
              onChange={(comparator) => onChange({ value, within, comparator })}
              value={comparator}
              fixedWidth={true}
              className="w-full h-10"
            ></Select>
          </div>
          <TextInput
            className={"w-1/2"}
            type="number"
            value={value}
            onChange={({ target }) =>
              onChange({
                value: parseInt(target.value, 10),
                within,
                comparator,
              })
            }
          />
        </div>

        <ShowAdvanced
          setIsAdvancedOpen={setIsAdvancedOpen}
          isAdvancedOpen={isAdvancedOpen}
        >
          <div className="grid grid-cols-2 gap-x-4">
            <DurationInput
              data-testid="within"
              value={within}
              onChange={(within) => onChange({ value, within, comparator })}
              onInfoClick={() => setIsDialogOpen(true)}
            />
          </div>
        </ShowAdvanced>
      </ConditionTemplate>

      <DialogBox.Container
        isDialogOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogBox.Card onClose={() => setIsDialogOpen(false)}>
          <DialogBox.Title>Tolerance</DialogBox.Title>
          <p className="text-sm">
            Specify a buffer of time around the occurence.
            <br />
            E.g., Ginger Rogers must appear in the frame{" "}
            <span className="font-bold">within 5 minutes</span> of a given point
            in the video for that point to meet segment condititions.
          </p>
        </DialogBox.Card>
      </DialogBox.Container>
    </>
  );
};
