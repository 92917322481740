import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Celebrities = createSvgIcon({
  content: (
    <path
      d="M7.99928 11.896L4.12128 13.9347L4.86194 9.61667L1.72461 6.55867L6.06061 5.92867L7.99928 2L9.93794 5.92867L14.2739 6.55867L11.1366 9.61667L11.8773 13.9347L7.99928 11.896Z"
      className="stroke-current"
      strokeLinecap="square"
    />
  ),
});
