import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Record = createSvgIcon({
  content: (
    <>
      <path
        d="M11.0112 4.88889C11.1001 4.88889 11.1668 4.95556 11.1668 5.04444V10.9444C11.1668 11.0333 11.1001 11.1 11.0112 11.1H2.10011C2.01122 11.1111 1.94455 11.0444 1.94455 10.9556V5.04444C1.94455 4.95556 2.01122 4.88889 2.10011 4.88889H11.0112ZM11.0112 4H2.10011C1.52233 4 1.05566 4.46667 1.05566 5.04444V10.9444C1.05566 11.5222 1.52233 11.9889 2.10011 11.9889H11.0001C11.5779 11.9889 12.0446 11.5222 12.0446 10.9444V5.04444C12.0557 4.46667 11.589 4 11.0112 4Z"
        className="fill-current"
      />
      <path
        d="M15.0555 6V10L12.0555 9V7L15.0555 6ZM15.0555 5.11111C14.9667 5.11111 14.8667 5.12223 14.7778 5.15556L11.7778 6.15556C11.4111 6.27778 11.1667 6.62223 11.1667 7V9C11.1667 9.37778 11.4111 9.72223 11.7778 9.84445L14.7778 10.8444C14.8667 10.8778 14.9667 10.8889 15.0555 10.8889C15.2444 10.8889 15.4222 10.8333 15.5778 10.7222C15.8111 10.5556 15.9444 10.2889 15.9444 10V6C15.9444 5.71111 15.8111 5.44445 15.5778 5.27778C15.4222 5.16667 15.2444 5.11111 15.0555 5.11111Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.61133 5.99999C2.61133 5.75453 2.81031 5.55554 3.05577 5.55554H5.05577C5.30123 5.55554 5.50022 5.75453 5.50022 5.99999C5.50022 6.24545 5.30123 6.44443 5.05577 6.44443H3.05577C2.81031 6.44443 2.61133 6.24545 2.61133 5.99999Z"
        className="fill-current"
      />
    </>
  ),
  width: 17,
  height: 16,
});
