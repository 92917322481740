import React, { HTMLAttributes } from "react";

export interface NotFoundProps extends HTMLAttributes<HTMLOrSVGElement> {}

export const NotFound = ({ ...rest }: NotFoundProps) => (
  <svg
    width="135"
    height="180"
    viewBox="0 0 135 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M68.0646 30.7527C19.6776 29.2473 19.0324 102.903 36.8819 133.011H52.5808L52.9034 125.161C43.5485 112.581 38.6023 53.5484 62.3657 47.0968L68.3872 30.7527H68.0646ZM70.7528 135.161V133.011C64.8388 133.763 58.9249 132.043 54.9464 127.742L54.7313 135.161H38.3872C47.527 149.355 71.613 152.688 86.5593 144.946V135.161H70.7528ZM101.721 49.5699C98.3872 43.3333 93.441 38.0645 87.4195 35.0538L81.398 51.2903C94.6238 65.8064 96.1292 128.065 72.9034 132.581V132.903H88.7098V143.656C115.161 125.376 113.333 73.9785 101.721 49.5699ZM70.6453 30.8602L64.8388 46.6667C70.0001 45.914 76.0216 46.9892 79.6775 49.8925L85.484 34.1935C81.0754 32.1505 76.1292 31.0753 70.6453 30.8602Z"
      fill="#A3FFF0"
    />
    <path
      d="M118.925 73.3333V47.4193H99.5699L98.6021 73.3333H73.3333L81.3978 51.2903C80.8602 50.7527 80.3226 50.215 79.6774 49.7849L70.215 75.4839H100.753L101.72 49.5699H116.774V75.4839H132.581V89.5699H116.774V118.065H100.753V89.6774H49.1398L64.8387 46.6667C63.9785 46.7742 63.1183 46.8817 62.3656 47.0968L46.0215 91.8279H98.6021V120.323H118.925V91.8279H134.731V73.3333H118.925ZM80.9677 2.7957L95.0538 8.06452L85.4839 34.086C86.129 34.4086 86.7742 34.7312 87.4193 35.0538L97.8494 6.77419L79.6774 0L68.3871 30.7527C69.1398 30.7527 69.8925 30.7527 70.6451 30.8602L80.9677 2.7957Z"
      fill="#454DE2"
    />
    <path
      d="M87.4194 35.0538L81.3979 51.2903C80.8603 50.7527 80.3227 50.2151 79.6775 49.785L85.4839 34.086C86.1291 34.4086 86.7743 34.7312 87.4194 35.0538Z"
      fill="#A3FFF0"
    />
    <path
      d="M70.645 30.8602L64.8386 46.6667C63.9784 46.7742 63.1182 46.8817 62.3655 47.0968L68.387 30.7527C69.1397 30.7527 69.8924 30.7527 70.645 30.8602Z"
      fill="#A3FFF0"
    />
    <path
      d="M53.5484 107.097L52.9032 125.161C53.5484 126.129 54.1936 126.989 54.9462 127.742L55.5914 109.247H70.6452V133.011C71.3979 132.903 72.1505 132.796 72.7957 132.688V107.097H53.5484ZM86.5591 144.946V149.247H70.7527V177.742H54.7312V149.247H3.11828C9.03226 133.011 29.2473 77.7419 34.9462 62.3656C35.8065 62.6882 48.6021 67.5269 49.0323 67.6344C43.1183 83.5484 30 119.355 24.1936 135.054H38.3871C37.8495 134.409 37.4194 133.656 36.8817 132.903H27.2043C33.5484 115.699 45.3763 83.4409 51.7204 66.3441C49.3548 65.4839 36.2366 60.5376 33.5484 59.5699C29.4624 70.8602 4.73118 138.602 0 151.398H52.5807V179.892H72.9032V151.398H88.7097V143.656C87.957 144.086 87.3118 144.516 86.5591 144.946Z"
      fill="#FC9226"
    />
    <path
      d="M88.7097 132.846V143.763C87.957 144.194 87.3118 144.624 86.5591 144.946V135.161H70.7527V133.011C71.5054 132.903 72.2581 132.796 72.9032 132.688L73.1182 132.796L88.7097 132.846Z"
      fill="#A3FFF0"
    />
    <path
      d="M54.9464 127.742L54.7313 135.161H38.3872C37.8496 134.516 37.4195 133.763 36.8818 133.011H52.5808L52.9033 125.161C53.5485 126.129 54.1937 126.989 54.9464 127.742Z"
      fill="#A3FFF0"
    />
  </svg>
);
