import React from "react";
import { isEmpty } from "lodash";
import capitalize from "lodash/capitalize";

import { Button } from "@components/atoms";
import { formatLength } from "@utils/temporal";

import { FilterType, useFilters } from "../hooks";

import { Pill } from "./Pill";

export interface PillBarProps {
  className?: any;
}

export const PillBar = ({ className }: PillBarProps) => {
  const {
    previousFilter,
    getFilter,
    addFilter,
    removeFilter,
    applyFilters,
    reset,
  } = useFilters();

  if (!previousFilter || isEmpty(previousFilter)) {
    return null;
  }

  const onRemoveLabel = (
    filterType:
      | FilterType.LABEL__CONTAINS
      | FilterType.CELEBRITY__EQUALS
      | FilterType.CELEBRITY__LIKE
      | FilterType.SPOKEN_NOUN__CONTAINS
      | FilterType.SENTIMENT__EQUALS
      | FilterType.CONTENT_MODERATION_CONTAINS
      | FilterType.CONTENT_MODERATION_EXCLUDES,
    index: number
  ) => {
    const filter = getFilter(filterType);
    if (filter && filter.values.length > 1) {
      filter.values.splice(index, 1);
      addFilter(filter);
    } else if (filter) {
      removeFilter(filterType);
    }
    applyFilters();
  };

  const onRemoveDuration = (limit: "min" | "max") => {
    const filter = getFilter(FilterType.DURATION__MIN_MAX);
    if (limit === "min" && filter?.max) {
      addFilter({ ...filter, min: undefined });
    } else if (limit === "max" && filter?.min) {
      addFilter({ ...filter, max: undefined });
    } else {
      removeFilter(FilterType.DURATION__MIN_MAX);
    }
    applyFilters();
  };

  const onRemoveTitle = () => {
    removeFilter(FilterType.TITLE__LIKE);
    applyFilters();
  };

  const clearAll = () => {
    reset();
  };

  const pills = Object.keys(previousFilter).flatMap((filterType, idx) => {
    if (filterType === FilterType.TITLE__LIKE) {
      const value = previousFilter[FilterType.TITLE__LIKE];

      return [
        <Pill
          data-testid="title-pill"
          title="Title"
          onRemoveClicked={onRemoveTitle}
          key={`title_${value?.title}_${idx}`}
        >
          {value?.title}
        </Pill>,
      ];
    }

    if (filterType === FilterType.LABEL__CONTAINS) {
      const labels = previousFilter[FilterType.LABEL__CONTAINS];

      return labels?.values
        .filter((label) => label !== "")
        .map((label, idx) => (
          <Pill
            data-testid={`label-pill-${idx}`}
            title="Contains"
            onRemoveClicked={() =>
              onRemoveLabel(FilterType.LABEL__CONTAINS, idx)
            }
            key={`label__contains_${label}_${idx}`}
          >
            {label}
          </Pill>
        ));
    }

    if (filterType === FilterType.SPOKEN_NOUN__CONTAINS) {
      const spokenNouns = previousFilter[FilterType.SPOKEN_NOUN__CONTAINS];

      return spokenNouns?.values
        .filter((spokenNoun) => spokenNoun !== "")
        .map((spokenNoun, idx) => (
          <Pill
            data-testid={`spoken-noun-pill-${idx}`}
            title="Nouns"
            onRemoveClicked={() =>
              onRemoveLabel(FilterType.SPOKEN_NOUN__CONTAINS, idx)
            }
            key={`spoken_noun__contains_${spokenNoun}_${idx}`}
          >
            {spokenNoun}
          </Pill>
        ));
    }

    if (filterType === FilterType.CELEBRITY__EQUALS) {
      const celebrities = previousFilter[FilterType.CELEBRITY__EQUALS];

      return celebrities?.values
        .filter((celeb) => celeb !== "")
        .map((celebrity, idx) => (
          <Pill
            data-testid={`celebrity-equals-pill-${idx}`}
            title="Celebrity"
            onRemoveClicked={() =>
              onRemoveLabel(FilterType.CELEBRITY__EQUALS, idx)
            }
            key={`celebrity__equals_${celebrity}_${idx}`}
          >
            {celebrity}
          </Pill>
        ));
    }

    if (filterType === FilterType.CELEBRITY__LIKE) {
      const celebrities = previousFilter[FilterType.CELEBRITY__LIKE];

      return celebrities?.values
        .filter((celeb) => celeb !== "")
        .map((celebrity, idx) => (
          <Pill
            data-testid={`celebrity-like-pill-${idx}`}
            title="Celebrity"
            onRemoveClicked={() =>
              onRemoveLabel(FilterType.CELEBRITY__LIKE, idx)
            }
            key={`celebrity__like_${celebrity}_${idx}`}
          >
            {celebrity}
          </Pill>
        ));
    }

    if (filterType === FilterType.SENTIMENT__EQUALS) {
      const sentiments = previousFilter[FilterType.SENTIMENT__EQUALS];

      return sentiments?.values.map((sentiment, idx) => (
        <Pill
          title="Sentiment"
          onRemoveClicked={() =>
            onRemoveLabel(FilterType.SENTIMENT__EQUALS, idx)
          }
          key={`sentiment__contains_${sentiment}_${idx}`}
        >
          {capitalize(sentiment)}
        </Pill>
      ));
    }

    if (filterType === FilterType.DURATION__MIN_MAX) {
      const duration = previousFilter[FilterType.DURATION__MIN_MAX];

      const pills = [];

      if (duration?.min) {
        pills.push(
          <Pill
            title="Min duration"
            onRemoveClicked={() => onRemoveDuration("min")}
            key={`duration_min_${duration.min}}`}
          >
            {formatLength(duration.min)}
          </Pill>
        );
      }

      if (duration?.max) {
        pills.push(
          <Pill
            title="Max duration"
            onRemoveClicked={() => onRemoveDuration("max")}
            key={`duration_min_${duration.max}`}
          >
            {formatLength(duration.max)}
          </Pill>
        );
      }

      return pills;
    }

    if (filterType === FilterType.CONTENT_MODERATION_CONTAINS) {
      const contentModeration =
        previousFilter[FilterType.CONTENT_MODERATION_CONTAINS];

      return contentModeration?.values.map((contentMod, idx) => (
        <Pill
          data-testid={`content-moderation-contains-pill-${idx}`}
          title="Contains"
          onRemoveClicked={() =>
            onRemoveLabel(FilterType.CONTENT_MODERATION_CONTAINS, idx)
          }
          key={`content_moderation__contains_${contentMod}_${idx}`}
        >
          {contentMod}
        </Pill>
      ));
    }

    if (filterType === FilterType.CONTENT_MODERATION_EXCLUDES) {
      const contentModeration =
        previousFilter[FilterType.CONTENT_MODERATION_EXCLUDES];

      return contentModeration?.values.map((contentMod, idx) => (
        <Pill
          data-testid={`content-moderation-excludes-pill-${idx}`}
          title="Excludes"
          onRemoveClicked={() =>
            onRemoveLabel(FilterType.CONTENT_MODERATION_EXCLUDES, idx)
          }
          key={`content_moderation__excludes_${contentMod}_${idx}`}
        >
          {contentMod}
        </Pill>
      ));
    }

    return [];
  });

  return (
    <div className={className}>
      <div className="inline-block space-y-3 -mr-3">
        {pills}
        <Button
          className="ml-7 text-blue-300 text-bodyXs"
          variant="text"
          onClick={clearAll}
        >
          Clear all
        </Button>
      </div>
    </div>
  );
};
