import React, { ReactNode } from "react";
import { Dialog } from "@headlessui/react";
import { classnames } from "tailwindcss-classnames";

import { Portal } from "@components/atoms";

export interface DescriptionProps {
  className?: any;
  centerText?: boolean;
  children?: ReactNode;
}

export const Description = ({
  children,
  className,
  centerText = true,
}: DescriptionProps) => (
  <Portal.Teleport refName="description">
    <Dialog.Description
      className={classnames("mt-8", className, { "text-center": centerText })}
    >
      {children}
    </Dialog.Description>
  </Portal.Teleport>
);
