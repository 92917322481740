import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const WinningTeam = createSvgIcon({
  content: (
    <>
      <path
        d="M5.3335 14H10.6668"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 11.3334V14"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.6665 2.66663H11.3332"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3332 2.66663V7.99996C11.3332 8.88401 10.982 9.73186 10.3569 10.357C9.73174 10.9821 8.88389 11.3333 7.99984 11.3333C7.11578 11.3333 6.26794 10.9821 5.64281 10.357C5.01769 9.73186 4.6665 8.88401 4.6665 7.99996V2.66663"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 5.99996C2 6.35358 2.14048 6.69272 2.39052 6.94277C2.64057 7.19282 2.97971 7.33329 3.33333 7.33329C3.68696 7.33329 4.02609 7.19282 4.27614 6.94277C4.52619 6.69272 4.66667 6.35358 4.66667 5.99996C4.66667 5.64634 4.52619 5.3072 4.27614 5.05715C4.02609 4.8071 3.68696 4.66663 3.33333 4.66663C2.97971 4.66663 2.64057 4.8071 2.39052 5.05715C2.14048 5.3072 2 5.64634 2 5.99996Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3335 5.99996C11.3335 6.35358 11.474 6.69272 11.724 6.94277C11.9741 7.19282 12.3132 7.33329 12.6668 7.33329C13.0205 7.33329 13.3596 7.19282 13.6096 6.94277C13.8597 6.69272 14.0002 6.35358 14.0002 5.99996C14.0002 5.64634 13.8597 5.3072 13.6096 5.05715C13.3596 4.8071 13.0205 4.66663 12.6668 4.66663C12.3132 4.66663 11.9741 4.8071 11.724 5.05715C11.474 5.3072 11.3335 5.64634 11.3335 5.99996Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
  defaultColor: "navy-100",
});
