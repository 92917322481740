export enum FilterType {
  LABEL__CONTAINS = "LABEL__CONTAINS",
  CELEBRITY__EQUALS = "CELEBRITY__EQUALS",
  TRANSCRIPT__CONTAINS = "TRANSCRIPT__CONTAINS",
  SENTIMENT__EQUALS = "SENTIMENT__EQUALS",
  CONTENT_MODERATION__EQUALS = "CONTENT_MODERATION__EQUALS",
  VIDEO_DURATION__EQUALS = "VIDEO_DURATION__EQUALS",
  VIDEO_TITLE__CONTAINS = "VIDEO_TITLE__CONTAINS",
  TEXT_IN_VIDEO__CONTAINS = "TEXT_IN_VIDEO__CONTAINS",
  MIN_OCCURRENCE__COUNT = "MIN_OCCURRENCE__COUNT",
  NESTED_SEGMENT_ID__EQUALS = "NESTED_SEGMENT_ID__EQUALS",
  METADATA__CONTAINS = "METADATA__CONTAINS",

  RUN_DIFFERENTIAL__COMPARE = "RUN_DIFFERENTIAL__COMPARE",
  INNING__COMPARE = "INNING__COMPARE",
  EVENT_RUNS_SCORED__COMPARE = "EVENT_RUNS_SCORED__COMPARE",
  EVENT_LEVERAGE_INDEX__COMPARE = "EVENT_LEVERAGE_INDEX__COMPARE",

  CLOSE_GAME__EQUALS = "CLOSE_GAME__EQUALS",
  DELAYED_GAME__EQUALS = "DELAYED_GAME__EQUALS",
  DOUBLE_HEADER__EQUALS = "DOUBLE_HEADER__EQUALS",
  NIGHT_GAME__EQUALS = "NIGHT_GAME__EQUALS",
  VENUE__EQUALS = "VENUE__EQUALS",
  PRE_ALL_STAR__EQUALS = "PRE_ALL_STAR__EQUALS",
  POST_ALL_STAR__EQUALS = "POST_ALL_STAR__EQUALS",
  SEASON_TYPE__EQUALS = "SEASON_TYPE__EQUALS",
  HOME_TEAM__EQUALS = "HOME_TEAM__EQUALS",
  AWAY_TEAM__EQUALS = "AWAY_TEAM__EQUALS",
  DIVISION__EQUALS = "DIVISION__EQUALS",
  LEAGUE__EQUALS = "LEAGUE__EQUALS",
  PLAYER__EQUALS = "PLAYER__EQUALS",
  WINNING_TEAM__EQUALS = "WINNING_TEAM__EQUALS",
  EXTRA_INNINGS__EQUALS = "EXTRA_INNINGS__EQUALS",
  SERIES_TYPE__EQUALS = "SERIES_TYPE__EQUALS",
  INNING_HALF__EQUALS = "INNING_HALF__EQUALS",
  EVENT_TYPE__EQUALS = "EVENT_TYPE__EQUALS",
  EVENT_DESCRIPTION__CONTAINS = "EVENT_DESCRIPTION__CONTAINS",
  LINEUP_CHANGE__EQUALS = "LINEUP_CHANGE__EQUALS",
  STOLEN_BASE__EQUALS = "STOLEN_BASE__EQUALS",
}

export type TitleFilter = {
  value?: string;
  exact?: boolean;
};
export type LabelFilter = {
  value?: string;
  confidence?: number;
  within?: number;
};
export type CelebrityEqualsFilter = {
  value?: string;
  confidence?: number;
  within?: number;
};

export type TranscriptContainsFilter = {
  value?: string;
  within?: number;
};
export type VideoTitleContainsFilter = {
  value?: string;
};
export type MetadataContainsFilter = {
  value?: string;
};

export type SentimentFilter = {
  value?: string;
  within?: number;
};

export type TextInVideoContainsFilter = {
  value?: string;
  within?: number;
};

export type MinOccurrenceCountFilter = {
  conditions?: FilterValue[];
  value?: number;
  within?: number;
};

export type ContentModerationFilter = {
  values?: string[];
  confidence?: number;
  within?: number;
};

export type VideoDurationFilter = {
  minDuration?: number;
  maxDuration?: number;
};

export type NestedSegmentFilter = {
  value?: string;
  version?: number;
  title?: string;
};

export type GenericSportsMetricFilter = {
  value?: number;
  within?: number;
  comparator?: string;
};

export type GenericSportsDimensionFilter = {
  value?: string;
  within?: number;
};

export type SportsDimensionBooleanWithToleranceFilter = {
  value?: boolean;
  within?: number;
};

export type SportsDimensionStringFilter = {
  value?: string;
};

export type SportsDimensionBooleanFilter = {
  value?: boolean;
};

export type FilterValue =
  | TitleFilter
  | LabelFilter
  | CelebrityEqualsFilter
  | TranscriptContainsFilter
  | VideoTitleContainsFilter
  | SentimentFilter
  | TextInVideoContainsFilter
  | ContentModerationFilter
  | VideoDurationFilter
  | MetadataContainsFilter
  | NestedSegmentFilter
  | GenericSportsMetricFilter
  | GenericSportsDimensionFilter
  | SportsDimensionBooleanWithToleranceFilter
  | SportsDimensionStringFilter
  | SportsDimensionBooleanFilter;
