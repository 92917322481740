import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const ArrowRightTop = createSvgIcon({
  content: (
    <>
      <path
        d="M12.6654 8.66666V3.33333H7.33203"
        className="stroke-current"
        strokeLinecap="square"
      />
      <path
        d="M12.6667 3.33333L12 3.99999"
        className="stroke-current"
        strokeLinecap="round"
      />
      <path
        d="M11.9987 4L3.33203 12.6667"
        className="stroke-current"
        strokeLinecap="square"
      />
    </>
  ),
});
