import React from "react";

import { RadioButton } from "@components/atoms";
import * as Icon from "@components/atoms/Icon";

export enum SegmentTypeValue {
  Segment = "segment",
  Inverse = "inverse",
}

const options: {
  [K in SegmentTypeValue]: {
    display: React.ReactNode;
    value: K;
  };
} = {
  segment: {
    display: (
      <div className="flex items-center gap-2">
        <Icon.Segment size={16} />
        <span className="text-bodySm font-medium">Segment</span>
      </div>
    ),
    value: SegmentTypeValue.Segment,
  },
  inverse: {
    display: (
      <div className="flex items-center gap-2">
        <Icon.InverseSegment className="text-red-300" size={16} />
        <span className="text-bodySm font-medium">Inverse Segment</span>
      </div>
    ),
    value: SegmentTypeValue.Inverse,
  },
};

const name = "segment-type";

interface SegmentTypeProps {
  value?: SegmentTypeValue;
  onChange: (value: SegmentTypeValue) => void;
}

export const SegmentType = ({ value, onChange }: SegmentTypeProps) => (
  <div className="flex flex-col">
    {Object.values(options).map((filter) => (
      <RadioButton
        key={filter.value}
        name={name}
        className="py-2"
        value={filter.value}
        checked={value === filter.value}
        onChange={(e) =>
          e.target.checked ? onChange(filter.value) : undefined
        }
      >
        {filter.display}
      </RadioButton>
    ))}
  </div>
);
