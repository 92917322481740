import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const ShirtSport = createSvgIcon({
  content: (
    <>
      <path
        d="M10 2.66663L14 3.99996V7.33329H12V12.6666C12 12.8434 11.9298 13.013 11.8047 13.138C11.6797 13.2631 11.5101 13.3333 11.3333 13.3333H4.66667C4.48986 13.3333 4.32029 13.2631 4.19526 13.138C4.07024 13.013 4 12.8434 4 12.6666V7.33329H2V3.99996L6 2.66663C6 3.19706 6.21071 3.70577 6.58579 4.08084C6.96086 4.45591 7.46957 4.66663 8 4.66663C8.53043 4.66663 9.03914 4.45591 9.41421 4.08084C9.78929 3.70577 10 3.19706 10 2.66663Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 7.33337H8.66667L7.66667 10.6667"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
});
