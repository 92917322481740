import React, { Fragment, HTMLAttributes } from "react";
import { Dialog, Transition } from "@headlessui/react";

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  onClose?: () => void;
  onOpened?: () => void;
  isDialogOpen?: boolean;
}

export const Container = ({
  isDialogOpen = false,
  children,
  onClose = () => {},
  onOpened = () => {},
}: ContainerProps) => {
  const handleStateChange = (open: boolean) => {
    open ? onOpened() : onClose();
  };

  return (
    <Transition.Root appear show={isDialogOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => handleStateChange(false)}
      >
        <div className="min-h-screen flex items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-navy-300 bg-opacity-40" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
