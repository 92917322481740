import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const ChevronDown = createSvgIcon({
  content: (
    <path
      d="M2 1.66663L6 5.66663L10 1.66663"
      className="stroke-current"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  ),
  width: 12,
  height: 7,
  "data-testid": "chevron-down",
});
