import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import { Select } from "@components/atoms";
import { Pager } from "@components/molecules";
import { ContentModerationTable as Table } from "@components/organisms";
import {
  ContentModerationTable_ListContentModerationOccurrenceDocument,
  ContentModerationTable_ListContentModerationOccurrenceQueryVariables,
  SortDirection,
} from "@graphql/operations";
import { usePaging } from "@hooks/usePaging";
import { useVideo } from "@hooks/useVideo";

export interface ContentModerationTableProps {
  videoId: string;
}

const limitOptions = { "25": "25", "50": "50", "100": "100" };

export const ContentModerationTable = ({
  videoId,
}: ContentModerationTableProps) => {
  const { currentTimeRange } = useVideo();
  const [limit, setLimit] = useState("25");
  const { discardStackTo, push, peek, setCurrentPage, getCurrentPage } =
    usePaging({
      tokenStack: [""],
      currentPage: 1,
    });
  const nextToken = peek();
  let variables: ContentModerationTable_ListContentModerationOccurrenceQueryVariables =
    {
      filter: {
        videoId: {
          eq: videoId,
        },
        timestamp: { between: [currentTimeRange.start, currentTimeRange.end] },
      },
      limit: Number(limit),
      sortDirection: SortDirection.Desc,
    };

  if (nextToken) {
    variables = {
      ...variables,
      nextToken,
    };
  }

  const { data, loading, previousData } = useQuery(
    ContentModerationTable_ListContentModerationOccurrenceDocument,
    {
      variables,
    }
  );
  const contentModerations =
    data?.listContentModerationOccurrence?.items ||
    previousData?.listContentModerationOccurrence?.items ||
    [];

  const hasNextPage = !!data?.listContentModerationOccurrence?.nextToken;

  const onPagedChanged = (pageNumber: number) => {
    if (pageNumber > getCurrentPage()) {
      push(data?.listContentModerationOccurrence?.nextToken || "");
    } else {
      discardStackTo(pageNumber);
    }
    setCurrentPage(pageNumber);
  };

  return (
    <div className="flex flex-col">
      <Table
        loading={loading}
        loadingRows={Number(limitOptions["25"])}
        contentModerations={contentModerations}
      />
      {(hasNextPage ||
        (contentModerations.length > 0 && getCurrentPage() > 0)) && (
        <div className="flex flex-row justify-between p-4">
          <div className="flex flex-row items-center align-middle pl-6">
            <p className="mr-2 text-sm text-black-100 font-medium">Per page</p>
            <Select
              direction="up"
              className="h-8"
              options={limitOptions}
              value={limit}
              rightToLeft={false}
              fixedWidth={true}
              onChange={(item) => setLimit(item)}
            />
          </div>
          <Pager
            className="mr-0"
            data-testid="pager"
            currentPage={getCurrentPage()}
            nextButtonEnabled={hasNextPage}
            onPageChanged={onPagedChanged}
          />
        </div>
      )}
    </div>
  );
};
