import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "@components/atoms";
import { CodeBlock, Images } from "@components/molecules";
import * as clipboard from "@utils/clipboard";

export type ErrorFallbackProps = {
  error: Error;
  resetErrorBoundary?: (...args: Array<unknown>) => void;
};

export const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: ErrorFallbackProps) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const originalLocation = React.useRef(currentLocation);
  const errorMessage = `Message: ${error.message || "-"}
Name: ${error.name || "-"}
Cause: ${error.cause || "-"}
Stack:
  ${error.stack}`;

  useEffect(() => {
    if (originalLocation.current.pathname !== currentLocation.pathname) {
      resetErrorBoundary && resetErrorBoundary();
    }
  }, [currentLocation, resetErrorBoundary]);

  return (
    <article className="flex-auto flex flex-col items-center pb-4 pt-24 self-center">
      <Images.AtlasIconLogo size={96} className="mb-10" />
      <h2 className="mb-6">Sorry, something went wrong</h2>
      <p className="mb-12 font-light text-lg text-black-300 max-w-126">
        Atlas encountered an unexpected error. Please refresh and try again. If
        the error persists, please contact support.
      </p>
      <Button
        size="large"
        color="primary"
        className="mb-6"
        onClick={() => {
          navigate(-1);
          resetErrorBoundary && resetErrorBoundary();
        }}
      >
        Go Back
      </Button>
      <div className="max-w-180">
        <strong>Stack:</strong>
        <CodeBlock
          onCopy={clipboard.copyToHandler({
            value: errorMessage,
            onComplete: clipboard.toastSuccess({
              message: "Copied to clipboard",
            }),
          })}
        >
          {errorMessage}
        </CodeBlock>
      </div>
    </article>
  );
};
