import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Avatar = createSvgIcon({
  content: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9919 15C8.98546 15.0027 3 16.5102 3 19.5V19.9375C5.19884 22.4287 8.41592 24 12 24C15.5841 24 18.8012 22.4287 21 19.9375V19.5C21 16.5102 15.0146 15.0027 12.0081 15C12.0054 15 12.0027 15 12 15C11.9973 15 11.9946 15 11.9919 15ZM20 19.5499V19.5C20 19.1192 19.8172 18.7116 19.3379 18.2693C18.8519 17.8207 18.1308 17.4071 17.2545 17.0561C15.5005 16.3534 13.3769 16 12 16C10.6231 16 8.49955 16.3534 6.74547 17.0561C5.86923 17.4071 5.14811 17.8207 4.66208 18.2693C4.18284 18.7116 4 19.1192 4 19.5V19.5499C6.00675 21.6755 8.84813 23 12 23C15.1519 23 17.9933 21.6755 20 19.5499Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5ZM12 12.5C13.6569 12.5 15 11.1569 15 9.5C15 7.84315 13.6569 6.5 12 6.5C10.3431 6.5 9 7.84315 9 9.5C9 11.1569 10.3431 12.5 12 12.5Z"
        className="fill-current"
      />
    </>
  ),
  width: 24,
  height: 24,
});
