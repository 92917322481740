import React, { useEffect, useMemo, useRef } from "react";
import { useDragLayer, XYCoord } from "react-dnd";

import { classnames } from "@external/tailwindcss-classnames";

const scrollOffset = 80;

function getDragLayerStyles(
  initialOffset: XYCoord | null,
  sourceConfig: (XYCoord & { width: number }) | null
) {
  if (!initialOffset || !sourceConfig) {
    return {
      display: "none",
    };
  }

  let { x, y } = sourceConfig || { x: 0, y: 0, width: "auto" };

  const transform = `translate(${x}px, ${y}px) rotate(5deg)`;
  return {
    transform,
    WebkitTransform: transform,
    width: sourceConfig.width,
  };
}

export const CustomDragLayer = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer<{
    item?: {
      ref?: HTMLDivElement | null;
      width?: number;
      height?: number;
      handleRef?: HTMLDivElement | null;
      handleWidth?: number;
      action?: string;
    };
    isDragging: boolean;
    initialOffset: XYCoord | null;
    currentOffset: XYCoord | null;
  }>((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const scrollRef = useRef<{ block: boolean; top: number }>({
    block: false,
    top: 0,
  });

  useEffect(() => {
    const stopScrolling = () => {
      if (scrollRef.current.block) {
        document.documentElement.scrollTop = scrollRef.current.top;
      }
    };

    window.addEventListener("scroll", stopScrolling);

    return () => {
      window.removeEventListener("scroll", stopScrolling);
    };
  }, []);

  useEffect(() => {
    const currentOffsetY = currentOffset?.y || 0;
    const isInTopDragArea =
      currentOffsetY >= scrollOffset &&
      currentOffsetY <= window.innerHeight / 2;
    const isInBottomDragArea =
      currentOffsetY <=
        window.innerHeight - scrollOffset - (item?.height || 0) &&
      currentOffsetY >= window.innerHeight / 2;

    const shouldStopScrolling =
      item?.action === "NEW" &&
      isDragging &&
      (isInTopDragArea || isInBottomDragArea);

    if (shouldStopScrolling) {
      if (!scrollRef.current.block) {
        scrollRef.current.top = document.documentElement.scrollTop;
      }
      scrollRef.current.block = true;
    } else {
      scrollRef.current.block = false;
    }
  }, [isDragging, currentOffset, item]);

  const sourceConfig = useMemo(() => {
    const width = item?.ref?.clientWidth || item?.width || 0;
    const handleWidth = item?.handleRef?.clientWidth || item?.handleWidth || 0;

    return {
      x: (currentOffset?.x || 0) - width + handleWidth,
      y: currentOffset?.y || 0,
      width,
    };
  }, [currentOffset, item]);

  useEffect(() => {
    if (item?.ref && ref?.current) {
      if (isDragging) {
        const cloned = item.ref.cloneNode(true);

        if (cloned instanceof Element) {
          cloned.classList.add("bg-navy-80");
          cloned.classList.remove("invisible");
        }

        ref.current.appendChild(cloned);
      } else {
        ref.current.childNodes.forEach((node) => node.remove());
      }
    }
  }, [isDragging, item?.ref]);

  if (!isDragging) {
    return null;
  }

  return (
    <div
      className={classnames(
        "fixed",
        "z-50",
        "left-0",
        "top-0",
        "pointer-events-none",
        "w-screen",
        "h-screen"
      )}
    >
      <div
        ref={ref}
        className={classnames("origin-right")}
        style={getDragLayerStyles(initialOffset, sourceConfig)}
      />
    </div>
  );
};
