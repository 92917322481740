import { cloneDeep } from "lodash";

import { TreeNode } from "./tree-node";
import type { Node, SelectionState } from "./types";

export class Tree {
  private _nodes: TreeNode[] = [];
  private _nodesDict: { [id: string]: TreeNode } = {};

  constructor(nodes: TreeNode[]) {
    this._nodes = nodes;
    this._nodesDict = this._buildDictionary(nodes);
  }

  get nodes(): TreeNode[] {
    return this._nodes;
  }

  get leaves(): TreeNode[] {
    return Object.values(this._nodesDict).filter(
      (node) => node.children.length === 0
    );
  }

  clone(): Tree {
    const clonedNodes = cloneDeep(this._nodes);
    return new Tree(clonedNodes);
  }

  findByName(name: string): TreeNode[] {
    return Object.values(this._nodesDict).filter((node) =>
      node.name.toLowerCase().includes(name.toLowerCase())
    );
  }

  findById(id: string): TreeNode | undefined {
    return this._nodesDict[id];
  }

  findOpenNode(): TreeNode | undefined {
    return Object.values(this._nodesDict).find((node) => node.isOpen);
  }

  updateSelectionState(id: string, state: SelectionState): Tree {
    const clone = this.clone();
    const node = clone.findById(id);

    if (node) {
      node.selectionState = state;
    }

    return clone;
  }

  setSelectedNodes(nodeIds: string[]): Tree {
    const clone = this.clone();

    Object.values(clone._nodesDict).forEach((node) => {
      if (nodeIds.includes(node.id)) {
        node.selectionState = "on";
      } else {
        node.selectionState = "off";
      }
    });

    return clone;
  }

  toggleOpenNode(nodeId: string): Tree {
    const clone = this.clone();
    const node = clone.findById(nodeId);

    if (node) {
      const openNode = clone.findOpenNode();

      if (openNode && openNode !== node) {
        openNode.isOpen = false;
      }

      node.isOpen = !node.isOpen;
    }

    return clone;
  }

  keepNodesVisible(nodes: TreeNode[]): Tree {
    const clone = this.clone();

    const nodeIds = nodes.map((node) => node.id);

    Object.values(clone._nodesDict).forEach((node) => {
      if (nodeIds.includes(node.id)) {
        node.isVisible = true;
      } else {
        node.isVisible = false;
      }
    });

    return clone;
  }

  removeNodesVirtually(nodeIds: string[]): Tree {
    const clone = this.clone();

    Object.values(clone._nodesDict).forEach((node) => {
      if (nodeIds.includes(node.id)) {
        node.isVirtuallyRemoved = true;
        node.selectionState = "off";

        if (
          node.parent &&
          !node.parent.children.some((child) => !child.isVirtuallyRemoved)
        ) {
          node.parent.isVirtuallyRemoved = true;
          node.parent.selectionState = "off";
        }
      } else {
        node.isVirtuallyRemoved = false;
      }
    });

    return clone;
  }

  static build(nodes: Node[] = []): Tree {
    const treeNodes = Tree._buildFromNodes(nodes, undefined);

    return new Tree(treeNodes);
  }

  private _buildDictionary(nodes: TreeNode[] = []): {
    [id: string]: TreeNode;
  } {
    return nodes.reduce<{ [id: string]: TreeNode }>((acc, node) => {
      acc[node.id] = node;
      return { ...acc, ...this._buildDictionary(node.children) };
    }, {});
  }

  private static _buildFromNode(node: Node, parent?: TreeNode): TreeNode {
    const treeNode = new TreeNode(parent, node.id, node.name);
    treeNode.children = Tree._buildFromNodes(node.children, treeNode);

    if (node.selected) {
      treeNode.selectionState = "on";
    }

    return treeNode;
  }

  private static _buildFromNodes(
    nodes: Node[] = [],
    parent: TreeNode | undefined
  ): TreeNode[] {
    return nodes.map((node) => Tree._buildFromNode(node, parent));
  }
}
