import React from "react";
import { useLazyQuery } from "@apollo/client";
import { merge } from "lodash";

import {
  AdvancedSearchExportDialog_UnloadAdvancedSearchDocument,
  AdvancedSearchExportDialog_UnloadAdvancedSearchQueryVariables,
  AdvancedSearchQuery,
} from "@graphql/operations";

import { GenericExportDialog } from "../GenericExportDialog/GenericExportDialog";

enum Category {
  INGESTION_DATA = "Ingestion Data",
  VIDEO_DATA = "Video Data",
}

export const ingestionSelectableFields: Record<any, string> = {
  IngestionJobVideoId: "Ingestion Job Video Id",
  IngestionJobVideoAssetId: "Ingestion Job Video Asset Id",
  IngestionJobVideoTitle: "Ingestion Job Video Title",
  IngestionJobVideoThumbnail: "Ingestion Job Video Thumbnail",
  IngestionJobVideoCreatedAt: "Ingestion Job Video Created At",
  IngestionJobVideoItemType: "Ingestion Job Video Item Type",
  IngestionJobVideoWorkflowExecutionId:
    "Ingestion Job Video Workflow Execution Id",
  IngestionJobVideoMieStatus: "Ingestion Job Video Mie Status",
  IngestionJobVideoIngestionStatus: "Ingestion Job Video Ingestion Status",
  IngestionJobVideoHref: "Ingestion Job Video Href",
  IngestionJobVideoMediaFile: "Ingestion Job Video Media File",
  IngestionJobVideoThumbnailFile: "Ingestion Job Video Thumbnail File",
  IngestionJobVideoError: "Ingestion Job Video Error",
};

const videoSelectableFields: Record<any, string> = {
  VideoFileName: "Video File Name",
  VideoVideoId: "Video Video Id",
  VideoAssetId: "Video Asset Id",
  VideoDuration: "Video Duration",
  VideoDurationMillis: "Video Duration Millis",
};

const selectableFields = [
  {
    id: Category.INGESTION_DATA,
    name: Category.INGESTION_DATA,
    children: Object.entries(ingestionSelectableFields).map(([k, v]) => ({
      id: k,
      name: v,
    })),
  },
  {
    id: Category.VIDEO_DATA,
    name: Category.VIDEO_DATA,
    selected: true,
    children: Object.entries(videoSelectableFields).map(([k, v]) => ({
      id: k,
      name: v,
    })),
  },
];

const selectedNodesId = [
  ...Object.keys(ingestionSelectableFields),
  ...Object.keys(videoSelectableFields),
];

export interface AdvancedSearchExportDialogProps {
  disabled?: boolean;
  query?: AdvancedSearchQuery;
  title: string;
}

export const AdvancedSearchExportDialog = ({
  disabled,
  query,
  title,
}: AdvancedSearchExportDialogProps) => {
  const [unloadAdvancedSearch, { loading, error }] = useLazyQuery(
    AdvancedSearchExportDialog_UnloadAdvancedSearchDocument,
    {
      fetchPolicy: "no-cache",
    }
  );

  return (
    <GenericExportDialog
      disabled={disabled}
      title={title}
      selectableFields={selectableFields}
      initialSelectedNodeIds={selectedNodesId}
      onExportSubmission={async (formData: string[]) => {
        let variables: AdvancedSearchExportDialog_UnloadAdvancedSearchQueryVariables =
          {};

        if (query) {
          variables = merge(variables, {
            filter: {
              query,
            },
          });
        }

        if ((formData.length || 0) > 1) {
          variables = merge(variables, {
            unload: {
              columns: formData.slice(1),
            },
          });
        }

        const { data } = await unloadAdvancedSearch({ variables });

        return data?.advancedSearch?.unload?.href || undefined;
      }}
      loading={loading}
      error={error?.message}
    />
  );
};
