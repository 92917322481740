import React from "react";

import { Label, LabelProps } from "@components/atoms";

export interface PillProps {
  title: string;
}

export const Pill = ({ title, children, ...rest }: PillProps & LabelProps) => (
  <Label
    removeable={true}
    className="bg-lightGrey-200 text-bodySm text-black-300 h-8 mr-3"
    {...rest}
  >
    {title}:&nbsp;<span className="font-semibold">{children}</span>
  </Label>
);
