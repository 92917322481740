import React, { useState } from "react";

import { Table } from "@components/molecules";
import { JobsTable_JobFragment } from "@graphql/operations";

import { VodSliderOverTab } from "../VodSlideOver/VodSlideOverBody";

import { NoJobsYet } from "./NoJobsYet";
import { TableContentElement } from "./TableContentElement";

export type JobsTableProps = {
  canHaveResults: boolean;
  jobs: JobsTable_JobFragment[];
  onJobClicked?: (
    idx: number,
    details?: VodSliderOverTab
  ) => Promise<void> | void;
  onRestartClicked: (
    videoId: string,
    href?: string,
    thumbnail?: string
  ) => Promise<void> | void;
  onNewJobClicked: () => void;
} & Pick<
  Table.TableProps,
  "loading" | "loadingRows" | "error" | "errorMessage"
>;

export const JobsTable = ({
  jobs = [],
  canHaveResults,
  onRestartClicked,
  onJobClicked,
  loading,
  loadingRows,
  error,
  errorMessage,
  onNewJobClicked,
}: JobsTableProps) => {
  const [enableHover, setEnableHover] = useState<boolean>(true);

  return (
    <Table.Table
      columns={[
        {
          title: "ID",
          width: "w-2/12",
        },
        {
          title: "Title",
          width: "w-3/12",
        },
        {
          title: "Business Unit",
          width: "w-2/12",
        },
        {
          title: "Started at",
          width: "w-2/12",
        },
        {
          title: "Status",
          width: "w-2/12",
        },
        {
          width: "w-1/12",
        },
      ]}
      loading={loading}
      loadingRows={loadingRows}
      loadingRowsHeight="h-12"
      emptyComponent={
        !canHaveResults ? (
          <NoJobsYet onNewJobClicked={onNewJobClicked} />
        ) : undefined
      }
      error={error}
      errorMessage={errorMessage}
    >
      {jobs.length > 0
        ? jobs.map((job, i) => (
            <TableContentElement
              key={job.videoId}
              setEnableHover={setEnableHover}
              enableHover={enableHover}
              onJobClicked={onJobClicked}
              onRestartClicked={onRestartClicked}
              idx={i}
              job={job}
            />
          ))
        : null}
    </Table.Table>
  );
};
