import React from "react";

import { Button, Loading } from "@components/atoms";

export interface PreparingProps {
  onClose: () => Promise<void> | void;
}

export const Preparing = ({ onClose }: PreparingProps) => (
  <div className="flex flex-col h-full items-center">
    <div className="flex flex-col flex-auto items-center justify-center">
      <Loading.Slide className="mb-6 self-center" />
      Preparing Export...
    </div>
    <Button variant="text" onClick={onClose}>
      Cancel
    </Button>
  </div>
);
