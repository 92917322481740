import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { isEmpty } from "lodash";

import { Button } from "@components/atoms";
import {
  CreateSegmentButton_CreateSegmentJobDocument,
  CreateSegmentButton_CreateSegmentJobMutationVariables,
  SegmentQuery,
} from "@graphql/operations";
import { useFilters } from "@hooks/segments";

import { createSegmentJobDefaultOptions } from "../CreateSegmentButton";

export const SaveDraftButton = () => {
  const { query, segmentTitle, segmentDescription } = useFilters();
  const [createSegmentJob] = useMutation(
    CreateSegmentButton_CreateSegmentJobDocument,
    createSegmentJobDefaultOptions
  );

  const navigate = useNavigate();

  const variables: CreateSegmentButton_CreateSegmentJobMutationVariables = {
    input: {
      title: segmentTitle,
      ...(segmentDescription ? { description: segmentDescription } : {}),
      query: (query || {}) as SegmentQuery,
    },
  };

  return (
    <Button
      type="button"
      variant="text"
      disabled={isEmpty(segmentTitle) || isEmpty(query)}
      onClick={async () => {
        await createSegmentJob({
          variables,
        });
        toast.success("Segment saved as draft", {
          position: "bottom-right",
        });
        navigate("/segments");
      }}
    >
      Save draft
    </Button>
  );
};
