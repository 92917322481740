import React, { type ReactNode, useState } from "react";

import { Button, Dropdown, Icon } from "@components/atoms";
import { CreateJobDialog } from "@components/organisms";
import { classnames } from "@external/tailwindcss-classnames";

export interface NewMediaButtonProps {
  className?: any;
  children?: ReactNode;
}

export const NewMediaButton = ({
  children,
  className,
  ...rest
}: NewMediaButtonProps) => {
  const [isCreateJobDialogOpen, setIsCreateJobDialogOpen] = useState(false);

  return (
    <>
      <Dropdown.Container
        className="flex flex-col"
        button={
          <Button
            color="primary"
            size="medium"
            type="button"
            className="text-white"
            {...rest}
          >
            Ingest a video
          </Button>
        }
      >
        <Dropdown.Items className={classnames("w-35")}>
          <Dropdown.Item
            onClick={() => setIsCreateJobDialogOpen(true)}
            icon={<Icon.Play className="mr-2 text-coolGrey-400" />}
          >
            New VoD
          </Dropdown.Item>
        </Dropdown.Items>
      </Dropdown.Container>
      <CreateJobDialog
        isDialogOpen={isCreateJobDialogOpen}
        onCancel={() => setIsCreateJobDialogOpen(false)}
        onClose={() => setIsCreateJobDialogOpen(false)}
        onSubmission={() => setIsCreateJobDialogOpen(false)}
      />
    </>
  );
};
