import React, { HTMLAttributes } from "react";

import { Loading } from "@components/atoms";

export interface ShimmerProps extends HTMLAttributes<HTMLTableRowElement> {
  col: number;
  rows?: number;
  padRight?: number;
}

export const Shimmer = ({
  col,
  rows = 3,
  padRight = 0,
  ...rest
}: ShimmerProps) => (
  <>
    {Array.from(Array(rows).keys()).map((idx) => (
      <tr key={idx} {...rest}>
        {Array.from(Array(col - padRight).keys()).map((idx) => (
          <td key={idx}>
            <Loading.Shimmer />
          </td>
        ))}
        {Array.from(Array(padRight).keys()).map((idx) => (
          <td key={col - padRight + idx} />
        ))}
      </tr>
    ))}
  </>
);
