import React, { ReactNode, useState } from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface TooltipProps {
  tip: ReactNode;
  verticalAlign?: "above" | "below";
  horizontalAlign?: "left" | "right" | "center";
  children: ReactNode;
}

export const Tooltip = ({
  tip,
  verticalAlign = "above",
  horizontalAlign = "center",
  children,
}: TooltipProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      <div
        className={classnames(
          "w-max" as any,
          "max-w-sm",
          "bg-navy-400",
          "rounded-lg",
          "absolute",
          "z-20",
          "origin-center",
          "transform" as any,
          `translate-y-${visible ? "0" : "1"}`,
          "text-white",
          "shadow-lg",
          "transition",
          "duration-1000",
          {
            "left-1/2": horizontalAlign === "center",
            "-translate-x-1/2": horizontalAlign === "center",
            "left-0": horizontalAlign === "left",
            "right-0": horizontalAlign === "right",
            "top-8": verticalAlign === "below",
            "bottom-8": verticalAlign === "above",
            visible: visible,
            "ease-out": visible,
            "ease-in": !visible,
            invisible: !visible,
          },
          `opacity-${visible ? 100 : 0}`
        )}
      >
        <div
          className={classnames(
            "text-navy-400",
            "absolute",
            "c-triangle-up" as any,
            {
              "-top-1.5": verticalAlign === "below",
              "-bottom-1.5": verticalAlign === "above",
              "rotate-180": verticalAlign === "above",
              "left-1/2": horizontalAlign === "center",
              "-translate-x-1/2": horizontalAlign === "center",
              "left-6": horizontalAlign === "left",
              "right-6": horizontalAlign === "right",
            }
          )}
        />
        <div className="my-3 mx-6">{tip}</div>
      </div>
    </div>
  );
};
