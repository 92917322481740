import React, { HTMLAttributes, useEffect, useState } from "react";
import { usePrevious } from "react-use";

import { Loading } from "@components/atoms";
import {
  classnames,
  TArg,
  THeight,
  TWidth,
} from "@external/tailwindcss-classnames";

import { Cell } from "./Cell";
import { Error } from "./Error";
import { NoResults } from "./NoResults";
import { Row } from "./Row";

export interface TableProps extends HTMLAttributes<HTMLTableElement> {
  columns: {
    title?: string;
    width: Extract<
      TWidth,
      | "w-1/12"
      | "w-2/12"
      | "w-3/12"
      | "w-4/12"
      | "w-5/12"
      | "w-6/12"
      | "w-7/12"
      | "w-8/12"
      | "w-9/12"
      | "w-10/12"
      | "w-11/12"
      | "w-12/12"
      | "w-full"
    >;
  }[];
  align?: "middle" | "top";
  compact?: boolean;
  rounded?: boolean;
  loading?: boolean;
  loadingRows?: number;
  loadingRowsHeight?: THeight;
  emptyComponent?: JSX.Element;
  error?: boolean;
  errorMessage?: string;
  errorComponent?: JSX.Element;
  children: JSX.Element | JSX.Element[] | null;
  className?: string;
}

export const Table = ({
  columns = [],
  compact = false,
  rounded = false,
  loading = false,
  loadingRows = 3,
  loadingRowsHeight = "h-12",
  emptyComponent = <NoResults />,
  error = false,
  errorMessage,
  errorComponent,
  align = "middle",
  className,
  children,
  ...rest
}: TableProps) => {
  const [initialized, setInitialized] = useState(false);
  const previousChildren = usePrevious(children);
  const empty = React.Children.count(children) === 0;
  const actualErrorComponent = errorComponent || (
    <Error message={errorMessage} />
  );

  useEffect(() => {
    if (initialized) {
      return;
    }

    if ((!loading && !empty) || (!loading && !previousChildren)) {
      setInitialized(true);
    }
  }, [initialized, setInitialized, loading, empty, previousChildren]);

  return (
    <table
      {...rest}
      className={classnames(
        className as TArg,
        "min-w-full",
        "table-auto",
        "filter",
        {
          ["compact" as any]: compact,
          ["table-rounded" as any]: rounded,
        }
      )}
    >
      <thead className="text-h8 text-left font-bold uppercase h-10 text-black-300 bg-lightGrey-200">
        <tr>
          {columns.map((column, i) => (
            <th
              key={i}
              className={classnames(
                column.width,
                compact || i !== 0 ? "pl-5" : "pl-10",
                "pr-5"
              )}
            >
              {i !== columns.length - 1 ? (
                column.title
              ) : (
                <div className="flex flex-row items-center justify-between">
                  {column.title}
                  <Loading.Slide
                    data-testid="loading-slide"
                    show={(!!previousChildren || empty) && loading}
                    className="ml-2"
                  />
                </div>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody
        className={`bg-white ${
          align === "middle" ? "align-middle" : "align-top"
        }`}
      >
        {loading && !initialized ? (
          new Array(loadingRows).fill(null).map((_, i) => (
            <Row
              key={i}
              data-testid="loading-row"
              hover={false}
              className={classnames(loadingRowsHeight, "align-middle")}
            >
              {columns.map((_, i) => (
                <Cell key={i}>
                  <Loading.Shimmer />
                </Cell>
              ))}
            </Row>
          ))
        ) : loading && previousChildren ? (
          previousChildren
        ) : error ? (
          <Row hover={false} className="align-middle">
            <Cell colSpan={columns.length} className="h-60">
              {actualErrorComponent}
            </Cell>
          </Row>
        ) : empty ? (
          <Row hover={false} className="align-middle">
            <Cell colSpan={columns.length} className="h-60">
              {emptyComponent}
            </Cell>
          </Row>
        ) : (
          children
        )}
      </tbody>
    </table>
  );
};
