import React from "react";

import {
  StatusLabel,
  StatusLabelColor,
  StatusLabelProps,
} from "../../StatusLabel";

import { LabelActive } from "./LabelActive";
import { LabelDraft } from "./LabelDraft";
import { LabelFailed } from "./LabelFailed";
import { LabelProcessing } from "./LabelProcessing";
import * as satisfiability from "./satisfiability";

interface Props {
  redshiftQueryStatus?: string | null;
  status: string | null;
}

type SegmentJobStatusProps = Props & Omit<StatusLabelProps, "color">;

export const Label = (props: SegmentJobStatusProps) => {
  if (satisfiability.isFailed(props)) {
    return <LabelFailed {...props} />;
  }

  if (satisfiability.isProcessing(props)) {
    return <LabelProcessing {...props} />;
  }

  if (satisfiability.isSuccessful(props)) {
    return <LabelActive {...props} />;
  }

  if (satisfiability.isDraft(props)) {
    return <LabelDraft {...props} />;
  }

  return (
    <StatusLabel color={StatusLabelColor.Red} {...props}>
      Unknown
    </StatusLabel>
  );
};
