import React, { HTMLAttributes } from "react";

import { classnames, TArg } from "@external/tailwindcss-classnames";

export interface CellProps
  extends Omit<HTMLAttributes<HTMLTableCellElement>, "className"> {
  colSpan?: number;
  rowSpan?: number;
  children?: React.ReactNode;
  className?: string;
}

export const Cell = ({ children, className, ...rest }: CellProps) => (
  <td {...rest} className={classnames(className as TArg, "py-2", "px-5")}>
    {children}
  </td>
);
