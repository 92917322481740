import React, {
  ForwardRefExoticComponent,
  HTMLAttributes,
  RefAttributes,
} from "react";

import { classnames } from "@external/tailwindcss-classnames";

type ButtonColor = "primary" | "default" | "clear" | "danger";
type ButtonVariant = "contained" | "text";
export type ButtonSize = "tight" | "small" | "medium" | "large" | "filter";
type ButtonFontWeight =
  | "thin"
  | "extralight"
  | "light"
  | "normal"
  | "medium"
  | "semibold"
  | "bold"
  | "extrabold"
  | "black";

const createButtonTextStyle = (
  color: ButtonColor,
  fontWeight: ButtonFontWeight
) => {
  return {
    [classnames(
      "text-black-300",
      "hover:underline",
      "active:text-black-400",
      `font-${fontWeight}` as any
    )]: color !== "clear",
  };
};

const createButtonContainedStyle = (
  color: ButtonColor,
  active: boolean,
  fontWeight: ButtonFontWeight
) => {
  const defaultStyles = classnames(
    "rounded-lg",
    "disabled:bg-coolGrey-200",
    "disabled:text-black-300",
    "disabled:text-opacity-38",
    "disabled:cursor-not-allowed",
    `font-${fontWeight}` as any
  );

  switch (color) {
    case "primary":
      return classnames(
        defaultStyles,
        "text-white",
        "bg-blue-300",

        "hover:bg-opacity-92",

        "active:bg-opacity-75",
        "active:ring-3",
        "active:ring-blue-300",
        "active:ring-opacity-24",
        {
          [classnames("ring-3", "ring-blue-300", "ring-opacity-24")]: active,
        }
      );
    case "danger":
      return classnames(
        defaultStyles,
        "text-white",
        "bg-red-300",

        "hover:bg-opacity-92",

        "active:bg-opacity-75",
        "active:ring-3",
        "active:ring-red-300",
        "active:ring-opacity-24",
        {
          [classnames("ring-3", "ring-red-300", "ring-opacity-24")]: active,
        }
      );
    case "clear":
      return classnames(defaultStyles, "text-white");
    case "default":
      return classnames(
        defaultStyles,
        "border-navy-100",
        "border",
        "bg-white",
        "text-black-300",

        "hover:border-navy-100",
        "hover:border-black-300",

        "disabled:border-0",

        "active:ring-3",
        "active:ring-blue-300",
        "active:ring-opacity-24",
        {
          [classnames("ring-3", "ring-blue-300", "ring-opacity-24")]: active,
        }
      );
  }
};

const createButtonStyle = (
  variant: ButtonVariant,
  color: ButtonColor,
  active: boolean,
  size: ButtonSize,
  fontWeight: ButtonFontWeight
) => {
  const defaultStyles = classnames(
    "text-h7",
    "font-secondary",
    "leading-btn",
    "inline-flex",
    "flex-row",
    "items-center",

    "transition",
    "ease-in-out",
    "duration-100"
  );

  const sizes = {
    "px-3": size === "filter",
    "px-4": size === "small" || size === "medium",
    "px-6": size === "large",
    "h-8": size === "small",
    "h-10": size === "medium" || size === "filter",
    "h-14": size === "large",
  };

  const buttonStyle = (() => {
    switch (variant) {
      case "text":
        return createButtonTextStyle(color, fontWeight);
      case "contained":
        return createButtonContainedStyle(color, active, fontWeight);
    }
  })();

  return classnames(defaultStyles, buttonStyle, sizes);
};

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  color?: ButtonColor;
  size?: ButtonSize;
  variant?: ButtonVariant;
  disabled?: boolean;
  icon?: React.ReactNode;
  className?: any;
  type?: "submit" | "reset" | "button" | undefined;
  active?: boolean;
  fontWeight?: ButtonFontWeight;
}

export const Button: ForwardRefExoticComponent<
  ButtonProps & RefAttributes<HTMLButtonElement>
> = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      color = "default",
      size = "tight",
      variant = "contained",
      fontWeight = "semibold",
      disabled = false,
      icon,
      className,
      type,
      active = false,
      ...rest
    },
    ref
  ) => (
    <button
      type={type}
      ref={ref}
      className={classnames(
        className,
        createButtonStyle(variant, color, active, size, fontWeight)
      )}
      disabled={disabled}
      {...rest}
    >
      {icon}
      {children}
    </button>
  )
);
