import React from "react";

import { Checkbox } from "@components/atoms";

export enum TableColumns {
  All = "All",
  Custom = "Custom",
}

export type TableColumnsSelection =
  | [TableColumns.All]
  | [TableColumns.Custom, ...string[]];

export interface CustomProps {
  checkboxes: { [key: string]: string };
  onChange: (e: {
    checked: boolean;
    key: string;
    value: string;
  }) => Promise<void> | void;
}

export const Custom = ({ checkboxes, onChange }: CustomProps) => (
  <>
    {Object.entries(checkboxes).map(([key, value]) => (
      <Checkbox
        key={key}
        value={key}
        onChange={(e) => onChange({ checked: e.target.checked, key, value })}
      >
        {value}
      </Checkbox>
    ))}
  </>
);
