import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const ChatAlt = createSvgIcon({
  content: (
    <path
      d="M8.66667 11.3334L4.66667 14V11.3334H2V2.66669H14V11.3334H8.66667Z"
      className="stroke-current"
      strokeLinecap="square"
    />
  ),
});
