export type TimeRange = {
  start: string;
  end: string;
};

export const secondsToTimeRange = (
  seconds: number,
  range: number = 30
): TimeRange => {
  let start = "00:00:00.000";
  let end = new Date(seconds * 1000).toISOString().substr(11, 12);

  if (seconds >= range) {
    start = new Date((seconds - range) * 1000).toISOString().substr(11, 12);
  }

  return {
    start,
    end,
  };
};
