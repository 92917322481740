import React from "react";

import { Loading, Segment } from "@components/atoms";

export type PreparingProps = {
  operation: Segment.OperationOptions;
};

const description: { [key in Segment.OperationOptions]: string } = {
  [Segment.OperationOptions.Create]: "Creating Segment...",
  [Segment.OperationOptions.Edit]: "Updating Segment...",
  [Segment.OperationOptions.Draft]: "Updating Segment...",
};

export const Preparing = ({ operation }: PreparingProps) => (
  <div className="flex flex-col items-center mt-12 mb-20 text-black-100">
    <Loading.Slide className="mb-6 self-center" />
    {description[operation]}
  </div>
);
