import React, { useState } from "react";

import { FormSelect } from "@components/molecules";

import * as Choices from "./Choices";

export enum TableColumns {
  All = "All",
  Custom = "Custom",
}

export type TableColumnsSelection =
  | [TableColumns.All]
  | [TableColumns.Custom, ...string[]];

export interface TableColumnsSelectorProps {
  selectValues?: Partial<Record<TableColumns, string>>;
  selectableFields: { [key: string]: string };
  onChange: (value: TableColumnsSelection) => Promise<void> | void;
}

export const TableColumnsSelector = ({
  selectValues,
  selectableFields,
  onChange,
}: TableColumnsSelectorProps) => {
  const [state, setState] = useState<
    | { type: TableColumns.All }
    | {
        type: TableColumns.Custom;
        values: { [key: string]: boolean };
      }
  >({ type: TableColumns.All });

  const onFormSelectChange = (v: string) => {
    if (v === TableColumns.All) {
      setState({ type: TableColumns.All });
      onChange([TableColumns.All]);
    } else if (v === TableColumns.Custom) {
      setState({
        type: TableColumns.Custom,
        values: {},
      });
      onChange([TableColumns.Custom]);
    }
  };

  const onCustomChoiceChange = ({
    key,
    checked,
  }: {
    key: string;
    checked: boolean;
  }) => {
    if (state.type === TableColumns.Custom) {
      const update: {
        type: TableColumns.Custom;
        values: { [key: string]: boolean };
      } = {
        ...state,
        values: { ...state.values, [key]: checked },
      };
      setState(update);

      onChange([
        TableColumns.Custom,
        ...Object.entries(update.values)
          .filter(([_, v]) => v)
          .map(([k]) => k),
      ]);
    }
  };

  return (
    <>
      <FormSelect
        label="Table Columns"
        className="w-full mt-7"
        onChange={onFormSelectChange}
        value={state.type}
        options={{
          [TableColumns.All]: `Current (${
            Object.keys(selectableFields).length
          })`,
          [TableColumns.Custom]: "Custom",
          ...selectValues,
        }}
      />
      <div className="space-y-3 mt-3">
        {(() => {
          if (state.type === TableColumns.All) {
            return <Choices.All values={selectableFields} />;
          }
          if (state.type === TableColumns.Custom) {
            return (
              <Choices.Custom
                checkboxes={selectableFields}
                onChange={onCustomChoiceChange}
              />
            );
          }
        })()}
      </div>
    </>
  );
};
