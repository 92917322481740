import React, { useEffect, useState } from "react";

import { Dropdown, Icon } from "@components/atoms";
import { useAuth } from "@hooks/useAuth";
import * as truncate from "@utils/truncate";

import { IconButton } from "./IconButton";

export interface AvatarProps {
  className?: any;
}

export interface Identity {
  userId?: string;
}

export interface JwtToken {
  identities?: Identity[];
}

export const Avatar = ({ className }: AvatarProps) => {
  const { signOut, getSession } = useAuth();
  const [name, setName] = useState("-");

  useEffect(() => {
    getSession().then((session) => {
      const decoded = session?.getIdToken().decodePayload();
      const name =
        decoded?.identities && decoded.identities.length > 0
          ? decoded.identities[0].userId || "-"
          : "-";

      if (name) {
        setName(name);
      }
    });
  }, [getSession]);

  return (
    <Dropdown.Container
      className="z-1"
      button={
        <IconButton className={className}>
          <Icon.Avatar />
        </IconButton>
      }
    >
      <Dropdown.Items className="w-50 text-black-300 divide-y divide-coolGrey-200">
        <div className="text-h7 font-semibold pt-4 px-4 pb-6">
          {truncate.email(name, 23, "...")}
        </div>
        <div className="p-2">
          <Dropdown.Item
            size="tight"
            className="text-black-300 rounded-md text-h7 p-2"
            icon={<Icon.Exit color="coolGrey-400" className="mr-2" />}
            onClick={() => signOut()}
          >
            Log Out
          </Dropdown.Item>
        </div>
      </Dropdown.Items>
    </Dropdown.Container>
  );
};
