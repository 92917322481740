import React, { useState } from "react";
import { Listbox } from "@headlessui/react";

import { classnames } from "@external/tailwindcss-classnames";

import * as Icon from "../Icon";

export type Unit = "sec" | "min";

export interface DurationInputProps {
  onChange: (unit: Unit, value: number | undefined) => void;
  min?: number;
  className?: any;
  value?: number;
  hasError?: boolean;
  unit?: Unit;
}

export const DurationInput = ({
  onChange,
  min = 0,
  className,
  value,
  hasError,
  unit = "min",
  ...rest
}: DurationInputProps) => {
  const [selectedUnit, setSelectedUnit] = useState<Unit>(unit);

  const handleChange = (unit: Unit) => {
    setSelectedUnit(unit);
    onChange(unit, value);
  };

  const onValueChange = (e: any) => {
    if (e.target.value === "" || e.target.value === undefined) {
      onChange(selectedUnit, undefined);
      return;
    }

    const parsedValue = parseInt(e.target.value);
    onChange(selectedUnit, parsedValue);
  };

  const inputClassName = classnames(
    "outline-none",
    "leading-input",
    "w-full",
    "py-2",
    "px-smx",
    "h-10",
    "disabled:bg-coolGrey-200",
    "disabled:text-black-100",
    "disabled:cursor-not-allowed",

    "border",
    "text-black-300",

    "focus:bg-white",
    "focus:ring-opacity-16",
    "focus:ring-3",
    "focus:border-opacity-0",
    "focus:ring-blue-300",

    "placeholder-black-100",

    "hover:bg-navy-300",
    "hover:bg-opacity-4",

    "rounded-tl-lg",
    "rounded-bl-lg",
    "transition",
    "duration-150",
    "ease-in-out",
    {
      "border-navy-100": !hasError,
      "border-red-300": hasError,
      "focus:ring-red-300": hasError,
    }
  );

  return (
    <div className={classnames("flex", "flex-row", className)}>
      <input
        min={min}
        type="number"
        className={inputClassName}
        onChange={onValueChange}
        value={value || ""}
        {...rest}
      />
      <Listbox value={selectedUnit} onChange={handleChange}>
        {({ open }) => (
          <div className="relative w-20">
            <Listbox.Button
              className={classnames(
                "font-semibold",
                "rounded-tr-lg",
                "rounded-br-lg",
                "text-black-300",
                "px-3",
                "py-2",
                "h-10",
                "w-20",
                "hover:bg-navy-300",
                "hover:bg-opacity-4",
                {
                  border: !open,
                  "border-l-0": !open,
                  "border-navy-100": !open && !hasError,
                  "border-3": open,
                  "border-blue-300": open && !hasError,
                  "border-opacity-16": open,
                  "border-red-300": !open && hasError,
                }
              )}
            >
              <div className="flex flex-row items-center">
                <p className="font-normal text-base">
                  {selectedUnit === "sec" ? "Sec" : "Min"}
                </p>
                <Icon.Sorting color="black-300" className="ml-2" />
              </div>
            </Listbox.Button>
            <Listbox.Options
              className={classnames(
                "left-0",
                "shadow-lg",
                "text-sm",
                "mt-2",
                "origin-top-right",
                "absolute",
                "font-semibold",
                "rounded-lg",
                "z-10",
                "border",
                "border-navy-100",
                "text-black-300",
                "w-20",
                "bg-white"
              )}
            >
              <div className="p-2 hover:bg-navy-300 hover:bg-opacity-4 rounder-lg">
                <Listbox.Option
                  key="sec"
                  value="sec"
                  className="h-10 px-1 flex items-center cursor-pointer font-normal text-base"
                >
                  Sec
                </Listbox.Option>
                <Listbox.Option
                  key="min"
                  value="min"
                  className="h-10 px-1 flex items-center cursor-pointer font-normal text-base"
                >
                  Min
                </Listbox.Option>
              </div>
            </Listbox.Options>
          </div>
        )}
      </Listbox>
    </div>
  );
};
