import React, { type ReactNode } from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface ContainerProps {
  className?: any;
  children: ReactNode;
}

export const Container = ({ className, children }: ContainerProps) => (
  <div
    className={classnames(
      className,
      "flex",
      "flex-row",
      "items-center",
      "justify-center"
    )}
  >
    {children}
  </div>
);
