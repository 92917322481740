import React from "react";

import { TreeNode } from "../model";
import { TreeViewItemHeader } from "../TreeViewItemHeader";

const TreeViewItemChildTree = ({ nodes }: { nodes: TreeNode[] }) => {
  return (
    <div className="ml-6">
      {nodes
        .filter((node) => node.isVisible && !node.isVirtuallyRemoved)
        .map((node) => (
          <TreeViewItem key={node.id} node={node} />
        ))}
    </div>
  );
};

export const TreeViewItem = ({ node }: { node: TreeNode }) => {
  return (
    <>
      <TreeViewItemHeader
        data-e2e={`treeview-item-${node.id.replace(/\s/g, "-")}`}
        node={node}
        selectionState={node.selectionState}
      />
      {node.isOpen && <TreeViewItemChildTree nodes={node.children || []} />}
    </>
  );
};
