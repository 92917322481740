import React, { HTMLAttributes } from "react";

import { createSvgIcon } from "./createSvgIcon";

export interface CombinationMarkStagingProps
  extends HTMLAttributes<HTMLOrSVGElement> {}

export const CombinationMarkStaging = createSvgIcon({
  content: (
    <>
      <path
        d="M41.2589 2.12891L48.8115 17.8315H46.0592L44.7151 14.7593H37.5251L36.1811 17.8315H33.5142L41.0242 2.12891H41.2589ZM40.1922 8.80667L38.4853 12.6043H43.7764L42.091 8.8281L41.1735 6.67323H41.1308L40.1922 8.80667Z"
        className="fill-current"
      />
      <path
        d="M56.1081 4.23926H52.0972V2.14844H62.5301V4.23926H58.4764V17.8296H56.1081V4.23926Z"
        className="fill-current"
      />
      <path
        d="M69.1011 2.14844H71.512V15.6962H76.0137V17.8298H69.1011V2.14844Z"
        className="fill-current"
      />
      <path
        d="M88.3243 2.12891L95.8769 17.8315H93.1247L91.7805 14.7593H84.5906L83.2465 17.8315H80.5796L88.0896 2.12891H88.3243ZM87.2575 8.80667L85.5506 12.6043H90.8417L89.1563 8.8281L88.239 6.67323H88.1962L87.2575 8.80667Z"
        className="fill-current"
      />
      <path
        d="M102.533 17.5954C101.694 17.2967 101.089 16.9627 100.72 16.5926L101.85 14.5658C102.149 14.8362 102.615 15.1172 103.248 15.4086C103.881 15.7003 104.489 15.8459 105.072 15.8459C105.826 15.8459 106.441 15.6682 106.918 15.3126C107.394 14.9572 107.632 14.4664 107.632 13.8404C107.632 13.357 107.504 12.9373 107.248 12.5816C106.992 12.2262 106.679 11.9308 106.309 11.6962C105.939 11.4615 105.413 11.1735 104.731 10.8322C103.92 10.434 103.365 10.1354 103.066 9.93612C101.687 9.01172 100.997 7.73158 100.997 6.09583C100.997 4.74472 101.445 3.70996 102.341 2.99155C103.237 2.27339 104.368 1.91406 105.733 1.91406C107.198 1.91406 108.443 2.33371 109.467 3.17289L108.336 5.11434C108.066 4.82994 107.686 4.58469 107.195 4.37822C106.704 4.17222 106.182 4.06893 105.627 4.06893C104.916 4.06893 104.357 4.22896 103.952 4.54902C103.547 4.86896 103.344 5.342 103.344 5.96776C103.344 6.40872 103.472 6.80347 103.728 7.15178C103.984 7.50057 104.307 7.80256 104.699 8.05859C105.09 8.31449 105.62 8.62762 106.288 8.99735C106.928 9.35297 107.419 9.63413 107.761 9.84C108.102 10.0464 108.422 10.2917 108.721 10.5761C109.133 10.9462 109.467 11.3868 109.723 11.8989C109.979 12.4109 110.107 12.973 110.107 13.5843C110.107 14.5374 109.89 15.3481 109.457 16.0165C109.023 16.6852 108.425 17.19 107.665 17.5312C106.903 17.8726 106.039 18.0433 105.072 18.0433C104.219 18.0434 103.372 17.894 102.533 17.5954Z"
        className="fill-current"
      />
      <path
        d="M10.771 10.8031L6.35725 8.57267L10.0473 12.4977L6.8397 20H0L8.54962 0L11.9695 8.00015L10.771 10.8031Z"
        className="fill-current"
      />
      <path
        d="M17.0988 20.0016L10.0469 12.4993L10.7705 10.8047L13.827 12.3482L14.5339 14.0016L17.0988 20.0016Z"
        className="fill-current"
      />
      <path
        d="M14.986 0.610687L23.0134 19.3893H17.502L15.0956 13.76L14.3887 12.1066L12.5307 7.76L9.47451 0.610687H14.986ZM15.389 0H8.54932L11.9692 8L13.8272 12.3466L14.534 14L17.0989 20H23.9386L15.389 0Z"
        className="fill-current"
      />
      <path
        d="M120.63 16.6569C121.246 17.6369 122.38 18.1269 124.004 18.1269C126.216 18.1269 127.434 17.1189 127.434 15.2989C127.434 14.3609 127.112 13.6609 126.496 13.2409C126.006 12.9049 125.768 12.8069 124.424 12.4849C122.716 12.0509 122.128 11.5469 122.128 10.5249C122.128 9.47491 122.842 8.84491 124.018 8.84491C124.942 8.84491 125.656 9.15291 126.062 9.71291L126.944 9.13891C126.412 8.32691 125.404 7.87891 124.06 7.87891C122.142 7.87891 120.994 8.88691 120.994 10.5949C120.994 12.1209 121.792 12.9189 123.85 13.4369C125.32 13.8009 125.32 13.8009 125.698 14.0669C126.076 14.3329 126.3 14.8089 126.3 15.3549C126.3 16.4889 125.46 17.1469 124.004 17.1469C122.828 17.1469 121.96 16.7689 121.54 16.0689L120.63 16.6569Z"
        className="fill-current"
      />
      <path
        d="M129.605 11.8969V15.6629C129.605 17.5529 130.053 18.0009 131.887 18.0009H132.139V17.0629H131.901C130.991 17.0769 130.711 16.7689 130.711 15.7749V11.8969H132.097V10.9729H130.711V8.71891H129.661L129.605 10.9729H128.751V11.8969H129.605Z"
        className="fill-current"
      />
      <path
        d="M138.111 13.8709H136.417C134.415 13.8709 133.743 14.4169 133.743 16.0409C133.743 17.5669 134.401 18.1269 136.207 18.1269C137.271 18.1269 137.803 17.9589 138.251 17.4969L138.335 18.0009H139.217V13.5489C139.217 12.6809 139.147 12.2609 138.923 11.8549C138.531 11.1829 137.761 10.8329 136.669 10.8329C135.563 10.8329 134.723 11.1829 134.121 11.8829L134.891 12.4709C135.297 12.0089 135.885 11.7569 136.571 11.7569C137.159 11.7569 137.621 11.9389 137.859 12.2609C138.041 12.5269 138.111 12.8069 138.111 13.3809V13.8709ZM138.111 14.7109V15.8029C138.111 16.3349 138.069 16.5449 137.915 16.7409C137.663 17.0629 137.229 17.2029 136.459 17.2029C135.213 17.2029 134.807 16.9089 134.807 16.0129C134.807 15.0749 135.283 14.7109 136.529 14.7109H138.111Z"
        className="fill-current"
      />
      <path
        d="M145.943 18.1129C145.901 19.6809 145.313 20.3809 144.011 20.3809C143.283 20.3809 142.835 20.1709 142.429 19.6669L141.603 20.1709C142.135 20.9409 142.891 21.2769 144.053 21.2769C146.055 21.2769 147.049 20.1989 147.049 18.0009V10.9729H146.181L146.097 11.6029C145.607 11.0569 144.991 10.8329 144.039 10.8329C142.205 10.8329 141.379 11.8129 141.379 13.9549V15.0189C141.379 17.1469 142.205 18.1269 144.011 18.1269C144.907 18.1269 145.495 17.9309 145.957 17.4549L145.943 18.1129ZM142.485 14.0249C142.485 12.4849 143.031 11.7569 144.193 11.7569C145.355 11.7569 145.943 12.4849 145.943 13.9129V15.0609C145.943 16.4889 145.355 17.2029 144.193 17.2029C143.031 17.2029 142.485 16.4749 142.485 14.9489V14.0249Z"
        className="fill-current"
      />
      <path
        d="M150.374 14.7109H154.966V14.2069C154.966 11.8829 154.084 10.8329 152.11 10.8329C150.136 10.8329 149.254 11.8829 149.254 14.2069V14.8089C149.254 17.0629 150.206 18.1269 152.222 18.1269C153.454 18.1269 154.21 17.8049 154.77 17.0489L153.944 16.5169C153.538 17.0069 153.076 17.2029 152.334 17.2029C151.06 17.2029 150.374 16.4329 150.374 15.0049V14.7109ZM150.374 13.8709V13.7589C150.374 13.2689 150.486 12.8069 150.668 12.4709C150.962 11.9669 151.396 11.7569 152.152 11.7569C152.81 11.7569 153.23 11.9389 153.524 12.3589C153.762 12.6949 153.902 13.2129 153.902 13.7589V13.8709H150.374Z"
        className="fill-current"
      />
    </>
  ),

  width: 155,
  height: 22,
});
