import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";

interface OptionsTransitionProps {
  children: React.ReactNode;
  direction?: "up" | "down";
}

export const OptionsTransition = ({
  children,
  direction,
}: OptionsTransitionProps) => (
  <Transition
    as={Fragment}
    enter="transition duration-300 ease-out"
    enterFrom={`transform ${
      direction === "up" ? "translate-y-2" : ""
    } opacity-0`}
    enterTo={`transform ${
      direction === "up" ? "translate-y-0" : ""
    } opacity-100`}
    leave="transition duration-100 ease-in"
    leaveFrom={`transform ${
      direction === "up" ? "translate-y-0" : ""
    } opacity-100`}
    leaveTo={`transform ${
      direction === "up" ? "-translate-y-2" : ""
    } opacity-0`}
  >
    {children}
  </Transition>
);
