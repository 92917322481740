import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Copy = createSvgIcon({
  content: (
    <>
      <path d="M20 7H8V21H20V7Z" strokeWidth="1.5" strokeLinecap="square" />
      <path d="M16 3H4V17" strokeWidth="1.5" strokeLinecap="square" />
    </>
  ),
  width: 24,
  height: 24,
  defaultClassName: "stroke-current",
});
