import React, { useState } from "react";

import { DurationInput, Unit } from "@components/atoms";

import { FilterType, useFilters } from "../../hooks";

const convertToMilliSeconds = (value: number, unit: Unit) => {
  if (unit === "sec") {
    return value * 1000;
  } else {
    return value * 60 * 1000;
  }
};

const convertToUnit = (value: number | undefined, unit: Unit) => {
  if (unit === "sec") {
    return value ? value / 1000 : undefined;
  } else {
    return value ? value / (60 * 1000) : undefined;
  }
};

export const VideoDuration = () => {
  const context = useFilters();
  const [minUnit, setMinUnit] = useState<Unit>("min");
  const [maxUnit, setMaxUnit] = useState<Unit>("min");

  const durationFilter = context.getFilter(FilterType.DURATION__MIN_MAX) || {
    filterType: FilterType.DURATION__MIN_MAX,
  };

  const onMinChange = (unit: Unit, value?: number) => {
    setMinUnit(unit);
    if (value === undefined) {
      if (durationFilter?.max) {
        context.addFilter({
          ...durationFilter,
          min: undefined,
        });
      } else {
        context.removeFilter(FilterType.DURATION__MIN_MAX);
      }
    } else {
      context.addFilter({
        ...durationFilter,
        min: convertToMilliSeconds(value, unit),
      });
    }
  };

  const onMaxChange = (unit: Unit, value?: number) => {
    setMaxUnit(unit);
    if (value === undefined) {
      if (durationFilter?.min) {
        context.addFilter({
          ...durationFilter,
          max: undefined,
        });
      } else {
        context.removeFilter(FilterType.DURATION__MIN_MAX);
      }
    } else {
      context.addFilter({
        ...durationFilter,
        max: convertToMilliSeconds(value, unit),
      });
    }
  };

  return (
    <>
      <div>
        <label className="font-semibold text-xs">Min</label>
        <DurationInput
          data-testid="min"
          value={convertToUnit(durationFilter.min, minUnit)}
          onChange={onMinChange}
        />
      </div>
      <div className="mt-4">
        <label className="font-semibold text-xs">Max</label>
        <DurationInput
          data-testid="max"
          value={convertToUnit(durationFilter.max, maxUnit)}
          onChange={onMaxChange}
        />
      </div>
    </>
  );
};
