import React from "react";

import { Icon } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

export interface FilterGroupProps {
  title: string;
  isOpen?: boolean;
  isSelected?: boolean;
  onClick: () => void;
  tabIndex?: number;
  children?: React.ReactNode;
}

export const FilterGroup = ({
  title,
  isOpen = false,
  isSelected = false,
  tabIndex,
  onClick,
  children,
}: FilterGroupProps) => (
  <div>
    <div
      className={classnames(
        "h-12",
        "px-6",
        "border-b",
        "hover:bg-navy-300",
        "hover:bg-opacity-4",
        "active:bg-blue-300",
        "active:bg-opacity-16",
        "border-coolGrey-200",
        "flex",
        "flex-row",
        "items-center",
        "justify-between",
        "cursor-pointer",
        "transition-colors",
        "duration-100",
        "ease-in-out",
        { "bg-blue-200": isSelected, "bg-opacity-8": isSelected }
      )}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      <p className="flex items-center font-semibold text-sm text-black-300 mr-3">
        {title}
      </p>
      {isOpen ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
    </div>
    {isOpen && (
      <div className="px-6 py-2 border-b border-coolGrey-200">{children}</div>
    )}
  </div>
);
