import React from "react";

import { Button, Icon } from "@components/atoms";

export interface ErrorProps {
  onTryAgain: () => void | Promise<void>;
  onCancel: () => void | Promise<void>;
}

export const Error = ({ onTryAgain, onCancel }: ErrorProps) => (
  <>
    <div className="flex flex-col items-center">
      <Icon.Exclamation size={40} color="red-300" className="mb-10" />
    </div>
    <div className="mb-10 text-center">
      We're unable to create your Segment. If this problem persists, please
      contact support.
    </div>
    <div className="flex justify-between">
      <Button onClick={onCancel} type="button" variant="text">
        Cancel
      </Button>
      <Button onClick={onTryAgain} type="button" size="medium" color="primary">
        Try again
      </Button>
    </div>
  </>
);
