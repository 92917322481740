import React, {
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  RefAttributes,
} from "react";

import { classnames } from "@external/tailwindcss-classnames";

import styles from "./RadioButton.module.css";

export interface RadioButtonProps
  extends InputHTMLAttributes<HTMLInputElement> {
  className?: any;
}

export const RadioButton: ForwardRefExoticComponent<
  RadioButtonProps & RefAttributes<HTMLInputElement>
> = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ children, className, ...rest }, ref) => (
    <label
      className={classnames(
        className,
        "flex",
        "justify-center",
        "items-center"
      )}
    >
      <input
        ref={ref}
        type="radio"
        className={classnames(styles.input as any, "min-w-0", "h-4", "w-4")}
        {...rest}
      />
      <span className="ml-2 flex-grow">{children}</span>
    </label>
  )
);
