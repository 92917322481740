import React, { type ReactNode } from "react";

import { Icon } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

export interface ItemProps {
  showChevron?: boolean;
  children: ReactNode;
}

export const Item = ({ showChevron, children }: ItemProps) => (
  <li
    className={classnames("flex", "items-center", {
      "hover:underline": showChevron,
    })}
  >
    {children}
    {showChevron && (
      <Icon.ChevronRight color="black-200" className="w-3 h-3 mx-2" />
    )}
  </li>
);
