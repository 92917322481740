import React from "react";

import {
  StatusLabel,
  StatusLabelColor,
  StatusLabelProps,
} from "../../StatusLabel";

type LabelFailedProps = Omit<StatusLabelProps, "color">;

export const LabelFailed = (props: LabelFailedProps) => (
  <StatusLabel color={StatusLabelColor.Red} {...props}>
    Failed
  </StatusLabel>
);
