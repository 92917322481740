import { SelectionState } from "./types";

export class TreeNode {
  private _selectionState: SelectionState;
  private _children: TreeNode[] = [];
  private _isOpen: boolean;
  private _isVisible: boolean;
  private _isVirtuallyRemoved: boolean;

  constructor(
    readonly parent: TreeNode | undefined,
    readonly id: string,
    readonly name: string
  ) {
    this._selectionState = "off";
    this._isOpen = false;
    this._isVisible = true;
    this._isVirtuallyRemoved = false;
  }

  get children(): TreeNode[] {
    return this._children;
  }
  set children(value: TreeNode[]) {
    this._children = value;
  }

  get isOpen(): boolean {
    return this._isOpen;
  }
  set isOpen(value: boolean) {
    this._isOpen = value;
  }

  get isVirtuallyRemoved(): boolean {
    return this._isVirtuallyRemoved;
  }

  set isVirtuallyRemoved(value: boolean) {
    this._isVirtuallyRemoved = value;
  }

  get isVisible(): boolean {
    const hasAnyVisibleChildren = this._children.some((node) => node.isVisible);
    return (
      !this._isVirtuallyRemoved && (hasAnyVisibleChildren || this._isVisible)
    );
  }
  set isVisible(value: boolean) {
    this._isVisible = value;
  }

  get selectionState(): SelectionState {
    if (this._children.length > 0) {
      const allChildrenAreOn = this._children
        .filter((child) => child.isVisible && !child.isVirtuallyRemoved)
        .every((child) => child.selectionState === "on");

      if (allChildrenAreOn) {
        return "on";
      }

      const someChildrenAreOn = this._children
        .filter((child) => child.isVisible && !child.isVirtuallyRemoved)
        .some((child) => child.selectionState === "on");

      if (someChildrenAreOn) {
        return "indeterminate";
      }

      return "off";
    }

    return this._selectionState;
  }
  set selectionState(value: SelectionState) {
    this._selectionState = value;

    if (value === "on") {
      this._children
        .filter((child) => child.isVisible && !child.isVirtuallyRemoved)
        .forEach((child) => (child.selectionState = "on"));
    }

    if (value === "off") {
      this._children
        .filter((child) => child.isVisible && !child.isVirtuallyRemoved)
        .forEach((child) => (child.selectionState = "off"));
    }
  }
}
