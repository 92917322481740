export function tail(
  input: string,
  maxLength: number,
  separator: string
): string {
  if (input.length <= maxLength) {
    return input;
  }

  const sepLen = separator.length;
  const charsToShow = maxLength - sepLen;
  const frontChars = Math.ceil(charsToShow);

  return input.substr(0, frontChars) + separator;
}
