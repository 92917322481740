import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const MoonStars = createSvgIcon({
  content: (
    <>
      <path
        d="M8.00002 1.99996C8.08802 1.99996 8.17535 1.99996 8.26202 1.99996C7.40558 2.79577 6.85508 3.86653 6.70621 5.02611C6.55734 6.18569 6.81952 7.36078 7.44717 8.34712C8.07483 9.33345 9.02826 10.0687 10.1418 10.4249C11.2552 10.7812 12.4584 10.7361 13.542 10.2973C13.1252 11.3003 12.4439 12.1713 11.5708 12.8175C10.6977 13.4636 9.66565 13.8607 8.58462 13.9662C7.5036 14.0718 6.41419 13.8819 5.43261 13.4169C4.45103 12.9519 3.61409 12.2291 3.01108 11.3257C2.40806 10.4223 2.06158 9.37215 2.00861 8.28728C1.95563 7.2024 2.19814 6.12349 2.71026 5.16564C3.22239 4.20778 3.98492 3.40691 4.91653 2.84845C5.84813 2.28999 6.91385 1.99489 8.00002 1.99463V1.99996Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3333 2.66663C11.3333 3.02025 11.4738 3.35939 11.7239 3.60943C11.9739 3.85948 12.313 3.99996 12.6667 3.99996C12.313 3.99996 11.9739 4.14043 11.7239 4.39048C11.4738 4.64053 11.3333 4.97967 11.3333 5.33329C11.3333 4.97967 11.1929 4.64053 10.9428 4.39048C10.6928 4.14043 10.3536 3.99996 10 3.99996C10.3536 3.99996 10.6928 3.85948 10.9428 3.60943C11.1929 3.35939 11.3333 3.02025 11.3333 2.66663Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.6665 7.33329H13.9998M13.3332 6.66663V7.99996"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
});
