import React, { useRef } from "react";
import type { SliderProps } from "rc-slider";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import raf from "rc-util/lib/raf";

import "rc-slider/assets/index.css";
import "./RangeSlider.css";

const HandleTooltip = (props: {
  value: number;
  children: React.ReactElement;
  visible: boolean;
  tipFormatter?: (value: number) => React.ReactNode;
  getTooltipContainer?: (node: HTMLElement) => HTMLElement;
}) => {
  const {
    value,
    children,
    visible,
    tipFormatter = (val) => `${val}`,
    getTooltipContainer,
    ...restProps
  } = props;

  const tooltipRef = React.useRef<any>();
  const rafRef = React.useRef<number | null>(null);

  function cancelKeepAlign() {
    raf.cancel(rafRef.current!);
  }

  function keepAlign() {
    rafRef.current = raf(() => {
      tooltipRef.current?.forcePopupAlign();
    });
  }

  React.useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [value, visible]);

  return (
    <Tooltip
      placement="top"
      builtinPlacements={{
        top: {
          points: ["bc", "tc"],
          overflow: {
            adjustX: 1,
            adjustY: 1,
          },
          offset: [0, 0],
          targetOffset: [0, 0],
        },
      }}
      overlay={tipFormatter(value)}
      ref={tooltipRef}
      visible={visible}
      getTooltipContainer={getTooltipContainer}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

export const TooltipSlider = ({
  tipFormatter,
  tipProps,
  className,
  ...props
}: SliderProps & {
  tipFormatter?: (value: number) => React.ReactNode;
  tipProps?: any;
  className?: any;
}) => {
  const container = useRef<HTMLDivElement | null>(null);
  const tipHandleRender: SliderProps["handleRender"] = (node, handleProps) => (
    <>
      <HandleTooltip
        value={handleProps.value}
        visible={true}
        tipFormatter={tipFormatter}
        getTooltipContainer={() => container.current}
        {...tipProps}
      >
        {node}
      </HandleTooltip>
    </>
  );

  return (
    <div className={className}>
      <div className="relative h-6" ref={container} />
      <Slider {...props} handleRender={tipHandleRender} />
    </div>
  );
};
