import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const BarChart = createSvgIcon({
  content: (
    <>
      <path
        className="fill-current"
        d="M10.6833 20.5567C10.9929 20.5567 11.251 20.8148 11.251 21.1244V33.1503H5.49616V21.0986C5.49616 20.789 5.75422 20.5309 6.0639 20.5309H10.6833V20.5567ZM10.6833 18.4922H6.0639C4.61874 18.4922 3.43164 19.6535 3.43164 21.1244V35.2148H13.2897V21.0986C13.3155 19.6535 12.1284 18.4922 10.6833 18.4922Z"
      />
      <path
        className="fill-current"
        d="M22.3989 12.6563C22.786 12.6563 23.0957 12.966 23.0957 13.3531V33.1466H17.3409V13.3531C17.3409 12.966 17.6506 12.6563 18.0377 12.6563H22.3989ZM22.3989 10.5918H18.0377C16.5151 10.5918 15.2764 11.8305 15.2764 13.3531V35.2112H25.1344V13.3531C25.1344 11.8305 23.9215 10.5918 22.3989 10.5918Z"
      />
      <path
        className="fill-current"
        d="M34.2718 4.76178C34.6331 4.76178 34.917 5.04565 34.917 5.40694V33.1489H29.188V5.40694C29.188 5.04565 29.4718 4.76178 29.8331 4.76178H34.2718ZM34.2718 2.69727H29.8073C28.3106 2.69727 27.0977 3.91017 27.0977 5.40694V35.2134H36.9557V5.40694C36.9815 3.91017 35.7686 2.69727 34.2718 2.69727Z"
      />
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.03226 1.66602C1.60236 1.66602 2.06452 2.12817 2.06452 2.69827V37.537C2.06452 38.1071 1.60236 38.5692 1.03226 38.5692C0.462158 38.5692 0 38.1071 0 37.537V2.69827C0 2.12817 0.462158 1.66602 1.03226 1.66602Z"
      />
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 37.5381C0 36.968 0.471279 36.5059 1.05263 36.5059H38.9474C39.5287 36.5059 40 36.968 40 37.5381C40 38.1082 39.5287 38.5704 38.9474 38.5704H1.05263C0.471279 38.5704 0 38.1082 0 37.5381Z"
      />
    </>
  ),
  width: 40,
  height: 40,
  defaultColor: "navy-100",
});
