import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Link = createSvgIcon({
  content: (
    <>
      <path
        d="M6.00016 4.66683C5.11127 4.66683 4.66683 4.66683 4.66683 4.66683C2.82588 4.66683 1.3335 6.15921 1.3335 8.00016C1.3335 9.84111 2.82588 11.3335 4.66683 11.3335C4.66683 11.3335 5.11127 11.3335 6.00016 11.3335"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M5.3335 8H10.6668"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M9.99984 11.3332C10.8887 11.3332 11.3332 11.3332 11.3332 11.3332C13.1741 11.3332 14.6665 9.84079 14.6665 7.99984C14.6665 6.15889 13.1741 4.6665 11.3332 4.6665C11.3332 4.6665 10.8887 4.6665 9.99984 4.6665"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </>
  ),
  width: 16,
  height: 16,
});
