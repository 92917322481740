import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Sorting = createSvgIcon({
  content: (
    <>
      <path
        d="M5.3335 6.02404L8.00016 3.32318L10.6668 6.02404"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M10.6668 10.976L8.00016 13.6769L5.3335 10.976"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </>
  ),
  width: 16,
  height: 17,
});
