import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import FileSaver from "file-saver";
import Papa from "papaparse";

import { Button, Card, Checkbox } from "@components/atoms";
import { FileQueue, FileUpload } from "@components/molecules";
import { classnames } from "@external/tailwindcss-classnames";
import { useSegmentImport } from "@hooks/segments";

import { FiletypeError } from "./errors";
import { ExampleCsv, ExampleCsvType } from "./Example.csv";

const GET_SEGMENT_IMPORT = gql`
  query GetSegmentImport {
    segmentImport @client {
      rawData
      firstRowContainsHeader
      file {
        name
        size
      }
    }
  }
`;

export const ImportCsv = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [urlSearchParams] = useSearchParams();
  const { setFile, errors, setFirstRowContainsHeader } = useSegmentImport();

  const { data: segmentImportData, loading } = useQuery<{
    segmentImport?: {
      rawData: string[][];
      firstRowContainsHeader?: boolean;
      file?: {
        name: string;
        size: number;
      };
    };
  }>(GET_SEGMENT_IMPORT, {
    returnPartialData: true,
  });

  if (loading) {
    return null;
  }

  const returnTo = urlSearchParams.get("returnTo") || "/segments/new";

  const file = segmentImportData?.segmentImport?.file;
  const data = segmentImportData?.segmentImport?.rawData;
  const firstRowContainsHeader =
    segmentImportData?.segmentImport?.firstRowContainsHeader || false;
  const importError = FiletypeError(errors);

  const downloadCsvTemplate = () => {
    const example = Papa.unparse<ExampleCsvType>(ExampleCsv);

    const blob = new Blob([example], { type: "text/csv;charset=utf-8;" });

    FileSaver.saveAs(blob, "atlas-segment-example.csv");
  };

  return (
    <div className="w-169">
      <Card.Container className="p-10 mb-6 w-169">
        <h4 className="mb-8 text-navy-300">Import a .CSV</h4>
        <div className="mb-10 text-bodySm">
          Your CSV file must include columns for condition value, condition
          category, and operator and can also optionally include columns for
          confidence type, confidence value, and tolerance.{" "}
          <Button
            onClick={() => downloadCsvTemplate()}
            className="text-blue-300 active:text-blue-400"
            variant="text"
            fontWeight="normal"
          >
            Download a CSV template here.
          </Button>
        </div>
        {file ? (
          <FileQueue file={file} onDelete={async () => await setFile()} />
        ) : (
          <FileUpload onChange={async (file) => await setFile(file)} />
        )}
        {importError && (
          <div className="text-red-300 text-sm mt-2">
            Invalid file '{importError.filetype}' - please upload a 'text/csv'
            file
          </div>
        )}
        <Checkbox
          checked={firstRowContainsHeader}
          onChange={() => setFirstRowContainsHeader(!firstRowContainsHeader)}
          className="text-bodySm text-black-300 mt-4"
          data-e2e="convert-header-titles-checkbox"
        >
          First row contains header titles
        </Checkbox>
      </Card.Container>
      <div className="flex justify-between px-10 mb-6">
        <Button onClick={() => navigate(returnTo)} variant="text">
          Back
        </Button>
        <Button
          onClick={() =>
            navigate({
              pathname: "fields/1",
              search,
            })
          }
          className={classnames({ invisible: !data })}
          type="button"
          size="medium"
          color="primary"
          data-e2e="upload-continue-button"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
