import { Node } from "@components/molecules";

export const nodes: Node[] = [
  {
    id: "1",
    name: "Explicit Nudity",
    children: [
      { id: "1.1", name: "Nudity" },
      { id: "1.2", name: "Graphic male nudity" },
      { id: "1.3", name: "Graphic female nudity" },
      { id: "1.4", name: "Sexual activity" },
      { id: "1.5", name: "Illustrated explicit nudity" },
      { id: "1.6", name: "Adult toys" },
    ],
  },
  {
    id: "2",
    name: "Suggestive",
    children: [
      { id: "2.1", name: "Female swimwear or underwear" },
      { id: "2.2", name: "Male swimwear or underwear" },
      { id: "2.3", name: "Partial nudity" },
      { id: "2.4", name: "Barechested male" },
      { id: "2.5", name: "Revealing clothes" },
      { id: "2.6", name: "Sexual situations" },
    ],
  },
  {
    id: "3",
    name: "Violence",
    children: [
      { id: "3.1", name: "Graphic violence or gore" },
      { id: "3.2", name: "Physical violence" },
      { id: "3.3", name: "Weapon violence" },
      { id: "3.4", name: "Weapons" },
      { id: "3.5", name: "Self injury" },
    ],
  },
  {
    id: "4",
    name: "Visually disturbing",
    children: [
      { id: "4.1", name: "Emaciated bodies" },
      { id: "4.2", name: "Corpses" },
      { id: "4.3", name: "Hanging" },
      { id: "4.4", name: "Air crash" },
      { id: "4.5", name: "Explosions and blasts" },
    ],
  },
  {
    id: "5",
    name: "Rude gestures",
    children: [{ id: "5.1", name: "Middle finger" }],
  },
  {
    id: "6",
    name: "Drugs",
    children: [
      { id: "6.1", name: "Drug products" },
      { id: "6.2", name: "Drug use" },
      { id: "6.3", name: "Pills" },
      { id: "6.4", name: "Drug paraphernalia" },
    ],
  },
  {
    id: "7",
    name: "Tobacco",
    children: [
      { id: "7.1", name: "Tobacco products" },
      { id: "7.2", name: "Smoking" },
    ],
  },
  {
    id: "8",
    name: "Alcohol",
    children: [
      { id: "8.1", name: "Drinking" },
      { id: "8.2", name: "Alcoholic beverages" },
    ],
  },
  {
    id: "9",
    name: "Gambling",
    children: [{ id: "9.1", name: "Gambling" }],
  },
  {
    id: "10",
    name: "Hate symbols",
    children: [
      { id: "10.1", name: "Nazi party" },
      { id: "10.2", name: "White supremacy" },
      { id: "10.3", name: "Extremist" },
    ],
  },
];
