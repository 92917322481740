import React, { type ReactNode } from "react";

import { Icon } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

interface IconContainerProps {
  className?: any;
  children: ReactNode;
}

const IconContainer = ({ className, children }: IconContainerProps) => (
  <div className={classnames(className, "p-1", "rounded-sm")}>{children}</div>
);

export const TagIcon = () => (
  <IconContainer className="bg-blue-100">
    <Icon.Tag color="blue-300" />
  </IconContainer>
);

export const ChatAltIcon = () => (
  <IconContainer className="bg-orange-100">
    <Icon.ChatAlt color="orange-300" />
  </IconContainer>
);

export const StarIcon = () => (
  <IconContainer className="bg-navy-100">
    <Icon.Star color="navy-300" />
  </IconContainer>
);
