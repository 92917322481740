import React, { ReactNode } from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface ContainerProps {
  className?: any;
  connected?: boolean;
  children: ReactNode;
}

export const Container = ({
  className,
  connected,
  children,
}: ContainerProps) => (
  <div>
    <div className={classnames(className, "space-x-2", "flex", "flex-row")}>
      {children}
    </div>
    {connected && (
      <div className="border-b border-coolGrey-200 mx-3 -mt-0.25" />
    )}
  </div>
);
