import React from "react";

import { Time } from "@components/atoms/Time";
import { Table } from "@components/molecules";
import { ContentModerationTable_ContentModerationOccurrenceFragment } from "@graphql/operations";

import { InsightsTable, InsightsTableProps } from "../InsightsTables";

export type ContentModerationTableProps = {
  contentModerations?: ContentModerationTable_ContentModerationOccurrenceFragment[];
} & Pick<InsightsTableProps, "loading" | "loadingRows">;

// this component renders a table of ContentModeration
export const ContentModerationTable = ({
  contentModerations = [],
  loading,
  loadingRows,
}: ContentModerationTableProps) => (
  <InsightsTable
    columns={[
      { title: "Name", width: "w-2/12" },
      { title: "Parent name", width: "w-6/12" },
      { title: "Timestamp", width: "w-2/12" },
      { title: "Confidence", width: "w-2/12" },
    ]}
    loading={loading}
    loadingRows={loadingRows}
  >
    {contentModerations.length > 0
      ? contentModerations.map((contentModeration) => (
          <Table.Row
            key={`${contentModeration.VideoId}_${contentModeration.Timestamp}_${contentModeration.Name}`}
          >
            <Table.Cell className="font-semibold">
              {contentModeration.Name}
            </Table.Cell>
            <Table.Cell>{contentModeration.ParentName}</Table.Cell>
            <Table.Cell>
              <Time>{contentModeration.Timestamp}</Time>
            </Table.Cell>
            <Table.Cell>{contentModeration.Confidence}</Table.Cell>
          </Table.Row>
        ))
      : null}
  </InsightsTable>
);
