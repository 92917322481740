import React, { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { Button } from "@components/atoms";
import { SegmentsOverview_ListSegmentJobs_AggregateDocument } from "@graphql/operations";
import { useDebounce } from "@hooks/useDebounce";

import { SegmentJobStatusValue } from "./Sidebar/SegmentJobStatus/SegmentJobStatus";
import { ExportSegments } from "./ExportSegments";
import { SegmentJobTable } from "./SegmentJobTable";
import { Filters, filterTypes, Sidebar } from "./Sidebar";
import {
  getSearchParams,
  removeSearchParams,
  updateSearchParams,
} from "./utils";

export enum SegmentJobStatus {
  Active = "ACTIVE",
  Draft = "DRAFT",
  Processing = "PROCESSING",
  Failed = "FAILED",
}

const segmentJobStatusMapping: Record<SegmentJobStatusValue, SegmentJobStatus> =
  {
    [SegmentJobStatusValue.Active]: SegmentJobStatus.Active,
    [SegmentJobStatusValue.Draft]: SegmentJobStatus.Draft,
    [SegmentJobStatusValue.Failed]: SegmentJobStatus.Failed,
    [SegmentJobStatusValue.Processing]: SegmentJobStatus.Processing,
  };

export const SegmentsOverview = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = useMemo(
    () => getSearchParams<Filters>(searchParams, filterTypes),
    [searchParams]
  );
  const { data, loading } = useQuery(
    SegmentsOverview_ListSegmentJobs_AggregateDocument
  );

  const [searchValue, setSearchValue] = useState<string>();
  const debouncedSearchValue = useDebounce(searchValue, 300);

  const totalSegmentJobCount =
    data?.listSegmentJobs?.aggregate?.TotalSegmentJobCount || 0;
  const totalSegments = totalSegmentJobCount * (filters.type ? 1 : 2);

  const handleChange = (newFilters: Filters | null) => {
    let newSearchParams: URLSearchParams;

    if (newFilters === null) {
      newSearchParams = removeSearchParams(searchParams, filterTypes);
    } else {
      newSearchParams = updateSearchParams<Filters>(newFilters, searchParams);
    }

    setSearchParams(newSearchParams);
  };

  const statusFilters = (filters?.status || []).map(
    (status) => segmentJobStatusMapping[status]
  );

  return (
    <article className="flex-auto grid grid-cols-12 gap-x-6 pt-8 pb-10 px-8 bg-lightGrey-100">
      <Sidebar
        value={filters}
        searchValue={searchValue}
        onSearchValueChange={setSearchValue}
        onChange={(v) => {
          handleChange(v);
        }}
      />
      <div className="col-span-9">
        <div className="flex flex-row items-center justify-between mb-4 px-6">
          <div className="flex flex-row items-center justify-start gap-6">
            <h4 className="text-navy-300">{totalSegments || "-"} Segments</h4>
            <ExportSegments disabled={loading} />
          </div>
          <Button
            data-e2e="create-segment"
            onClick={() => navigate("/segments/new")}
            type="button"
            size="medium"
            color="primary"
            disabled={loading}
          >
            Create Segment
          </Button>
        </div>
        <SegmentJobTable
          type={filters.type || undefined}
          searchValue={debouncedSearchValue}
          minSegmentSize={filters.size?.min}
          maxSegmentSize={filters.size?.max}
          totalSegments={totalSegments}
          status={statusFilters}
          data-e2e="segments-overview-table"
          onClick={(id, version) =>
            navigate(`/segments/${id}/version/${version}`)
          }
        />
      </div>
    </article>
  );
};
