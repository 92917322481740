import React, {
  createContext,
  type ReactNode,
  useContext,
  useRef,
  useState,
} from "react";
import cloneDeep from "lodash/cloneDeep";

import {
  AdvancedSearchQuery,
  AdvancedSearchQueryIntrinsicsCombinators,
} from "@graphql/operations";

import { buildQueryFromFilters } from "./query-builder";
import {
  BusinessUnitFilter,
  CelebrityEqualsFilter,
  CelebrityLikeFilter,
  ContentModerationContainsFilter,
  ContentModerationExcludesFilter,
  Filter,
  FilterType,
  LabelContainsFilter,
  SentimentEqualsFilter,
  SpokenNounContainsFilter,
  TitleLikeFilter,
} from "./types";
import { DurationFilter } from ".";

export interface Filters {
  filters: FilterDict;
  previousFilter?: FilterDict;
  combinator: AdvancedSearchQueryIntrinsicsCombinators;
  queryFilter?: AdvancedSearchQuery;

  addFilter(filter: Filter): void;

  removeFilter(filterType: keyof FilterDict): void;

  reset(): void;

  applyFilters(): void;

  changeCombinator(combinator: AdvancedSearchQueryIntrinsicsCombinators): void;

  getFilter<T extends keyof FilterDict>(
    filterType: T
  ): FilterDict[T] | undefined;

  subscribeToReset(callback: () => void): () => void;
}

const FilterContext = createContext<Filters | null>(null);

export type FilterDict = {
  [FilterType.TITLE__LIKE]?: TitleLikeFilter;
  [FilterType.LABEL__CONTAINS]?: LabelContainsFilter;
  [FilterType.CELEBRITY__EQUALS]?: CelebrityEqualsFilter;
  [FilterType.CELEBRITY__LIKE]?: CelebrityLikeFilter;
  [FilterType.SENTIMENT__EQUALS]?: SentimentEqualsFilter;
  [FilterType.SPOKEN_NOUN__CONTAINS]?: SpokenNounContainsFilter;
  [FilterType.DURATION__MIN_MAX]?: DurationFilter;
  [FilterType.CONTENT_MODERATION_CONTAINS]?: ContentModerationContainsFilter;
  [FilterType.CONTENT_MODERATION_EXCLUDES]?: ContentModerationExcludesFilter;
  [FilterType.BUSINESS_UNIT]?: BusinessUnitFilter;
};

export interface FiltersProviderProps {
  filters?: FilterDict;
  previousFilter?: FilterDict;
  combinator?: AdvancedSearchQueryIntrinsicsCombinators;
  children?: ReactNode;
}

export const FiltersProvider = ({
  filters: defaultFilters,
  previousFilter: defaultPreviousFilter,
  combinator: defaultCombinator,
  children,
}: FiltersProviderProps) => {
  const [filters, setFilters] = useState<FilterDict>(defaultFilters || {});
  const [previousFilter, setPreviousFilter] = useState<FilterDict | undefined>(
    defaultPreviousFilter || undefined
  );
  const [combinator, setCombinator] =
    useState<AdvancedSearchQueryIntrinsicsCombinators>(
      defaultCombinator || AdvancedSearchQueryIntrinsicsCombinators.And
    );
  const [queryFilter, setQueryFilters] = useState<AdvancedSearchQuery>();
  const ref = useRef<{
    filters: FilterDict;
    combinator: AdvancedSearchQueryIntrinsicsCombinators;
    resetSubscriptions: (() => void)[];
  }>({ filters, combinator, resetSubscriptions: [] });

  function getFilter<T extends keyof FilterDict>(
    filterType: T
  ): FilterDict[T] | undefined {
    return ref.current.filters[filterType];
  }

  function addFilter(filter: Filter) {
    setFilters(
      (prev) =>
        (ref.current.filters = {
          ...prev,
          [filter.filterType]: filter,
        })
    );
  }

  function removeFilter(filterType: FilterType) {
    setFilters((prev) => {
      const obj = { ...prev };
      delete obj[filterType];
      return (ref.current.filters = obj);
    });
  }

  function reset() {
    setFilters((ref.current.filters = {}));
    setPreviousFilter({});
    setCombinator(
      (ref.current.combinator = AdvancedSearchQueryIntrinsicsCombinators.And)
    );
    ref.current.resetSubscriptions.forEach((callback) => callback());
    applyFilters();
  }

  function subscribeToReset(callback: () => void): () => void {
    ref.current.resetSubscriptions.push(callback);

    return () => {
      ref.current.resetSubscriptions = ref.current.resetSubscriptions.filter(
        (sub) => sub !== callback
      );
    };
  }

  function applyFilters() {
    const filtersList = Object.values(ref.current.filters).reduce<
      (
        | TitleLikeFilter
        | LabelContainsFilter
        | CelebrityEqualsFilter
        | CelebrityLikeFilter
        | SentimentEqualsFilter
        | SpokenNounContainsFilter
        | DurationFilter
        | ContentModerationContainsFilter
        | ContentModerationExcludesFilter
        | BusinessUnitFilter
      )[]
    >((acc, elem) => {
      const filter = { ...elem };
      if ("values" in filter) {
        filter.values = filter.values.filter((val) => val !== "");
      }
      acc.push(filter);
      return acc;
    }, []);
    const query = buildQueryFromFilters(ref.current.combinator, filtersList);
    setQueryFilters(query);
    setPreviousFilter(cloneDeep(ref.current.filters));
  }

  function changeCombinator(
    condition: AdvancedSearchQueryIntrinsicsCombinators
  ) {
    setCombinator((ref.current.combinator = condition));
  }

  return (
    <FilterContext.Provider
      value={{
        filters,
        previousFilter,
        changeCombinator,
        getFilter,
        addFilter,
        removeFilter,
        reset,
        applyFilters,
        queryFilter,
        combinator,
        subscribeToReset,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilters = (): Filters => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error(
      "useFilters should be used inside a FiltersProvider component"
    );
  }

  return context;
};
