import React from "react";

import { Select, SelectProps } from "@components/atoms";

interface Props {
  label: string;
  className?: any;
}

export type FormSelectProps<K extends keyof any> = Props & SelectProps<K>;

export const FormSelect = <K extends keyof any>({
  label,
  className,
  ...rest
}: FormSelectProps<K>) => (
  <div className={className}>
    <label className="font-semibold text-xs text-navy-400 mb-2">{label}</label>
    <Select className="w-full h-10" fixedWidth={true} {...rest} />
  </div>
);
