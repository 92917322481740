import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const BellZ = createSvgIcon({
  content: (
    <>
      <path
        d="M6.6665 3.33333C6.6665 2.97971 6.80698 2.64057 7.05703 2.39052C7.30708 2.14048 7.64622 2 7.99984 2C8.35346 2 8.6926 2.14048 8.94265 2.39052C9.19269 2.64057 9.33317 2.97971 9.33317 3.33333C10.0988 3.69535 10.7514 4.25888 11.2212 4.96353C11.691 5.66818 11.9601 6.48738 11.9998 7.33333V9.33333C12.05 9.7478 12.1968 10.1447 12.4284 10.4921C12.66 10.8395 12.9699 11.1276 13.3332 11.3333H2.6665C3.0298 11.1276 3.33971 10.8395 3.5713 10.4921C3.80288 10.1447 3.94967 9.7478 3.99984 9.33333V7.33333C4.03954 6.48738 4.3087 5.66818 4.77847 4.96353C5.24824 4.25888 5.9009 3.69535 6.6665 3.33333Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 11.3333V12C6 12.5304 6.21071 13.0391 6.58579 13.4142C6.96086 13.7893 7.46957 14 8 14C8.53043 14 9.03914 13.7893 9.41421 13.4142C9.78929 13.0391 10 12.5304 10 12V11.3333"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.6665 6H9.33317L6.6665 8.66667H9.33317"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
});
