import React, {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { Node, SelectionState, Tree } from "../model";

export interface TreeViewModel {
  search(text: string): void;
  updateSelectionState(nodeId: string, selectionState: SelectionState): void;
  toggleOpenNode(nodeId: string): void;
  tree: Tree;
}

const TreeViewModelContext = createContext<TreeViewModel | null>(null);

export interface TreeViewModelProviderProps {
  nodes?: Node[];
  selectedNodeIds: string[];
  hiddenNodeIds: string[];
  onTreeChange?: (tree: Tree) => void;
  children?: ReactNode;
}

export const TreeViewModelProvider = ({
  nodes = [],
  selectedNodeIds,
  hiddenNodeIds,
  children,
  onTreeChange = () => {},
}: TreeViewModelProviderProps) => {
  const [tree, setTree] = useState<Tree>(Tree.build(nodes));
  const ref = React.useRef({ tree });

  useEffect(() => {
    let mutatedTree: Tree = ref.current.tree;

    mutatedTree = mutatedTree
      .setSelectedNodes(selectedNodeIds)
      .removeNodesVirtually(hiddenNodeIds);

    setTree((ref.current.tree = mutatedTree));
  }, [selectedNodeIds, hiddenNodeIds]);

  const updateSelectionState = (
    nodeId: string,
    selectionState: SelectionState
  ) => {
    const { tree } = ref.current;
    const mutatedTree = tree.updateSelectionState(nodeId, selectionState);
    setTree((ref.current.tree = mutatedTree));
    onTreeChange(mutatedTree);
  };

  const toggleOpenNode = (nodeId: string) => {
    const { tree } = ref.current;
    const mutatedTree = tree.toggleOpenNode(nodeId);
    setTree((ref.current.tree = mutatedTree));
  };

  const search = (text: string) => {
    const { tree } = ref.current;
    const nodes = tree.findByName(text);

    const mutatedTree = tree.keepNodesVisible(nodes);
    setTree((ref.current.tree = mutatedTree));
  };

  return (
    <TreeViewModelContext.Provider
      value={{
        tree,
        updateSelectionState,
        toggleOpenNode,
        search,
      }}
    >
      {children}
    </TreeViewModelContext.Provider>
  );
};

export const useTreeViewModel = (): TreeViewModel => {
  const context = useContext(TreeViewModelContext);

  if (!context) {
    throw new Error(
      "useTreeViewModel should be used inside a TreeViewModelProvider component"
    );
  }

  return context;
};
