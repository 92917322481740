import React from "react";

import { Icon } from "@components/atoms";

export interface TitleItemProps {
  value: string;
  onClick: (value: string) => Promise<void> | void;
}

export const TitleItem = ({ value, onClick }: TitleItemProps) => {
  return (
    <div
      onClick={() => onClick(value)}
      className="m-2 mb-0 p-2 rounded-lg cursor-pointer bg-coolGrey-100 flex flex-row items-center text-blue-300"
    >
      <Icon.ArrowRightTop className="mr-4" />
      <div className="flex-1 truncate">
        Title contains&nbsp;
        <strong className="font-semibold">‘{value}’</strong>
      </div>
      <div className="ml-4 px-2 bg-coolGrey-200 rounded-lg text-coolGrey-400 text-bodyXs h-6 flex justify-center items-center font-semibold">
        Select
        <Icon.Return className="ml-1" />
      </div>
    </div>
  );
};
