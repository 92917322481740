import React, { ReactNode } from "react";

import { Tooltip } from "../Tooltip";

import { SentimentIndicator } from "./SentimentIndicator";

export interface SentimentTooltipProps {
  children: ReactNode;
  sentiment: {
    SentimentScoreMixed: number;
    SentimentScoreNegative: number;
    SentimentScoreNeutral: number;
    SentimentScorePositive: number;
  };
}

export const SentimentTooltip = ({
  children,
  sentiment,
}: SentimentTooltipProps) => (
  <Tooltip
    verticalAlign="below"
    horizontalAlign="left"
    tip={
      <>
        <div className="flex flex-row justify-between w-80">
          <div className="flex flex-col">
            <span className="font-semibold text-xs">Mixed</span>
            <span className="text-xs mt-2">
              {(sentiment.SentimentScoreMixed || 0).toFixed(2)}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="font-semibold text-xs">Negative</span>
            <span className="text-xs mt-2">
              {(sentiment.SentimentScoreNegative || 0).toFixed(2)}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="font-semibold text-xs">Neutral</span>
            <span className="text-xs mt-2">
              {(sentiment.SentimentScoreNeutral || 0).toFixed(2)}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="font-semibold text-xs">Positive</span>
            <span className="text-xs mt-2">
              {(sentiment.SentimentScorePositive || 0).toFixed(2)}
            </span>
          </div>
        </div>
        <SentimentIndicator className="mt-2" sentiment={sentiment} />
      </>
    }
  >
    {children}
  </Tooltip>
);
