import React from "react";

import { classnames } from "@external/tailwindcss-classnames";

import * as Icon from "../Icon";

export interface NoContentProps {
  className?: any;
  message?: string;
  iconType?: keyof typeof Icon;
}

export const NoContent = ({
  className,
  message = "No data available",
  iconType = "Bars",
}: NoContentProps) => {
  const NoContentIcon = Icon[iconType];

  return (
    <div
      className={classnames(
        className,
        "flex",
        "flex-col",
        "align-middle",
        "items-center",
        "justify-center",
        "h-full"
      )}
    >
      {NoContentIcon && <NoContentIcon color="coolGrey-200" />}
      <h6 className="uppercase font-secondary text-coolGrey-300 mt-6 font-semibold tracking-widest select-none">
        {message}
      </h6>
    </div>
  );
};
