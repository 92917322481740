import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Image = createSvgIcon({
  content: (
    <>
      <path
        d="M38.2778 2.55554V30.6111H2.72222V2.55554H38.2778ZM38.2778 0.333313H2.72222C1.5 0.333313 0.5 1.33331 0.5 2.55554V30.6111C0.5 31.8333 1.5 32.8333 2.72222 32.8333H38.2778C39.5 32.8333 40.5 31.8333 40.5 30.6111V2.55554C40.5 1.33331 39.5 0.333313 38.2778 0.333313Z"
        className="fill-current"
      />
      <path
        d="M10.5 7.19744C12.0278 7.19744 13.2778 8.44744 13.2778 9.97522C13.2778 11.503 12.0278 12.753 10.5 12.753C8.97222 12.753 7.72222 11.503 7.72222 9.97522C7.72222 8.44744 8.97222 7.19744 10.5 7.19744ZM10.5 4.97522C7.75 4.97522 5.5 7.22522 5.5 9.97522C5.5 12.7252 7.75 14.9752 10.5 14.9752C13.25 14.9752 15.5 12.7252 15.5 9.97522C15.5 7.22522 13.25 4.97522 10.5 4.97522Z"
        className="fill-current"
      />
      <path
        d="M33.2778 15.2236V26.918H7.72222V20.2236L14.5 23.6125C14.8056 23.7791 15.1667 23.8347 15.5 23.8347C15.8333 23.8347 16.1944 23.7514 16.5 23.6125L33.2778 15.2236ZM35.5 11.6403L15.5 21.6403L5.5 16.6403V29.1403H35.5V11.6403Z"
        className="fill-current"
      />
    </>
  ),
  width: 41,
  height: 33,
});
