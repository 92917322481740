import { IngestionStatus, MieStatus } from "@graphql/operations";

export interface SatisfiabilityProps {
  mieStatus: string | null;
  ingestionStatus: string | null;
}

export function isFailed(props: SatisfiabilityProps): boolean {
  return isFailedIngestion(props) || isFailedAnalytics(props);
}

export function isFailedIngestion({
  ingestionStatus,
}: SatisfiabilityProps): boolean {
  return ingestionStatus === IngestionStatus.Error;
}

export function isCreated({ ingestionStatus }: SatisfiabilityProps) {
  return ingestionStatus === IngestionStatus.Created;
}

export function isStarted({ ingestionStatus }: SatisfiabilityProps) {
  return ingestionStatus === IngestionStatus.Started;
}

export function isQueued({ ingestionStatus, mieStatus }: SatisfiabilityProps) {
  return (
    ingestionStatus === IngestionStatus.Completed &&
    (mieStatus === MieStatus.Queued || mieStatus === MieStatus.Waiting)
  );
}

export function isProcessing({
  ingestionStatus,
  mieStatus,
}: SatisfiabilityProps) {
  return (
    ingestionStatus === IngestionStatus.Completed &&
    (mieStatus === MieStatus.Started || mieStatus === MieStatus.Resumed)
  );
}

export function isFailedAnalytics({
  ingestionStatus,
  mieStatus,
}: SatisfiabilityProps) {
  return (
    ingestionStatus === IngestionStatus.Completed &&
    mieStatus === MieStatus.Error
  );
}

export function isSuccessful({
  mieStatus,
  ingestionStatus,
}: SatisfiabilityProps): boolean {
  return (
    ingestionStatus === IngestionStatus.Completed &&
    mieStatus === MieStatus.Complete
  );
}
