import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NetworkStatus, useQuery } from "@apollo/client";
import { merge } from "lodash";

import { Portal } from "@components/atoms";
import { Pager } from "@components/molecules";
import { AdvancedSearchExportDialog, VideosTable } from "@components/organisms";
import {
  VodInsightsTable_AdvancedSearchDocument,
  VodInsightsTable_AdvancedSearchQuery,
  VodInsightsTable_AdvancedSearchQueryVariables,
} from "@graphql/operations";
import { usePaging } from "@hooks/usePaging";
import { getLocaleCode } from "@utils/i18n";

import { PillBar } from "../AdvancedSearch";
import { useFilters } from "../AdvancedSearch/hooks";

const limit = 8;

export interface VodInsightsTableProps {
  className?: any;
}

export const VodInsightsTable = ({ className }: VodInsightsTableProps) => {
  const { discardStackTo, push, peek, getCurrentPage, setCurrentPage, clear } =
    usePaging({
      tokenStack: [{ pageNumber: 1, offset: 0 }],
      currentPage: 1,
    });
  const nextToken = peek();
  const navigate = useNavigate();
  const filtersContext = useFilters();
  const [data, setData] = useState<VodInsightsTable_AdvancedSearchQuery>();

  let variables: VodInsightsTable_AdvancedSearchQueryVariables = {
    limit,
  };

  if (filtersContext.queryFilter) {
    variables = merge(variables, {
      filter: {
        query: filtersContext.queryFilter,
      },
    });
  }

  if (nextToken.offset > 0) {
    variables = merge(variables, {
      offset: nextToken.offset,
    });
  }

  const {
    error,
    data: advancedSearchResultsQueryData,
    loading,
    networkStatus,
  } = useQuery(VodInsightsTable_AdvancedSearchDocument, {
    variables,
  });

  useEffect(() => {
    clear();
  }, [clear, filtersContext.queryFilter]);

  useEffect(() => {
    if (!loading) {
      setData(advancedSearchResultsQueryData);
    }
  }, [loading, advancedSearchResultsQueryData]);

  const onPagedChanged = (pageNumber: number) => {
    if (pageNumber > getCurrentPage()) {
      push({ pageNumber: pageNumber, offset: (pageNumber - 1) * limit });
    } else {
      discardStackTo(pageNumber);
    }
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil((data?.advancedSearch?.resultRows || 0) / limit);
  const hasNextPage = nextToken.pageNumber < totalPages;
  const showPager =
    networkStatus !== NetworkStatus.loading &&
    (hasNextPage || getCurrentPage() > 1);

  return (
    <>
      <Portal.Teleport refName="results">
        {data?.advancedSearch?.resultRows?.toLocaleString(getLocaleCode()) ||
          "-"}
        &nbsp;Result
        {((data?.advancedSearch?.resultRows || 0) > 1 ||
          (data?.advancedSearch?.resultRows || 0) === 0) &&
          "s"}
      </Portal.Teleport>
      <Portal.Teleport refName="export">
        <AdvancedSearchExportDialog
          title={`Export ${data?.advancedSearch?.resultRows?.toLocaleString(
            getLocaleCode()
          )} results`}
          disabled={loading}
          query={filtersContext.queryFilter}
        />
      </Portal.Teleport>
      <div className={className}>
        <PillBar className="px-6 bg-white pb-6" />
        <VideosTable
          loading={loading}
          error={!!error}
          errorMessage={error?.message}
          videos={data?.advancedSearch?.items || []}
          loadingRows={limit}
          onVideoClicked={(videoId) => {
            navigate(`/insights/${videoId}/overview`);
          }}
        />
        <Portal.Teleport refName="pager">
          {showPager && (
            <Pager
              className="mr-0 mt-4"
              data-testid="pager"
              currentPage={getCurrentPage()}
              nextButtonEnabled={hasNextPage}
              onPageChanged={onPagedChanged}
              totalPages={totalPages}
            />
          )}
        </Portal.Teleport>
      </div>
    </>
  );
};
