import React, { HTMLAttributes } from "react";
import {
  ErrorData,
  FragChangedData,
  FragLoadedData,
  InitPTSFoundData,
} from "hls.js";

import { classnames } from "@external/tailwindcss-classnames";
import { useVideo } from "@hooks/useVideo";

import { Slide } from "../Loading";

import { Error, Video } from ".";

export interface PlayerProps extends HTMLAttributes<HTMLElement> {
  url?: string;
  streamId?: string;
  className?: any;
  debug?: boolean;
  autoPlay?: boolean;
  controls?: boolean;
  muted?: boolean;
  xhrSetup?: (xhr: XMLHttpRequest, url: string) => void;
  onInitPtsFound?: (e: InitPTSFoundData) => void;
  onFragLoaded?: (e: FragLoadedData) => void;
  onFragChanged?: (e: FragChangedData) => void;
  onErrorData?: (e: ErrorData) => void;
}

export const Player = ({ url, streamId, className, ...rest }: PlayerProps) => {
  const { videoLoading, videoError } = useVideo();

  const hasGradient = videoLoading || !!videoError;

  const classNames = classnames(className, "w-full", "h-full", {
    "bg-gradient-to-b": hasGradient,
    "from-navy-300": hasGradient,
    "to-navy-400": hasGradient,
    "bg-black-300": !hasGradient,
  });

  return (
    <figure className={classNames}>
      {videoError && <Error />}
      {videoLoading && !videoError && (
        <div className="flex py-40 w-full h-full justify-center items-center">
          <Slide />
        </div>
      )}
      {(url || streamId) && <Video url={url} streamId={streamId} {...rest} />}
    </figure>
  );
};
