import React from "react";
import { Listbox } from "@headlessui/react";

import { Icon } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

import { InputContent } from "./InputContent";
import { ListboxContent } from "./ListboxContent";

interface FixupCellProps {
  className?: any;
  children: string | number;
  editable?: boolean;
  success?: boolean;
  error?: boolean;
  onChange: (value: string | number) => void | Promise<void>;
  type: "input" | "select";
  options?: { [key: string]: string };
}

export const FixupCell = ({
  error,
  className,
  children,
  onChange,
  editable,
  success,
  type = "input",
  options,
  ...rest
}: FixupCellProps) => {
  const [focused, setFocused] = React.useState(false);

  const onFocus = () => setFocused(true);
  const onFocusLost = () => setFocused(false);

  let child: React.ReactNode = children;

  if (editable && type === "input") {
    child = (
      <InputContent onFocus={onFocus} onChange={onChange} onBlur={onFocusLost}>
        {children}
      </InputContent>
    );
  }
  if (editable && type === "select") {
    child = (
      <Listbox
        as="div"
        className="w-full"
        value={children}
        onClick={onFocus}
        onChange={(v) => onChange(v)}
        data-e2e="cell-error-list"
      >
        {({ open }) => (
          <ListboxContent
            values={options || {}}
            open={open}
            onFocusLost={onFocusLost}
            onFocus={onFocus}
          >
            {children}
          </ListboxContent>
        )}
      </Listbox>
    );
  }

  return (
    <td className={classnames(className, "relative")} {...rest}>
      <div className="flex w-full h-full">
        {child}
        {success && <Icon.CheckmarkOutline />}
      </div>
      <div
        data-testid="cell-feedback"
        className={classnames(
          "transition",
          "ease-in-out",
          "duration-75",
          "-mb-0.25" as any,
          "inset-0",
          "z-0",
          "absolute",
          {
            [classnames("bg-red-300", "bg-opacity-16")]: !focused && error,
            [classnames("border-b", "border-b-red-300" as any)]:
              !focused && error,
            [classnames("ring-3", "ring-blue-300", "ring-opacity-16")]: focused,
          }
        )}
      ></div>
    </td>
  );
};
