import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  gql,
  MutationHookOptions,
  useApolloClient,
  useMutation,
} from "@apollo/client";

import {
  Button,
  ButtonProps,
  Segment as SegmentAtoms,
} from "@components/atoms";
import { Segment } from "@components/molecules";
import {
  SaveSegmentButton_EditSegmentJobDocument,
  SaveSegmentButton_EditSegmentJobMutation,
  SaveSegmentButton_EditSegmentJobMutationVariables,
  SegmentJobTable_ListSegmentJobsDocument,
  SegmentsOverview_ListSegmentJobs_AggregateDocument,
} from "@graphql/operations";
import { useFilters } from "@hooks/segments";
import * as errors from "@utils/errors";

export const editSegmentJobDefaultOptions: MutationHookOptions<
  SaveSegmentButton_EditSegmentJobMutation,
  SaveSegmentButton_EditSegmentJobMutationVariables
> = {
  refetchQueries: [
    SegmentJobTable_ListSegmentJobsDocument,
    SegmentsOverview_ListSegmentJobs_AggregateDocument,
  ],
};
interface Props {
  postSaveAction?: "toast" | "dialogue";
  segmentId: string;
  version: number;
  children?: string;
  disabled?: boolean;
}

type SaveSegmentButtonProps = Props & ButtonProps;

export const SaveSegmentButton = ({
  postSaveAction = "dialogue",
  children,
  segmentId,
  disabled,
  ...rest
}: SaveSegmentButtonProps) => {
  const client = useApolloClient();
  const [isDialogueOpen, setIsDialogueOpen] = useState(false);
  const navigate = useNavigate();
  const { segmentTitle, version, segmentDescription, query } = useFilters();
  const [
    editSegmentJob,
    {
      data: editSegmentJobData,
      loading: editSegmentJobLoading,
      error: editSegmentJobError,
    },
  ] = useMutation(
    SaveSegmentButton_EditSegmentJobDocument,
    editSegmentJobDefaultOptions
  );

  if (editSegmentJobError) {
    const { message } = editSegmentJobError;
    const circularDepErr = "circular dependency found";
    const errorMsg = message.split(":")[0];
    const segmentIds = message
      .split(":")[1]
      .split("->")
      .map((id) => id.trim());

    if (errorMsg === circularDepErr) {
      client.writeQuery({
        query: gql`
          query SetNestedSegmentError {
            nestedSegmentError {
              __typename
              id
              circularDependencyChain
              circularDependencyDetected
            }
          }
        `,
        data: {
          nestedSegmentError: {
            __typename: "NestedSegmentError",
            id: segmentId,
            circularDependencyChain: segmentIds,
            circularDependencyDetected: true,
          },
        },
        variables: {
          id: segmentId,
        },
      });
    }
  }

  const variables: SaveSegmentButton_EditSegmentJobMutationVariables = {
    input: {
      segmentId,
      version: version ? version : 1,
      ...(segmentTitle ? { title: segmentTitle } : {}),
      ...(segmentDescription ? { description: segmentDescription } : {}),
      ...(query ? { query: JSON.stringify(query) } : {}),
    },
  };

  return (
    <>
      <Button
        type="button"
        size="medium"
        color="primary"
        data-e2e="button-save-segment"
        onClick={async () => {
          if (postSaveAction === "dialogue") {
            setIsDialogueOpen(true);
          }
          const output = await editSegmentJob({
            variables,
          });

          if (postSaveAction === "toast") {
            if ((output.errors?.length || 0) > 0) {
              toast.error(
                `Unable to save segment: ${JSON.stringify(output.errors)}`,
                {
                  position: "bottom-right",
                }
              );
            } else {
              toast.success("Segment saved as draft", {
                position: "bottom-right",
              });
              navigate("/segments");
            }
          }
        }}
        disabled={disabled}
        {...rest}
      >
        {children || "Save Segment"}
      </Button>
      {postSaveAction === "dialogue" && (
        <Segment.OperationDialogue
          operation={SegmentAtoms.OperationOptions.Edit}
          loading={editSegmentJobLoading}
          segmentId={editSegmentJobData?.editSegmentJob?.segmentId || ""}
          isDialogOpen={isDialogueOpen}
          onClose={() => {
            if (!errors.isApolloErrorPresent(editSegmentJobError)) {
              navigate(
                `/segments/${segmentId}/version/${editSegmentJobData?.editSegmentJob?.version}`
              );
            }
          }}
          onConfirmButtonClicked={() => {
            navigate(
              `/segments/${segmentId}/version/${editSegmentJobData?.editSegmentJob?.version}`
            );
          }}
          onCancelButtonClicked={() => navigate(`/segments`)}
          error={errors.isApolloErrorPresent(editSegmentJobError)}
          onTryAgain={async () => {
            await editSegmentJob({
              variables,
            });
          }}
        />
      )}
    </>
  );
};
