import React, { ForwardedRef, InputHTMLAttributes } from "react";

import { classnames } from "@external/tailwindcss-classnames";

import * as Icon from "../Icon";

import styles from "./DatePicker.module.css";

interface Props {
  multiline?: false;
  className?: any;
}

export type DatePickerProps = Props & InputHTMLAttributes<HTMLInputElement>;

export const DatePicker = React.forwardRef<any, DatePickerProps>(
  (props, ref: ForwardedRef<any>) => {
    let { className, ...rest } = props;

    className = classnames(
      className,

      "outline-none",
      "leading-input",
      "w-full",
      "py-2",
      "px-smx",
      "disabled:bg-coolGrey-200",
      "disabled:text-black-100",
      "disabled:cursor-not-allowed",

      "border",
      "text-black-300",

      "focus:bg-white",
      "focus:ring-opacity-16",
      "focus:ring-3",
      "focus:border-opacity-0",
      "focus:ring-blue-300",

      "placeholder-black-100",

      "hover:bg-navy-300",
      "hover:bg-opacity-4",

      "rounded-lg",
      "transition",
      "duration-200",

      "h-10",
      "border-coolGrey-300",
      "pl-9"
    );

    return (
      <div className={classnames(styles.root as any, "relative")}>
        <div className="absolute top-0 left-0 bottom-0 flex items-center">
          <Icon.CalendarOutline className="ml-3" />
        </div>
        <input type="date" className={className} ref={ref} {...rest} />
      </div>
    );
  }
);
