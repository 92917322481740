import React from "react";

import { classnames, TArg } from "@external/tailwindcss-classnames";

export enum ColorLabels {
  Red = "Red",
  CoolGrey = "CoolGrey",
  Teal = "Teal",
  Navy = "Navy",
  Blue = "Blue",
  Orange = "Orange",
}

const BulletColorMapping: { [key in ColorLabels]: TArg[] } = {
  [ColorLabels.Red]: ["bg-red-300"],
  [ColorLabels.CoolGrey]: ["bg-coolGrey-300"],
  [ColorLabels.Teal]: ["bg-teal-500"],
  [ColorLabels.Navy]: ["bg-navy-300"],
  [ColorLabels.Blue]: ["bg-blue-300"],
  [ColorLabels.Orange]: ["bg-orange-300"],
};

interface ColorfulBulletProps {
  color: ColorLabels;
}
export const ColorfulBullet = ({ color }: ColorfulBulletProps) => {
  let bulletClassName = classnames(
    "rounded-full",
    "bg-black-300",
    "w-1.5",
    "h-1.5",
    "mr-2",
    ...BulletColorMapping[color]
  );
  return <div className={bulletClassName} />;
};
