import React, {
  ForwardedRef,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
} from "react";

import { classnames, TArg } from "@external/tailwindcss-classnames";

interface InputProps {
  multiline?: false;
  icon?: React.ReactNode;
  error?: boolean;
  className?: any;
  inputClassName?: any;
  endAdornment?: React.ReactNode;
  rows?: number;
}

interface TextAreaProps {
  multiline: true;
  className?: any;
  inputClassName?: any;
  error?: boolean;
  rows?: number;
}

export type TextInputProps =
  | (InputProps & InputHTMLAttributes<HTMLInputElement>)
  | (TextAreaProps & TextareaHTMLAttributes<HTMLTextAreaElement>);

export const TextInput = React.forwardRef<any, TextInputProps>(
  (props, ref: ForwardedRef<any>) => {
    let { error, className, inputClassName, ...compProps } = props;

    className = classnames(className, "relative");
    inputClassName = classnames(
      inputClassName,

      "outline-none",
      "leading-input",
      "w-full",
      "py-2",
      "px-smx",
      "disabled:bg-lightGrey-200",
      "disabled:text-black-100",
      "disabled:cursor-not-allowed",

      "border",
      "text-black-300",

      "focus:bg-white",
      "focus:ring-opacity-16",
      "focus:ring-3",
      "focus:border-opacity-0",
      "focus:ring-blue-300",

      "placeholder-black-100",

      "hover:bg-navy-300",
      "hover:bg-opacity-4",

      "rounded-lg",
      "transition",
      "duration-200",
      {
        [classnames(
          "border-coolGrey-300",
          "placeholder-not-shown:bg-lightGrey-100" as TArg
        )]: !error,
        [classnames("border-red-300", "placeholder-red-300")]: error,
      }
    );

    if (compProps.multiline) {
      const { rows, multiline, ...rest } = compProps;
      return (
        <textarea
          className={classnames(className, inputClassName)}
          rows={rows}
          ref={ref}
          {...rest}
        />
      );
    }

    const { icon, endAdornment, multiline, ...rest } = compProps;

    inputClassName = classnames(inputClassName, "h-10", {
      "pl-9": !!icon,
      "pr-16": !!endAdornment,
    });

    return (
      <div className={className}>
        {icon && (
          <div className="absolute top-0 left-0 bottom-0 flex items-center">
            {icon}
          </div>
        )}
        <input type="text" className={inputClassName} ref={ref} {...rest} />
        {endAdornment && (
          <div className="absolute right-0 top-0 bottom-0 flex items-center">
            {endAdornment}
          </div>
        )}
      </div>
    );
  }
);
