import React from "react";

import { GenericLabel } from "@components/atoms/GenericLabel";
import { Table } from "@components/molecules";
import { VideosTable_VideoFragment } from "@graphql/operations";
import { temporal } from "@utils/index";

import { Thumbnail } from "./Thumbnail";

export type VideosTableProps = {
  loading: boolean;
  loadingRows: number;
  videos: VideosTable_VideoFragment[];
  onVideoClicked?: (videoId: string) => Promise<void> | void;
} & Pick<Table.TableProps, "error" | "errorMessage">;

export const VideosTable = ({
  videos = [],
  onVideoClicked,
  loading,
  loadingRows,
  error,
  errorMessage,
}: VideosTableProps) => (
  <Table.Table
    columns={[
      {
        title: "Video",
        width: "w-7/12",
      },
      {
        title: "Business unit",
        width: "w-3/12",
      },
      {
        title: "Length",
        width: "w-2/12",
      },
    ]}
    loading={loading}
    loadingRows={loadingRows}
    loadingRowsHeight="h-20"
    error={error}
    errorMessage={errorMessage}
  >
    {videos.length > 0
      ? videos.map((video) => (
          <Table.Row
            key={video.VideoId}
            onClick={
              onVideoClicked ? () => onVideoClicked(video.VideoId) : undefined
            }
            height="h-20"
          >
            <Table.Cell>
              <div className="flex flex-row items-center">
                <Thumbnail
                  className="w-24 h-14 shrink-0"
                  src={video.Job?.thumbnailHref}
                />
                <span className="ml-6 font-semibold">
                  {video.Job?.title || "-"}
                </span>
              </div>
            </Table.Cell>
            <Table.Cell>
              {video.Job?.businessUnit ? (
                <GenericLabel>
                  {video.Job.businessUnit.toLowerCase()}
                </GenericLabel>
              ) : (
                "-"
              )}
            </Table.Cell>
            <Table.Cell>
              {temporal.formatLength(video.DurationMillis)}
            </Table.Cell>
          </Table.Row>
        ))
      : null}
  </Table.Table>
);
