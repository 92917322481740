import React, { HTMLAttributes } from "react";

import { classnames } from "@external/tailwindcss-classnames";

export type Ref = HTMLDivElement;

interface Props {
  disabled?: boolean;
  children?: React.ReactNode;
  isOver?: boolean;
}

export type DropTargetProps = Props & HTMLAttributes<HTMLDivElement>;

export const DropTarget = React.forwardRef<Ref, DropTargetProps>(
  ({ children, disabled, isOver, ...rest }: Props, ref) => {
    return (
      <div
        ref={ref}
        className={classnames(
          "mx-6",
          "my-4",
          "h-12",
          "px-4",
          "rounded",
          "text-bodySm",
          "flex",
          "items-center",
          "transition-colors",
          "ease-in",
          "duration-75",
          {
            [classnames(
              "border",
              "text-black-100",
              "border-dashed",
              "bg-coolGrey-100",
              {
                [classnames("border-coolGrey-300")]: !isOver,
                [classnames("border-blue-300")]: isOver,
              }
            )]: !disabled,
            [classnames(
              "bg-lightGrey-200",
              "text-black-300",
              "text-opacity-38"
            )]: disabled,
          }
        )}
        {...rest}
      >
        {children}
      </div>
    );
  }
);
