export type Label = {
  value: string;
  secondLevelCategories?: Array<{ value: string; parentKey: string }>;
};

export const ContentModerationLabels: Array<Label> = [
  {
    value: "Explicit Nudity",
    secondLevelCategories: [
      {
        value: "Nudity",
        parentKey: "Explicit Nudity",
      },
      {
        value: "Graphic Male Nudity",
        parentKey: "Explicit Nudity",
      },
      {
        value: "Graphic Female Nudity",
        parentKey: "Explicit Nudity",
      },
      {
        value: "Sexual Activity",
        parentKey: "Explicit Nudity",
      },
      {
        value: "Illustrated Explicit Nudity",
        parentKey: "Explicit Nudity",
      },
      {
        value: "Adult Toys",
        parentKey: "Explicit Nudity",
      },
    ],
  },
  {
    value: "Suggestive",
    secondLevelCategories: [
      {
        value: "Female Swimwear Or Underwear",
        parentKey: "Suggestive",
      },
      {
        value: "Male Swimwear Or Underwear",
        parentKey: "Suggestive",
      },
      {
        value: "Partial Nudity",
        parentKey: "Suggestive",
      },
      {
        value: "Barechested Male",
        parentKey: "Suggestive",
      },
      {
        value: "Revealing Clothes",
        parentKey: "Suggestive",
      },
      {
        value: "Sexual Situations",
        parentKey: "Suggestive",
      },
    ],
  },
  {
    value: "Violence",
    secondLevelCategories: [
      {
        value: "Graphic Violence Or Gore",
        parentKey: "Violence",
      },
      {
        value: "Physical Violence",
        parentKey: "Violence",
      },
      {
        value: "Weapon Violence",
        parentKey: "Violence",
      },
      {
        value: "Weapons",
        parentKey: "Violence",
      },
      {
        value: "Self Injury",
        parentKey: "Violence",
      },
    ],
  },
  {
    value: "Visually Disturbing",
    secondLevelCategories: [
      {
        value: "Emaciated Bodies",
        parentKey: "Visually Disturbing",
      },
      {
        value: "Corpses",
        parentKey: "Visually Disturbing",
      },
      {
        value: "Hanging",
        parentKey: "Visually Disturbing",
      },
      {
        value: "Air Crash",
        parentKey: "Visually Disturbing",
      },
      {
        value: "Explosions And Blasts",
        parentKey: "Visually Disturbing",
      },
    ],
  },
  {
    value: "Rude Gestures",
    secondLevelCategories: [
      {
        value: "Middle Finger",
        parentKey: "Rude Gestures",
      },
    ],
  },
  {
    value: "Drugs",
    secondLevelCategories: [
      {
        value: "Drug Products",
        parentKey: "Drugs",
      },
      {
        value: "Drug Use",
        parentKey: "Drugs",
      },
      {
        value: "Pills",
        parentKey: "Drugs",
      },
      {
        value: "Drug Paraphernalia",
        parentKey: "Drugs",
      },
    ],
  },
  {
    value: "Tobacco",
    secondLevelCategories: [
      {
        value: "Tobacco Products",
        parentKey: "Tobacco",
      },
      {
        value: "Smoking",
        parentKey: "Tobacco",
      },
    ],
  },
  {
    value: "Alcohol",
    secondLevelCategories: [
      {
        value: "Tobacco",
        parentKey: "Alcohol",
      },
      {
        value: "Alcoholic Beverages",
        parentKey: "Alcohol",
      },
    ],
  },
  {
    value: "Gambling",
  },
  {
    value: "Hate Symbols",
    secondLevelCategories: [
      {
        value: "Nazi Party",
        parentKey: "Hate Symbols",
      },
      {
        value: "White Supremacy",
        parentKey: "Hate Symbols",
      },
      {
        value: "Extremist",
        parentKey: "Hate Symbols",
      },
    ],
  },
];
