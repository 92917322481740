import React from "react";

import { Button, Icon } from "@components/atoms";
import * as truncate from "@utils/truncate";

interface FileQueueProps {
  file: {
    name: string;
    size: number;
  };
  onDelete: () => void | Promise<void>;
}

export const FileQueue = ({ file, onDelete }: FileQueueProps) => (
  <div className="border border-coolGrey-200 rounded py-4 px-12 flex">
    <div className="flex-1">
      <div className="text-sm" data-e2e="filename-upload">
        {file.name}
      </div>
      <div className="text-sm text-black-100">
        {truncate.bytes(file.size)} -100% uploaded
      </div>

      <Button
        className="font-medium"
        variant="text"
        fontWeight="medium"
        onClick={onDelete}
      >
        Cancel
      </Button>
    </div>
    <Icon.CheckmarkOutline />
  </div>
);
