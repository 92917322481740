import React from "react";

import { Icon } from "@components/atoms";

import { IconButton } from "./IconButton";

export interface BellProps {
  className?: any;
}

export const Bell = ({ className }: BellProps) => (
  <IconButton className={className}>
    <Icon.Bell />
  </IconButton>
);
