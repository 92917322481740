import React from "react";
import { Listbox } from "@headlessui/react";

import { classnames } from "@external/tailwindcss-classnames";

interface Props {
  rightToLeft?: boolean;
  index: number;
  lastItem: boolean;
}

export type OptionProps = Props &
  React.ComponentPropsWithRef<typeof Listbox.Option>;

export const Option = ({
  children,
  rightToLeft,
  index,
  lastItem,
  ...rest
}: OptionProps) => (
  <Listbox.Option
    className={classnames(
      {
        "mt-1": index === 0,
        "mb-1": lastItem,
        "justify-end": rightToLeft,
        "text-right": rightToLeft,
      },
      "flex",
      "z-30",
      "items-center",
      "px-3",
      "h-10",
      "cursor-pointer",
      "hover:bg-navy-300",
      "hover:bg-opacity-4"
    )}
    {...rest}
  >
    {children}
  </Listbox.Option>
);
