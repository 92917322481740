import React, {
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  RefAttributes,
} from "react";

import { classnames } from "@external/tailwindcss-classnames";

import styles from "./Checkbox.module.css";

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: any;
}

export const Checkbox: ForwardRefExoticComponent<
  CheckboxProps & RefAttributes<HTMLInputElement>
> = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, className, ...rest }, ref) => {
    return (
      <label
        className={classnames(
          className,
          "flex",
          "justify-center",
          "items-center"
        )}
      >
        <input
          ref={ref}
          type="checkbox"
          className={classnames(styles.input as any, "h-4", "w-4")}
          {...rest}
        />
        <span className="ml-2 flex-grow">{children}</span>
      </label>
    );
  }
);
