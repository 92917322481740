import React, { type HTMLAttributes } from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  className?: any;
}

export const Container = ({ children, className }: ContainerProps) => (
  <nav className={classnames(className, "text-breadcrumb", "w-full")}>
    <ol className="list-none p-0 inline-flex">{children}</ol>
  </nav>
);
