import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const VideoContains = createSvgIcon({
  content: (
    <path
      d="M5.33398 12L1.33398 8L5.33398 4H13.334C13.6876 4 14.0267 4.14048 14.2768 4.39052C14.5268 4.64057 14.6673 4.97971 14.6673 5.33333V10.6667C14.6673 11.0203 14.5268 11.3594 14.2768 11.6095C14.0267 11.8595 13.6876 12 13.334 12H5.33398Z"
      className="stroke-current"
      strokeLinecap="square"
    />
  ),
  width: 16,
  height: 16,
});
