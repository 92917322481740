import React from "react";

import { Link } from "../Link";

export interface ItemProps {
  values?: string[];
  title?: string;
  link?: string;
}

// If a link is given, wrap the value into a Link tag and set the link as href
export const Item = ({ values, title, link }: ItemProps) =>
  values && values.length > 0 ? (
    <div data-testid="condition-summary-item">
      {title && <strong>{title}: </strong>}
      {!link ? (
        values.join(", ")
      ) : (
        <Link className="text-navy-400" href={link}>
          {values.join(", ")}
        </Link>
      )}
    </div>
  ) : null;
