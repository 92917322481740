import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Bars = createSvgIcon({
  content: (
    <>
      <path
        d="M9.12745 20.9151C9.70261 20.9151 10.1732 21.3856 10.1732 21.9608V36.7059H2.5915V21.9608C2.5915 21.3856 3.06209 20.9151 3.63726 20.9151H9.12745ZM9.12745 18.8235H3.63726C1.91176 18.8235 0.5 20.2353 0.5 21.9608V38.7974H12.2647V21.9608C12.2647 20.2353 10.8529 18.8235 9.12745 18.8235Z"
        className="fill-current"
      />
      <path
        d="M23.0883 11.5032C23.7419 11.5032 24.2909 12.0261 24.2909 12.7059V36.7059H16.7092V12.7059C16.7092 12.0523 17.2321 11.5032 17.9118 11.5032H23.0883ZM23.0883 9.41174H17.9118C16.0817 9.41174 14.6177 10.8758 14.6177 12.7059V38.7974H26.3824V12.7059C26.3824 10.8758 24.9183 9.41174 23.0883 9.41174Z"
        className="fill-current"
      />
      <path
        d="M37.2845 2.0915C37.9119 2.0915 38.4087 2.58824 38.4087 3.21569V36.6797H30.827V3.21569C30.827 2.58824 31.3237 2.0915 31.9512 2.0915H37.2845ZM37.2845 0H31.9512C30.1734 0 28.7355 1.43791 28.7355 3.21569V38.7712H40.5002V3.21569C40.5002 1.43791 39.0623 0 37.2845 0Z"
        className="fill-current"
      />
    </>
  ),
  width: 41,
  height: 39,
  defaultColor: "coolGrey-200",
});
