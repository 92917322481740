import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const CalendarStats = createSvgIcon({
  content: (
    <>
      <path
        d="M7.86333 14H3.33333C2.97971 14 2.64057 13.8596 2.39052 13.6095C2.14048 13.3595 2 13.0203 2 12.6667V4.66671C2 4.31309 2.14048 3.97395 2.39052 3.7239C2.64057 3.47385 2.97971 3.33337 3.33333 3.33337H11.3333C11.687 3.33337 12.0261 3.47385 12.2761 3.7239C12.5262 3.97395 12.6667 4.31309 12.6667 4.66671V7.33337"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 9.33337V12H14.6667"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.3335 12C9.3335 12.7073 9.61445 13.3856 10.1145 13.8857C10.6146 14.3858 11.2929 14.6667 12.0002 14.6667C12.7074 14.6667 13.3857 14.3858 13.8858 13.8857C14.3859 13.3856 14.6668 12.7073 14.6668 12C14.6668 11.2928 14.3859 10.6145 13.8858 10.1144C13.3857 9.61433 12.7074 9.33337 12.0002 9.33337C11.2929 9.33337 10.6146 9.61433 10.1145 10.1144C9.61445 10.6145 9.3335 11.2928 9.3335 12Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 2V4.66667"
        stroke="#303030"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.6665 2V4.66667"
        stroke="#303030"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 7.33337H12.6667"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
});
