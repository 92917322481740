import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";

import { Select } from "@components/atoms";
import { Pager, Table } from "@components/molecules";
import { DeleteSegmentDialog, type SegmentData } from "@components/organisms";
import {
  BeginSegmentButton_BeginSegmentJobDocument,
  SegmentJobDetails_SegmentJobDocument,
  SegmentJobTable_ListSegmentJobsDocument,
  SegmentJobTable_ListSegmentJobsQueryVariables,
  SegmentJobTable_ReindexSegmentJobDocument,
  SegmentsOverview_ListSegmentJobs_AggregateDocument,
} from "@graphql/operations";
import { limitOptions, usePaging } from "@hooks/usePaging";
import { getLocaleCode } from "@utils/i18n";
import { clipboard } from "@utils/index";

import { SegmentTypeValue } from "../Sidebar/SegmentType/SegmentType";

import { SegmentRow } from "./SegmentRow";

const localeCode = getLocaleCode();

export const fixSearchValue = (searchValue: string) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^(?:\$)(?<id>[\w-]{7})$/g;
  const id = regex.exec(searchValue)?.groups?.id;

  return id || searchValue;
};

const formatTotalVideoPercentage = (
  videoCount?: number,
  totalJobs?: number
) => {
  if (!videoCount || !totalJobs) {
    return "-";
  }

  return `${((videoCount / totalJobs) * 100).toLocaleString(localeCode, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  })}%`;
};

export interface SegmentJobTableProps {
  type?: SegmentTypeValue;
  onClick?: (id: string, version: number) => void | Promise<void>;
  searchValue?: string;
  minSegmentSize?: number;
  maxSegmentSize?: number;
  status?: string[];
  totalSegments: number;
}

export const SegmentJobTable = ({
  type,
  onClick,
  searchValue,
  status,
  minSegmentSize,
  maxSegmentSize,
  totalSegments,
  ...props
}: SegmentJobTableProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { discardStackTo, push, peek, getCurrentPage, setCurrentPage, clear } =
    usePaging({
      tokenStack: [""],
      currentPage: 1,
    });

  const nextToken = peek();
  const [limit, setLimit] = useState(
    searchParams.get("itemsPerPage") || limitOptions["10"]
  );

  const [isDeleteSegmentDialogOpen, setIsDeleteSegmentDialogOpen] =
    useState(false);

  // @NOTICE: Is there a better way to do this?
  const [selectedSegment, setSelectedSegment] = useState<SegmentData>({
    segmentId: "",
    segmentVersion: -Infinity,
    hasParent: false,
  });

  let variables: SegmentJobTable_ListSegmentJobsQueryVariables = {
    // Dividing by 2 to account for addition of inverse segments
    // If we use the "itemsPerPage" url param, we need to take into account
    // the possibility of the number being odd
    limit: Math.max(1, Math.floor(parseInt(limit, 10) / (type ? 1 : 2))),
  };

  if (nextToken) {
    variables = _.merge<
      SegmentJobTable_ListSegmentJobsQueryVariables,
      SegmentJobTable_ListSegmentJobsQueryVariables
    >(variables, {
      nextToken,
    });
  }

  if (status && status.length > 0) {
    variables = _.merge<
      SegmentJobTable_ListSegmentJobsQueryVariables,
      SegmentJobTable_ListSegmentJobsQueryVariables
    >(variables, {
      filter: {
        Status: status.length > 1 ? { in: status } : { eq: status[0] },
      },
    });
  }

  if (searchValue) {
    variables = _.merge<
      SegmentJobTable_ListSegmentJobsQueryVariables,
      SegmentJobTable_ListSegmentJobsQueryVariables
    >(variables, {
      filter: {
        IdOrTitle: {
          contains: fixSearchValue(searchValue),
        },
      },
    });
  }

  if (minSegmentSize) {
    variables = _.merge<
      SegmentJobTable_ListSegmentJobsQueryVariables,
      SegmentJobTable_ListSegmentJobsQueryVariables
    >(variables, {
      filter: {
        SegmentCount: {
          gt: minSegmentSize,
        },
      },
    });
  }
  if (maxSegmentSize) {
    variables = _.merge<
      SegmentJobTable_ListSegmentJobsQueryVariables,
      SegmentJobTable_ListSegmentJobsQueryVariables
    >(variables, {
      filter: {
        SegmentCount: {
          lt: maxSegmentSize,
        },
      },
    });
  }

  useEffect(() => {
    clear();
  }, [clear, searchValue, status, minSegmentSize, maxSegmentSize]);

  const { loading, error, data } = useQuery(
    SegmentJobTable_ListSegmentJobsDocument,
    {
      variables: variables,
    }
  );

  const [restartSegmentJob, { data: restartJobData, error: restartJobError }] =
    useMutation(BeginSegmentButton_BeginSegmentJobDocument);

  const [
    reindexSegmentJob,
    { data: reindexSegmentJobData, error: reindexSegmentJobError },
  ] = useMutation(SegmentJobTable_ReindexSegmentJobDocument, {
    refetchQueries: [
      SegmentJobDetails_SegmentJobDocument,
      SegmentJobTable_ListSegmentJobsDocument,
      SegmentsOverview_ListSegmentJobs_AggregateDocument,
    ],
  });

  useEffect(() => {
    if (reindexSegmentJobError) {
      toast.error("Failed to reindex job", {
        id: "reindex-job-error",
      });
    } else if (reindexSegmentJobData) {
      toast.success("Job has been successfully started to reindex", {
        id: "reindex-job-success",
      });
    }
  }, [reindexSegmentJobData, reindexSegmentJobError]);

  useEffect(() => {
    if (restartJobError) {
      toast.error("Failed to restart job", {
        id: "restart-job-error",
      });
    } else if (restartJobData) {
      toast.success("Job has been successfully restarted", {
        id: "restart-job-success",
      });
    }
  }, [restartJobData, restartJobError]);

  const hasNextPage = !!data?.listSegmentJobs?.nextToken;

  const onPagedChanged = (pageNumber: number) => {
    if (pageNumber > getCurrentPage()) {
      push(data?.listSegmentJobs?.nextToken || "");
    } else {
      discardStackTo(pageNumber);
    }
    setCurrentPage(pageNumber);
  };

  const onSelectChange = (item: string) => {
    if (parseInt(item, 10) >= totalSegments) {
      clear();
    }
    setLimit(item);
  };
  const totalJobs = data?.jobs?.aggregate?.Total;
  const items = data?.listSegmentJobs?.items || [];
  const totalPages = Math.ceil(totalSegments / Number(limit));

  return (
    <>
      <div className="rounded-md shadow-1dp overflow-clip" {...props}>
        <Table.Table
          columns={[
            {
              title: "Segment",
              width: "w-3/12",
            },
            {
              title: "ID",
              width: "w-2/12",
            },
            {
              title: "Size",
              width: "w-2/12",
            },
            {
              title: "Created",
              width: "w-1/12",
            },
            {
              title: "Status",
              width: "w-1/12",
            },
            {
              width: "w-1/12",
            },
          ]}
          align="top"
          loading={loading}
          loadingRows={Number(limitOptions["10"])}
          loadingRowsHeight="h-24"
          error={!!error}
        >
          {items.length > 0
            ? items.flatMap((segmentJob) => {
                const inverseVideoCount =
                  !!totalJobs && !!segmentJob.VideoCount
                    ? totalJobs - segmentJob.VideoCount
                    : undefined;

                const items: JSX.Element[] = [];

                if (!type || type === SegmentTypeValue.Segment) {
                  items.push(
                    <SegmentRow
                      key={segmentJob.SegmentId}
                      segmentId={segmentJob.SegmentId}
                      version={segmentJob.Version}
                      title={segmentJob.Title || undefined}
                      description={segmentJob.Description || undefined}
                      videoCount={segmentJob.VideoCount || undefined}
                      totalVideoPercentage={formatTotalVideoPercentage(
                        segmentJob.VideoCount || undefined,
                        totalJobs
                      )}
                      createdAt={segmentJob.CreatedAt}
                      redshiftQueryStatus={
                        segmentJob.RedshiftQueryStatus || undefined
                      }
                      status={segmentJob.Status}
                      segmentCount={segmentJob.SegmentCount || undefined}
                      onClick={
                        onClick
                          ? () =>
                              onClick(segmentJob.SegmentId, segmentJob.Version)
                          : undefined
                      }
                      onCopyLinkClick={clipboard.copyToHandler({
                        value: `${window.location.origin}/segments/${segmentJob.SegmentId}`,
                        onComplete: clipboard.toastSuccess({
                          message: "Segment URL copied to clipboard",
                        }),
                      })}
                      onDuplicateClick={() =>
                        navigate(
                          `/segments/${segmentJob.SegmentId}/version/${segmentJob.Version}/duplicate`
                        )
                      }
                      onEditClick={() =>
                        navigate(
                          `/segments/${segmentJob.SegmentId}/version/${segmentJob.Version}/edit`
                        )
                      }
                      onDeleteClick={() => {
                        setSelectedSegment({
                          segmentId: segmentJob.SegmentId,
                          segmentVersion: segmentJob.Version,
                          hasParent: (segmentJob.Parents?.length || 0) > 0,
                        });
                        setIsDeleteSegmentDialogOpen(true);
                      }}
                      onRetryClick={() =>
                        restartSegmentJob({
                          variables: {
                            input: {
                              segmentId: segmentJob.SegmentId,
                              version: segmentJob.Version,
                            },
                          },
                        })
                      }
                      onReindexClick={async () => {
                        await reindexSegmentJob({
                          variables: {
                            input: {
                              segmentId: segmentJob.SegmentId,
                              version: segmentJob.Version,
                            },
                          },
                        });
                      }}
                    />
                  );
                }

                if (!type || type === SegmentTypeValue.Inverse) {
                  items.push(
                    <SegmentRow
                      key={`${segmentJob.SegmentId}-inverse`}
                      inverse
                      segmentId={segmentJob.SegmentId}
                      version={segmentJob.Version}
                      title={segmentJob.Title || undefined}
                      description={segmentJob.Description || undefined}
                      videoCount={inverseVideoCount}
                      totalVideoPercentage={formatTotalVideoPercentage(
                        inverseVideoCount,
                        totalJobs
                      )}
                      createdAt={segmentJob.CreatedAt}
                      redshiftQueryStatus={
                        segmentJob.RedshiftQueryStatus || undefined
                      }
                      status={segmentJob.Status}
                    />
                  );
                }

                return items;
              })
            : null}
        </Table.Table>
      </div>

      <DeleteSegmentDialog
        segmentData={selectedSegment}
        isDialogOpen={isDeleteSegmentDialogOpen}
        onCancel={() => setIsDeleteSegmentDialogOpen(false)}
        onClose={() => setIsDeleteSegmentDialogOpen(false)}
        onSubmission={() => setIsDeleteSegmentDialogOpen(false)}
      />
      <div className="mt-4 flex flex-row justify-between" data-e2e="pagination">
        <div className="flex flex-row items-center align-middle pl-6">
          <p className="mr-2 text-sm text-black-100 font-medium">Per page</p>
          <Select
            direction="up"
            className="h-8"
            options={limitOptions}
            value={limit}
            rightToLeft={false}
            fixedWidth={true}
            onChange={onSelectChange}
          />
        </div>

        <Pager
          className="mr-0"
          data-testid="pager"
          data-e2e="pagination"
          currentPage={getCurrentPage()}
          nextButtonEnabled={hasNextPage && !loading}
          onPageChanged={onPagedChanged}
          totalPages={totalPages}
        />
      </div>
    </>
  );
};
