import { ConvertToUpperSnakeCase } from "@utils/casing";

import { ImportColumnType } from "../useImportState/importColumnType";

import { pass, pushError, Result } from "./result";

const validTypes = [
  "CELEBRITY",
  "OBJECT_OR_SCENE",
  "FLAGGED_CONTENT",
  "VIDEO_TITLE_CONTAINS",
  "VIDEO_DURATION",
  "SENTIMENT",
  "SPOKEN_WORD_OR_PHRASE",
  "TEXT_IN_VIDEO",
  "CLOSE_GAME",
  "RUN_DIFFERENTIAL",
  "DELAYED_GAME",
  "DOUBLE_HEADER",
  "NIGHT_GAME",
  "VENUE",
  "PRE_ALL_STAR",
  "POST_ALL_STAR",
  "SEASON_TYPE",
  "HOME_TEAM",
  "AWAY_TEAM",
  "DIVISION",
  "LEAGUE",
  "PLAYER",
  "WINNING_TEAM",
  "EXTRA_INNINGS",
  "SERIES_TYPE",
  "INNING",
  "INNING_HALF",
  "EVENT_TYPE",
  "EVENT_DESCRIPTION",
  "EVENT_RUNS_SCORED",
  "EVENT_LEVERAGE_INDEX",
  "LINEUP_CHANGE",
  "STOLEN_BASE",
];

export type Input = Partial<{
  [ImportColumnType.conditionType]: string;
}>;

export function validate({ conditionType }: Input): Result {
  const err = pass();

  if (!conditionType) {
    return pushError(
      err,
      ImportColumnType.conditionType,
      "mandatory field which has to be selected"
    );
  }

  if (!validTypes.includes(ConvertToUpperSnakeCase(conditionType))) {
    return pushError(
      err,
      ImportColumnType.conditionType,
      `must be one of [${validTypes.join(", ")}]`
    );
  }

  return err;
}
