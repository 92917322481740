import React from "react";
import { Link } from "react-router-dom";

import { Icon } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

type Type = "production" | "stage" | "test";

const icon: Record<Type, keyof typeof Icon> = {
  test: "CombinationMarkTest",
  stage: "CombinationMarkStaging",
  production: "CombinationMark",
};

export interface CombinationMarkProps {
  type?: Type;
  className?: any;
}

export const CombinationMark = ({
  type = "production",
  className,
}: CombinationMarkProps) => {
  const IconType = Icon[icon[type]];
  return (
    <div
      className={classnames(className, "flex", "flex-row", "items-center")}
      data-e2e="atlas-logo"
    >
      <Link to="/">
        <IconType />
      </Link>
    </div>
  );
};
