import React, { useState } from "react";

import { Button, ButtonSize, Icon } from "@components/atoms";
import { CustomDialog } from "@components/molecules";

import { Card, CardProps } from "./Card";

export type GenericExportDialogProps = {
  disabled?: boolean;
  size?: ButtonSize;
} & Omit<CardProps, "onClose">;

export const GenericExportDialog = ({
  disabled,
  title,
  loading,
  selectableFields,
  initialSelectedNodeIds,
  onExportSubmission,
  size = "small",
  error,
}: GenericExportDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        disabled={disabled}
        size={size}
        color="default"
        onClick={() => setIsOpen(true)}
        icon={<Icon.Export className="text-black-100 mr-2" />}
        data-e2e="button-export"
      >
        Export
      </Button>
      <CustomDialog.Container
        isDialogOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Card
          title={title}
          loading={loading}
          selectableFields={selectableFields}
          initialSelectedNodeIds={initialSelectedNodeIds}
          onExportSubmission={onExportSubmission}
          error={error}
          onClose={() => setIsOpen(false)}
        />
      </CustomDialog.Container>
    </>
  );
};
