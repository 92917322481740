import React from "react";

import { Item } from "./Item";
import { ItemTitle } from "./ItemTitle";

interface ResponseItem {
  value: string;
  occurrences: number;
}

export interface ItemsProps {
  terms?: ResponseItem[];
  title: string;
  icon: React.ReactNode;
  caser?: (string: string) => string;
  onClick?: (item: ResponseItem) => Promise<void> | void;
}

export const Items = ({ title, terms, icon, caser, onClick }: ItemsProps) => {
  if (!terms || terms.length <= 0) {
    return null;
  }
  return (
    <>
      <ItemTitle className="px-2 mx-1 mt-2">{title}</ItemTitle>
      <>
        {terms?.map((responseItem) => (
          <Item
            responseItem={responseItem}
            onClick={onClick}
            className="p-2"
            icon={icon}
            key={responseItem.value}
            caser={caser}
          />
        ))}
      </>
    </>
  );
};
