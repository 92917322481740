import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import _ from "lodash";

import {
  Card,
  DoughnutChart,
  DoughnutValues,
  Icon,
  NoContent,
} from "@components/atoms";
import {
  SentimentCard_ListSentimentOccurrenceDocument,
  SentimentCard_ListSentimentOccurrenceQuery,
} from "@graphql/operations";
import { useVideo } from "@hooks/useVideo";

interface SentimentCardProps {
  videoId: string;
}

type SentimentItems = NonNullable<
  NonNullable<SentimentCard_ListSentimentOccurrenceQuery>["listSentimentOccurrence"]
>["items"];

const convertSentimentToDoughnutValues = (
  sentiments: SentimentItems = []
): DoughnutValues[] => {
  const orderedSentiments = _.orderBy(sentiments, "Timestamp", "desc");

  const sentiment =
    orderedSentiments.length > 0
      ? [
          {
            name: "Positive",
            value: orderedSentiments[0].SentimentScorePositive,
            color: "#0E337A",
          },
          {
            name: "Negative",
            value: orderedSentiments[0].SentimentScoreNegative,
            color: "#F25252",
          },
          {
            name: "Neutral",
            value: orderedSentiments[0].SentimentScoreNeutral,
            color: "#454DE2",
          },
          {
            name: "Mixed",
            value: orderedSentiments[0].SentimentScoreMixed,
            color: "#FC9226",
          },
        ]
      : [];

  return sentiment;
};

export const SentimentCard = ({ videoId }: SentimentCardProps) => {
  const { currentTimeRange } = useVideo();

  const { data, previousData, error, refetch } = useQuery(
    SentimentCard_ListSentimentOccurrenceDocument,
    {
      variables: {
        filter: {
          videoId: {
            eq: videoId,
          },
          timestamp: {
            between: [currentTimeRange.start, currentTimeRange.end],
          },
        },
        limit: 999,
      },
    }
  );

  const errorBody = error ? (
    <Card.ErrorRetry onRetry={() => refetch()} />
  ) : null;

  let sentiments: DoughnutValues[] = [];

  if (previousData?.listSentimentOccurrence?.items) {
    sentiments = convertSentimentToDoughnutValues(
      previousData?.listSentimentOccurrence?.items
    );
  }

  if (data?.listSentimentOccurrence?.items) {
    sentiments = convertSentimentToDoughnutValues(
      data?.listSentimentOccurrence?.items
    );
  }

  let noDataBody;

  if (sentiments.length === 0) {
    noDataBody = <NoContent />;
  }

  return (
    <Card.Container>
      <Card.Header>Current Sentiment</Card.Header>
      <Card.Body>
        {errorBody || noDataBody || (
          <DoughnutChart title="" data={sentiments} height={100} />
        )}
      </Card.Body>
      <Card.Footer className="text-right flex justify-end items-center">
        <Link to={`/insights/${videoId}/sentiments`}>
          <span className="hover:underline">Overview </span>
          <Icon.ChevronRight color="black-200" className="inline" />
        </Link>
      </Card.Footer>
    </Card.Container>
  );
};
