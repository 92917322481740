import React from "react";

import { Icon } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

export interface ErrorLoadingImageProps {
  className?: any;
  size?: "small" | "medium";
}

export const ErrorLoadingImage = ({
  className,
  size = "medium",
  ...rest
}: ErrorLoadingImageProps) => (
  <div
    className={classnames(
      className,
      "bg-lightGrey-200",
      "text-coolGrey-300",
      "flex",
      "flex-col",
      "justify-center",
      "items-center"
    )}
    {...rest}
  >
    <Icon.Exclamation
      className={classnames({
        "mb-6": size === "medium",
      })}
      size={20}
    />
    {size === "medium" && (
      <div className="uppercase text-h8 font-semibold">Error Loading Image</div>
    )}
  </div>
);
