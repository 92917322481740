import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const OutlineTwo = createSvgIcon({
  content: (
    <>
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13.6828C0 13.4502 0.188264 13.2617 0.420499 13.2617H4.01577C4.248 13.2617 4.43627 13.4502 4.43627 13.6828C4.43627 13.9153 4.248 14.1038 4.01577 14.1038H0.420499C0.188264 14.1038 0 13.9153 0 13.6828Z"
        fill="#303030"
      />
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.99918C0 7.76664 0.188264 7.57812 0.420499 7.57812H10.8489C11.0811 7.57812 11.2694 7.76664 11.2694 7.99918C11.2694 8.23172 11.0811 8.42023 10.8489 8.42023H0.420499C0.188264 8.42023 0 8.23172 0 7.99918Z"
        fill="#303030"
      />
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.31558C0 2.08304 0.188264 1.89453 0.420499 1.89453H7.06439C7.29662 1.89453 7.48489 2.08304 7.48489 2.31558C7.48489 2.54812 7.29662 2.73664 7.06439 2.73664H0.420499C0.188264 2.73664 0 2.54812 0 2.31558Z"
        fill="#303030"
      />
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.06682 12.2093C4.77343 12.2093 4.54119 12.4418 4.54119 12.7356V14.6303C4.54119 14.9241 4.77343 15.1567 5.06682 15.1567C5.36021 15.1567 5.59244 14.9241 5.59244 14.6303V12.7356C5.59244 12.4449 5.35274 12.2093 5.06682 12.2093ZM3.7002 12.7356C3.7002 11.9768 4.30896 11.3672 5.06682 11.3672C5.81112 11.3672 6.43344 11.9737 6.43344 12.7356V14.6303C6.43344 15.3892 5.82468 15.9988 5.06682 15.9988C4.30896 15.9988 3.7002 15.3892 3.7002 14.6303V12.7356Z"
        fill="#303030"
      />
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7953 6.5257C11.5019 6.5257 11.2697 6.75824 11.2697 7.05201V8.94675C11.2697 9.24052 11.5019 9.47307 11.7953 9.47307C12.0887 9.47307 12.321 9.24052 12.321 8.94675V7.05201C12.321 6.76128 12.0813 6.5257 11.7953 6.5257ZM10.4287 7.05201C10.4287 6.29316 11.0375 5.68359 11.7953 5.68359C12.5396 5.68359 13.162 6.29012 13.162 7.05201V8.94675C13.162 9.70561 12.5532 10.3152 11.7953 10.3152C11.0375 10.3152 10.4287 9.70561 10.4287 8.94675V7.05201Z"
        fill="#303030"
      />
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01018 0.842105C7.71679 0.842105 7.48455 1.07465 7.48455 1.36842V3.26316C7.48455 3.55693 7.71679 3.78947 8.01018 3.78947C8.30357 3.78947 8.5358 3.55693 8.5358 3.26316V1.36842C8.5358 1.07768 8.2961 0.842105 8.01018 0.842105ZM6.64355 1.36842C6.64355 0.609564 7.25232 0 8.01018 0C8.75448 0 9.3768 0.606528 9.3768 1.36842V3.26316C9.3768 4.02201 8.76804 4.63158 8.01018 4.63158C7.25232 4.63158 6.64355 4.02201 6.64355 3.26316V1.36842Z"
        fill="#303030"
      />
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.53565 2.3071C8.53601 2.07456 8.72458 1.88635 8.95681 1.88672L15.5797 1.89725C15.8119 1.89761 15.9999 2.08643 15.9995 2.31897C15.9991 2.55151 15.8106 2.73972 15.5783 2.73935L8.95548 2.72882C8.72324 2.72845 8.53528 2.53964 8.53565 2.3071Z"
        fill="#303030"
      />
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3208 8.02262C12.3208 7.79007 12.5091 7.60156 12.7413 7.60156H15.5797C15.8119 7.60156 16.0002 7.79007 16.0002 8.02262C16.0002 8.25516 15.8119 8.44367 15.5797 8.44367H12.7413C12.5091 8.44367 12.3208 8.25516 12.3208 8.02262Z"
        fill="#303030"
      />
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.69775 13.5343C5.69775 13.3018 5.88602 13.1133 6.11825 13.1133H15.5795C15.8117 13.1133 16 13.3018 16 13.5343C16 13.7669 15.8117 13.9554 15.5795 13.9554H6.11825C5.88602 13.9554 5.69775 13.7669 5.69775 13.5343Z"
        fill="#303030"
      />
    </>
  ),
  width: 16,
  height: 16,
});
