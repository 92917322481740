import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const CombinationMark = createSvgIcon({
  content: (
    <>
      <path
        d="M41.2594 2.12854L48.812 17.8312H46.0597L44.7156 14.759H37.5256L36.1816 17.8312H33.5146L41.0247 2.12854H41.2594ZM40.1927 8.8063L38.4858 12.604H43.7769L42.0915 8.82773L41.174 6.67287H41.1313L40.1927 8.8063Z"
        className="fill-current"
      />
      <path
        d="M56.1086 4.24067H52.0977V2.14984H62.5306V4.24067H58.4769V17.831H56.1086V4.24067Z"
        className="fill-current"
      />
      <path
        d="M69.1016 2.14984H71.5124V15.6976H76.0142V17.8312H69.1016V2.14984Z"
        className="fill-current"
      />
      <path
        d="M88.3248 2.12854L95.8774 17.8312H93.1251L91.781 14.759H84.591L83.247 17.8312H80.5801L88.0901 2.12854H88.3248ZM87.258 8.8063L85.5511 12.604H90.8422L89.1568 8.82773L88.2395 6.67287H88.1967L87.258 8.8063Z"
        className="fill-current"
      />
      <path
        d="M102.533 17.5964C101.694 17.2978 101.089 16.9637 100.72 16.5936L101.85 14.5668C102.149 14.8372 102.615 15.1183 103.248 15.4096C103.881 15.7013 104.489 15.847 105.072 15.847C105.826 15.847 106.441 15.6692 106.918 15.3136C107.394 14.9582 107.632 14.4675 107.632 13.8415C107.632 13.358 107.504 12.9384 107.248 12.5826C106.992 12.2273 106.679 11.9319 106.309 11.6973C105.939 11.4625 105.413 11.1746 104.731 10.8332C103.92 10.4351 103.365 10.1364 103.066 9.93716C101.687 9.01275 100.997 7.73262 100.997 6.09687C100.997 4.74576 101.445 3.711 102.341 2.99259C103.237 2.27442 104.368 1.9151 105.733 1.9151C107.198 1.9151 108.443 2.33475 109.467 3.17393L108.336 5.11538C108.066 4.83098 107.686 4.58573 107.195 4.37925C106.704 4.17326 106.182 4.06996 105.627 4.06996C104.916 4.06996 104.357 4.22999 103.952 4.55006C103.547 4.87 103.344 5.34304 103.344 5.9688C103.344 6.40975 103.472 6.80451 103.728 7.15282C103.984 7.50161 104.307 7.8036 104.699 8.05962C105.09 8.31553 105.62 8.62865 106.288 8.99839C106.928 9.354 107.419 9.63517 107.761 9.84104C108.102 10.0474 108.422 10.2928 108.721 10.5772C109.133 10.9473 109.467 11.3879 109.723 11.8999C109.979 12.412 110.107 12.974 110.107 13.5853C110.107 14.5385 109.89 15.3491 109.457 16.0175C109.023 16.6863 108.425 17.191 107.665 17.5323C106.903 17.8736 106.039 18.0443 105.072 18.0443C104.219 18.0444 103.372 17.8951 102.533 17.5964Z"
        className="fill-current"
      />
      <path
        d="M10.771 10.8031L6.35725 8.57267L10.0473 12.4977L6.8397 20H0L8.54962 0L11.9695 8.00015L10.771 10.8031Z"
        className="fill-current"
      />
      <path
        d="M17.0988 20L10.0469 12.4977L10.7705 10.803L13.827 12.3466L14.5339 14L17.0988 20Z"
        className="fill-current"
      />
      <path
        d="M14.9864 0.610687L23.0139 19.3893H17.5025L15.0961 13.76L14.3892 12.1066L12.5312 7.76L9.475 0.610687H14.9864ZM15.3895 0H8.5498L11.9697 8L13.8277 12.3466L14.5345 14L17.0994 20H23.9391L15.3895 0Z"
        className="fill-current"
      />
    </>
  ),
  width: 112,
  height: 20,
});
