import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import { Button, Card, Icon } from "@components/atoms";
import { ImportColumnType, useValidate } from "@hooks/segments";

import { ReviewErrorsCell } from "./ReviewErrorsCell";

const QUERY = gql`
  query ReviewSegmentImportErrorData {
    segmentImport @client {
      numberOfColumns
      query
      data
    }
  }
`;

export const ReviewErrors = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const returnTo = search.get("returnTo") || "/segments/new";

  const { validate } = useValidate();
  const [firstRenderData, setFirstRenderData] =
    useState<{ [key in ImportColumnType]: string }[]>();
  const { data: segmentImportData, loading } = useQuery<{
    segmentImport?: {
      data?: { [key in ImportColumnType]: string }[];
      numberOfColumns?: number;
    };
  }>(QUERY, {
    returnPartialData: true,
  });

  useEffect(() => {
    if (!loading && !firstRenderData && segmentImportData?.segmentImport) {
      const {
        segmentImport: { data },
      } = segmentImportData;
      setFirstRenderData(data);
    }
  }, [loading, firstRenderData, segmentImportData]);

  if (loading) {
    return null;
  }

  if (!segmentImportData?.segmentImport?.data) {
    navigate("/segments/import");
    return null;
  }

  const {
    segmentImport: { numberOfColumns, data },
  } = segmentImportData;

  const hasFirstRenderErrors = firstRenderData?.some(
    (row) => !validate(row).success
  );

  let content = null;

  if (!hasFirstRenderErrors) {
    content = (
      <>
        <h4 className="mb-8 text-navy-300 flex items-center gap-2">
          <Icon.CheckmarkOutline />
          <span>No errors</span>
        </h4>

        <p className="text-bodySm">There are no errors detected.</p>
      </>
    );
  } else {
    content = (
      <>
        <h4 className="mb-8 text-navy-300">Review Errors</h4>
        <table className="table-fixed">
          <colgroup>
            <col className="w-10 min-w-10 max-w-10" />
            <col className="w-2/10 min-w-2/10 max-w-2/10" />
            <col className="w-2/10 min-w-2/10 max-w-2/10" />
            <col className="w-1/10 min-w-1/10 max-w-1/10" />
            <col className="w-1/10 min-w-1/10 max-w-1/10" />
            <col className="w-2/10 min-w-2/10 max-w-2/10" />
          </colgroup>
          <thead>
            <tr className="text-black-200 text-h8 font-bold uppercase h-10">
              <th />
              <th>Condition Type</th>
              <th>Condition Value</th>
              <th>Conf. Score</th>
              <th>Within</th>
              <th>Operator</th>
            </tr>
          </thead>
          <tbody>
            {(firstRenderData || []).map((row, rowIdx) => {
              const updatedRow = data[rowIdx];
              const result = validate(row);

              if (result.success) {
                return null;
              }

              const updatedResults = validate(updatedRow);

              const errors = updatedResults.success ? {} : updatedResults.error;

              return (
                <tr className="h-10" key={rowIdx}>
                  <td className="text-bodySm font-semibold">{rowIdx + 1}</td>
                  <ReviewErrorsCell
                    rowIndex={rowIdx + 1}
                    type={ImportColumnType.conditionType}
                    errors={errors}
                    originalRow={row}
                    updatedRow={updatedRow}
                  />
                  <ReviewErrorsCell
                    rowIndex={rowIdx + 1}
                    type={ImportColumnType.conditionValue}
                    errors={errors}
                    originalRow={row}
                    updatedRow={updatedRow}
                  />
                  <ReviewErrorsCell
                    rowIndex={rowIdx + 1}
                    type={ImportColumnType.confidenceScore}
                    errors={errors}
                    originalRow={row}
                    updatedRow={updatedRow}
                  />
                  <ReviewErrorsCell
                    rowIndex={rowIdx + 1}
                    type={ImportColumnType.tolerance}
                    errors={errors}
                    originalRow={row}
                    updatedRow={updatedRow}
                  />
                  <ReviewErrorsCell
                    rowIndex={rowIdx + 1}
                    type={ImportColumnType.operator}
                    errors={errors}
                    originalRow={row}
                    updatedRow={updatedRow}
                  />
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }

  return (
    <div className="p-8 flex-1">
      <Card.Container className="p-10 mb-6">{content}</Card.Container>
      <div className="flex justify-between px-10 mb-6">
        <Button
          onClick={() =>
            navigate({ pathname: `/segments/import/fields/${numberOfColumns}` })
          }
          variant="text"
        >
          Back
        </Button>
        <Button
          onClick={() => navigate({ pathname: returnTo })}
          type="button"
          size="medium"
          color="primary"
          data-e2e="review-error-continue-button"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
