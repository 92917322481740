import React, { type ReactNode } from "react";

import { classnames } from "@external/tailwindcss-classnames";

import { Button } from "../Button";
import * as Icon from "../Icon";

export interface LabelProps {
  className?: any;
  removeable?: boolean;
  onRemoveClicked?: () => Promise<void> | void;
  onClick?: () => void | Promise<void>;
  children: ReactNode;
}

export const Label = ({
  children,
  className,
  onClick,
  removeable,
  onRemoveClicked,
  ...rest
}: LabelProps) => {
  return (
    <div
      onClick={onClick}
      className={classnames(
        className,
        "rounded-full",
        "px-3",
        "inline-flex",
        "items-center",
        "h-7"
      )}
      {...rest}
    >
      {children}
      {removeable && (
        <Button
          data-testid="remove-button"
          className="ml-4"
          type="button"
          variant="text"
          color="clear"
          onClick={onRemoveClicked}
        >
          <Icon.Close
            className="text-coolGrey-300 hover:text-coolGrey-400"
            size={16}
          />
        </Button>
      )}
    </div>
  );
};
