import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Return = createSvgIcon({
  content: (
    <>
      <path
        d="M12.6667 5.83333V7.83333C12.6667 8.56971 12.0697 9.16666 11.3333 9.16666H4"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M5.33203 11.1667L3.33203 9.16667L5.33203 7.16667"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </>
  ),
  width: 16,
  height: 17,
});
