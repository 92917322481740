import React, { ChangeEvent, useState } from "react";
import { useDebounce } from "react-use";

import {
  DialogBox,
  Loading,
  RadioButton,
  Segment,
  TextInput,
} from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";
import { useFilters } from "@hooks/segments";

export type SegmentDetailsProps = {
  operation: Segment.OperationOptions;
  loading?: boolean;
};

const description: { [key in Segment.OperationOptions]: string } = {
  [Segment.OperationOptions.Create]: "Create a new Segment",
  [Segment.OperationOptions.Edit]: "Edit Segment",
  [Segment.OperationOptions.Draft]: "Edit Draft Segment",
};

enum SegmentTypes {
  AtlasCustom = "Atlas Custom",
  FwContextual = "Freewheel Contextual",
}

export const SegmentDetails = ({ operation, loading }: SegmentDetailsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    setSegmentTitle,
    segmentTitle,
    segmentDescription,
    setSegmentDescription,
    segmentId,
    setSegmentId,
  } = useFilters();

  // const [segmentId, setSegmentId] = useState<string>();
  const [tempSegmentTitle, setTempSegmentTitle] = useState(() => segmentTitle);
  const [tempSegmentId, setTempSegmentId] = useState(() => segmentId);
  const [tempSegmentDescription, setTempSegmentDescription] = useState(
    () => segmentDescription
  );

  useDebounce(
    () => {
      setSegmentTitle(tempSegmentTitle);
    },
    200,
    [tempSegmentTitle]
  );

  useDebounce(
    () => {
      setSegmentDescription(tempSegmentDescription);
    },
    200,
    [tempSegmentDescription]
  );

  useDebounce(
    () => {
      if (selectedSegmentType === "Atlas Custom") {
        setSegmentId("");
      } else {
        setSegmentId(tempSegmentId);
      }
    },
    200,
    [tempSegmentId]
  );

  const [selectedSegmentType, setSelectedSegmentType] = useState<string>();

  const handleSelectSentimentEvent = (e: ChangeEvent<HTMLInputElement>) => {
    const chosenSentiment = e.target.value;
    setSelectedSegmentType(chosenSentiment);
    //onChange({ value: chosenSentiment, within });
  };

  return (
    <div data-e2e="segment-details">
      <h4 className="text-navy-300 mb-6">{description[operation]}</h4>
      <div className="relative">
        {loading && (
          <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
            <Loading.Slide size={5} />
          </div>
        )}
        <div
          className={classnames({
            invisible: loading,
          })}
        >
          <div className="mb-6">
            <label
              htmlFor="segment-title"
              className="font-semibold text-xs text-navy-400 mb-2"
            >
              Segment title*
            </label>
            <TextInput
              id="segment-title"
              data-e2e="segment-title"
              value={tempSegmentTitle}
              onChange={(v) => setTempSegmentTitle(v.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="description"
              className="font-semibold text-xs text-navy-400 mb-2"
            >
              Description - optional
            </label>
            <TextInput
              id="description"
              data-e2e="segment-description"
              multiline
              rows={4}
              value={tempSegmentDescription}
              onChange={(v) => setTempSegmentDescription(v.target.value)}
            />
          </div>

          <div className="grid grid-cols-4 gap-x-4">
            {Object.entries(SegmentTypes).map(([k, v]) => (
              <div key={k} className="flex items-center h-10">
                <RadioButton
                  data-testid={k}
                  name={v}
                  onChange={(e) => handleSelectSentimentEvent(e)}
                  value={v}
                  checked={selectedSegmentType === v}
                >
                  {v}
                </RadioButton>
              </div>
            ))}
          </div>
          {selectedSegmentType !== "Freewheel Contextual" ? null : (
            <div className="mb-6">
              <label
                htmlFor="ID"
                className="font-semibold text-xs text-navy-400 mb-2"
              >
                ID
              </label>
              <TextInput
                id="ID"
                data-e2e="segment-id"
                value={tempSegmentId}
                onChange={(v) => setTempSegmentId(v.target.value)}
                required
              />
            </div>
          )}
        </div>
      </div>

      <DialogBox.Container
        isDialogOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DialogBox.Card onClose={() => setIsOpen(false)}>
          <DialogBox.Title>Campaign end date</DialogBox.Title>
          <p className="text-sm">
            The Segment will be automatically deactivated at the end date
            specified. To “re-launch” a deactivated Segment, simply update the
            end date to a date in the future. For “evergreen” Segments, specify
            a date far into the future e.g., 01/01/3022
          </p>
        </DialogBox.Card>
      </DialogBox.Container>
    </div>
  );
};
