import React, { type ReactNode } from "react";

type ContainerProps = {
  children: ReactNode;
};

export const Container = ({ children }: ContainerProps) => (
  <div className="bg-coolGrey-200 rounded p-3 text-bodySm max-h-27 overflow-y-auto">
    {children}
  </div>
);
