import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { ErrorFallback, Header } from "@components/molecules";
import {
  Insights,
  InsightsTables,
  JobsOverview,
  Login,
  NotFound,
  SegmentImport,
  SegmentJobDetails,
  SegmentsJobDuplicate,
  SegmentsJobEdit,
  SegmentsNew,
  SegmentsOverview,
  SessionLoader,
} from "@components/pages";
import * as SegmentImportStages from "@components/pages/SegmentImport/Stages";
import { classnames } from "@external/tailwindcss-classnames";
import { useAuth } from "@hooks/useAuth";

function CustomRoutes() {
  const { initializing, isAuthenticated } = useAuth();

  if (initializing) {
    return <SessionLoader />;
  }

  const publicRoutes = (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );

  const authRoutes = (
    <main className={classnames("min-h-screen", "flex", "flex-col")}>
      <Header />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Routes>
          <Route
            path="/error"
            element={
              <ErrorFallback error={new Error("Something went wrong")} />
            }
          />
          <Route path="/" element={<Navigate to="/segments" />} />

          <Route path="videos" element={<JobsOverview />} />
          <Route path="videos/:id" element={<JobsOverview />} />

          <Route path="insights" element={<InsightsTables />} />
          <Route path="insights/:id/*" element={<Insights />} />

          <Route path="segments" element={<SegmentsOverview />} />
          <Route path="segments/new" element={<SegmentsNew />} />
          <Route path="/segments/:id" element={<SegmentJobDetails />} />
          <Route
            path="/segments/:id/version/:version"
            element={<SegmentJobDetails />}
          />
          <Route
            path="segments/:id/version/:version/duplicate"
            element={<SegmentsJobDuplicate />}
          />
          <Route
            path="segments/:id/version/:version/edit"
            element={<SegmentsJobEdit />}
          />
          <Route path="segments/import" element={<SegmentImport />}>
            <Route path="" element={<SegmentImportStages.ImportCsv />} />
            <Route
              path="fields/:columnNumber"
              element={<SegmentImportStages.FieldMapping />}
            />
            <Route
              path="errors"
              element={<SegmentImportStages.ReviewErrors />}
            />
            <Route path="*" element={<Navigate to="/segments/import" />} />
          </Route>
          <Route path="login/callback/*" element={<Navigate to="/" />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ErrorBoundary>
    </main>
  );

  return (
    <BrowserRouter>
      {!isAuthenticated && publicRoutes}
      {isAuthenticated && authRoutes}
    </BrowserRouter>
  );
}

export default CustomRoutes;
