import React, { HTMLAttributes } from "react";

import { Icon } from "@components/atoms";
import { useVideo } from "@hooks/useVideo";

export interface ErrorProps extends HTMLAttributes<HTMLDivElement> {}

export const Error = () => {
  const { videoError } = useVideo();
  return (
    <div className="flex flex-col h-full items-center justify-center">
      <Icon.Exclamation color="white" />
      <h6 className="text-center mt-6 font-semibold text-coolGrey-300">
        {videoError || "A FATAL ERROR HAS OCCURRED"}
      </h6>
    </div>
  );
};
