import React from "react";

import { Time } from "@components/atoms";
import { Table } from "@components/molecules";
import { SpokenNounsTable_SpokenNounOccurrenceFragment } from "@graphql/operations";

import { InsightsTable, InsightsTableProps } from "../InsightsTables";

export type SpokenNounsTableProps = {
  loading: boolean;
  spokenNouns?: SpokenNounsTable_SpokenNounOccurrenceFragment[];
} & Pick<InsightsTableProps, "loading" | "loadingRows">;

// this component renders a table of spokenNouns
export const SpokenNounsTable = ({
  spokenNouns = [],
  loading,
  loadingRows,
}: SpokenNounsTableProps) => (
  <InsightsTable
    columns={[
      { title: "Name", width: "w-8/12" },
      { title: "Timestamp", width: "w-2/12" },
      { title: "Confidence", width: "w-2/12" },
    ]}
    loading={loading}
    loadingRows={loadingRows}
    loadingRowsHeight="h-10"
  >
    {spokenNouns.length > 0
      ? spokenNouns.map((spokenNoun) => (
          <Table.Row
            key={`${spokenNoun.Type}_${spokenNoun.Timestamp}`}
            height="h-10"
          >
            <Table.Cell className="font-bold">{spokenNoun.Text}</Table.Cell>
            <Table.Cell>
              <Time>{spokenNoun.Timestamp}</Time>
            </Table.Cell>
            <Table.Cell>{spokenNoun.Confidence?.toFixed(2) || "-"}</Table.Cell>
          </Table.Row>
        ))
      : null}
  </InsightsTable>
);
