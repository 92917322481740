import React, { ReactNode } from "react";
import { Transition } from "@headlessui/react";

import { ChevronDown } from "@components/atoms/Icon/ChevronDown";
import { ChevronUp } from "@components/atoms/Icon/ChevronUp";

interface ShowAdvancedProps {
  isAdvancedOpen: boolean;
  setIsAdvancedOpen: (isAdvanedOpen: boolean) => void;
  prependHeader?: React.ReactNode;
  children: ReactNode;
}
export const ShowAdvanced = ({
  isAdvancedOpen,
  setIsAdvancedOpen,
  prependHeader,
  children,
}: ShowAdvancedProps) => (
  <div className="w-full flex flex-col">
    <div
      className={`cursor-pointer flex ${
        prependHeader ? "justify-between" : "justify-end"
      } items-center`}
      onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
    >
      {!!prependHeader && prependHeader}
      <div className="flex items-center">
        <button className="text-xs font-semibold mr-2">Show Advanced</button>
        {!isAdvancedOpen ? <ChevronDown /> : <ChevronUp />}
      </div>
    </div>
    <Transition
      show={isAdvancedOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      {children}
    </Transition>
  </div>
);
