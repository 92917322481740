import React, { useRef } from "react";

import { Checkbox, Icon } from "@components/atoms";

import { useTreeViewModel } from "../hooks";
import { SelectionState, TreeNode } from "../model";

export const TreeViewItemHeader = ({
  node,
  selectionState,
  ...props
}: {
  node: TreeNode;
  selectionState: SelectionState;
}) => {
  const context = useTreeViewModel();
  const ref = useRef<any>();

  if (ref.current) {
    if (node.selectionState === "indeterminate") {
      ref.current.indeterminate = true;
    } else {
      ref.current!.indeterminate = false;
    }
  }

  return (
    <div className="mb-5" {...props}>
      <Checkbox
        ref={ref}
        checked={selectionState === "on" ? true : false}
        className="font-semibold text-sm"
        data-e2e="checkbox"
        onChange={() => {
          context.updateSelectionState(
            node.id,
            node.selectionState === "on" ? "off" : "on"
          );
        }}
      >
        <div
          className="flex flex-row items-center cursor-pointer"
          onClick={(e) => {
            e.preventDefault();

            if (node.children.length > 0) {
              context.toggleOpenNode(node.id);
            }
          }}
        >
          {node.name}
          {node.children.length > 0 &&
            (node.isOpen ? (
              <Icon.ChevronUp className="ml-3" />
            ) : (
              <Icon.ChevronDown className="ml-3" />
            ))}
        </div>
      </Checkbox>
    </div>
  );
};
