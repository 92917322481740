import React, { useState } from "react";
import { camelCase, groupBy, startCase } from "lodash";

import { ConditionSummary } from "@components/atoms";
import { CustomDialog } from "@components/molecules";
import {
  QueryCondition,
  QueryConditionTuple,
  QueryConditionType,
} from "@components/types";

interface QuerySummaryProps {
  queryConditions: QueryCondition[];
  title: string;
}

type ConditionItem = {
  value: string;
  title: string;
  version?: string;
  conditions?: QueryCondition[];
};

export const formatMs = (ms: number) => {
  const minutes = Math.floor(ms / 1000 / 60);
  const seconds = ms / 1000 - minutes * 60;

  if (ms >= 60000) {
    return `${minutes}m${seconds ? ` ${seconds}s` : ""}`;
  } else {
    return `${seconds}s`;
  }
};

const createConditionItems = (
  queryCondition: QueryConditionTuple
): ConditionItem[] => {
  const [type, variables] = queryCondition;

  const title = QueryConditionTypeMapping[type];

  switch (type) {
    case QueryConditionType.VIDEO_DURATION__EQUALS: {
      return [
        {
          title,
          value: [variables.minDuration, "x", variables.maxDuration]
            .filter(Boolean)
            .map((v) => (typeof v === "number" ? formatMs(v) : v))
            .join(" < "),
        },
      ];
    }

    case QueryConditionType.NESTED_SEGMENT_ID__EQUALS: {
      return [
        {
          title,
          value: variables.value,
          version: variables.version.toString(),
        },
      ];
    }

    case QueryConditionType.MIN_OCCURRENCE__COUNT: {
      return [
        {
          title,
          value: `${variables.minCount}`,
        },
        ...formatQueryCondtions(variables.conditions),
      ];
    }

    case QueryConditionType.SENTIMENT__EQUALS:
    case QueryConditionType.VIDEO_TITLE__CONTAINS:
    case QueryConditionType.TEXT_IN_VIDEO__CONTAINS:
    case QueryConditionType.METADATA__CONTAINS:
    case QueryConditionType.TRANSCRIPT__CONTAINS:
    case QueryConditionType.CELEBRITY__EQUALS:
    case QueryConditionType.LABEL__EQUALS: {
      return [
        {
          title,
          value: `${variables.value}`,
        },
      ];
    }

    case QueryConditionType.CONTENT_MODERATION__EQUALS: {
      return variables.values.map((value) => ({
        title,
        value,
      }));
    }

    case QueryConditionType.RUN_DIFFERENTIAL__COMPARE:
    case QueryConditionType.INNING__COMPARE:
    case QueryConditionType.EVENT_RUNS_SCORED__COMPARE:
    case QueryConditionType.EVENT_LEVERAGE_INDEX__COMPARE:
    case QueryConditionType.CLOSE_GAME__EQUALS:
    case QueryConditionType.DELAYED_GAME__EQUALS:
    case QueryConditionType.DOUBLE_HEADER__EQUALS:
    case QueryConditionType.NIGHT_GAME__EQUALS:
    case QueryConditionType.VENUE__EQUALS:
    case QueryConditionType.PRE_ALL_STAR__EQUALS:
    case QueryConditionType.POST_ALL_STAR__EQUALS:
    case QueryConditionType.SEASON_TYPE__EQUALS:
    case QueryConditionType.HOME_TEAM__EQUALS:
    case QueryConditionType.AWAY_TEAM__EQUALS:
    case QueryConditionType.DIVISION__EQUALS:
    case QueryConditionType.LEAGUE__EQUALS:
    case QueryConditionType.PLAYER__EQUALS:
    case QueryConditionType.WINNING_TEAM__EQUALS:
    case QueryConditionType.EXTRA_INNINGS__EQUALS:
    case QueryConditionType.SERIES_TYPE__EQUALS:
    case QueryConditionType.INNING_HALF__EQUALS:
    case QueryConditionType.EVENT_TYPE__EQUALS:
    case QueryConditionType.EVENT_DESCRIPTION__CONTAINS:
    case QueryConditionType.LINEUP_CHANGE__EQUALS:
    case QueryConditionType.STOLEN_BASE__EQUALS: {
      return [
        {
          title,
          value: `${variables.value}`,
        },
      ];
    }
  }
};

const formatQueryCondtions = (qc: QueryCondition[]): ConditionItem[] => {
  return qc
    .flatMap(Object.entries)
    .reduce<ConditionItem[]>(
      (acc, queryCondition) => [
        ...acc,
        ...createConditionItems(queryCondition as QueryConditionTuple),
      ],
      []
    );
};

export const renderConditionItems = (
  ci: ConditionItem[],
  startIdx: number,
  endIdx?: number
) => {
  return ci.slice(startIdx, endIdx).map((props, idx) => {
    // We don't want to format values like IDs and similar
    // and properties like segment ID, require an extra version field
    switch (props.title) {
      case QueryConditionTypeMapping[
        QueryConditionType.NESTED_SEGMENT_ID__EQUALS
      ]:
        return (
          <ConditionSummary.Item
            key={idx}
            title={props.title}
            values={[props.value]}
            link={`/segments/${props.value}/version/${props.version}`}
          />
        );
      case QueryConditionTypeMapping[QueryConditionType.VIDEO_DURATION__EQUALS]:
        return (
          <ConditionSummary.Item
            key={idx}
            title={props.title}
            values={[props.value]}
          />
        );
      case QueryConditionTypeMapping[QueryConditionType.TRANSCRIPT__CONTAINS]:
        return (
          <ConditionSummary.Item
            key={idx}
            title={props.title}
            values={[props.value]}
          />
        );
      case QueryConditionTypeMapping[QueryConditionType.VIDEO_TITLE__CONTAINS]:
        return (
          <ConditionSummary.Item
            key={idx}
            title={props.title}
            values={[props.value]}
          />
        );

      default:
        return (
          <ConditionSummary.Item
            key={idx}
            title={props.title}
            values={[startCase(camelCase(props.value))]}
          />
        );
    }
  });
};

export const renderGroupedConditionItems = (conditionItem: ConditionItem[]) => {
  const sortedConditionItems = conditionItem.sort((a, b) =>
    a.value.localeCompare(b.value)
  );
  const groupedConditionItems = groupBy(sortedConditionItems, "title");

  return Object.keys(groupedConditionItems)
    .sort((a, b) => a.localeCompare(b))
    .map((title) => {
      let content = null;

      switch (title) {
        case QueryConditionTypeMapping[
          QueryConditionType.NESTED_SEGMENT_ID__EQUALS
        ]:
          content = groupedConditionItems[title].map(
            ({ value, version }, index) => (
              <ConditionSummary.Item
                key={index + value}
                values={[value]}
                link={`/segments/${value}/version/${version}`}
              />
            )
          );
          break;
        case QueryConditionTypeMapping[
          QueryConditionType.VIDEO_DURATION__EQUALS
        ]:
          content = groupedConditionItems[title].map(({ value }, index) => (
            <ConditionSummary.Item key={index + value} values={[value]} />
          ));
          break;
        case QueryConditionTypeMapping[QueryConditionType.TRANSCRIPT__CONTAINS]:
          content = groupedConditionItems[title].map(({ value }, index) => (
            <ConditionSummary.Item key={index + value} values={[value]} />
          ));
          break;
        case QueryConditionTypeMapping[
          QueryConditionType.VIDEO_TITLE__CONTAINS
        ]:
          content = groupedConditionItems[title].map(({ value }, index) => (
            <ConditionSummary.Item key={index + value} values={[value]} />
          ));
          break;
        default:
          content = groupedConditionItems[title].map(({ value }, index) => (
            <ConditionSummary.Item
              key={index + value}
              values={[startCase(camelCase(value))]}
            />
          ));
          break;
      }

      return (
        <div key={title} data-testid="grouped-condition-item">
          <div className="font-medium">
            {title} ({groupedConditionItems[title].length}):
          </div>
          <div className="p-2">{content}</div>
        </div>
      );
    });
};

type QueryConditionTypeMappingRecord = { [key in QueryConditionType]: string };

const QueryConditionTypeMapping: QueryConditionTypeMappingRecord = {
  [QueryConditionType.MIN_OCCURRENCE__COUNT]: "Occurrences (Min)",
  [QueryConditionType.LABEL__EQUALS]: "Label",
  [QueryConditionType.CELEBRITY__EQUALS]: "Celebrity",
  [QueryConditionType.CONTENT_MODERATION__EQUALS]: "Content Moderation",
  [QueryConditionType.TRANSCRIPT__CONTAINS]: "Spoken Word Or Phrase",
  [QueryConditionType.VIDEO_TITLE__CONTAINS]: "Video Title",
  [QueryConditionType.SENTIMENT__EQUALS]: "Sentiment",
  [QueryConditionType.VIDEO_DURATION__EQUALS]: "Video Duration",
  [QueryConditionType.NESTED_SEGMENT_ID__EQUALS]: "Segment ID",
  [QueryConditionType.TEXT_IN_VIDEO__CONTAINS]: "Text In Video",
  [QueryConditionType.METADATA__CONTAINS]: "FMC Metadata",
  [QueryConditionType.RUN_DIFFERENTIAL__COMPARE]: "Run Differential",
  [QueryConditionType.INNING__COMPARE]: "Inning",
  [QueryConditionType.EVENT_LEVERAGE_INDEX__COMPARE]: "Event Leverage Index",
  [QueryConditionType.EVENT_RUNS_SCORED__COMPARE]: "Event Runs Scored",

  [QueryConditionType.CLOSE_GAME__EQUALS]: "Close Game",
  [QueryConditionType.DELAYED_GAME__EQUALS]: "Delayed Game",
  [QueryConditionType.DOUBLE_HEADER__EQUALS]: "Double Header",
  [QueryConditionType.NIGHT_GAME__EQUALS]: "Night Game",
  [QueryConditionType.VENUE__EQUALS]: "Venue",
  [QueryConditionType.PRE_ALL_STAR__EQUALS]: "Pre All-Star",
  [QueryConditionType.POST_ALL_STAR__EQUALS]: "Post All-Star",
  [QueryConditionType.SEASON_TYPE__EQUALS]: "Season Type",
  [QueryConditionType.HOME_TEAM__EQUALS]: "Home Team",
  [QueryConditionType.AWAY_TEAM__EQUALS]: "Away Team",
  [QueryConditionType.DIVISION__EQUALS]: "Division",
  [QueryConditionType.LEAGUE__EQUALS]: "League",
  [QueryConditionType.PLAYER__EQUALS]: "Player",
  [QueryConditionType.WINNING_TEAM__EQUALS]: "Winning Team",
  [QueryConditionType.EXTRA_INNINGS__EQUALS]: "Extra Innings",
  [QueryConditionType.SERIES_TYPE__EQUALS]: "Series Type",
  [QueryConditionType.INNING_HALF__EQUALS]: "Inning Half",
  [QueryConditionType.EVENT_TYPE__EQUALS]: "Event Type",
  [QueryConditionType.EVENT_DESCRIPTION__CONTAINS]: "Event Description",
  [QueryConditionType.LINEUP_CHANGE__EQUALS]: "Lineup Change",
  [QueryConditionType.STOLEN_BASE__EQUALS]: "Stolen Base",
};

export const QuerySummary = ({
  queryConditions,
  title,
  ...rest
}: QuerySummaryProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const conditionItems = formatQueryCondtions(queryConditions);

  return (
    <div {...rest}>
      <div className="text-bodyXs text-navy-400 font-semibold mb-2">
        {title}:
      </div>
      <div className="flex flex-col bg-lightGrey-200 rounded-md p-3 text-bodySm">
        {renderConditionItems(conditionItems, 0, 3)}
        {conditionItems.length > 3 && (
          <p
            className="text-blue-300 font-semibold cursor-pointer mt-0.5"
            onClick={() => setIsExpanded(true)}
          >
            and {conditionItems.length - 3} more
          </p>
        )}
      </div>
      <CustomDialog.Container
        isDialogOpen={isExpanded}
        onClose={() => setIsExpanded(false)}
      >
        <CustomDialog.Card onClose={() => setIsExpanded(false)}>
          <CustomDialog.Title>Segment Conditions</CustomDialog.Title>
          <div className="text-bodyXs text-navy-400 font-semibold mb-3">
            {title}:
          </div>
          <div className="flex flex-col bg-lightGrey-200 rounded p-3 text-bodySm max-h-80 overflow-auto gap-2 overflow-y-scroll">
            {renderGroupedConditionItems(conditionItems)}
          </div>
        </CustomDialog.Card>
      </CustomDialog.Container>
    </div>
  );
};
