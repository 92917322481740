import React from "react";

import { Icon } from "@components/atoms";

import { IconButton } from "./IconButton";

export interface QuestionProps {
  className?: any;
}

export const Question = ({ className }: QuestionProps) => (
  <IconButton className={className}>
    <Icon.Question />
  </IconButton>
);
