import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Refresh = createSvgIcon({
  content: (
    <>
      <path
        d="M14.667 8L12.667 10L10.667 8"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M7.33333 13.3327C4.38781 13.3327 2 10.9449 2 7.99935C2 5.05383 4.38781 2.66602 7.33333 2.66602C10.2789 2.66602 12.6667 5.05383 12.6667 7.99935V9.33268"
        className="stroke-current"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </>
  ),
  width: 16,
  height: 16,
});
