import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const LineChart = createSvgIcon({
  content: (
    <>
      <path
        d="M2.6665 12V4C2.6665 3.64638 2.80698 3.30724 3.05703 3.05719C3.30708 2.80714 3.64622 2.66666 3.99984 2.66666H11.9998C12.3535 2.66666 12.6926 2.80714 12.9426 3.05719C13.1927 3.30724 13.3332 3.64638 13.3332 4V12C13.3332 12.3536 13.1927 12.6928 12.9426 12.9428C12.6926 13.1929 12.3535 13.3333 11.9998 13.3333H3.99984C3.64622 13.3333 3.30708 13.1929 3.05703 12.9428C2.80698 12.6928 2.6665 12.3536 2.6665 12Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.6665 9.33333L6.6665 7.33333L7.99984 8.66666L9.99984 6.66666L11.3332 8"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
  defaultColor: "navy-100",
});
