import { FileFormatError, ImportError } from "@hooks/segments";

export const FiletypeError = (
  errors: readonly ImportError[] | undefined
): FileFormatError | undefined => {
  if (!errors) {
    return;
  }
  for (let error of errors) {
    if (error.type === "FileFormat") {
      return error.payload;
    }
  }
};
