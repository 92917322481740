import React from "react";

import { Icon } from "@components/atoms";

export interface CopyProps {
  onClick?: () => Promise<void> | void;
}

export const Copy = ({ onClick }: CopyProps) => (
  <div>
    <Icon.Copy
      onClick={onClick}
      className="cursor-pointer ml-2 text-navy-100 hover:text-navy-200 transition duration-200 ease-in-out"
    />
  </div>
);
