import React, { useRef } from "react";

import { Portal } from "@components/atoms";

import { FiltersProvider } from "./AdvancedSearch/hooks";
import { TypeAhead } from "./AdvancedSearch/TypeAhead";
import { AdvancedSearchPanel } from "./AdvancedSearch";
import { VodInsightsTable } from "./VodInsightsTable";

export const InsightsTables = () => {
  const resultPortalRef = useRef<HTMLDivElement>(null);
  const exportPortalRef = useRef<HTMLDivElement>(null);
  const pagerPortalRef = useRef<HTMLDivElement>(null);

  return (
    <FiltersProvider>
      <div className="px-8 py-8 bg-lightGrey-100 flex-auto">
        <div className="py-6 bg-white shadow-1dp rounded-md">
          <div className="mx-auto w-3/5">
            <TypeAhead />
          </div>
        </div>

        <div className="flex flex-row items-start mt-6">
          <Portal.PortalProvider
            targets={{
              results: resultPortalRef,
              export: exportPortalRef,
              pager: pagerPortalRef,
            }}
          >
            <AdvancedSearchPanel className="mr-6 shrink-0" />
            <div className="flex-grow">
              <div className="flex-4 shadow-1dp rounded-md overflow-clip">
                <div className="flex pt-6 px-10 bg-white pb-md">
                  <div
                    ref={resultPortalRef}
                    className="text-h6 font-semibold text-navy-300 mr-10 flex flex-row items-center"
                  />
                  <div
                    ref={exportPortalRef}
                    className="text-h6 font-semibold text-navy-300 flex flex-row items-center"
                  />
                </div>
                <VodInsightsTable />
              </div>
              <div ref={pagerPortalRef} />
            </div>
          </Portal.PortalProvider>
        </div>
      </div>
    </FiltersProvider>
  );
};
