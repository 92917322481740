import React, { HTMLAttributes } from "react";

import { LabelProcessing } from "../Segment/Status";

import { LabelFailedAnalytics } from "./LabelFailedAnalytics";
import { LabelFailedIngestion } from "./LabelFailedIngestion";
import { LabelQueued } from "./LabelQueued";
import { LabelSuccess } from "./LabelSuccess";
import { LabelUnknown } from "./LabelUnknown";
import { LabelWaiting } from "./LabelWaiting";
import * as satisfiability from "./satisfiability";

export interface JobStatusProps extends HTMLAttributes<HTMLButtonElement> {
  ingestionStatus: string | null;
  mieStatus: string | null;
}

export const Label = (props: JobStatusProps) => {
  if (satisfiability.isFailedIngestion(props)) {
    return <LabelFailedIngestion />;
  }

  if (satisfiability.isCreated(props)) {
    return <LabelWaiting />;
  }

  if (satisfiability.isStarted(props)) {
    return <LabelProcessing />;
  }

  if (satisfiability.isQueued(props)) {
    return <LabelQueued />;
  }

  if (satisfiability.isProcessing(props)) {
    return <LabelProcessing />;
  }

  if (satisfiability.isFailedAnalytics(props)) {
    return <LabelFailedAnalytics />;
  }

  if (satisfiability.isSuccessful(props)) {
    return <LabelSuccess />;
  }

  return <LabelUnknown />;
};
