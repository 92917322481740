import React, { useEffect, useState } from "react";

import { DialogBox, TextInput } from "@components/atoms";
import {
  ConditionTemplate,
  ConditionTemplateProps,
  DurationInput,
} from "@components/atoms/Segment";
import { MinOccurrenceCountFilter } from "@hooks/segments";

interface Props {
  onChange: (minCount: MinOccurrenceCountFilter) => void | Promise<void>;
}

export type OccurencesProps = Props &
  MinOccurrenceCountFilter &
  ConditionTemplateProps;

export const Occurences = ({
  onChange,
  value = 2,
  within,
  location,
  type,
  ...rest
}: OccurencesProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    onChange({ value, within });
  }, [value, within, onChange]);

  return (
    <>
      <ConditionTemplate type={type} location={location} {...rest}>
        <div className={`inline-block "w-2/3"}`}>
          <label className="mb-2 font-semibold text-xs text-navy-400">
            Occurences (Minimum)
          </label>
          <div className="flex mt-2 mb-4 mr-4">
            <TextInput
              type="number"
              defaultValue={2}
              className={"w-full"}
              value={value}
              onChange={({ target }) =>
                onChange({ value: parseInt(target.value, 10), within })
              }
            />
          </div>
        </div>

        <div className="inline-block mt-2 w-1/3">
          <DurationInput
            data-testid="within"
            value={within}
            onChange={(within) => onChange({ value, within })}
            onInfoClick={() => setIsDialogOpen(true)}
          />
        </div>
      </ConditionTemplate>
      <DialogBox.Container
        isDialogOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogBox.Card onClose={() => setIsDialogOpen(false)}>
          <DialogBox.Title>Tolerance</DialogBox.Title>
          <p className="text-sm">
            Specify a buffer of time around the occurence.
            <br />
            E.g., Ginger Rogers must appear in the frame{" "}
            <span className="font-bold">within 5 minutes</span> of a given point
            in the video for that point to meet segment condititions.
          </p>
        </DialogBox.Card>
      </DialogBox.Container>
    </>
  );
};
