import React, {
  ForwardedRef,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useRef,
  useState,
} from "react";

import { classnames, TArg } from "@external/tailwindcss-classnames";

import * as Icon from "../Icon";

interface InputProps {
  multiline?: false;
  icon?: keyof typeof Icon;
  error?: boolean;
  className?: any;
  inputClassName?: any;
  endAdornment?: React.ReactNode;
  rows?: number;
  metadata?: string;
}

interface TextAreaProps {
  multiline: true;
  className?: any;
  inputClassName?: any;
  error?: boolean;
  rows?: number;
}

export type TextInputWithMetadataProps =
  | (InputProps & InputHTMLAttributes<HTMLInputElement>)
  | (TextAreaProps & TextareaHTMLAttributes<HTMLTextAreaElement>);

export const TextInputWithMetadata = React.forwardRef<
  any,
  TextInputWithMetadataProps
>((props, ref: ForwardedRef<any>) => {
  let { error, className, inputClassName, ...compProps } = props;
  const [size, setSize] = useState(
    getTextWidth(
      (props?.value?.toString()?.length || 0) > 0
        ? props?.value?.toString()!
        : props.placeholder || ""
    ) || 240
  );
  const [inputFocus, setInputFocus] = useState(false);
  const inputRef = useRef(null);

  className = classnames(className, "relative");

  if (compProps.multiline) {
    const { rows, multiline, ...rest } = compProps;
    return (
      <textarea
        className={classnames(className, inputClassName)}
        rows={rows}
        ref={ref}
        {...rest}
      />
    );
  }
  const divClassName = classnames(
    "flex",
    "cursor-text",
    "outline-none",
    "disabled:bg-lightGrey-200",
    "disabled:text-black-100",
    "disabled:cursor-not-allowed",

    "border",
    "text-black-300",

    "placeholder-black-100",

    {
      "hover:bg-navy-300": !inputFocus,
      "hover:bg-opacity-4": !inputFocus,
    },

    "rounded-lg",
    "transition",
    "duration-200",

    {
      [classnames("border-coolGrey-300")]: !error,
      [classnames("border-red-300", "placeholder-red-300")]: error,
    },

    {
      "bg-white": inputFocus,
      "ring-opacity-16": inputFocus,
      "ring-3": inputFocus,
      "border-opacity-0": inputFocus,
      "ring-blue-300": inputFocus,
    }
  );

  const { icon, endAdornment, multiline, metadata, ...rest } = compProps;

  inputClassName = classnames(
    inputClassName,
    "h-10",

    "inline",
    "outline-none",
    "bg-transparent",

    "leading-input",
    "py-2",
    "px-smx",
    "pr-0",

    "disabled:bg-lightGrey-200",
    "disabled:text-black-100",
    "disabled:cursor-not-allowed",

    {
      "pl-9": !!icon,
      "pr-16": !!endAdornment,

      [classnames("placeholder-not-shown:bg-lightGrey-100" as TArg)]: !error,
    }
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSize(getTextWidth(e.target.value));
  };

  const onPaste = (e: any) => {
    if (e.target && e.target.value) {
      setSize(e.target.value.length);
    }
  };

  const onFocus = () => {
    const el: HTMLInputElement = inputRef.current!;
    const val = el.value;
    setInputFocus(true);

    // Move the cursor to the end of the input text
    el.value = "";
    el.value = val;

    el && el.focus();
  };

  const onBlur = () => {
    setInputFocus(false);
  };

  const IconType = icon ? Icon[icon] : null;

  return (
    <div
      className={classnames(className, divClassName)}
      onClick={onFocus}
      onBlur={onBlur}
    >
      {IconType && (
        <div className="absolute top-0 left-0 bottom-0 flex items-center">
          <IconType size={16} className="ml-2 text-black-100" />
        </div>
      )}
      <input
        style={{ width: `${size + 14}px` }}
        type="text"
        className={inputClassName}
        ref={inputRef}
        onChange={onChange}
        onInput={onChange}
        onPaste={onPaste}
        onFocus={onFocus}
        onClick={(e) => e.stopPropagation()}
        {...rest}
      />
      {endAdornment && (
        <div className="absolute right-0 top-0 bottom-0 flex items-center">
          {endAdornment}
        </div>
      )}

      {metadata && (
        <span className="text-black-100 leading-10 line-clamp-1">
          {metadata}
        </span>
      )}
    </div>
  );
});

const getTextWidth = (text: string): number => {
  const el = document.createElement("span");

  el.setAttribute("style", "visibility:none;position:absolute;left:-99999px");
  el.innerText = text;

  document.body.appendChild(el);

  const width = el.offsetWidth;
  const cs = window.getComputedStyle(el, null);
  el.parentNode?.removeChild(el);

  return (
    width +
    parseInt(cs.getPropertyValue("padding-left") || "0", 10) +
    parseInt(cs.getPropertyValue("padding-right") || "0", 10) +
    parseInt(cs.getPropertyValue("border-left") || "0", 10) +
    parseInt(cs.getPropertyValue("border-right") || "0", 10)
  );
};
