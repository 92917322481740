import React, { useEffect, useState } from "react";

import { FilterGroup } from "../FilterGroup";
import {
  BusinessUnit,
  Celebrities,
  ContentModeration,
  Sentiments,
  SpokenNouns,
  VideoContains,
  VideoDuration,
  VideoTitleLike,
} from "../Filters";
import { FilterType, useFilters } from "../hooks";

type FilterTracker = {
  [key: string]: boolean;
};

export const FilterPanel = () => {
  const [openFilter, setOpenFilter] = useState<FilterTracker>({});
  const context = useFilters();

  const toggleFilter = (filter: string) => {
    setOpenFilter((prev) => ({
      ...prev,
      [filter]: !prev[filter],
    }));
  };

  useEffect(() => {
    const unsubscribe = context.subscribeToReset(() => setOpenFilter({}));
    return () => unsubscribe();
  }, [context]);

  return (
    <>
      <FilterGroup
        tabIndex={1}
        filterType={FilterType.TITLE__LIKE}
        title="Video title contains"
        isOpen={openFilter[FilterType.TITLE__LIKE]}
        toggleOpen={toggleFilter}
      >
        <VideoTitleLike />
      </FilterGroup>
      <FilterGroup
        tabIndex={2}
        filterType={FilterType.LABEL__CONTAINS}
        title="Object or scene"
        isOpen={openFilter[FilterType.LABEL__CONTAINS]}
        toggleOpen={toggleFilter}
      >
        <VideoContains />
      </FilterGroup>
      <FilterGroup
        tabIndex={3}
        filterType={FilterType.SPOKEN_NOUN__CONTAINS}
        title="Spoken noun"
        isOpen={openFilter[FilterType.SPOKEN_NOUN__CONTAINS]}
        toggleOpen={toggleFilter}
      >
        <SpokenNouns />
      </FilterGroup>
      <FilterGroup
        tabIndex={4}
        filterType={FilterType.SENTIMENT__EQUALS}
        title="Sentiment"
        isOpen={openFilter[FilterType.SENTIMENT__EQUALS]}
        toggleOpen={toggleFilter}
      >
        <Sentiments />
      </FilterGroup>
      <FilterGroup
        tabIndex={5}
        filterType={FilterType.CELEBRITY__LIKE}
        title="Celebrity"
        isOpen={openFilter[FilterType.CELEBRITY__LIKE]}
        toggleOpen={toggleFilter}
      >
        <Celebrities />
      </FilterGroup>
      <FilterGroup
        tabIndex={6}
        filterType={FilterType.CONTENT_MODERATION_EXCLUDES}
        title="Flagged content excludes"
        isOpen={openFilter[FilterType.CONTENT_MODERATION_EXCLUDES]}
        toggleOpen={toggleFilter}
      >
        <ContentModeration mode="excludes" />
      </FilterGroup>
      <FilterGroup
        tabIndex={7}
        filterType={FilterType.CONTENT_MODERATION_CONTAINS}
        title="Flagged content contains"
        isOpen={openFilter[FilterType.CONTENT_MODERATION_CONTAINS]}
        toggleOpen={toggleFilter}
      >
        <ContentModeration mode="contains" />
      </FilterGroup>
      <FilterGroup
        tabIndex={8}
        filterType={FilterType.DURATION__MIN_MAX}
        title="Video duration"
        isOpen={openFilter[FilterType.DURATION__MIN_MAX]}
        toggleOpen={toggleFilter}
      >
        <VideoDuration />
      </FilterGroup>
      <FilterGroup
        tabIndex={9}
        filterType={FilterType.BUSINESS_UNIT}
        title="Business unit"
        isOpen={openFilter[FilterType.BUSINESS_UNIT]}
        toggleOpen={toggleFilter}
      >
        <BusinessUnit />
      </FilterGroup>
    </>
  );
};
