import { ContentModerationLabels } from "@components/molecules/Segment/Condition/ContentModeration/labels";
import { ConvertToUpperSnakeCase } from "@utils/casing";
import * as temporal from "@utils/temporal";

import { ImportColumnType } from "../useImportState/importColumnType";

import { amazonRekognitionLabels } from "./AmazonRekognitionAllLabels_v3.0";
import * as Result from "./result";

export type Input = Partial<{
  [ImportColumnType.conditionType]: string;
  [ImportColumnType.conditionValue]: string;
}>;

export function validate({
  conditionType,
  conditionValue,
}: Input): Result.Result {
  let err = Result.pass();
  const conditionTypeSnakeCase = ConvertToUpperSnakeCase(conditionType);

  switch (conditionTypeSnakeCase) {
    case "SENTIMENT": {
      err = Result.join(err, validateSentimentConditionValue(conditionValue));
      break;
    }
    case "VIDEO_DURATION": {
      err = Result.join(err, validateVideoDurationValue(conditionValue));
      break;
    }
    case "FLAGGED_CONTENT": {
      err = Result.join(err, validateContentModeration(conditionValue));
      break;
    }
    case "SPOKEN_WORD_OR_PHRASE": {
      err = Result.join(err, validateSpokenWordOrPhrase(conditionValue));
      break;
    }
    case "OBJECT_OR_SCENE": {
      err = Result.join(err, validateObjectOrScene(conditionValue));
      break;
    }
    case "CLOSE_GAME" ||
      "DELAYED_GAME" ||
      "DOUBLE_HEADER" ||
      "NIGHT_GAME" ||
      "PRE_ALL_STAR" ||
      "POST_ALL_STAR" ||
      "EXTRA_INNINGS" ||
      "LINEUP_CHANGE" ||
      "STOLEN_BASE": {
      err = Result.join(err, validateBooleanConditionValue(conditionValue));
      break;
    }
    case "RUN_DIFFERENTIAL" || "EVENT_LEVERAGE_INDEX": {
      err = Result.join(err, validateDifferentConditionValue(conditionValue));
      break;
    }
    case "VENUE" ||
      "SEASON_TYPE" ||
      "HOME_TEAM" ||
      "AWAY_TEAM" ||
      "DIVISION" ||
      "LEAGUE" ||
      "PLAYER" ||
      "WINNING_TEAM" ||
      "SERIES_TYPE" ||
      "INNING_HALF" ||
      "EVENT_TYPE" ||
      "EVENT_DESCRIPTION" ||
      "EVENT_RUNS_SCORED": {
      err = Result.join(err, validateNames(conditionValue));
      break;
    }

    case "EVENT_RUNS_SCORED" || "INNING": {
      err = Result.join(err, validateNumbers(conditionValue));
      break;
    }
  }

  if (!conditionValue) {
    err = Result.pushError(
      err,
      ImportColumnType.conditionValue,
      "must not be empty"
    );
  }

  return err;
}

const validateObjectOrScene = (value?: string): Result.Result => {
  let err = Result.pass();

  if (value && !amazonRekognitionLabels.includes(value.toLowerCase())) {
    err = Result.pushError(
      err,
      ImportColumnType.conditionValue,
      `must be one of Amazon Rekognition labels`
    );
  }

  return err;
};

export const contentModerationValues = ContentModerationLabels.flatMap(
  (topLevel) => [
    ConvertToUpperSnakeCase(topLevel.value),
    ...(topLevel.secondLevelCategories?.map((child) =>
      ConvertToUpperSnakeCase(child.value)
    ) || []),
  ]
);

function validateContentModeration(value?: string): Result.Result {
  let err = Result.pass();

  if (!contentModerationValues.includes(ConvertToUpperSnakeCase(value))) {
    err = Result.pushError(
      err,
      ImportColumnType.conditionValue,
      `must be one of [${contentModerationValues.join(", ")}]`
    );
  }

  return err;
}

function validateVideoDurationValue(value?: string): Result.Result {
  let err = Result.pass();

  if (value && !temporal.parseDurationRange(value)) {
    err = Result.pushError(
      err,
      ImportColumnType.conditionValue,
      `invalid format`
    );
  }

  return err;
}

const spokenWordOrPhraseRegex = /^[a-zA-Z0-9 ]+$/;

function validateSpokenWordOrPhrase(value?: string): Result.Result {
  let err = Result.pass();

  if (!value?.match(spokenWordOrPhraseRegex)) {
    err = Result.pushError(
      err,
      ImportColumnType.conditionValue,
      `must only contain letters, digits or spaces`
    );
  }

  return err;
}

const sentimentConditionValues = ["POSITIVE", "NEGATIVE", "NEUTRAL", "MIXED"];

function validateSentimentConditionValue(value?: string): Result.Result {
  let err = Result.pass();

  if (!sentimentConditionValues.includes(ConvertToUpperSnakeCase(value))) {
    err = Result.pushError(
      err,
      ImportColumnType.conditionValue,
      `must be one of [${sentimentConditionValues.join(", ")}]`
    );
  }

  return err;
}

const booleanConditionValues = ["YES", "NO"];
function validateBooleanConditionValue(value?: string): Result.Result {
  let err = Result.pass();

  if (!booleanConditionValues.includes(ConvertToUpperSnakeCase(value))) {
    err = Result.pushError(
      err,
      ImportColumnType.conditionValue,
      `must be one of [${booleanConditionValues.join(", ")}]`
    );
  }

  return err;
}

const differenceFormat = ["X<5", "X<=5", "X>=5", "X>5"];
function validateDifferentConditionValue(value?: string): Result.Result {
  let err = Result.pass();

  if (value?.includes("<=")) {
    var val = value?.split("<=");
    if (val.length !== 2 || (isNaN(+val[0]) && isNaN(+val[1]))) {
      err = Result.pushError(
        err,
        ImportColumnType.conditionValue,
        `must be one of [${differenceFormat.join(", ")}]`
      );
      return err;
    }
  } else if (value?.includes("<")) {
    val = value?.split("<");
    if (val.length !== 2 || (isNaN(+val[0]) && isNaN(+val[1]))) {
      err = Result.pushError(
        err,
        ImportColumnType.conditionValue,
        `must be one of [${differenceFormat.join(", ")}]`
      );
      return err;
    }
  } else if (value?.includes(">=")) {
    val = value?.split(">=");
    if (val.length !== 2 || (isNaN(+val[0]) && isNaN(+val[1]))) {
      err = Result.pushError(
        err,
        ImportColumnType.conditionValue,
        `must be one of [${differenceFormat.join(", ")}]`
      );
      return err;
    }
  } else if (value?.includes(">")) {
    val = value?.split(">");
    if (val.length !== 2 || (isNaN(+val[0]) && isNaN(+val[1]))) {
      err = Result.pushError(
        err,
        ImportColumnType.conditionValue,
        `must be one of [${differenceFormat.join(", ")}]`
      );
      return err;
    }
  } else if (!value || isNaN(+value)) {
    err = Result.pushError(
      err,
      ImportColumnType.conditionValue,
      `given number is not valid`
    );
    return err;
  }
  return err;
}

//todo check validation for following
const words = /^[a-zA-Z0-9 ]+$/;

function validateNames(value?: string): Result.Result {
  let err = Result.pass();

  if (!value?.match(words)) {
    err = Result.pushError(
      err,
      ImportColumnType.conditionValue,
      `must only contain letters, digits or spaces`
    );
  }

  return err;
}

const compareFormat = ["<5", "<=5", ">=5", ">5", "!=5"];
function validateNumbers(value?: string): Result.Result {
  let err = Result.pass();

  var val: string[] = [];
  if (value?.includes("<=")) {
    val = value?.split("<=");
  } else if (value?.includes("<")) {
    val = value?.split("<");
  } else if (value?.includes(">=")) {
    val = value?.split(">=");
  } else if (value?.includes(">")) {
    val = value?.split(">");
  } else if (value?.includes("!=")) {
    val = value?.split("!=");
  }
  if (val.length !== 2 || isNaN(+val[1])) {
    err = Result.pushError(
      err,
      ImportColumnType.conditionValue,
      `must be one of [${compareFormat.join(", ")}]`
    );
    return err;
  }

  return err;
}
