import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Star = createSvgIcon({
  content: (
    <path
      d="M8.00123 11.896L4.12323 13.9347L4.8639 9.61667L1.72656 6.55867L6.06256 5.92867L8.00123 2L9.9399 5.92867L14.2759 6.55867L11.1386 9.61667L11.8792 13.9347L8.00123 11.896Z"
      className="stroke-current"
      strokeLinecap="square"
    />
  ),
  width: 16,
  height: 16,
});
