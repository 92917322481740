import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const WorldQuestion = createSvgIcon({
  content: (
    <>
      <path
        d="M13.9833 7.5533C13.8892 6.29308 13.3995 5.09478 12.5842 4.12926C11.7688 3.16374 10.6695 2.48031 9.44281 2.17643C8.21617 1.87254 6.9249 1.96372 5.75313 2.43696C4.58136 2.9102 3.58893 3.74133 2.91736 4.81184C2.24579 5.88235 1.92937 7.13757 2.01323 8.39851C2.09709 9.65945 2.57694 10.8617 3.38435 11.8339C4.19176 12.806 5.28551 13.4984 6.50963 13.8123C7.73375 14.1262 9.02573 14.0456 10.2013 13.582"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.3999 6H13.5999"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.3999 10H8.9999"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.66677 2C6.54367 3.79974 5.94824 5.87858 5.94824 8C5.94824 10.1214 6.54367 12.2003 7.66677 14"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.3335 2C9.51929 3.89925 10.1155 6.10736 10.0468 8.34533M9.34283 11.9473C9.07647 12.6638 8.7383 13.3516 8.3335 14"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.6665 14.6666V14.6733"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.6665 12.6666C12.9654 12.6657 13.2553 12.5645 13.4898 12.3793C13.7244 12.194 13.89 11.9355 13.9602 11.645C14.0304 11.3545 14.0011 11.0488 13.8771 10.7769C13.753 10.505 13.5413 10.2826 13.2758 10.1453C13.0106 10.0094 12.7072 9.96731 12.415 10.0258C12.1228 10.0842 11.859 10.2399 11.6665 10.4673"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  ),
  width: 16,
  height: 16,
});
