import React, { type ReactNode } from "react";

import { Label } from "@components/atoms";
import { classnames, TArg } from "@external/tailwindcss-classnames";

export interface StatusLabelProps {
  onClick?: () => void | Promise<void>;
  className?: any;
  color: StatusLabelColor;
  children?: ReactNode;
}

export enum StatusLabelColor {
  Red = "Red",
  CoolGrey = "CoolGrey",
  Teal = "Teal",
  Navy = "Navy",
  Blue = "Blue",
}

const ContainerColorMapping: { [key in StatusLabelColor]: TArg[] } = {
  [StatusLabelColor.Red]: ["bg-red-300", "bg-opacity-10"],
  [StatusLabelColor.CoolGrey]: ["bg-coolGrey-100"],
  [StatusLabelColor.Teal]: ["bg-teal-100"],
  [StatusLabelColor.Navy]: ["bg-navy-300", "bg-opacity-10"],
  [StatusLabelColor.Blue]: ["bg-blue-300", "bg-opacity-10"],
};

const BulletColorMapping: { [key in StatusLabelColor]: TArg[] } = {
  [StatusLabelColor.Red]: ["bg-red-300"],
  [StatusLabelColor.CoolGrey]: ["bg-coolGrey-300"],
  [StatusLabelColor.Teal]: ["bg-teal-500"],
  [StatusLabelColor.Navy]: ["bg-navy-300"],
  [StatusLabelColor.Blue]: ["bg-blue-300"],
};
export const StatusLabel = ({
  onClick,
  className,
  color,
  children,
}: StatusLabelProps) => {
  let containerClassName = classnames(
    className,
    ...ContainerColorMapping[color]
  );
  let bulletClassName = classnames(
    "rounded-full",
    "bg-black-300",
    "w-1.5",
    "h-1.5",
    "mr-2",
    ...BulletColorMapping[color]
  );

  return (
    <Label onClick={onClick} className={containerClassName}>
      <div className={bulletClassName} />
      <p className="text-h8 text-black-400 font-semibold">{children}</p>
    </Label>
  );
};
