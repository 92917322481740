import React, { useEffect, useState } from "react";
import FileSaver from "file-saver";

import { CustomDialog } from "@components/molecules";
import { classnames } from "@external/tailwindcss-classnames";

import {
  IngestionJobsExportDialogFormData,
  PreviousDays,
} from "./IngestionJobsExportDialog";
import * as Pages from "./pages";

export type CardProps = {
  title: string;
  loading?: boolean;
  error?: string;
} & Pick<
  Pages.ConfigProps,
  "selectableFields" | "initialSelectedNodeIds" | "onClose"
> & {
    timeOptions: Record<string, PreviousDays>;
    onExportSubmission: (
      v: IngestionJobsExportDialogFormData
    ) => Promise<string | undefined>;
  };

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      title,
      onClose,
      loading,
      timeOptions,
      selectableFields,
      initialSelectedNodeIds,
      onExportSubmission,
      error,
    },
    ref
  ) => {
    const [href, setHref] = useState<string | undefined>(undefined);
    useEffect(() => {
      if (!error && href) {
        FileSaver.saveAs(href);
      }
    }, [error, href]);

    const completed = !error && !!href;

    return (
      <CustomDialog.Card
        ref={ref}
        className={classnames({ "h-121": completed || loading })}
        onClose={onClose}
      >
        <CustomDialog.Title>{title}</CustomDialog.Title>
        {(() => {
          if (loading) {
            return <Pages.Preparing onClose={onClose} />;
          }

          if (completed && href) {
            return <Pages.Complete href={href} onClose={onClose} />;
          }

          if (error) {
            return <Pages.Error onCancel={onClose} />;
          }

          return (
            <Pages.Config
              initialSelectedNodeIds={initialSelectedNodeIds}
              selectableFields={selectableFields}
              timeOptions={timeOptions}
              onSubmission={async (v) => {
                const href = await onExportSubmission(v);
                setHref(href);
              }}
              onClose={onClose}
            />
          );
        })()}
      </CustomDialog.Card>
    );
  }
);
