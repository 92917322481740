import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const FlaggedContentIncludes = createSvgIcon({
  content: (
    <>
      <path
        d="M3.33203 11.1667V7.83333C4.2207 6.94467 5.33203 6.5 6.66536 6.5C8.66536 6.5 9.33203 7.83333 10.6654 7.83333C11.332 7.83333 11.9987 7.61133 12.6654 7.16667V1.16667C11.9987 1.61133 11.332 1.83333 10.6654 1.83333C9.33203 1.83333 8.66536 0.5 6.66536 0.5C5.33203 0.5 4.2207 0.944667 3.33203 1.83333V7.83333"
        className="stroke-current"
        strokeLinecap="square"
      />
      <g clipPath="url(#clip0_2171_57596)">
        <path
          d="M8.83398 15.1665V10.1665M11.334 12.6665H6.33398H11.334Z"
          className="stroke-current"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_2171_57596">
          <rect
            width="8"
            height="8"
            className="fill-current"
            transform="translate(4 7.83313)"
          />
        </clipPath>
      </defs>
    </>
  ),
  width: 16,
  height: 16,
});
