import React, { useRef, useState } from "react";
import { Listbox } from "@headlessui/react";

import { Icon, OptionsTransition } from "@components/atoms";
import { Option } from "@components/atoms/Select/Option";
import { classnames } from "@external/tailwindcss-classnames";

import { Options } from "./Options";

export interface SelectProps<K extends keyof any> {
  options: { [P in K]?: string };
  placeholder?: string;
  className?: any;
  onChange: (value: K) => void;
  onBlur?: () => void;
  value: K | undefined;
  rightToLeft?: boolean;
  fixedWidth?: boolean;
  direction?: "up" | "down";
}

export const Select = <K extends keyof any>({
  placeholder,
  options,
  onChange,
  value,
  className,
  rightToLeft = false,
  fixedWidth = false,
  direction = "down",
}: SelectProps<K>) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const ref = useRef();
  const showPlaceholder = !value;

  let popOverTransformationStyle: any;

  if (ref.current && direction === "up") {
    const button = ref.current as HTMLButtonElement;

    popOverTransformationStyle = { bottom: `${button.offsetHeight + 8}px` };
  }

  const renderDropdown = () => {
    const optionEntries: [string, string][] = Object.entries(options);
    return (
      <OptionsTransition direction={direction}>
        <Options
          style={popOverTransformationStyle}
          direction={direction}
          fixedWidth={fixedWidth}
        >
          {optionEntries.map(([key, value], index) => (
            <Option
              data-e2e="select-option"
              key={key}
              value={key}
              index={index}
              rightToLeft={rightToLeft}
              lastItem={index === optionEntries.length - 1}
            >
              {value}
            </Option>
          ))}
        </Options>
      </OptionsTransition>
    );
  };

  const renderButton = (open: boolean) => (
    <Listbox.Button
      ref={ref as any}
      data-e2e="select-button"
      className={classnames(
        className,
        "rounded-lg",
        "border",
        "border-coolGrey-300",
        "px-3",

        "flex",
        "flex-row",
        "items-center",
        "transition",
        "ease-in-out",
        "duration-100",

        "active:ring-3",
        "active:ring-blue-300",
        "active:ring-opacity-24",

        {
          "justify-between": !rightToLeft,
          "justify-end": rightToLeft,
          "w-max": !fixedWidth,
          [classnames("ring-3", "ring-blue-300", "ring-opacity-24")]: open,
          "text-black-100": showPlaceholder,
        }
      )}
    >
      {showPlaceholder ? placeholder : options[value]}
      <Icon.Sorting
        color={open || isMouseOver ? "black-300" : "black-100"}
        className="ml-2"
      />
    </Listbox.Button>
  );

  return (
    <Listbox
      as="div"
      className={classnames(className, "relative")}
      value={value}
      onChange={(v) => v && onChange(v)}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      {({ open }) => (
        <>
          {direction === "up" && (
            <>
              {renderDropdown()}
              {renderButton(open)}
            </>
          )}
          {direction === "down" && (
            <>
              {renderButton(open)}
              {renderDropdown()}
            </>
          )}
        </>
      )}
    </Listbox>
  );
};
