import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const SportsField = createSvgIcon({
  content: (
    <>
      <path
        d="M2 4.66671C2 4.31309 2.14048 3.97395 2.39052 3.7239C2.64057 3.47385 2.97971 3.33337 3.33333 3.33337H12.6667C13.0203 3.33337 13.3594 3.47385 13.6095 3.7239C13.8595 3.97395 14 4.31309 14 4.66671V11.3334C14 11.687 13.8595 12.0261 13.6095 12.2762C13.3594 12.5262 13.0203 12.6667 12.6667 12.6667H3.33333C2.97971 12.6667 2.64057 12.5262 2.39052 12.2762C2.14048 12.0261 2 11.687 2 11.3334V4.66671Z"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 3.33337V12.6667"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 4L4 4.5V5.66667V8V10.3333V11.5L2.5 12"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5 4L12 4.5V5.66667V8V10.3333V11.5L13.5 12"
        stroke="#303030"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="7.99984" cy="7.99996" r="1.83333" stroke="#303030" />
    </>
  ),
  width: 16,
  height: 16,
});
