import React, { HTMLAttributes } from "react";

import { classnames, TArg } from "@external/tailwindcss-classnames";

type SvgIconProps = {
  content: React.ReactNode;
  width?: number;
  height?: number;
  defaultColor?: string;
  defaultClassName?: string;
  "data-testid"?: string;
};

/**
 * Two major properties:
 * `size` and `color`.
 *
 * `size` will define the width of the icon, since the height is not defined
 * the icon will keep its proportions.
 *
 * `color` will use tailwindcss to set the text color. Only the color name needs
 * to be given
 *
 * All other properties are passed to the parent `<svg> component`
 */
export type IconProps = {
  size?: number;
  color?: string;
  className?: string | TArg;
} & HTMLAttributes<HTMLOrSVGElement>;

export const createSvgIcon =
  ({
    content,
    width = 16,
    height = 16,
    defaultColor,
    defaultClassName,
    ...defaultRest
  }: SvgIconProps) =>
  ({ size = width, color, className, ...rest }: IconProps) =>
    (
      <svg
        width={size}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classnames(
          (color || defaultColor
            ? `text-${color || defaultColor}`
            : null) as TArg,
          defaultClassName as TArg,
          className as TArg
        )}
        {...defaultRest}
        {...rest}
      >
        {content}
      </svg>
    );
