import React, { createContext, MutableRefObject, type ReactNode } from "react";

export interface Portals {
  readonly targets: { [key: string]: MutableRefObject<HTMLElement | null> };
}

export const PortalContext = createContext<Portals | null>(null);

export interface PortalProviderProps {
  readonly targets?: { [key: string]: MutableRefObject<HTMLElement | null> };
  readonly target?: MutableRefObject<HTMLElement | null>;
  children: ReactNode;
}

export const PortalProvider = ({
  targets,
  target,
  children,
}: PortalProviderProps) => {
  return (
    <PortalContext.Provider
      value={{
        targets: targets ? targets : target ? { default: target } : {},
      }}
    >
      {children}
    </PortalContext.Provider>
  );
};
