import React from "react";

import { Button, Icon } from "@components/atoms";

export interface ErrorProps {
  onCancel: () => void | Promise<void>;
}

export const Error = ({ onCancel }: ErrorProps) => (
  <>
    <div className="flex flex-col items-center">
      <Icon.Exclamation size={40} className="mb-10 text-red-300" />
    </div>
    <div className="mb-10 text-center">
      We are unable to export your Segment. If this problem persists, please
      contact support.
    </div>
    <div className="flex justify-between">
      <Button onClick={onCancel} type="button" variant="text">
        Cancel
      </Button>
    </div>
  </>
);
