import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Info = createSvgIcon({
  content: (
    <>
      <path
        d="M12 1.33333C17.8833 1.33333 22.6667 6.11667 22.6667 12C22.6667 17.8833 17.8833 22.6667 12 22.6667C6.11667 22.6667 1.33333 17.8833 1.33333 12C1.33333 6.11667 6.11667 1.33333 12 1.33333ZM12 0C5.36667 0 0 5.36667 0 12C0 18.6333 5.36667 24 12 24C18.6333 24 24 18.6333 24 12C24 5.36667 18.6333 0 12 0Z"
        className="fill-current"
      />
      <path
        d="M11.158 18.1152V9.44779H13.2007V18.1152H11.158ZM12.185 8.21764C11.8615 8.21764 11.5831 8.11043 11.3499 7.896C11.1166 7.67781 11 7.41636 11 7.11164C11 6.80317 11.1166 6.54171 11.3499 6.32729C11.5831 6.1091 11.8615 6 12.185 6C12.5123 6 12.7907 6.1091 13.0201 6.32729C13.2534 6.54171 13.37 6.80317 13.37 7.11164C13.37 7.41636 13.2534 7.67781 13.0201 7.896C12.7907 8.11043 12.5123 8.21764 12.185 8.21764Z"
        className="fill-current"
      />
    </>
  ),
  width: 24,
  height: 24,
});
