import { gql } from "@apollo/client";
import { FieldPolicy } from "@apollo/client/cache/inmemory/policies";

import { toColumnTypeIndex } from "../../../segments/useImportState/columnTypeIndex";
import { ImportColumnType } from "../../../segments/useImportState/importColumnType";

export const resolver: FieldPolicy = {
  read(_, { cache }) {
    const data = cache.readQuery<{
      segmentImport: {
        rawData: any[][];
        firstRowContainsHeader: boolean;
        columnTypes: ImportColumnType[];
      };
    }>({
      query: gql`
        query SegmentImportRawData {
          segmentImport {
            rawData
            firstRowContainsHeader
            columnTypes
          }
        }
      `,
    });
    if (data) {
      const columnTypes = data.segmentImport.columnTypes;
      let rawDataItems = [...data.segmentImport.rawData];
      if (data.segmentImport.firstRowContainsHeader) {
        rawDataItems.shift();
      }

      const {
        conditionType,
        confidenceScore,
        operator,
        tolerance,
        conditionValue,
      } = toColumnTypeIndex(columnTypes);

      const items = [];

      for (let i = 0; i < rawDataItems.length; i++) {
        const rawItem = rawDataItems[i];
        const item: { [key in ImportColumnType]?: any } = {};

        if (conditionType !== undefined) {
          item[ImportColumnType.conditionType] = rawItem[conditionType];
        } else {
          item[ImportColumnType.conditionType] = "";
        }

        if (conditionValue !== undefined) {
          item[ImportColumnType.conditionValue] = rawItem[conditionValue];
        } else {
          item[ImportColumnType.conditionValue] = "";
        }

        if (confidenceScore !== undefined) {
          item[ImportColumnType.confidenceScore] =
            rawItem[confidenceScore] || "50";
        } else {
          item[ImportColumnType.confidenceScore] = "50";
        }

        if (operator !== undefined) {
          item[ImportColumnType.operator] = rawItem[operator] || "Any";
        } else {
          item[ImportColumnType.operator] = "Any";
        }

        if (tolerance !== undefined) {
          item[ImportColumnType.tolerance] = rawItem[tolerance] || "30s";
        } else {
          item[ImportColumnType.tolerance] = "30s";
        }

        items.push(item);
      }

      return items;
    }
  },
};

export default resolver;
