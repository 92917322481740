import React from "react";

import { Icon } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

export interface NoImageAvailableProps {
  className?: any;
  size?: "small" | "medium";
  iconWidth?: number;
  iconHeight?: number;
}

export const NoImageAvailable = ({
  className,
  size = "medium",
  iconWidth,
  ...rest
}: NoImageAvailableProps) => (
  <div
    className={classnames(
      className,
      "bg-lightGrey-200",
      "text-coolGrey-300",
      "flex",
      "flex-col",
      "justify-center",
      "items-center"
    )}
    {...rest}
  >
    <Icon.Image
      className={classnames({
        "mb-6": size === "medium",
      })}
      size={
        iconWidth ||
        {
          medium: 40,
          small: 20,
        }[size]
      }
    />
    {size === "medium" && (
      <div className="uppercase text-h8 font-semibold tracking-wider">
        No Image Available
      </div>
    )}
  </div>
);
