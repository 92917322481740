export function formatMilliseconds(duration: number) {
  const hours = Math.floor(duration / (1000 * 60 * 60));
  duration -= hours * (1000 * 60 * 60);

  let minutes = Math.floor(duration / (1000 * 60));
  duration -= minutes * (1000 * 60);

  let seconds = Math.floor(duration / 1000);

  const hoursStr = hours < 10 ? "0" + hours : hours;
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;
  const secondsStr = seconds < 10 ? "0" + seconds : seconds;

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

export function formatLength(duration: number) {
  const hours = Math.floor(duration / (1000 * 60 * 60));
  duration -= hours * (1000 * 60 * 60);

  let minutes = Math.floor(duration / (1000 * 60));
  duration -= minutes * (1000 * 60);

  let seconds = Math.floor(duration / 1000);

  const hoursStr = hours < 10 ? "0" + hours : hours;
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;
  const secondsStr = seconds < 10 ? "0" + seconds : seconds;

  if (hours > 0) {
    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  } else {
    return `${minutesStr}:${secondsStr}`;
  }
}
