export const colorPalette = {
  blue: {
    default: "#454DE2",
    alternate: "#7177E9",
    defaultClassName: "bg-blue-300",
    alternateClassName: "bg-blue-200",
  },
  orange: {
    default: "#FC9226",
    alternate: "#FEC48A",
    defaultClassName: "bg-orange-300",
    alternateClassName: "bg-orange-200",
  },
};
