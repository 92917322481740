import React, { useState } from "react";

interface TrappedInputProps {
  children: string | number;
  onBlur: () => void | Promise<void>;
  onFocus: () => void | Promise<void>;
  onChange: (value: string | number) => void | Promise<void>;
}

export const TrappedInput = ({
  onBlur,
  onFocus,
  onChange,
  children,
}: TrappedInputProps) => {
  const [value, setValue] = useState(children || "");

  return (
    <input
      style={{ fontWeight: "inherit" }}
      className="w-full bg-transparent focus:outline-none"
      onFocus={() => onFocus()}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          onBlur();
          onChange(value);
        }
        if (event.key === "Esc" || event.key === "Escape") {
          onBlur();
        }
      }}
      onChange={({ target: { value } }) => setValue(value)}
      onBlur={() => {
        onBlur();
        onChange(value);
      }}
      autoFocus={true}
      value={value}
    />
  );
};
