import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import { NoContent, SlideOver } from "@components/atoms";
import { Pager, Table } from "@components/molecules";
import { formatMilliseconds } from "@utils/temporal";

export interface OccurrencesSlideOverProps {
  title?: string;
  occurrences?: {
    StartTimeInMillis: number;
    EndTimeInMillis: number;
  }[];
  open: boolean;
  videoId?: string;
  onClose: () => void;
}

export interface OccurrencesTimes {
  StartTimeInMillis: number;
  EndTimeInMillis: number;
}

export const OccurrencesSlideOver = ({
  open,
  title,
  occurrences = [],
  onClose,
  videoId = "",
}: OccurrencesSlideOverProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const oc = useMemo(
    () => _.chunk(_.sortBy(occurrences, ["StartTimeInMillis"]), 15),
    [occurrences]
  );
  const totalPages = useMemo(
    () => Math.ceil((occurrences || []).length / 15),
    [occurrences]
  );
  const label = useMemo(
    () => ((occurrences || []).length > 1 ? "Occurrences" : "Occurrence"),
    [occurrences]
  );

  if (!occurrences) {
    return (
      <SlideOver.SlideOver open={open} onClose={onClose}>
        <NoContent />
      </SlideOver.SlideOver>
    );
  }

  return (
    <SlideOver.SlideOver open={open} onClose={onClose}>
      <SlideOver.Header onClose={onClose}>
        <div
          className="flex flex-col text-ellipsis overflow-hidden"
          data-e2e="slideover-header"
        >
          <p className="text-navy-300 pb-3 font-semibold leading-4 mr-20 truncate">
            <Link
              target="_blank"
              to={`/insights/${videoId}/overview`}
              className="focus-visible:outline-none hover:outline"
            >
              {title}
            </Link>
          </p>
          <p className="text-black-100 font-normal text-sm pb-6">
            {occurrences.length} {label}
          </p>
        </div>
      </SlideOver.Header>
      <SlideOver.Body>
        <div
          className="flex flex-col p-4 w-full gap-4"
          data-e2e="slideover-body"
        >
          <Table.Table
            columns={[
              {
                title: "Timestamp",
                width: "w-full",
              },
            ]}
            compact
            className="shadow-1dp"
          >
            {oc.length
              ? oc[currentPage - 1].map((occurrence, idx) => (
                  <Table.Row key={idx}>
                    <Table.Cell>
                      {formatMilliseconds(occurrence.StartTimeInMillis)}-
                      {formatMilliseconds(occurrence.EndTimeInMillis)}
                    </Table.Cell>
                  </Table.Row>
                ))
              : null}
          </Table.Table>

          <Pager
            className="!m-0 self-end"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChanged={(page) => setCurrentPage(page)}
          />
        </div>
      </SlideOver.Body>
    </SlideOver.SlideOver>
  );
};
