import React from "react";
import { ToastBar, Toaster } from "react-hot-toast";

import { Icon } from "@components/atoms";

export const ThemedToaster = () => (
  <Toaster
    toastOptions={{
      icon: <Icon.Checkmark color="white" />,
    }}
    containerStyle={{
      top: 40,
      left: 40,
      right: 40,
      bottom: 40,
    }}
  >
    {(t) => (
      <ToastBar
        toast={t}
        style={{
          ...t.style,
          padding: 0,
          margin: 0,
          boxShadow:
            "0px 4px 5px rgba(9, 32, 76, 0.14), 0px 1px 10px rgba(9, 32, 76, 0.12), 0px 2px 4px rgba(9, 32, 76, 0.2)",
        }}
      >
        {({ icon, message }) => (
          <div className="rounded p-4 shadow-4dp bg-navy-400 text-white flex flex-1 items-center justify-center text-bodyXs font-semibold">
            {icon}
            {message}
          </div>
        )}
      </ToastBar>
    )}
  </Toaster>
);
