import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Connector,
  Loading,
  Segment as SegmentAtoms,
} from "@components/atoms";
import { Segment } from "@components/organisms";
import { classnames } from "@external/tailwindcss-classnames";
import { useFilters } from "@hooks/segments";

import { CustomDragLayer } from "./DragLayer";

export type SegmentBuilderProps =
  | {
      loading: true;
      operation: SegmentAtoms.OperationOptions;
      segmentId?: string;
    }
  | ({ loading: false } & ButtonBarProps);

export const SegmentBuilder = (props: SegmentBuilderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const filters = useFilters();

  return (
    <DndProvider backend={HTML5Backend}>
      <CustomDragLayer />
      <article
        className="flex-auto grid items-start grid-cols-12 gap-x-6 pt-8 pb-10 px-8 bg-lightGrey-100"
        data-e2e="segment-build"
      >
        <Segment.Sidebar
          className="col-span-3 sticky top-8"
          filters={filters}
        />
        <div className="col-span-6">
          <Card.Container className="p-10 mb-6">
            <Segment.SegmentDetails
              operation={props.operation}
              loading={props.loading}
            />
          </Card.Container>
          <Card.Container
            className="p-6 pt-10 mb-6"
            data-e2e="segment-conditions"
          >
            <div className="px-4 mb-6 flex justify-between">
              <div className="font-semibold text-base text-navy-300">
                Segment Conditions
              </div>
              <Button
                onClick={() =>
                  navigate({
                    pathname: "/segments/import",
                    search: new URLSearchParams({
                      returnTo: location.pathname,
                    }).toString(),
                  })
                }
                variant="contained"
                size="small"
                color="default"
                data-e2e="import-csv-button"
                disabled={props.loading}
              >
                Import .CSV
              </Button>
            </div>
            <div className="relative">
              {props.loading && (
                <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                  <Loading.Slide size={5} />
                </div>
              )}

              <div
                className={classnames({
                  invisible: props.loading,
                })}
              >
                <Segment.AllOfTheFollowing
                  data-testid="all-of-the-following"
                  data-e2e="all-of-the-following"
                  filters={filters}
                />
                <Connector className="self-start">AND</Connector>
                <Segment.AnyOfTheFollowing
                  data-testid="any-of-the-following"
                  data-e2e="any-of-the-following"
                  filters={filters}
                />
                <Connector className="self-start">AND</Connector>
                <Segment.Exclude data-e2e="exclude" filters={filters} />
              </div>
            </div>
          </Card.Container>
        </div>
        <div className="col-span-3 sticky top-8">
          <Segment.ConnectedSegmentPreview loadingQuery={props.loading} />
          <div className="flex justify-between items-center px-6 mt-4">
            {!props.loading && <ButtonBar {...props} />}
          </div>
        </div>
      </article>
    </DndProvider>
  );
};

type DraftButtonBarProps = {
  version: number;
  segmentId: string;
  operation: SegmentAtoms.OperationOptions.Draft;
};

type EditButtonBarProps = {
  version: number;
  segmentId: string;
  operation: SegmentAtoms.OperationOptions.Edit;
};

type CreateButtonBarProps = {
  operation: SegmentAtoms.OperationOptions.Create;
};

type ButtonBarProps =
  | DraftButtonBarProps
  | EditButtonBarProps
  | CreateButtonBarProps;

const ButtonBar = (props: ButtonBarProps) => {
  const navigate = useNavigate();

  switch (props.operation) {
    case SegmentAtoms.OperationOptions.Create:
      return (
        <>
          <Segment.SaveDraftButton />
          <Segment.CreateSegmentButton />
        </>
      );
    case SegmentAtoms.OperationOptions.Draft:
      return (
        <>
          <Segment.SaveSegmentButton
            postSaveAction="toast"
            version={props.version}
            segmentId={props.segmentId}
            color="default"
            type="button"
            variant="text"
          />
          <Segment.BeginSegmentButton
            segmentId={props.segmentId}
            version={props.version}
          />
        </>
      );
    case SegmentAtoms.OperationOptions.Edit:
      return (
        <>
          <Button
            onClick={() => navigate("/segments")}
            color="default"
            type="button"
            variant="text"
          >
            Cancel
          </Button>
          <Segment.SaveSegmentButton
            version={props.version}
            segmentId={props.segmentId}
          />
        </>
      );
  }
};
