import React from "react";
import { Outlet } from "react-router-dom";

export const SegmentImport = () => (
  <article
    data-e2e="segment-import"
    className="flex flex-1 justify-center pt-8 pb-10 px-8 bg-lightGrey-100"
  >
    <Outlet />
  </article>
);
