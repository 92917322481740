export function head(input: string, maxLength: number, separator: string) {
  if (input.length > maxLength) {
    return [
      true,
      `${separator}${input.substr(
        Math.max(input.length - maxLength, 0),
        input.length
      )}`,
    ];
  }
  return [false, input];
}
