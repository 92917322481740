import React, { HTMLAttributes } from "react";
import { Link } from "react-router-dom";

export interface FooterProps extends HTMLAttributes<HTMLDivElement> {}

export const Footer = () => (
  <div className="flex flex-row items-center justify-between shadow p-lg px-10">
    <p className="text-coolGrey-400 text-sm select-none">
      &#169;FOX Corporation
    </p>
    <div className="flex flex-row">
      <Link to="/">
        <p className="text-blue-400 text-sm pr-10 select-none">
          API Documentation
        </p>
      </Link>
      <Link to="/">
        <p className="text-blue-400 text-sm select-none">Contact Support</p>
      </Link>
    </div>
  </div>
);
