import React, { HTMLAttributes } from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface KeyProps extends HTMLAttributes<HTMLDivElement> {
  className?: any;
}

export const Key = ({ className, children }: KeyProps) => (
  <div
    className={classnames(
      className,
      "text-black-100",
      "flex-1",
      "font-semibold",
      "text-h8",
      "whitespace-nowrap",
      "mr-2"
    )}
  >
    {children}
  </div>
);
