import React, { type ReactNode } from "react";

import {
  FilterGroup as FilterGroupComponent,
  FilterGroupProps as FilterGroupComponentProps,
} from "@components/molecules";

import { FilterType, useFilters } from "../hooks";

interface FilterGroupProps
  extends Pick<FilterGroupComponentProps, "tabIndex" | "title" | "isOpen"> {
  filterType: FilterType;
  toggleOpen: (filterType: FilterType) => void;
  children?: ReactNode;
}

export const FilterGroup = ({
  filterType,
  toggleOpen,
  ...rest
}: FilterGroupProps) => {
  const context = useFilters();
  const isSelected =
    !!context.previousFilter &&
    context.previousFilter[filterType] !== undefined;

  return (
    <FilterGroupComponent
      {...rest}
      onClick={() => toggleOpen(filterType)}
      isSelected={isSelected}
    />
  );
};
