import React, { type ReactNode } from "react";
import { Link as RouterLink, useMatch } from "react-router-dom";

import { Button } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

export interface LinkProps {
  to: string;
  className?: any;
  children: ReactNode;
}

export const Link = ({ children, to, className, ...rest }: LinkProps) => {
  let active = useMatch(`${to}/*`);

  return (
    <RouterLink to={to}>
      <Button
        {...rest}
        variant={active ? "contained" : "text"}
        color="clear"
        size="medium"
        className={classnames(className, "font-semibold", {
          [classnames("bg-opacity-40")]: active,
          [classnames(
            "rounded-lg",
            "bg-opacity-0",
            "hover:bg-opacity-24",
            "active:bg-opacity-64",
            "transition-opacity",
            "duration-100"
          )]: !active,
        })}
      >
        {children}
      </Button>
    </RouterLink>
  );
};
