import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Draggable = createSvgIcon({
  content: (
    <>
      <circle cx="5.33333" cy="3.33333" r="1.33333" className="fill-current" />
      <ellipse
        cx="10.6663"
        cy="3.33333"
        rx="1.33333"
        ry="1.33333"
        className="fill-current"
      />
      <circle cx="5.33333" cy="7.99935" r="1.33333" className="fill-current" />
      <ellipse
        cx="10.6663"
        cy="7.99935"
        rx="1.33333"
        ry="1.33333"
        className="fill-current"
      />
      <ellipse
        cx="5.33333"
        cy="12.6673"
        rx="1.33333"
        ry="1.33333"
        className="fill-current"
      />
      <circle cx="10.6663" cy="12.6673" r="1.33333" className="fill-current" />
    </>
  ),
  width: 16,
  height: 16,
});
