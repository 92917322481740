import React from "react";

import { classnames } from "@external/tailwindcss-classnames";

import { Button, ButtonProps } from "../Button";

export interface ItemProps extends ButtonProps {
  className?: any;
  size?: any;
}

export const Item = ({ children, size, className, ...rest }: ItemProps) => (
  <Button
    type="button"
    variant="text"
    color="clear"
    size={size || "medium"}
    className={classnames(
      className,
      "w-full",
      "rounded-none",
      "hover:bg-opacity-4",
      "hover:bg-navy-300",
      "text-black-300"
    )}
    {...rest}
  >
    {children}
  </Button>
);
