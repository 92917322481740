import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const EventType = createSvgIcon({
  content: (
    <>
      <rect
        x="6.25"
        y="0.75"
        width="2.5"
        height="2.5"
        rx="1.25"
        stroke="#303030"
      />
      <rect
        x="4"
        y="6.75"
        width="2.5"
        height="2.5"
        rx="1.25"
        stroke="#303030"
      />
      <rect
        x="1.5"
        y="12.75"
        width="2.5"
        height="2.5"
        rx="1.25"
        stroke="#303030"
      />
      <rect
        x="6.5"
        y="12.75"
        width="2.5"
        height="2.5"
        rx="1.25"
        stroke="#303030"
      />
      <rect
        x="11.5"
        y="12.75"
        width="2.5"
        height="2.5"
        rx="1.25"
        stroke="#303030"
      />
      <rect
        x="9"
        y="6.75"
        width="2.5"
        height="2.5"
        rx="1.25"
        stroke="#303030"
      />
      <line x1="7.5" y1="4" x2="7.5" y2="5" stroke="#303030" />
      <line x1="7.5" y1="3" x2="7.5" y2="5" stroke="#303030" />
      <line x1="5.25" y1="9.5" x2="5.25" y2="10.5" stroke="#303030" />
      <line x1="10.25" y1="9.5" x2="10.25" y2="10.5" stroke="#303030" />
      <line x1="5.25" y1="5.25" x2="5.25" y2="6.75" stroke="#303030" />
      <line x1="5.25" y1="5.25" x2="5.25" y2="6.75" stroke="#303030" />
      <line x1="2.75" y1="11.25" x2="2.75" y2="12.75" stroke="#303030" />
      <line x1="10.25" y1="5.25" x2="10.25" y2="6.75" stroke="#303030" />
      <line x1="7.75" y1="11.25" x2="7.75" y2="12.75" stroke="#303030" />
      <line x1="12.75" y1="11.25" x2="12.75" y2="12.75" stroke="#303030" />
      <path d="M4.75006 5.25H10.75" stroke="#303030" />
      <path d="M4.75006 5.25H10.75" stroke="#303030" />
      <path d="M2.25006 11H8.25" stroke="#303030" />
      <path d="M7.25006 11H13.25" stroke="#303030" />
    </>
  ),
  width: 16,
  height: 16,
});
