import React from "react";
import toast from "react-hot-toast";

import { Button, Icon, Segment, TextInput } from "@components/atoms";
import { CustomDialog } from "@components/molecules";
import * as clipboard from "@utils/clipboard";

export interface CompleteProps {
  onConfirmButtonClicked: () => void;
  onCancelButtonClicked?: () => void;
  segmentId: string;
  operation: Segment.OperationOptions;
}

type ActionProps = {
  onConfirmButtonClick: () => void;
  onCancelButtonClick?: () => void;
};

const EditActions = ({
  onConfirmButtonClick,
  onCancelButtonClick,
}: ActionProps) => (
  <>
    <Button variant="text" type="button" onClick={onCancelButtonClick}>
      Back to Segments
    </Button>
    <Button
      type="button"
      size="medium"
      color="primary"
      onClick={onConfirmButtonClick}
    >
      View Segment Details
    </Button>
  </>
);

const CreateActions = ({ onConfirmButtonClick }: ActionProps) => (
  <Button
    type="button"
    size="medium"
    color="primary"
    onClick={onConfirmButtonClick}
    data-e2e="button-back-segment"
  >
    Back to Segments
  </Button>
);

const description: { [key in Segment.OperationOptions]: string } = {
  [Segment.OperationOptions.Create]: "Successfully created",
  [Segment.OperationOptions.Edit]: "Successfully updated",
  [Segment.OperationOptions.Draft]: "Successfully updated",
};

export const Complete = ({
  operation,
  onCancelButtonClicked,
  onConfirmButtonClicked,
  segmentId,
}: CompleteProps) => (
  <>
    <CustomDialog.Description>
      {description[operation]}
    </CustomDialog.Description>
    <label className="mb-2 font-semibold text-xs text-navy-400">
      Segment ID
    </label>
    <TextInput
      endAdornment={
        <Icon.Copy
          onClick={clipboard.copyToHandler({
            value: segmentId,
            onComplete: () =>
              toast.success("Segment ID copied to clipboard", {
                position: "bottom-right",
              }),
          })}
          className="cursor-pointer text-navy-100 hover:text-navy-200 transition duration-100 ease-in-out mr-3"
        />
      }
      value={segmentId}
      disabled={true}
      className="mb-10"
      data-e2e="segment-id"
    />
    <div className="flex justify-between">
      {(() => {
        switch (operation) {
          case Segment.OperationOptions.Create:
            return (
              <CreateActions onConfirmButtonClick={onConfirmButtonClicked} />
            );
          case Segment.OperationOptions.Edit:
            return (
              <EditActions
                onConfirmButtonClick={onConfirmButtonClicked}
                onCancelButtonClick={onCancelButtonClicked}
              />
            );
        }
      })()}
    </div>
  </>
);
