// credit: https://gist.github.com/zentala/1e6f72438796d74531803cc3833c039c
export function bytes(bytes: number, decimals?: number) {
  if (bytes === 0) {
    return "0 Bytes";
  }
  let k = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
