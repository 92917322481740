import React from "react";

import { Segment } from "@components/atoms";
import { CustomDialog } from "@components/molecules";

import * as pages from "./pages";

export interface OperationDialogueProps {
  isDialogOpen?: boolean;
  onClose: () => void | Promise<void>;
  onConfirmButtonClicked: () => void | Promise<void>;
  onCancelButtonClicked?: () => void | Promise<void>;
  onTryAgain: () => void | Promise<void>;
  segmentId: string;
  operation: Segment.OperationOptions;
  loading: boolean;
  error: boolean;
}

const title: { [key in Segment.OperationOptions]: string } = {
  [Segment.OperationOptions.Create]: "New Segment",
  [Segment.OperationOptions.Edit]: "Segment updated",
  [Segment.OperationOptions.Draft]: "Segment updated",
};

export const OperationDialogue = ({
  isDialogOpen,
  onClose,
  segmentId,
  operation,
  onConfirmButtonClicked,
  onCancelButtonClicked,
  loading,
  error,
  onTryAgain,
}: OperationDialogueProps) => (
  <CustomDialog.Container isDialogOpen={isDialogOpen} onClose={onClose}>
    <CustomDialog.Card onClose={onClose}>
      <CustomDialog.Title>{title[operation]}</CustomDialog.Title>
      {(() => {
        if (error) {
          return <pages.Error onCancel={onClose} onTryAgain={onTryAgain} />;
        }
        if (loading) {
          return <pages.Preparing operation={operation} />;
        }
        return (
          <pages.Complete
            operation={operation}
            onConfirmButtonClicked={onConfirmButtonClicked}
            onCancelButtonClicked={onCancelButtonClicked}
            segmentId={segmentId}
          />
        );
      })()}
    </CustomDialog.Card>
  </CustomDialog.Container>
);
