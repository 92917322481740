import React from "react";

import {
  StatusLabel,
  StatusLabelColor,
  StatusLabelProps,
} from "../../StatusLabel";

type LabelActiveProps = Omit<StatusLabelProps, "color">;

export const LabelActive = (props: LabelActiveProps) => (
  <StatusLabel color={StatusLabelColor.Teal} {...props}>
    Active
  </StatusLabel>
);
