import React, {
  ForwardRefExoticComponent,
  HTMLAttributes,
  RefAttributes,
  useRef,
} from "react";

import { Button, Icon, Portal } from "@components/atoms";
import { classnames, TArg } from "@external/tailwindcss-classnames";

export type Ref = HTMLDivElement;

export interface CardProps extends HTMLAttributes<Ref> {
  onClose?: () => void;
  onOpened?: () => void;
  className?: any;
}

export const Card: ForwardRefExoticComponent<CardProps & RefAttributes<Ref>> =
  React.forwardRef<Ref, CardProps>(({ children, onClose, className }, ref) => {
    const titlePortalRef = useRef<HTMLDivElement | null>(null);
    const descriptionPortalRef = useRef<HTMLDivElement | null>(null);

    return (
      <Portal.PortalProvider
        targets={{
          title: titlePortalRef,
          description: descriptionPortalRef,
        }}
      >
        <div
          ref={ref}
          className={classnames(
            className,
            "m-4",
            "flex",
            "flex-col",
            "w-full",
            "max-w-xl",
            "transform" as TArg,
            "bg-white",
            "shadow-4dp",
            "rounded-lg"
          )}
        >
          <Button
            onClick={onClose}
            className="self-end mb-4 mr-6 mt-6 hover:text-coolGrey-400 text-coolGrey-300"
            color="clear"
          >
            <Icon.Close />
          </Button>
          <div>
            <div ref={titlePortalRef} />
            <div ref={descriptionPortalRef} />
          </div>
          <div className="px-16 pb-10 flex-auto">{children}</div>
        </div>
      </Portal.PortalProvider>
    );
  });
