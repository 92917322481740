import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { Card, Icon, NoContent } from "@components/atoms";
import { TranscriptCard_ListSentimentOccurrenceDocument } from "@graphql/operations";
import { useVideo } from "@hooks/useVideo";
import { secondsToTimeRange } from "@utils/temporal";

interface TranscriptionCardProps {
  videoId: string;
}

export const TranscriptionCard = ({ videoId }: TranscriptionCardProps) => {
  const { currentTimestamp } = useVideo();
  const timeRange = secondsToTimeRange(currentTimestamp / 1000, 10);

  const { data, previousData, error, refetch } = useQuery(
    TranscriptCard_ListSentimentOccurrenceDocument,
    {
      variables: {
        filter: {
          videoId: {
            eq: videoId,
          },
          timestamp: { between: [timeRange.start, timeRange.end] },
        },
        limit: 999,
      },
    }
  );

  const errorBody = error ? (
    <Card.ErrorRetry onRetry={() => refetch()} />
  ) : null;

  let content = "";

  if (
    previousData?.listSentimentOccurrence?.items &&
    previousData?.listSentimentOccurrence.items.length > 0
  ) {
    const items = previousData.listSentimentOccurrence.items;
    content = items[items.length - 1].Sentence;
  }

  if (
    data?.listSentimentOccurrence?.items &&
    data?.listSentimentOccurrence.items.length > 0
  ) {
    const items = data.listSentimentOccurrence.items;
    content = items[items.length - 1].Sentence;
  }

  let noDataBody;

  if (!content) {
    noDataBody = <NoContent />;
  }

  const TextContent = () => <p className="text-black-300">{content}</p>;

  return (
    <Card.Container>
      <Card.Header>Transcript</Card.Header>
      <Card.Body>
        {errorBody || noDataBody || (content && <TextContent />)}
      </Card.Body>
      <Card.Footer className="text-right flex justify-end items-center">
        <Link to={`/insights/${videoId}/transcript`}>
          <span className="hover:underline">Overview </span>
          <Icon.ChevronRight color="black-200" className="inline" />
        </Link>
      </Card.Footer>
    </Card.Container>
  );
};
