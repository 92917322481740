import { useState } from "react";

import { Button, Segment, TextInput } from "@components/atoms";
import * as Icon from "@components/atoms/Icon";
import { Segment as DraggableSegment } from "@components/molecules";
import { FilterType } from "@hooks/segments";
import { useDebounce } from "@hooks/useDebounce";

import { Section } from "./Section";

enum Panels {
  Options,
  Conditions,
}

enum Sections {
  Metrics = "Metrics",
  Dimensions = "Dimensions",
}

type Condition = { name: string; icon: React.ReactNode };
type ConditionsMap = {
  [key in Sections]: { [key in FilterType]?: Condition };
};
const Conditions: ConditionsMap = {
  [Sections.Metrics]: {
    [FilterType.RUN_DIFFERENTIAL__COMPARE]: {
      name: "Run Differential",
      icon: <Icon.Separator size={16} />,
    },
    [FilterType.INNING__COMPARE]: {
      name: "Inning",
      icon: <Icon.BaseballBall size={16} />,
    },
    [FilterType.EVENT_RUNS_SCORED__COMPARE]: {
      name: "Event Runs Scored",
      icon: <Icon.Running size={16} />,
    },
    [FilterType.EVENT_LEVERAGE_INDEX__COMPARE]: {
      name: "Event Leverage Index",
      icon: <Icon.LineChart size={16} />,
    },
  },
  [Sections.Dimensions]: {
    [FilterType.CLOSE_GAME__EQUALS]: {
      name: "Close Game",
      icon: <Icon.CircleClose size={16} />,
    },
    [FilterType.DELAYED_GAME__EQUALS]: {
      name: "Delayed Game",
      icon: <Icon.BellZ size={16} />,
    },
    [FilterType.DOUBLE_HEADER__EQUALS]: {
      name: "Double Header",
      icon: <Icon.Double size={16} />,
    },
    [FilterType.NIGHT_GAME__EQUALS]: {
      name: "Night Game",
      icon: <Icon.MoonStars size={16} />,
    },
    [FilterType.VENUE__EQUALS]: {
      name: "Venue",
      icon: <Icon.Venue size={16} />,
    },
    // [FilterType.PRE_ALL_STAR__EQUALS]: {
    //   name: "Pre All-Star",
    //   icon: <Icon.Sparkles size={16} />,
    // },
    // [FilterType.POST_ALL_STAR__EQUALS]: {
    //   name: "Post All-Star",
    //   icon: <Icon.Stars size={16} />,
    // },
    [FilterType.SEASON_TYPE__EQUALS]: {
      name: "Season Type",
      icon: <Icon.CalendarStats size={16} />,
    },
    [FilterType.HOME_TEAM__EQUALS]: {
      name: "Home Team",
      icon: <Icon.HomeStar size={16} />,
    },
    [FilterType.AWAY_TEAM__EQUALS]: {
      name: "Away Team",
      icon: <Icon.Map size={16} />,
    },
    [FilterType.DIVISION__EQUALS]: {
      name: "Division",
      icon: <Icon.UserGroup size={16} />,
    },
    [FilterType.LEAGUE__EQUALS]: {
      name: "League",
      icon: <Icon.SpokenNoun size={16} />,
    },
    [FilterType.PLAYER__EQUALS]: {
      name: "Player",
      icon: <Icon.ShirtSport size={16} />,
    },
    [FilterType.WINNING_TEAM__EQUALS]: {
      name: "Winning Team",
      icon: <Icon.WinningTeam size={16} />,
    },
    // [FilterType.EXTRA_INNINGS__EQUALS]: {
    //   name: "Extra Innings",
    //   icon: <Icon.EqualNot size={16} />,
    // },
    [FilterType.SERIES_TYPE__EQUALS]: {
      name: "Series Type",
      icon: <Icon.WorldQuestion size={16} />,
    },
    [FilterType.INNING_HALF__EQUALS]: {
      name: "Inning Half",
      icon: <Icon.CircleHalf size={16} />,
    },
    [FilterType.EVENT_TYPE__EQUALS]: {
      name: "Event Type",
      icon: <Icon.EventType size={16} />,
    },
    [FilterType.EVENT_DESCRIPTION__CONTAINS]: {
      name: "Event Description",
      icon: <Icon.LayoutLeft size={16} />,
    },
    [FilterType.LINEUP_CHANGE__EQUALS]: {
      name: "Lineup Change",
      icon: <Icon.ArrowsCross size={16} />,
    },
    // [FilterType.STOLEN_BASE__EQUALS]: {
    //   name: "Stolen Base",
    //   icon: <Icon.StolenBase size={16} />,
    // },
  },
};

export const SportsDataSection = () => {
  const [panel, setPanel] = useState<Panels>(Panels.Options);
  const [section, setSection] = useState<Sections>(Sections.Metrics);
  const [showAll, setShowAll] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 100);

  return (
    <Section
      title={switchFn(panel, {
        [Panels.Options]: "Sports Data",
        [Panels.Conditions]: (
          <Button
            color="clear"
            icon={<Icon.ArrowLeftCircle />}
            onClick={() => setPanel(0)}
          />
        ),
      })}
    >
      {switchFn(panel, {
        [Panels.Options]: (
          <>
            <Segment.MinimisedCondition
              leftIcon={<Icon.Stop />}
              rightIcon={<Icon.ChevronRight />}
              onClick={() => {
                setSection(Sections.Metrics);
                setPanel(Panels.Conditions);
              }}
              colour={Segment.ConditionColor.Orange}
              classNames="cursor-pointer"
            >
              Metrics
            </Segment.MinimisedCondition>
            <Segment.MinimisedCondition
              leftIcon={<Icon.Stop />}
              rightIcon={<Icon.ChevronRight />}
              onClick={() => {
                setSection(Sections.Dimensions);
                setPanel(Panels.Conditions);
              }}
              colour={Segment.ConditionColor.Orange}
              classNames="cursor-pointer"
            >
              Dimensions
            </Segment.MinimisedCondition>
          </>
        ),
        [Panels.Conditions]: (
          <>
            <div className="px-6 py-2 bg-white border-b border-coolGrey-200">
              <TextInput
                icon={<Icon.Search size={16} className="ml-2 text-black-100" />}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue || ""}
                placeholder={`Search ${section}`}
              />
            </div>
            {Object.entries(Conditions[Sections[section]])
              .filter(filterCondition(debouncedSearchValue))
              .map(buildCondition)
              .slice(0, showAll ? undefined : 5)}

            {switchFn(section, {
              [Sections.Dimensions]: (
                <div className="bg-white px-6 py-4">
                  <Button variant="text" onClick={() => setShowAll((s) => !s)}>
                    {showAll ? "Show less" : "View all"}
                  </Button>
                </div>
              ),
            })}
          </>
        ),
      })}
    </Section>
  );
};

const filterCondition =
  (searchValue: string) =>
  ([, props]: [string, Condition]) =>
    !searchValue
      ? true
      : props.name.toLowerCase().includes(searchValue.toLowerCase());

const buildCondition = ([key, props]: [string, Condition]) => (
  <DraggableSegment.MinimisedCondition
    key={key}
    icon={props.icon}
    type={key as FilterType}
  >
    {props.name}
  </DraggableSegment.MinimisedCondition>
);

const switchFn = (value: Panels | Sections, map: any) =>
  map[value] ? map[value] : map.default ? map.default : null;
