import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Plus = createSvgIcon({
  content: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.02164 0C8.25418 0 8.44269 0.188512 8.44269 0.421053V15.5789C8.44269 15.8115 8.25418 16 8.02164 16C7.7891 16 7.60059 15.8115 7.60059 15.5789V0.421053C7.60059 0.188512 7.7891 0 8.02164 0Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.97849C0 7.74595 0.188512 7.55743 0.421053 7.55743H15.5789C15.8115 7.55743 16 7.74595 16 7.97849C16 8.21103 15.8115 8.39954 15.5789 8.39954H0.421053C0.188512 8.39954 0 8.21103 0 7.97849Z"
        className="fill-current"
      />
    </>
  ),
  width: 16,
  height: 16,
});
