import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import _ from "lodash";

import {
  Card,
  Icon,
  NoContent,
  ProgressChart,
  ProgressValue,
} from "@components/atoms";
import {
  CelebritiesCard_ListCelebrityOccurrenceDocument,
  SortDirection,
} from "@graphql/operations";
import { useVideo } from "@hooks/useVideo";

export interface CelebritiesProps {
  videoId: string;
}

const cleanResult = (data: ProgressValue[] = []) => {
  const sortedByConfidence = _.sortBy(data, ["value"]);
  const dedupedByCelebrity = _.uniqBy(sortedByConfidence, "name");
  const top4Results = _.take(dedupedByCelebrity, 4);

  return top4Results;
};

export const CelebritiesCard = ({ videoId }: CelebritiesProps) => {
  const { currentTimeRange } = useVideo();

  const { data, previousData, error, refetch } = useQuery(
    CelebritiesCard_ListCelebrityOccurrenceDocument,
    {
      variables: {
        filter: {
          videoId: {
            eq: videoId,
          },
          timestamp: {
            between: [currentTimeRange.start, currentTimeRange.end],
          },
        },
        limit: 999,
        sortDirection: SortDirection.Desc,
      },
    }
  );

  const errorBody = error ? (
    <Card.ErrorRetry onRetry={() => refetch()} />
  ) : null;

  let list: ProgressValue[] = [];

  if (previousData?.listCelebrityOccurrence?.items) {
    list = previousData.listCelebrityOccurrence.items.map((item) => ({
      name: item.Name,
      value: item.Confidence,
    }));
  }

  if (data?.listCelebrityOccurrence?.items) {
    list = data.listCelebrityOccurrence.items.map((item) => ({
      name: item.Name,
      value: item.Confidence,
    }));
  }

  list = cleanResult(list);

  let noDataBody;

  if (list.length === 0) {
    noDataBody = <NoContent />;
  }

  return (
    <Card.Container>
      <Card.Header>Celebrities</Card.Header>
      <Card.Body>
        {errorBody || noDataBody || <ProgressChart data={list} color="blue" />}
      </Card.Body>
      <Card.Footer className="text-right flex justify-end items-center">
        <Link to={`/insights/${videoId}/celebrities`}>
          <span className="hover:underline">Overview </span>
          <Icon.ChevronRight color="black-200" className="inline" />
        </Link>
      </Card.Footer>
    </Card.Container>
  );
};
