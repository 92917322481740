import React from "react";

import { FormInput } from "@components/molecules";

const updateValue = (
  sizeType: "min" | "max",
  sizeValue: string,
  currentValue: SegmentSizeValue = {}
) => {
  const otherSizeType = sizeType === "min" ? "max" : "min";
  const newSizeValue = parseInt(sizeValue) || undefined;

  if (newSizeValue === undefined && currentValue[otherSizeType] === undefined) {
    return {};
  } else {
    return {
      [sizeType]: newSizeValue,
      [otherSizeType]: currentValue[otherSizeType],
    };
  }
};

export type SegmentSizeValue = { min?: number; max?: number };

interface SegmentSizeProps {
  value?: SegmentSizeValue;
  onChange: (value: SegmentSizeValue) => void;
}

export const SegmentSize = ({ value, onChange }: SegmentSizeProps) => (
  <div className="grid grid-cols-2 gap-x-5 pb-2">
    <FormInput
      type="number"
      value={value?.min || ""}
      onChange={(e) => {
        onChange(updateValue("min", e.target.value, value));
      }}
      placeholder="Min"
      label="Min"
      fieldName="min"
    />
    <FormInput
      type="number"
      value={value?.max || ""}
      onChange={(e) => {
        onChange(updateValue("max", e.target.value, value));
      }}
      placeholder="Max"
      label="Max"
      fieldName="max"
    />
  </div>
);
