import React, { useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";

import { Button, Icon, TextInput } from "@components/atoms";
import { Pager, Segment, Table } from "@components/molecules";
import { useDebounce } from "@hooks/useDebounce";
import { clipboard } from "@utils/index";

import { SegmentPill } from "./SegmentPill";

export interface GetSegmentJobQueryResponse {
  [K: string]: {
    SegmentId: string;
    Status: string;
    RedshiftQueryStatus: string;
    Title: string;
  };
}

const PER_PAGE = 15;

export interface SegmentsTabBodyProps {
  segmentIds?: string[];
}

export const SegmentsTabBody = ({ segmentIds = [] }: SegmentsTabBodyProps) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 300);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalPages = useMemo(
    () => Math.ceil(segmentIds.length / PER_PAGE),
    [segmentIds]
  );

  const query = useMemo(
    () =>
      segmentIds.length === 0
        ? gql`
            query Empty {
              jobs {
                aggregate {
                  Total
                }
              }
            }
          `
        : gql`
            query GetSegmentJob {
            ${segmentIds.map(
              (segmentId, i) => `
              segment_${i}: segmentJob(filter: { segmentId: { eq: "${
                segmentId.startsWith("$") ? segmentId.slice(1) : segmentId
              }" } }) {
                SegmentId
                Status
                RedshiftQueryStatus
                Title
              }
            `
            )}
            }
          `,
    [segmentIds]
  );

  const { loading, data, error } = useQuery<GetSegmentJobQueryResponse>(query, {
    skip: segmentIds.length === 0,
  });

  const segments = useMemo(
    () =>
      data
        ? segmentIds.map((SegmentId, i) => ({
            ...data![`segment_${i}`],
            SegmentId,
          }))
        : [],
    [data, segmentIds]
  );

  const filteredSegments = useMemo(() => {
    const lowerCaseDebouncedSearchValue = debouncedSearchValue.toLowerCase();

    return segments.filter(
      (segment) =>
        segment.Title.toLowerCase().includes(lowerCaseDebouncedSearchValue) ||
        segment.SegmentId.toLowerCase().includes(lowerCaseDebouncedSearchValue)
    );
  }, [segments, debouncedSearchValue]);

  const currentSegments = useMemo(
    () =>
      filteredSegments.slice(
        PER_PAGE * (currentPage - 1),
        PER_PAGE * (currentPage - 1) + PER_PAGE
      ),
    [currentPage, filteredSegments]
  );

  return (
    <div className="m-4 grow-0 flex flex-col gap-4">
      <TextInput
        icon={<Icon.Search size={16} className="ml-2 text-black-100" />}
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        placeholder="Title or ID"
      />

      <div className="flex-row flex-auto bg-white">
        <Table.Table
          columns={[
            { title: "Segment title", width: "w-9/12" },
            { width: "w-3/12" },
          ]}
          compact
          loading={loading}
          loadingRows={PER_PAGE}
          loadingRowsHeight="h-12"
          error={!!error}
        >
          {data && currentSegments.length > 0
            ? currentSegments.map((segment) => (
                <Table.Row
                  key={segment.SegmentId}
                  height="h-12"
                  data-testid="trtr"
                >
                  <Table.Cell>
                    <div className="inline-flex gap-1 align-center">
                      <SegmentPill
                        id={segment.SegmentId}
                        title={segment.Title}
                      />
                      <Button
                        className="ml-2 text-coolGrey-300"
                        variant="contained"
                        color="clear"
                        icon={<Icon.Copy size={24} />}
                        onClick={clipboard.copyToHandler({
                          value: segment.SegmentId,
                          onComplete: clipboard.toastSuccess({
                            message: "Copied to clipboard",
                          }),
                        })}
                      />
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <Segment.Status.Label
                      className="mx-6"
                      redshiftQueryStatus={segment.RedshiftQueryStatus}
                      status={segment.Status}
                    />
                  </Table.Cell>
                </Table.Row>
              ))
            : null}
        </Table.Table>
      </div>

      {totalPages > 1 && (
        <Pager
          className="mr-0"
          data-testid="pager"
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChanged={(page) => setCurrentPage(page)}
        />
      )}
    </div>
  );
};
