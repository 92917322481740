import React, { type ReactNode } from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface CardProps {
  className?: any;
  children: ReactNode;
}

export const Container = ({ children, className, ...rest }: CardProps) => (
  <div
    className={classnames(
      className,
      "flex",
      "flex-col",
      "flex-initial",
      "bg-white",
      "rounded",
      "shadow"
    )}
    {...rest}
  >
    {children}
  </div>
);
