import React, { ChangeEvent, useState } from "react";

import { DialogBox, Segment } from "@components/atoms";
import {
  ContentModerationFilter,
  FilterType,
  LabelAction,
  useFilters,
} from "@hooks/segments";
import { ConvertToUpperSnakeCase } from "@utils/casing";

import { ShowAdvanced } from "../ShowAdvanced";

import { LabelGroup } from "./LabelGroup";
import { ContentModerationLabels } from "./labels";

interface Props {
  onChange: (value: ContentModerationFilter) => void | Promise<void>;
}

export type ContentModerationEqualsProps = Props &
  ContentModerationFilter &
  Omit<Segment.ConditionTemplateProps, "type">;

const handleChildLabels = (
  checkedState: boolean,
  parentKey: string | undefined
): Array<string> | null => {
  const childKeyValues: Array<string> = [];
  if (!parentKey) {
    return null;
  }
  const children = document.querySelectorAll(
    `[data-parent="${parentKey}"]`
  ) as NodeListOf<HTMLInputElement>;
  if (children.length > 0) {
    children.forEach((child) => {
      childKeyValues.push(child.value);
      child.checked = checkedState;
    });
  }

  return childKeyValues;
};

export const ContentModerationEquals = ({
  id,
  onChange,
  values,
  within,
  confidence,
  ...rest
}: ContentModerationEqualsProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState<Array<string>>([]);
  const { contentModerationCtx } = useFilters();

  const handleContentModerationSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked, dataset } = e.target;
    const parentKey = dataset["parent"];

    if (checked) {
      let updatedSelectedLabels = selectedLabels.concat(value);
      if (!parentKey) {
        const children = handleChildLabels(checked, value) as string[];
        updatedSelectedLabels = updatedSelectedLabels.concat(children);
      }
      contentModerationCtx.dispatch({
        value,
        checkedStatus: LabelAction.Checked,
        parentKey: parentKey,
        ownerId: id,
      });

      onChange({ values: updatedSelectedLabels, confidence, within });
      setSelectedLabels(updatedSelectedLabels);
    }

    if (!checked) {
      let updatedSelectedLabels = selectedLabels.filter(
        (label) => label !== value
      );
      if (!parentKey) {
        updatedSelectedLabels = updatedSelectedLabels.filter((selectedLabel) =>
          handleChildLabels(checked, value)?.includes(selectedLabel)
            ? false
            : true
        );
      }
      contentModerationCtx.dispatch({
        value,
        checkedStatus: LabelAction.UnChecked,
        parentKey: parentKey,
        ownerId: id,
      });

      if (updatedSelectedLabels.length === 0) {
        onChange({ values: undefined, confidence, within });
      }
      if (updatedSelectedLabels.length > 0) {
        onChange({ values: updatedSelectedLabels, confidence, within });
      }
      setSelectedLabels(updatedSelectedLabels);
    }
  };

  return (
    <>
      <Segment.ConditionTemplate
        id={id}
        type={FilterType.CONTENT_MODERATION__EQUALS}
        {...rest}
      >
        <label className="mb-2 font-semibold text-xs text-navy-400">
          Flagged Content
        </label>
        <div className="flex flex-col mb-6 h-64 rounded-lg border border-navy-100 p-3 overflow-y-scroll bg-lightGrey-100">
          {ContentModerationLabels.map((label) => {
            return (
              <LabelGroup
                occupiedLabels={contentModerationCtx.state}
                planeId={id}
                key={ConvertToUpperSnakeCase(label.value)}
                labels={label}
                handleClickEvent={handleContentModerationSelect}
              />
            );
          })}
        </div>
        <div className="w-full flex justify-end">
          <ShowAdvanced
            setIsAdvancedOpen={setIsAdvancedOpen}
            isAdvancedOpen={isAdvancedOpen}
          >
            <div className="grid grid-cols-2 gap-x-4">
              <Segment.ConfidenceThreshold
                onAfterChange={(confidence) =>
                  onChange({ values, confidence, within })
                }
              />
              <Segment.DurationInput
                value={within}
                onChange={(within) => onChange({ values, confidence, within })}
                onInfoClick={() => setIsDialogOpen(true)}
              />
            </div>
          </ShowAdvanced>
        </div>
      </Segment.ConditionTemplate>
      <DialogBox.Container
        isDialogOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogBox.Card onClose={() => setIsDialogOpen(false)}>
          <DialogBox.Title>Tolerance</DialogBox.Title>
          <p className="text-sm">
            Specify a buffer of time around the occurence.
            <br />
            E.g., Ginger Rogers must appear in the frame{" "}
            <span className="font-bold">within 5 minutes</span> of a given point
            in the video for that point to meet segment condititions.
          </p>
        </DialogBox.Card>
      </DialogBox.Container>
    </>
  );
};
