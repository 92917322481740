import React, { useState } from "react";

import { Button } from "@components/atoms";
import {
  TreeViewColumnsSelector,
  TreeViewColumnsSelectorProps,
} from "@components/molecules/Exporting/TreeViewColumnsSelector";

export type ConfigProps = {
  onClose: () => void;
  onSubmission: (v: string[]) => void;
} & Pick<TreeViewColumnsSelectorProps, "selectableFields"> & {
    initialSelectedNodeIds?: TreeViewColumnsSelectorProps["selectedNodesId"];
  };

export const Config = ({
  initialSelectedNodeIds,
  selectableFields,
  onClose,
  onSubmission,
}: ConfigProps) => {
  const [columns, setColumns] = useState<string[]>(
    initialSelectedNodeIds || []
  );

  return (
    <>
      <TreeViewColumnsSelector
        onChange={setColumns}
        selectedNodesId={initialSelectedNodeIds}
        selectableFields={selectableFields}
      />

      <div className="flex flex-row justify-between mt-12">
        <Button
          type="button"
          variant="text"
          onClick={onClose}
          data-e2e="button-cancel-export-data"
        >
          Cancel
        </Button>

        <Button
          onClick={() => onSubmission(columns)}
          variant="contained"
          color="primary"
          size="medium"
          disabled={columns.length === 0}
          data-e2e="button-export-data"
        >
          Export data
        </Button>
      </div>
    </>
  );
};
