import React from "react";

import { Button, TextInput } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

import { FilterType, useFilters } from "../../hooks";

export const Celebrities = () => {
  const context = useFilters();
  const filter = context.getFilter(FilterType.CELEBRITY__LIKE);
  const celebrityFilter = filter || {
    filterType: FilterType.CELEBRITY__LIKE,
    values: [""],
  };

  const onTextChange = (index: number, e: any) => {
    celebrityFilter.values[index] = e.target.value;
    context.addFilter(celebrityFilter);
  };

  const onAddAnother = () => {
    celebrityFilter.values.push("");
    context.addFilter(celebrityFilter);
  };

  const onClearClicked = (index: number) => {
    if (celebrityFilter.values.length > 1) {
      celebrityFilter.values.splice(index, 1);
      context.addFilter(celebrityFilter);
    } else {
      context.removeFilter(FilterType.CELEBRITY__LIKE);
    }
  };

  const isAnotherButtonVisible =
    celebrityFilter.values.length > 0 &&
    celebrityFilter.values[celebrityFilter.values.length - 1] !== "";

  return (
    <div>
      <div className="flex flex-col">
        {celebrityFilter.values.map((value, index) => (
          <TextInput
            className={classnames({
              "mb-3":
                celebrityFilter.values.length > 1 || isAnotherButtonVisible,
            })}
            inputClassName="w-52"
            key={index}
            placeholder="Search celebrity"
            onChange={(e) => onTextChange(index, e)}
            value={value}
            endAdornment={
              ((index === 0 && value.length > 0) || index > 0) && (
                <span
                  className="text-sm font-semibold cursor-pointer mr-4 text-black-300"
                  onClick={() => onClearClicked(index)}
                >
                  Clear
                </span>
              )
            }
          />
        ))}
      </div>
      {isAnotherButtonVisible ? (
        <Button size="small" onClick={onAddAnother}>
          + Another
        </Button>
      ) : null}
    </div>
  );
};
