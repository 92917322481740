import { classnames } from "@external/tailwindcss-classnames";
import { FilterContextProps } from "@hooks/segments";

import {
  InsightsSection,
  SegmentsSection,
  SportsDataSection,
} from "./Sections";

interface SidebarProps {
  className?: any;
  filters: FilterContextProps;
}

export const Sidebar = ({ className, filters }: SidebarProps) => (
  <div className={classnames(className, "flex", "gap-4", "flex-col")}>
    <InsightsSection filters={filters} />
    <SportsDataSection />
    <SegmentsSection />
  </div>
);
