import React from "react";

import { Time } from "@components/atoms";
import { Table } from "@components/molecules";
import { CelebritiesTable_CelebrityOccurenceFragment } from "@graphql/operations";

import { InsightsTable, InsightsTableProps } from "../InsightsTables";

import { UrlDisplayHandler } from "./UrlDisplayHandler";

export type CelebritiesTableProps = {
  celebrities?: CelebritiesTable_CelebrityOccurenceFragment[];
} & Pick<InsightsTableProps, "loading" | "loadingRows">;

// this component renders a table of celebrities
export const CelebritiesTable = ({
  celebrities = [],
  loading,
  loadingRows,
}: CelebritiesTableProps) => (
  <InsightsTable
    columns={[
      { title: "Name", width: "w-3/12" },
      { title: "Urls", width: "w-5/12" },
      { title: "Timestamp", width: "w-2/12" },
      { title: "Confidence", width: "w-2/12" },
    ]}
    loading={loading}
    loadingRows={loadingRows}
    loadingRowsHeight="h-18"
  >
    {celebrities.length > 0
      ? celebrities.map((celebrity) => (
          <Table.Row
            key={`${celebrity.Id}_${celebrity.Timestamp}`}
            height="h-18"
          >
            <Table.Cell className="font-bold">{celebrity.Name}</Table.Cell>
            <Table.Cell>
              {celebrity.Urls && celebrity.Urls.length > 0 ? (
                <UrlDisplayHandler urls={celebrity.Urls} />
              ) : (
                "-"
              )}
            </Table.Cell>
            <Table.Cell>
              <Time>{celebrity.Timestamp}</Time>
            </Table.Cell>
            <Table.Cell>{celebrity.Confidence}</Table.Cell>
          </Table.Row>
        ))
      : null}
  </InsightsTable>
);
