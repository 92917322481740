import React, { ForwardRefExoticComponent, RefAttributes } from "react";

import { classnames } from "@external/tailwindcss-classnames";

import { Button, ButtonProps } from "../Button";

interface Props {
  active?: boolean;
  variant?: "default" | "slim";
  underlineVerticalSpacing?: "small" | "medium";
}

export type TabProps = Props &
  Omit<ButtonProps, "variant"> &
  RefAttributes<HTMLButtonElement>;

export const Tab: ForwardRefExoticComponent<TabProps> = React.forwardRef<
  HTMLButtonElement,
  TabProps
>(
  (
    {
      children,
      active,
      variant = "default",
      underlineVerticalSpacing = "small",
      ...rest
    },
    ref
  ) => (
    <div className="inline-flex flex-col">
      <Button
        ref={ref}
        size="tight"
        variant="contained"
        color="clear"
        className={classnames(
          "hover:no-underline",
          "px-3",
          "hover:bg-navy-300/4" as any,
          {
            "text-blue-300": active,
            ["text-black-300/38" as any]: !active,
            "h-8": variant === "default",
            [classnames("h-4", "font-semibold")]: variant === "slim",
          }
        )}
        {...rest}
      >
        <div
          className={classnames("font-medium", {
            "text-h6": variant === "default",
            "text-xs": variant === "slim",
          })}
        >
          {children}
        </div>
      </Button>
      <div
        className={classnames(
          "h-0.5",
          "bg-blue-300",
          "transition-opacity",
          "ease-in-out",
          "duration-100",
          "mx-3",
          {
            "opacity-0": !active,
            "mt-2": underlineVerticalSpacing === "medium",
            "mt-0.5": underlineVerticalSpacing === "small",
          }
        )}
      />
    </div>
  )
);
