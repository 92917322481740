import React, { useState } from "react";
import Hls, {
  ErrorData,
  FragChangedData,
  FragLoadedData,
  InitPTSFoundData,
} from "hls.js";

import { Player } from "@components/atoms";
import { useVideo } from "@hooks/useVideo";

export interface HlsPlayerProps {
  url?: string;
  onInitPtsFound?: (e: InitPTSFoundData) => void;
  onFragLoaded?: (e: FragLoadedData) => void;
  onFragChanged?: (e: FragChangedData) => void;
}

export const HlsPlayer = ({
  url,
  onInitPtsFound,
  onFragLoaded,
  onFragChanged,
}: HlsPlayerProps) => {
  const { setVideoError } = useVideo();
  const [withCredentials, setWithCredentials] = useState<boolean>(false);
  const [alreadyAttemptedRecovery, setAttemptedRecovery] = useState<boolean>();

  const xhrSetup = React.useCallback(
    (xhr: XMLHttpRequest) => {
      xhr.withCredentials = withCredentials;
      xhr.setRequestHeader(
        "Access-Control-Allow-Origin",
        process.env.REACT_APP_URL || ""
      );
    },
    [withCredentials]
  );

  const onError = React.useCallback(
    (e: ErrorData) => {
      if (e.fatal) {
        setVideoError(e.type);
      }

      switch (e.details) {
        case Hls.ErrorDetails.FRAG_LOAD_ERROR:
          switch ((e.networkDetails as XMLHttpRequest).status) {
            case 403:
              if (alreadyAttemptedRecovery) {
                setVideoError(e.type);
              } else {
                setWithCredentials(true);
                setAttemptedRecovery(true);
              }
              break;
          }
          break;
      }
    },
    [alreadyAttemptedRecovery, setVideoError]
  );

  return (
    <Player
      xhrSetup={xhrSetup}
      streamId={url}
      autoPlay={true}
      controls={true}
      muted={true}
      onErrorData={onError}
      onInitPtsFound={onInitPtsFound}
      onFragLoaded={onFragLoaded}
      onFragChanged={onFragChanged}
    />
  );
};
