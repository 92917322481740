import React, { useState } from "react";

import { DialogBox } from "@components/atoms";
import {
  ConditionTemplate,
  ConditionTemplateProps,
  DurationInput,
} from "@components/atoms/Segment";
import { FilterType, TranscriptContainsFilter } from "@hooks/segments";

import { MultilineShortcut } from "./MultilineShortcut";
import { ShowAdvanced } from "./ShowAdvanced";

interface Props {
  onChange: (value: TranscriptContainsFilter) => void | Promise<void>;
}

export type TranscriptContainsProps = Props &
  TranscriptContainsFilter &
  Omit<ConditionTemplateProps, "type">;

export const TranscriptContains = ({
  onChange,
  value,
  within,
  location,
  ...rest
}: TranscriptContainsProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);

  return (
    <>
      <ConditionTemplate
        type={FilterType.TRANSCRIPT__CONTAINS}
        location={location}
        {...rest}
      >
        <label className="mb-2 font-semibold text-xs text-navy-400">
          Spoken Word Or Phrase
        </label>

        <MultilineShortcut
          conditionId={rest.id}
          label="Spoken Word Or Phrase"
          value={value}
          placeholderTextName="spoken word or phrase"
          location={location}
          filterType={FilterType.TRANSCRIPT__CONTAINS}
          onChange={(value) => onChange({ value, within })}
          appendFilterProps={{ within }}
        />

        <ShowAdvanced
          setIsAdvancedOpen={setIsAdvancedOpen}
          isAdvancedOpen={isAdvancedOpen}
        >
          <div className="grid grid-cols-2 gap-x-4">
            <DurationInput
              data-testid="within"
              value={within}
              onChange={(within) => onChange({ value, within })}
              onInfoClick={() => setIsDialogOpen(true)}
            />
          </div>
        </ShowAdvanced>
      </ConditionTemplate>
      <DialogBox.Container
        isDialogOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogBox.Card onClose={() => setIsDialogOpen(false)}>
          <DialogBox.Title>Tolerance</DialogBox.Title>
          <p className="text-sm">
            Specify a buffer of time around the occurence.
            <br />
            E.g., Ginger Rogers must appear in the frame{" "}
            <span className="font-bold">within 5 minutes</span> of a given point
            in the video for that point to meet segment condititions.
          </p>
        </DialogBox.Card>
      </DialogBox.Container>
    </>
  );
};
