import React, { HTMLAttributes } from "react";

import { classnames } from "@external/tailwindcss-classnames";

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  className?: any;
}

export const Container = ({ className, children }: ContainerProps) => (
  <div
    className={classnames(
      className,
      "flex",
      "flex-row",
      "gap-x-2",
      "items-center",
      "justify-end"
    )}
  >
    {children}
  </div>
);
