import React, { type ReactNode } from "react";
import { Menu } from "@headlessui/react";

import { classnames } from "@external/tailwindcss-classnames";

export interface ItemsProps {
  className?: any;
  children: ReactNode;
}

export const Items = ({ children, className }: ItemsProps) => (
  <Menu.Items
    data-e2e="dropdown-menu"
    className={classnames(
      className,
      "absolute",
      "right-0",
      "mt-2",
      "bg-white",
      "rounded-md",
      "shadow-3dp"
    )}
  >
    {children}
  </Menu.Items>
);
