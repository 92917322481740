import React, { type ReactNode } from "react";
import { useDrop } from "react-dnd";

import { DropTarget, ItemTypes } from "@components/atoms/Segment";
import { classnames } from "@external/tailwindcss-classnames";
import { FilterType, FilterValue, useFilters } from "@hooks/segments";

export interface ConditionContainerProps {
  className?: any;
  disabled?: boolean;
  empty?: boolean;
  onDrop?: (item: {
    type: FilterType;
    action?: string;
    filter?: FilterValue;
    id?: string;
    source: "ANY" | "ALL" | "EXCLUDE";
  }) => void;
  minLength?: number;
  children?: ReactNode;
}

export const ConditionContainer = ({
  minLength,
  className,
  disabled,
  empty,
  onDrop,
  children,
}: ConditionContainerProps) => {
  const { length } = useFilters();

  const [{ isOver, canDrop, isDragging }, drop] = useDrop(
    () => ({
      accept: ItemTypes.CONDITION,
      drop: (dragObject: {
        type: FilterType;
        index?: number;
        action?: string;
        filter?: FilterValue;
        source: "ANY" | "ALL" | "EXCLUDE";
      }) => {
        onDrop && onDrop(dragObject);
      },
      canDrop: (_obj, monitor) => {
        const isMoveAction = monitor.getItem()?.action === "MOVE";
        const isLogicallyDisabled =
          isMoveAction && (minLength ? minLength >= length : false);
        const isDisabled = !!disabled || isLogicallyDisabled;
        return !isDisabled;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        isDragging: !!monitor.getItemType(),
      }),
    }),
    [disabled, length]
  );

  const logicalDisabled = disabled || (isDragging && !canDrop);

  return (
    <div className={classnames(className)}>
      {children}
      {empty && (
        <DropTarget ref={drop} isOver={isOver} disabled={logicalDisabled}>
          {empty &&
            logicalDisabled &&
            "Add at least one other condition before adding exclude conditions"}
          {empty &&
            !logicalDisabled &&
            "Drag and drop one or more conditions to create your Segment."}
        </DropTarget>
      )}
    </div>
  );
};
