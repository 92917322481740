import * as temporal from "@utils/temporal";

import { ImportColumnType } from "../useImportState/importColumnType";

import { pass, pushError, Result } from "./result";

export type Input = Partial<{
  [ImportColumnType.tolerance]: string;
}>;

export function validate({ tolerance }: Input): Result {
  let err = pass();

  if (!tolerance) {
    return err;
  }

  if (!temporal.parseDuration(tolerance)) {
    err = pushError(err, ImportColumnType.tolerance, "must be a duration");
  }

  return err;
}
