import React, { useState } from "react";

import { Image, Loading } from "@components/atoms";
import { Images } from "@components/molecules";
import { classnames } from "@external/tailwindcss-classnames";

export interface JobThumbnailProps {
  size?: "small" | "medium";
  className?: any;
  src?: string | null;
}

export const JobThumbnail = ({
  className,
  src,
  size = "medium",
}: JobThumbnailProps) => {
  const [error, setError] = useState(false);

  if (error) {
    return (
      <Images.ErrorLoadingImage
        size={size}
        data-testid="ErrorLoadingImage"
        className={classnames(className, {
          "h-47": size === "medium",
        })}
      />
    );
  }

  if (!src) {
    return (
      <Images.NoImageAvailable
        data-testid="NoImageAvailable"
        size={size}
        className={classnames(className, {
          "h-47": size === "medium",
        })}
      />
    );
  }

  return (
    <Image
      data-testid="Image"
      className={className}
      alt="thumbnail"
      src={src}
      loading={
        <Loading.Shimmer
          data-testid="Shimmer"
          className={classnames(className, {
            "h-47": size === "medium",
          })}
        />
      }
      onError={() => setError(true)}
    />
  );
};
