import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MutationHookOptions, useMutation } from "@apollo/client";
import { isEmpty } from "lodash";

import { Button, Segment as SegmentAtoms } from "@components/atoms";
import { Segment } from "@components/molecules";
import {
  BeginSegmentButton_BeginSegmentJobDocument,
  BeginSegmentButton_BeginSegmentJobMutation,
  BeginSegmentButton_BeginSegmentJobMutationVariables,
  NestedSegmentIdEquals_SegmentJobDocument,
  SegmentJobDetails_SegmentJobDocument,
  SegmentJobTable_ListSegmentJobsDocument,
  SegmentsJobDuplicate_SegmentJobDocument,
  SegmentsJobEdit_SegmentJobDocument,
  SegmentsOverview_ListSegmentJobs_AggregateDocument,
} from "@graphql/operations";
import { useFilters } from "@hooks/segments";
import * as errors from "@utils/errors";

export const beginSegmentJobDefaultOptions: MutationHookOptions<
  BeginSegmentButton_BeginSegmentJobMutation,
  BeginSegmentButton_BeginSegmentJobMutationVariables
> = {
  refetchQueries: [
    SegmentsJobDuplicate_SegmentJobDocument,
    SegmentsJobEdit_SegmentJobDocument,
    NestedSegmentIdEquals_SegmentJobDocument,
    SegmentJobDetails_SegmentJobDocument,
    SegmentJobTable_ListSegmentJobsDocument,
    SegmentsOverview_ListSegmentJobs_AggregateDocument,
  ],
};

export interface BeginSegmentButtonProps {
  segmentId: string;
  version: number;
  disabled?: boolean;
}

export const BeginSegmentButton = ({
  segmentId,
  version,
  disabled,
}: BeginSegmentButtonProps) => {
  const { query, segmentTitle } = useFilters();

  const [
    beginSegmentJob,
    {
      loading: beginSegmentJobLoading,
      error: beginSegmentJobError,
      reset: beginSegmentJobReset,
    },
  ] = useMutation(
    BeginSegmentButton_BeginSegmentJobDocument,
    beginSegmentJobDefaultOptions
  );
  const [isDialogueOpen, setIsDialogueOpen] = useState(false);
  const navigate = useNavigate();

  const variables = {
    input: {
      segmentId,
      version,
    },
  };

  const createAndStartJob = useCallback(
    async (variables: BeginSegmentButton_BeginSegmentJobMutationVariables) => {
      await beginSegmentJob({
        variables,
      });
    },
    [beginSegmentJob]
  );

  return (
    <>
      <Button
        type="button"
        size="medium"
        color="primary"
        disabled={disabled || isEmpty(segmentTitle) || isEmpty(query)}
        data-e2e="button-begin-segment"
        onClick={async () => {
          setIsDialogueOpen(true);
          await createAndStartJob(variables);
        }}
      >
        Begin Segment
      </Button>
      <Segment.OperationDialogue
        operation={SegmentAtoms.OperationOptions.Create}
        loading={beginSegmentJobLoading}
        segmentId={segmentId}
        isDialogOpen={isDialogueOpen}
        onClose={() => setIsDialogueOpen(false)}
        onConfirmButtonClicked={() => navigate("/segments")}
        error={errors.isApolloErrorPresent(beginSegmentJobError)}
        onTryAgain={async () => {
          beginSegmentJobReset();
          await createAndStartJob(variables);
        }}
      />
    </>
  );
};
