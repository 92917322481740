import React from "react";

import { Label } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

export type SquaredLabelProps = {
  children: React.ReactNode;
};

export const SquaredLabel = ({ children }: SquaredLabelProps) => (
  <Label
    className={classnames(
      "inline-block",
      "py-2",
      "px-4",
      "border",
      "rounded-lg",
      "border-transparent",
      "bg-lightGrey-100",
      "text-black-300",
      "font-semibold",
      "text-sm",
      "mr-2"
    )}
  >
    {children}
  </Label>
);
