import React from "react";

import { FormSelect } from "@components/molecules";
import { classnames } from "@external/tailwindcss-classnames";

export interface ColumnMappingProps<K extends keyof any> {
  title: string;
  value?: K;
  columns: { [P in K]?: string };
  onColumnChange: (v: K) => void | Promise<void>;
  values: any[];
  firstRowContainsHeader?: boolean;
  className?: any;
}

export const ColumnMapping = <K extends keyof any>({
  title,
  values,
  value,
  columns,
  onColumnChange,
  firstRowContainsHeader,
  className,
}: ColumnMappingProps<K>) => {
  let header = "";
  let displayableValues = values;
  if (firstRowContainsHeader) {
    header = values[0];
    displayableValues = displayableValues.slice(1);
  }
  displayableValues = displayableValues
    .map((value, idx) => ({ value, idx }))
    .filter(({ value }) => !!value);
  const slice = displayableValues.slice(0, 5);
  const remaining = displayableValues.length - slice.length;

  return (
    <div className={classnames(className, "border", "border-coolGrey-200")}>
      <div className="px-6 pt-6 mb-4">
        <h6 className="text-navy-300 font-semibold mb-8">{title}</h6>
        <FormSelect
          label="Map to"
          className="w-full"
          placeholder="Select..."
          onChange={onColumnChange}
          value={value}
          options={columns}
        />
      </div>
      {header && (
        <div className="flex items-center text-h8 text-black-200 font-semibold h-10 bg-lightGrey-200 px-6">
          {header}
        </div>
      )}
      <div className="px-6 pb-6 grid grid-cols-[min-content_1fr] gap-x-3">
        {slice.map(({ value, idx }) => (
          <React.Fragment key={`${value}_${idx}`}>
            <div
              data-testid="row-index"
              className="h-10 text-sm font-semibold flex items-center text-black-100"
            >
              {idx + 1}
            </div>
            <div
              data-testid="row-value"
              className="text-sm font-semibold flex items-center text-black-300 overflow-hidden"
            >
              <div className="truncate">{value}</div>
            </div>
          </React.Fragment>
        ))}
        {remaining ? (
          <>
            <div className="h-10 text-sm font-semibold flex items-center text-black-100">
              ...
            </div>
            <div className="h-10 text-sm font-semibold flex items-center text-black-100">
              {remaining} more
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
