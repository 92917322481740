import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Metadata = createSvgIcon({
  content: (
    <>
      <path d="M13.0376458,36.4168708 C8.77595861,35.9939854 5.95894595,35.4118504 4.58660782,34.6704658 C1.89866932,33.2183482 0.770293973,30.4677336 0.304493053,29.1629694 C-0.254086759,27.5983204 -0.21793789,23.5266516 1.92128273,20.7797466 C2.5951807,19.9144157 3.86643734,18.7042301 5.73505265,17.1491899 C4.80427036,14.0481669 4.42145542,11.8089935 4.58660782,10.4316698 C5.07300273,6.37527542 7.59265837,3.16592409 9.35619494,2.0625594 C11.1202351,0.958879611 14.9289512,-0.742186063 19.1780813,0.353548818 C20.6196267,0.725284084 22.8363418,2.13336912 25.8282268,4.57780394 C28.118495,4.09526057 29.7259628,3.86334535 30.6506304,3.88205829 C32.4596447,3.91866817 34.2438391,4.58480775 35.0999025,5.07049 C36.0354271,5.60125407 37.223696,6.61886721 38.0156435,8.25015963 C38.3412164,8.92079045 38.6031791,10.1501132 38.8015319,11.938128"></path>
      <path d="M19.1780813,36.5583936 L19.1780813,21.5583936 C19.1780813,18.7969699 21.4166576,16.5583936 24.1780813,16.5583936 C26.9395051,16.5583936 29.1780813,18.7969699 29.1780813,21.5583936 L29.1780813,36.5583936 L29.1780813,36.5583936"></path>
      <path d="M29.1780813,36.5583936 L29.1780813,21.5583936 C29.1780813,18.7969699 31.4166576,16.5583936 34.1780813,16.5583936 C36.9395051,16.5583936 39.1780813,18.7969699 39.1780813,21.5583936 L39.1780813,36.5583936 L39.1780813,36.5583936"></path>
    </>
  ),
  width: 43,
  height: 41,
  defaultClassName: "stroke-current",
});
