import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { Card, Icon, NoContent } from "@components/atoms";
import { RollupChart, RollupType } from "@components/organisms";
import { RollupSpokenNounsCard_CumulativeSpokenNounsDocument } from "@graphql/operations";
import { useVideo } from "@hooks/useVideo";

export interface RollupSpokenNounsCardProps {
  videoId: string;
  rollupType: RollupType;
}

export const RollupSpokenNounsCard = ({
  videoId,
  rollupType,
}: RollupSpokenNounsCardProps) => {
  const { currentTimeRange } = useVideo();
  const timestamp = currentTimeRange.end;

  const { data, previousData, error, refetch } = useQuery(
    RollupSpokenNounsCard_CumulativeSpokenNounsDocument,
    {
      variables: {
        filter: {
          videoId: {
            eq: videoId,
          },
          timestamp: {
            lt: timestamp,
          },
        },
      },
    }
  );

  const errorBody = error ? (
    <Card.ErrorRetry onRetry={() => refetch()} />
  ) : null;

  let noDataBody;

  const cumulativeSpokenNouns =
    data?.cumulativeSpokenNouns || previousData?.cumulativeSpokenNouns;

  if (!cumulativeSpokenNouns) {
    noDataBody = <NoContent />;
  }

  return (
    <Card.Container>
      <Card.Header>Rollup Syntax Detection: Spoken Nouns</Card.Header>
      <Card.Body>
        {errorBody || noDataBody || (
          <RollupChart
            rollupData={cumulativeSpokenNouns || undefined}
            rollupType={rollupType}
            color="orange"
          />
        )}
      </Card.Body>
      <Card.Footer className="text-right flex justify-end items-center">
        <Link to={`/insights/${videoId}/syntax`}>
          <span className="hover:underline">Overview </span>
          <Icon.ChevronRight color="black-200" className="inline" />
        </Link>
      </Card.Footer>
    </Card.Container>
  );
};
