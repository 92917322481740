import React from "react";
import {
  DefaultValues,
  FieldValues,
  Path,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";

import { Button, Checkbox } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

const checkboxStyle = (state: boolean) =>
  classnames("px-4", "py-3", "cursor-pointer", {
    "bg-blue-300": state,
    "bg-opacity-8": state,
    "hover:bg-navy-300": !state,
    "hover:bg-opacity-4": !state,
  });

type Filter<Name> = {
  display: JSX.Element;
  name: Name;
};

export interface FormData extends FieldValues {}

export interface FormProps<T extends FormData> {
  filters: {
    [K in keyof T]: Filter<K>;
  };
  defaultValues?: DefaultValues<T>;
  onSubmit: SubmitHandler<T>;
}

export function Form<T extends FormData>({
  filters,
  defaultValues,
  onSubmit,
}: FormProps<T>) {
  const { register, handleSubmit, control, reset } = useForm<T>({
    defaultValues,
  });

  const watched = useWatch({
    control,
  });

  return (
    <div className="rounded-lg shadow-4dp ring-1 ring-black-300 ring-opacity-5 bg-white">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-coolGrey-100 h-16 flex justify-center items-center border-b border-coolGrey-200">
          <div className="flex justify-center items-center px-4">
            <Button
              size="small"
              color="default"
              variant="contained"
              onClick={() => {
                reset();
              }}
            >
              Clear
            </Button>
            <div className="mx-8 text-black-300 text-h6 font-semibold">
              Status
            </div>
            <Button size="small" color="primary" variant="contained">
              Apply
            </Button>
          </div>
        </div>
        {Object.values(filters).map((filter: Filter<Path<T>>) => (
          <Checkbox
            key={filter.name}
            className={checkboxStyle(watched[filter.name])}
            {...register(filter.name)}
          >
            {filter.display}
          </Checkbox>
        ))}
      </form>
    </div>
  );
}
