import React, { type ReactNode, useRef } from "react";
import { Transition } from "@headlessui/react";

import { useWindowEvent } from "@hooks/useWindowEvent";

export interface DropdownProps {
  open: boolean;
  onClose: () => Promise<void> | void;
  className?: any;
  children: ReactNode;
}

export const Dropdown = ({
  className,
  onClose,
  open,
  children,
  ...rest
}: DropdownProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useWindowEvent("mousedown", (event: MouseEvent) => {
    let target = event.target as HTMLElement;

    if (!open) {
      return;
    }
    if (ref.current?.contains(target)) {
      return;
    }

    onClose();
  });

  useWindowEvent("keydown", (event: KeyboardEvent) => {
    if (event.key !== "Escape" || !open) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    onClose();
  });

  return (
    <Transition
      as="div"
      className={className}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
      show={open}
      {...rest}
    >
      <div
        ref={ref}
        className="flex flex-col w-110 transform bg-white shadow-2dp rounded-lg"
      >
        {children}
      </div>
    </Transition>
  );
};
