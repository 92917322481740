import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import _ from "lodash";

import { Button, Card } from "@components/atoms";
import { ColumnMapping } from "@components/organisms";
import { classnames } from "@external/tailwindcss-classnames";
import { ImportColumnType, useSegmentImport } from "@hooks/segments";
import { notEmpty } from "@utils/typing";

export const QUERY = gql`
  query GetSegmentImport {
    segmentImport @client {
      rawData
      firstRowContainsHeader
      numberOfColumns
      columnTypes
    }
  }
`;

const options: { [key in ImportColumnType]: string } = {
  [ImportColumnType.skip]: "Skip this column",
  [ImportColumnType.conditionType]: "Condition Type",
  [ImportColumnType.conditionValue]: "Condition Value",
  [ImportColumnType.confidenceScore]: "Confidence Score",
  [ImportColumnType.tolerance]: "Within",
  [ImportColumnType.operator]: "Operator",
};

export const FieldMapping = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { setColumnType } = useSegmentImport();
  const { columnNumber: columnNumberStr } = useParams<{
    columnNumber: string;
  }>();
  const columnNumber = parseInt(columnNumberStr || "", 10);
  const columnIndex = columnNumber - 1;
  const { data: segmentImportData, loading } = useQuery<{
    segmentImport?: {
      rawData: string[][];
      firstRowContainsHeader?: boolean;
      numberOfColumns: number;
      columnTypes: ImportColumnType[];
    };
  }>(QUERY, {
    returnPartialData: true,
  });

  if (loading) {
    return null;
  }

  if (!segmentImportData?.segmentImport?.rawData) {
    navigate("/segments/import");
    return null;
  }

  const { rawData, firstRowContainsHeader, numberOfColumns, columnTypes } =
    segmentImportData.segmentImport;
  const columnType = columnTypes[columnIndex];

  const backLink =
    columnNumber <= 1
      ? "/segments/import"
      : `/segments/import/fields/${columnNumber - 1}`;
  const continueLink =
    columnNumber >= numberOfColumns
      ? "/segments/import/errors"
      : `/segments/import/fields/${columnNumber + 1}`;

  const displayOptions = _.omit(
    options,
    (columnTypes || [])
      .filter(notEmpty)
      .filter((x) => x !== ImportColumnType.skip)
      .filter((x) => columnTypes && x !== columnType)
  );

  return (
    <div className="w-169">
      <Card.Container className="p-10 mb-6">
        <h4 className="mb-8 text-navy-300">Field Mapping</h4>
        <div className="mb-10 text-black-300 text-bodySm">
          Match each column from your uploaded CSV to the relevant condition
          property. You can skip irrelevant columns.
        </div>
        <ColumnMapping
          className="w-[306px] self-center"
          title={`Column ${columnNumber}`}
          firstRowContainsHeader={firstRowContainsHeader}
          value={columnType}
          columns={displayOptions}
          onColumnChange={(v) => setColumnType(v, columnIndex)}
          values={(rawData || []).map((row) => row[columnIndex])}
        />
      </Card.Container>
      <div className="flex justify-between px-10 mb-6">
        <Button
          onClick={() =>
            navigate({
              pathname: backLink,
              search,
            })
          }
          variant="text"
        >
          Back
        </Button>
        <Button
          data-e2e="field-mapping-continue-button"
          className={classnames({ invisible: !rawData })}
          onClick={() =>
            navigate({
              pathname: continueLink,
              search,
            })
          }
          disabled={!columnType}
          type="button"
          size="medium"
          color="primary"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
