import { ReactNode } from "react";

type SectionProps = {
  title: ReactNode;
  children: ReactNode;
};

export const Section = ({ title, children }: SectionProps) => (
  <div>
    <div className="text-navy-300 font-semibold bg-white h-16 flex items-center justify-between px-6 border-b border-coolGrey-200">
      {title}
    </div>
    <div className="flex flex-col">{children}</div>
  </div>
);
