import React, { ReactNode } from "react";

import { classnames } from "@external/tailwindcss-classnames";

interface ConnectorProps {
  className?: any;
  children: ReactNode;
}

export const Connector = ({ className, children }: ConnectorProps) => (
  <div
    className={classnames(
      className,
      "text-bodyXs",
      "font-bold",
      "text-black-100"
    )}
  >
    <div className="border-l border-coolGrey-200 w-[1px] mx-auto h-4" />
    {children}
    <div className="border-l border-coolGrey-200 w-[1px] mx-auto h-4" />
  </div>
);
