import React from "react";
import { Listbox } from "@headlessui/react";

import { classnames } from "@external/tailwindcss-classnames";

interface Props {
  children: React.ReactNode;
  fixedWidth?: boolean;
  style?: any;
  className?: any;
  direction?: "up" | "down";
  static?: boolean;
}

export type OptionsProps = React.ComponentPropsWithRef<typeof Listbox.Options> &
  Props;

export const Options = React.forwardRef<HTMLUListElement, OptionsProps>(
  ({ className, children, fixedWidth, direction, ...rest }, ref) => (
    <Listbox.Options
      ref={ref}
      className={classnames(
        className,
        {
          "right-0": !fixedWidth,
          "w-max": !fixedWidth,
          "w-full": fixedWidth,
          "left-0": fixedWidth,
          "mt-2": direction === "down",
        },
        "max-h-100",
        "overflow-y-scroll",
        "absolute",
        "z-30",
        "shadow-4dp",
        "text-h6",
        "rounded-lg",
        "border",
        "border-navy-100",
        "bg-white",
        "text-black-300"
      )}
      {...rest}
    >
      {children}
    </Listbox.Options>
  )
);
