import React from "react";
import { isEqual } from "lodash";

import { Button } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

import { ConditionSelector } from "../ConditionSelector";
import { FilterPanel } from "../FilterPanel";
import { useFilters } from "../hooks";

interface AdvancedSearchPanelProps {
  className?: any;
}

export const AdvancedSearchPanel = ({
  className,
}: AdvancedSearchPanelProps) => {
  const context = useFilters();

  const disabled = isEqual(context.previousFilter || {}, context.filters);

  return (
    <div
      className={classnames(
        className,
        "pt-6",
        "bg-white",
        "shadow-1dp",
        "rounded-md",
        "w-72"
      )}
    >
      <h6 className="text-navy-300 font-semibold px-6">Refine Search</h6>
      <ConditionSelector />
      <FilterPanel />
      <div className="flex justify-between items-center px-6 py-4">
        <Button type="button" variant="text" onClick={() => context.reset()}>
          Reset
        </Button>
        <Button
          type="button"
          size="medium"
          color="primary"
          disabled={disabled}
          onClick={() => context.applyFilters()}
        >
          Apply filters
        </Button>
      </div>
    </div>
  );
};
