import React from "react";

import { Segment } from "@components/atoms";
import { FilterType, MetadataContainsFilter } from "@hooks/segments";

import { MultilineShortcut } from "./MultilineShortcut";

interface Props {
  onChange: (value: MetadataContainsFilter) => void | Promise<void>;
}

export type MetadataContainsProps = Props &
  MetadataContainsFilter &
  Omit<Segment.ConditionTemplateProps, "type">;

export const MetadataContains = ({
  onChange,
  value,
  location,
  ...rest
}: MetadataContainsProps) => {
  return (
    <>
      <Segment.ConditionTemplate
        type={FilterType.METADATA__CONTAINS}
        location={location}
        {...rest}
      >
        <label className="mb-2 font-semibold text-xs text-navy-400">
          FMC Metadata
        </label>
        <MultilineShortcut
          conditionId={rest.id}
          label="FMC Metadata"
          value={value || ""}
          onChange={(value) => {
            onChange({ value });
          }}
          placeholderTextName="FMC Metadata"
          location={location}
          filterType={FilterType.METADATA__CONTAINS}
        />
      </Segment.ConditionTemplate>
    </>
  );
};
