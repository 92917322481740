import React from "react";
import { Link } from "react-router-dom";

import { Button } from "@components/atoms";
import { Images } from "@components/molecules";

export const SegmentDoesntExist = () => (
  <article className="flex-auto flex flex-col items-center justify-center">
    <Images.AtlasIconLogo className="mb-10" size={96} />
    <h2 className="mb-6">Segment not found</h2>
    <div className="text-bodyLg text-black-300 mb-12 font-light">
      This Segment does not exist or was removed.
    </div>
    <Link to="/segments">
      <Button color="primary" size="large">
        Back to Segments
      </Button>
    </Link>
  </article>
);
