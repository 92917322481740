import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Close = createSvgIcon({
  content: (
    <path
      d="M6.34302 6.34314L17.6567 17.6568M6.34302 17.6568L17.6567 6.34314"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  ),
  width: 24,
  height: 24,
  defaultClassName: "stroke-current",
});
