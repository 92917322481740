import React, { type ReactNode } from "react";

export interface TextWithStatusPointProps {
  pointColor: "coolGrey" | "navy" | "blue" | "red" | "teal" | "orange";
  children: ReactNode;
}

export const TextWithStatusPoint = ({
  pointColor,
  children,
}: TextWithStatusPointProps) => (
  <div className="flex items-center text-black-300 font-semibold text-h7">
    <div className={`mr-2 rounded-full w-1.5 h-1.5 bg-${pointColor}-300`} />
    {children}
  </div>
);
