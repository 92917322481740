import React from "react";

import {
  StatusLabel,
  StatusLabelColor,
  StatusLabelProps,
} from "../../StatusLabel";

type LabelProcessingProps = Omit<StatusLabelProps, "color">;

export const LabelProcessing = (props: LabelProcessingProps) => (
  <StatusLabel color={StatusLabelColor.Navy} {...props}>
    Processing
  </StatusLabel>
);
