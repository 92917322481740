import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const Sparkles = createSvgIcon({
  content: (
    <path
      d="M5.33333 12C4.97971 12 4.64057 11.8596 4.39052 11.6095C4.14048 11.3595 4 11.0203 4 10.6667C4 11.0203 3.85952 11.3595 3.60948 11.6095C3.35943 11.8596 3.02029 12 2.66667 12C3.02029 12 3.35943 12.1405 3.60948 12.3906C3.85952 12.6406 4 12.9798 4 13.3334C4 12.9798 4.14048 12.6406 4.39052 12.3906C4.64057 12.1405 4.97971 12 5.33333 12ZM10 4.00004C10 5.06091 9.57857 6.07832 8.82843 6.82847C8.07828 7.57861 7.06087 8.00004 6 8.00004C7.06087 8.00004 8.07828 8.42147 8.82843 9.17161C9.57857 9.92176 10 10.9392 10 12C10 10.9392 10.4214 9.92176 11.1716 9.17161C11.9217 8.42147 12.9391 8.00004 14 8.00004C12.9391 8.00004 11.9217 7.57861 11.1716 6.82847C10.4214 6.07832 10 5.06091 10 4.00004Z"
      stroke="#303030"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  ),
  width: 16,
  height: 16,
});
