import { gql } from "@apollo/client";

export const typeDefs = gql`
  extend type Query {
    segmentImport: SegmentImport
  }

  type WebFile {
    name: String!
    size: Int!
    type: String!
  }

  type SegmentImport {
    id: ID!
    firstRowContainsHeader: Boolean
    numberOfColumns: Int
    rawData: [[String]]
    file: WebFile
    columnTypes: [String]
  }
`;
