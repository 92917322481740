import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";

import { Button, Icon } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

import { Form, FormData, FormProps } from "./Form";

export interface FilterPopoverProps<T extends FormData> extends FormProps<T> {
  buttonText: string;
  buttonSize?: "filter" | "large" | "medium" | "small" | "tight";
}

export const FilterPopover = <T extends FormData>({
  buttonText,
  buttonSize = "filter",
  filters,
  defaultValues,
  onSubmit,
}: FilterPopoverProps<T>) => {
  const numberOfSelectedItems =
    defaultValues &&
    Object.values(defaultValues).reduce((acc, v) => acc + v, 0);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            as={Button}
            active={open}
            size={buttonSize}
            color="default"
            className="ml-4 text-black-300 px-3"
            icon={<Icon.Filter size={16} className="mr-2 text-black-100" />}
          >
            {buttonText}
            <span
              className={classnames("ml-3", "text-blue-300", {
                hidden: !numberOfSelectedItems,
              })}
            >
              {numberOfSelectedItems}
            </span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 mt-1">
              <Form<T>
                onSubmit={onSubmit}
                filters={filters}
                defaultValues={defaultValues}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
