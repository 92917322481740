import { ImportColumnType } from "../useImportState/importColumnType";

import { pass, pushError, Result } from "./result";

export type Input = Partial<{
  [ImportColumnType.confidenceScore]: string;
}>;

export function validate({ confidenceScore }: Input): Result {
  let err = pass();
  if (!confidenceScore) {
    return err;
  }

  const confidenceScoreValue = parseInt(confidenceScore);

  if (Number.isNaN(confidenceScoreValue)) {
    return pushError(err, ImportColumnType.confidenceScore, "must be a number");
  }

  if (confidenceScoreValue < 50) {
    return pushError(err, ImportColumnType.confidenceScore, "min");
  }

  if (confidenceScoreValue > 100) {
    return pushError(err, ImportColumnType.confidenceScore, "max");
  }

  return err;
}
