import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const VideoDuration = createSvgIcon({
  content: (
    <>
      <path
        d="M8 0.888889C11.9222 0.888889 15.1111 4.07778 15.1111 8C15.1111 11.9222 11.9222 15.1111 8 15.1111C4.07778 15.1111 0.888889 11.9222 0.888889 8C0.888889 4.07778 4.07778 0.888889 8 0.888889ZM8 0C3.57778 0 0 3.57778 0 8C0 12.4222 3.57778 16 8 16C12.4222 16 16 12.4222 16 8C16 3.57778 12.4222 0 8 0Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99913 3.55554C8.24459 3.55554 8.44358 3.75453 8.44358 3.99999V7.99999C8.44358 8.24545 8.24459 8.44443 7.99913 8.44443C7.75367 8.44443 7.55469 8.24545 7.55469 7.99999V3.99999C7.55469 3.75453 7.75367 3.55554 7.99913 3.55554Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.67597 7.70755C7.85002 7.53447 8.13143 7.53525 8.30451 7.7093L10.2934 9.7093C10.4665 9.88335 10.4657 10.1648 10.2916 10.3378C10.1176 10.5109 9.83619 10.5101 9.66311 10.3361L7.67422 8.33608C7.50114 8.16204 7.50192 7.88063 7.67597 7.70755Z"
        className="fill-current"
      />
    </>
  ),
  width: 16,
  height: 16,
});
