import React, { useCallback } from "react";

import { Button, Icon } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

export interface FileUploadProps {
  onChange?: (file: File) => void | Promise<void>;
}

export const FileUpload = ({ onChange }: FileUploadProps) => {
  const [isDragging, setIsDragging] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        onChange && onChange(e.dataTransfer.files[0]);
      }
    },
    [onChange]
  );

  const onDragEnter = useCallback((e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const onDragOver = useCallback((e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const onDragLeave = useCallback((e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.target.files && e.target.files[0]) {
        onChange && onChange(e.target.files[0]);
      }
    },
    [onChange]
  );

  return (
    <label
      onDragEnter={onDragEnter}
      onClick={() => inputRef.current?.click()}
      className={classnames(
        "relative",
        "flex",
        "flex-col",
        "items-center",
        "border",
        "border-dashed",
        "bg-coolGrey-100",
        "p-4",
        "transition-colors",
        "ease-in",
        "duration-75",
        "cursor-pointer",
        {
          [classnames("border-blue-300")]: isDragging,
          [classnames("border-coolGrey-300")]: !isDragging,
        }
      )}
      htmlFor="input-file-upload"
    >
      <input
        ref={inputRef}
        data-testid="input"
        className="hidden"
        type="file"
        name="input-file-upload"
        onChange={handleInputChange}
        data-e2e="csv-upload-input"
      />
      <Icon.Upload size={20} className="mb-5" />
      <div className="text-sm">
        <Button
          className="text-blue-300 active:text-blue-400"
          fontWeight="medium"
          variant="text"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            inputRef.current?.click();
          }}
        >
          Click to upload
        </Button>{" "}
        or drag and drop
      </div>
      {isDragging && (
        <div
          className="absolute w-full h-full inset-0"
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={handleDrop}
        ></div>
      )}
    </label>
  );
};
