import React, { useEffect, useState } from "react";

import { Button, Icon } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";

const MAX_PAGE_NUMBERS = 5;
const MAX_TOTAL_PAGE_NUMBERS = 7;
const BREAKDOWN_PAGER = Math.ceil(MAX_TOTAL_PAGE_NUMBERS / 2);
const SeparatorText = "...";

type PagerItem = string | number;

export interface PagerProps {
  currentPage: number;
  totalPages?: number;
  showNextPages?: boolean;
  nextButtonEnabled?: boolean;
  className?: any;
  onPageChanged: (newPage: number) => void;
}

export interface PagerLinkProps {
  pageNumber: number | string;
  separator?: boolean;
  isCurrentPage?: boolean;
  onPageNumberClicked: (pageNumber: number) => void;
}

function rebuildPages(
  currentPage: number,
  showNextPages?: boolean,
  totalPages?: number
): PagerItem[] | number[] {
  if (!totalPages || (!showNextPages && totalPages)) {
    const pages = [];
    let page = currentPage;

    while (page + MAX_PAGE_NUMBERS > currentPage && page > 0) {
      pages.push(page);
      page--;
    }
    return pages.reverse();
  }

  let delta = 1;
  if (currentPage < BREAKDOWN_PAGER) {
    delta = BREAKDOWN_PAGER - 1 - currentPage;
  } else if (totalPages - currentPage < BREAKDOWN_PAGER) {
    delta = BREAKDOWN_PAGER - (totalPages - currentPage);
  }

  const leftBound = currentPage - delta;
  const rightBound = currentPage + delta + BREAKDOWN_PAGER + 1;
  const visiblePageNumbers: number[] = [];

  for (let i = 1; i <= totalPages; i++) {
    if (i === 1 || i === totalPages || (i >= leftBound && i < rightBound)) {
      visiblePageNumbers.push(i);
    }
  }

  let previousPageNumber = visiblePageNumbers[0];
  const eclipsedRange: PagerItem[] = [previousPageNumber];

  for (let pageNumber of visiblePageNumbers.slice(1)) {
    if (pageNumber - previousPageNumber === 2) {
      eclipsedRange.push(previousPageNumber + 1);
    } else if (pageNumber - previousPageNumber !== 1) {
      eclipsedRange.push(SeparatorText);
    }
    eclipsedRange.push(pageNumber);
    previousPageNumber = pageNumber;
  }

  return eclipsedRange;
}

const PageLink = ({
  pageNumber,
  isCurrentPage,
  separator,
  onPageNumberClicked,
}: PagerLinkProps) => {
  let pageLinkClassName = classnames(
    "text-sm",
    "text-center",
    "text-black-300",
    "rounded-lg",
    "font-medium",
    "p-1.5",
    "min-w-8",
    "hover:transition-colors",
    "hover:duration-150",
    "hover:ease-in-out",
    "select-none"
  );

  if (separator) {
    pageLinkClassName = classnames(pageLinkClassName, "cursor-default");
    return <div className={pageLinkClassName}>...</div>;
  }

  if (isCurrentPage) {
    pageLinkClassName = classnames(pageLinkClassName, "bg-coolGrey-300");
  }

  pageLinkClassName = classnames(
    pageLinkClassName,
    "hover:bg-coolGrey-200",
    "cursor-pointer"
  );

  return (
    <div
      data-e2e="page-link"
      className={pageLinkClassName}
      onClick={() => onPageNumberClicked(pageNumber as number)}
    >
      {pageNumber}
    </div>
  );
};

export const Pager = ({
  currentPage,
  totalPages,
  nextButtonEnabled = true,
  showNextPages = false,
  onPageChanged,
  className,
  ...rest
}: PagerProps) => {
  const [pages, setPages] = useState<PagerItem[] | number[]>([]);

  useEffect(() => {
    setPages(
      rebuildPages(currentPage, showNextPages, totalPages) as PagerItem[]
    );
  }, [currentPage, showNextPages, totalPages]);

  if (currentPage < 1 || (totalPages && currentPage > totalPages)) {
    throw Error(
      `Total number of pages '${totalPages}' is lower than the current page '${currentPage}'`
    );
  }

  const containerClassName = classnames(
    "flex",
    "flex-row",
    "items-center",
    "space-x-3",
    "max-w-max",
    "m-auto",
    className
  );

  const arrowClassName = classnames(
    "text-black-300",
    "rounded-lg",
    "p-2",
    "min-w-8",
    "h-8",
    "cursor-pointer",
    "hover:transition-colors",
    "hover:duration-150",
    "hover:ease-in-out",
    "select-none"
  );

  let leftArrowClassName = classnames(arrowClassName);
  let rightArrowClassName = classnames(arrowClassName);
  let allowleftClick = true;
  let allowRightClick = true;

  if (currentPage === 1) {
    allowleftClick = false;
    leftArrowClassName = classnames(
      leftArrowClassName,
      "text-coolGrey-300",
      "cursor-not-allowed"
    );
  } else {
    leftArrowClassName = classnames(
      leftArrowClassName,
      "hover:bg-coolGrey-200"
    );
  }

  if ((totalPages && currentPage === totalPages) || !nextButtonEnabled) {
    allowRightClick = false;
    rightArrowClassName = classnames(
      rightArrowClassName,
      "text-coolGrey-300",
      "cursor-not-allowed"
    );
  } else {
    rightArrowClassName = classnames(
      rightArrowClassName,
      "hover:bg-coolGrey-200"
    );
  }

  return (
    <div className={containerClassName} {...rest} data-e2e="pager">
      <Button
        color="clear"
        variant="text"
        data-testid="previous-page"
        data-e2e="previous-page"
        disabled={!allowleftClick}
        className={leftArrowClassName}
        onClick={() => onPageChanged(currentPage - 1)}
      >
        <Icon.ChevronLeft />
      </Button>
      {(pages as PagerItem[]).map((value, idx) => (
        <PageLink
          key={`${idx}_${value}`}
          pageNumber={value}
          separator={value === SeparatorText}
          onPageNumberClicked={onPageChanged}
          isCurrentPage={value === currentPage}
        />
      ))}
      <Button
        color="clear"
        variant="text"
        data-testid="next-page"
        data-e2e="next-page"
        disabled={!allowRightClick}
        className={rightArrowClassName}
        onClick={() => onPageChanged(currentPage + 1)}
      >
        <Icon.ChevronRight />
      </Button>

      {!!totalPages && (
        <div className="text-black-100 font-medium text-bodySm !ml-6">
          Page {currentPage} of {totalPages}
        </div>
      )}
    </div>
  );
};
