import React from "react";

import { RadioButton } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";
import { AdvancedSearchQueryIntrinsicsCombinators } from "@graphql/operations";

import { useFilters } from "../hooks";

interface ConditionSelectorProps {
  className?: any;
}

export const ConditionSelector = ({ className }: ConditionSelectorProps) => {
  const context = useFilters();

  return (
    <div
      className={classnames(
        "flex",
        "flex-col",
        "mx-6",
        "mt-6",
        "border-b",
        "border-coolGrey-200",
        "pb-6",
        className
      )}
    >
      <p className="text-xs font-semibold text-navy-400">Combine filters by</p>
      <div className="flex flex-row mt-2">
        <RadioButton
          checked={
            context.combinator === AdvancedSearchQueryIntrinsicsCombinators.And
          }
          onChange={(e) => {
            e.target.value &&
              context.changeCombinator(
                AdvancedSearchQueryIntrinsicsCombinators.And
              );
          }}
        >
          And
        </RadioButton>
        <RadioButton
          className="ml-6"
          checked={
            context.combinator === AdvancedSearchQueryIntrinsicsCombinators.Or
          }
          onChange={(e) => {
            e.target.value &&
              context.changeCombinator(
                AdvancedSearchQueryIntrinsicsCombinators.Or
              );
          }}
        >
          Or
        </RadioButton>
      </div>
    </div>
  );
};
