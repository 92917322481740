import React, {
  createContext,
  type ReactNode,
  useContext,
  useState,
} from "react";

import { TimeRange } from "@utils/temporal";

interface IVideoProvider {
  setVideoLoadingState: (state: boolean) => void;
  videoLoading: boolean;
  setVideoError: (error: string | null) => void;
  videoError: string | null;
  setCurrentTimestamp: (timeStamp: number) => void;
  currentTimestamp: number;
  setCurrentTimeRange: (timeRange: TimeRange) => void;
  currentTimeRange: TimeRange;
}

const VideoContext = createContext<IVideoProvider | null>(null);

export const useVideo = (): IVideoProvider => {
  const context = useContext(VideoContext);

  if (!context) {
    throw new Error("useVideo should be used inside a VideoProvider component");
  }

  return context;
};

interface VideoProviderProps {
  children: ReactNode;
}
interface VideoMockProviderProps extends VideoProviderProps {
  mockedCtx: any;
}

export const VideoProvider = ({ children }: VideoProviderProps) => {
  const [videoError, setVideoError] = useState<string | null>(null);
  const [videoLoading, setVideoLoadingState] = useState(true);
  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const [currentTimeRange, setCurrentTimeRange] = useState<TimeRange>({
    start: "00:00:00.000",
    end: "00:00:30.000",
  });

  return (
    <VideoContext.Provider
      value={{
        setVideoLoadingState: (state: boolean) => setVideoLoadingState(state),
        videoLoading: videoLoading,
        setVideoError: (error: string | null) => setVideoError(error),
        videoError: videoError,
        setCurrentTimestamp: (timestamp: number) =>
          setCurrentTimestamp(timestamp),
        currentTimestamp: currentTimestamp,
        setCurrentTimeRange: (timeRange: TimeRange) =>
          setCurrentTimeRange(timeRange),
        currentTimeRange: currentTimeRange,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

export const VideoMockProvider = ({
  children,
  mockedCtx,
}: VideoMockProviderProps) => {
  return (
    <VideoContext.Provider value={mockedCtx}>{children}</VideoContext.Provider>
  );
};
