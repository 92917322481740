import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const CombinationMarkTest = createSvgIcon({
  content: (
    <>
      <path
        d="M41.2589 2.12891L48.8115 17.8315H46.0592L44.7151 14.7593H37.5251L36.1811 17.8315H33.5142L41.0242 2.12891H41.2589ZM40.1922 8.80667L38.4853 12.6043H43.7764L42.091 8.8281L41.1735 6.67323H41.1308L40.1922 8.80667Z"
        className="fill-current"
      />
      <path
        d="M56.1081 4.23926H52.0972V2.14844H62.5301V4.23926H58.4764V17.8296H56.1081V4.23926Z"
        className="fill-current"
      />
      <path
        d="M69.1011 2.14844H71.512V15.6962H76.0137V17.8298H69.1011V2.14844Z"
        className="fill-current"
      />
      <path
        d="M88.3243 2.12891L95.8769 17.8315H93.1247L91.7805 14.7593H84.5906L83.2465 17.8315H80.5796L88.0896 2.12891H88.3243ZM87.2575 8.80667L85.5506 12.6043H90.8417L89.1563 8.8281L88.239 6.67323H88.1962L87.2575 8.80667Z"
        className="fill-current"
      />
      <path
        d="M102.533 17.5954C101.694 17.2967 101.089 16.9627 100.72 16.5926L101.85 14.5658C102.149 14.8362 102.615 15.1172 103.248 15.4086C103.881 15.7003 104.489 15.8459 105.072 15.8459C105.826 15.8459 106.441 15.6682 106.918 15.3126C107.394 14.9572 107.632 14.4664 107.632 13.8404C107.632 13.357 107.504 12.9373 107.248 12.5816C106.992 12.2262 106.679 11.9308 106.309 11.6962C105.939 11.4615 105.413 11.1735 104.731 10.8322C103.92 10.434 103.365 10.1354 103.066 9.93612C101.687 9.01172 100.997 7.73158 100.997 6.09583C100.997 4.74472 101.445 3.70996 102.341 2.99155C103.237 2.27339 104.368 1.91406 105.733 1.91406C107.198 1.91406 108.443 2.33371 109.467 3.17289L108.336 5.11434C108.066 4.82994 107.686 4.58469 107.195 4.37822C106.704 4.17222 106.182 4.06893 105.627 4.06893C104.916 4.06893 104.357 4.22896 103.952 4.54902C103.547 4.86896 103.344 5.342 103.344 5.96776C103.344 6.40872 103.472 6.80347 103.728 7.15178C103.984 7.50057 104.307 7.80256 104.699 8.05859C105.09 8.31449 105.62 8.62762 106.288 8.99735C106.928 9.35297 107.419 9.63413 107.761 9.84C108.102 10.0464 108.422 10.2917 108.721 10.5761C109.133 10.9462 109.467 11.3868 109.723 11.8989C109.979 12.4109 110.107 12.973 110.107 13.5843C110.107 14.5374 109.89 15.3481 109.457 16.0165C109.023 16.6852 108.425 17.19 107.665 17.5312C106.903 17.8726 106.039 18.0433 105.072 18.0433C104.219 18.0434 103.372 17.894 102.533 17.5954Z"
        className="fill-current"
      />
      <path
        d="M10.771 10.8031L6.35725 8.57267L10.0473 12.4977L6.8397 20H0L8.54962 0L11.9695 8.00015L10.771 10.8031Z"
        className="fill-current"
      />
      <path
        d="M17.0988 20.0016L10.0469 12.4993L10.7705 10.8047L13.827 12.3482L14.5339 14.0016L17.0988 20.0016Z"
        className="fill-current"
      />
      <path
        d="M14.986 0.610687L23.0134 19.3893H17.502L15.0956 13.76L14.3887 12.1066L12.5307 7.76L9.47451 0.610687H14.986ZM15.389 0H8.54932L11.9692 8L13.8272 12.3466L14.534 14L17.0989 20H23.9386L15.389 0Z"
        className="fill-current"
      />
      <path
        d="M123.318 17.9999H124.48V8.98391H127.336V8.00391H120.434V8.98391H123.318V17.9999Z"
        className="fill-current"
      />
      <path
        d="M128.854 14.7099H133.446V14.2059C133.446 11.8819 132.564 10.8319 130.59 10.8319C128.616 10.8319 127.734 11.8819 127.734 14.2059V14.8079C127.734 17.0619 128.686 18.1259 130.702 18.1259C131.934 18.1259 132.69 17.8039 133.25 17.0479L132.424 16.5159C132.018 17.0059 131.556 17.2019 130.814 17.2019C129.54 17.2019 128.854 16.4319 128.854 15.0039V14.7099ZM128.854 13.8699V13.7579C128.854 13.2679 128.966 12.8059 129.148 12.4699C129.442 11.9659 129.876 11.7559 130.632 11.7559C131.29 11.7559 131.71 11.9379 132.004 12.3579C132.242 12.6939 132.382 13.2119 132.382 13.7579V13.8699H128.854Z"
        className="fill-current"
      />
      <path
        d="M134.953 17.0339C135.443 17.7479 136.381 18.1259 137.697 18.1259C139.573 18.1259 140.497 17.4399 140.497 16.0539C140.497 15.3539 140.273 14.7939 139.867 14.4999C139.489 14.2199 139.027 14.0939 137.949 13.9399C137.319 13.8559 137.081 13.7999 136.843 13.6739C136.563 13.5059 136.409 13.2119 136.409 12.8619C136.409 12.1479 136.885 11.7559 137.753 11.7559C138.509 11.7559 138.943 11.9519 139.377 12.4699L140.217 11.9519C139.671 11.1819 138.859 10.8319 137.669 10.8319C136.185 10.8319 135.345 11.5739 135.345 12.9179C135.345 13.5199 135.527 13.9679 135.905 14.2899C136.241 14.5839 136.689 14.7239 137.655 14.8639C138.495 14.9759 138.677 15.0179 138.957 15.1579C139.265 15.3119 139.433 15.6199 139.433 16.0259C139.433 16.8099 138.845 17.2019 137.655 17.2019C136.731 17.2019 136.255 17.0199 135.765 16.5159L134.953 17.0339Z"
        className="fill-current"
      />
      <path
        d="M142.798 11.8959V15.6619C142.798 17.5519 143.246 17.9999 145.08 17.9999H145.332V17.0619H145.094C144.184 17.0759 143.904 16.7679 143.904 15.7739V11.8959H145.29V10.9719H143.904V8.71791H142.854L142.798 10.9719H141.944V11.8959H142.798Z"
        className="fill-current"
      />
    </>
  ),
  width: 146,
  height: 20,
});
