import { ConvertToUpperSnakeCase } from "@utils/casing";

import { ImportColumnType } from "../useImportState/importColumnType";

import { pass, pushError, Result } from "./result";

export type Input = Partial<{
  [ImportColumnType.operator]: string;
}>;

const operatorValues = ["ALL", "ANY", "EXCLUDE"];

export function validate({ operator }: Input): Result {
  let err = pass();

  if (!operator) {
    return err;
  }

  if (!operatorValues.includes(ConvertToUpperSnakeCase(operator))) {
    err = pushError(err, ImportColumnType.operator, "invalid");
  }

  return err;
}
