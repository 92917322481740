import { Query, QueryCondition, QueryConditionType } from "@components/types";
import { ConvertToUpperSnakeCase } from "@utils/casing";
import * as temporal from "@utils/temporal";

import { ImportColumnType } from "../useImportState/importColumnType";
import { validate } from "../useValidate/validate";

type Row = { [key in ImportColumnType]: string };

const mapCelebrity = (elem: Row): QueryCondition => ({
  [QueryConditionType.CELEBRITY__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapObjectOrScene = (elem: Row): QueryCondition => ({
  [QueryConditionType.LABEL__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapFlaggedContent = (elem: Row): QueryCondition => ({
  [QueryConditionType.CONTENT_MODERATION__EQUALS]: {
    values: [elem.conditionValue],
    ...mapTolerance(elem),
  },
});

const mapVideoTitleContains = (elem: Row): QueryCondition => ({
  [QueryConditionType.VIDEO_TITLE__CONTAINS]: {
    value: elem.conditionValue,
  },
});

const mapVideoDuration = (elem: Row): QueryCondition => {
  const durationRange = temporal.parseDurationRange(elem.conditionValue);
  if (!durationRange) {
    return {
      [QueryConditionType.VIDEO_DURATION__EQUALS]: {
        maxDuration: 0,
        minDuration: 0,
      },
    };
  }
  const max = durationRange.max && temporal.durationToMs(durationRange.max);
  const min = durationRange.min && temporal.durationToMs(durationRange.min);
  return {
    [QueryConditionType.VIDEO_DURATION__EQUALS]: {
      maxDuration: max || 0,
      minDuration: min || 0,
    },
  };
};

const mapSentiment = (elem: Row): QueryCondition => ({
  [QueryConditionType.SENTIMENT__EQUALS]: {
    value: ConvertToUpperSnakeCase(elem.conditionValue),
    ...mapTolerance(elem),
  },
});

const mapTranscriptContains = (elem: Row): QueryCondition => ({
  [QueryConditionType.TRANSCRIPT__CONTAINS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapCloseGame = (elem: Row): QueryCondition => ({
  [QueryConditionType.CLOSE_GAME__EQUALS]: {
    value: elem.conditionValue.toLowerCase() === "yes",
    ...mapTolerance(elem),
  },
});

const mapDelayedGame = (elem: Row): QueryCondition => ({
  [QueryConditionType.DELAYED_GAME__EQUALS]: {
    value: elem.conditionValue.toLowerCase() === "yes",
    ...mapTolerance(elem),
  },
});

const mapDoubleHeader = (elem: Row): QueryCondition => ({
  [QueryConditionType.DOUBLE_HEADER__EQUALS]: {
    value: elem.conditionValue.toLowerCase() === "yes",
    ...mapTolerance(elem),
  },
});

const mapNightGame = (elem: Row): QueryCondition => ({
  [QueryConditionType.NIGHT_GAME__EQUALS]: {
    value: elem.conditionValue.toLowerCase() === "yes",
    ...mapTolerance(elem),
  },
});

const mapPreStar = (elem: Row): QueryCondition => ({
  [QueryConditionType.PRE_ALL_STAR__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapPostStar = (elem: Row): QueryCondition => ({
  [QueryConditionType.POST_ALL_STAR__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapExtraInnings = (elem: Row): QueryCondition => ({
  [QueryConditionType.EXTRA_INNINGS__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapLineupChange = (elem: Row): QueryCondition => ({
  [QueryConditionType.LINEUP_CHANGE__EQUALS]: {
    value: elem.conditionValue.toLowerCase() === "yes",
    ...mapTolerance(elem),
  },
});

const mapStolenBase = (elem: Row): QueryCondition => ({
  [QueryConditionType.STOLEN_BASE__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapRunnDiff = (elem: Row): QueryCondition => {
  const [compare, val] = parseCompare(elem.conditionValue);
  return {
    [QueryConditionType.RUN_DIFFERENTIAL__COMPARE]: {
      comparator: compare,
      value: val,
      ...mapTolerance(elem),
    },
  };
};

const mapEventIndex = (elem: Row): QueryCondition => {
  const [compare, val] = parseCompare(elem.conditionValue);
  return {
    [QueryConditionType.EVENT_LEVERAGE_INDEX__COMPARE]: {
      comparator: compare,
      value: val,
      ...mapTolerance(elem),
    },
  };
};

const mapEventRunScored = (elem: Row): QueryCondition => {
  const [compare, val] = parseCompare(elem.conditionValue);
  return {
    [QueryConditionType.EVENT_RUNS_SCORED__COMPARE]: {
      comparator: compare,
      value: val,
      ...mapTolerance(elem),
    },
  };
};

const mapInning = (elem: Row): QueryCondition => {
  const [compare, val] = parseCompare(elem.conditionValue);
  return {
    [QueryConditionType.INNING__COMPARE]: {
      comparator: compare,
      value: val,
      ...mapTolerance(elem),
    },
  };
};

const mapVenue = (elem: Row): QueryCondition => ({
  [QueryConditionType.VENUE__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapSeasonType = (elem: Row): QueryCondition => ({
  [QueryConditionType.SEASON_TYPE__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapHomeTeam = (elem: Row): QueryCondition => ({
  [QueryConditionType.HOME_TEAM__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapAwayTeam = (elem: Row): QueryCondition => ({
  [QueryConditionType.AWAY_TEAM__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapDivision = (elem: Row): QueryCondition => ({
  [QueryConditionType.DIVISION__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapLeague = (elem: Row): QueryCondition => ({
  [QueryConditionType.LEAGUE__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapPlayer = (elem: Row): QueryCondition => ({
  [QueryConditionType.PLAYER__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapWinningTeam = (elem: Row): QueryCondition => ({
  [QueryConditionType.WINNING_TEAM__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapSeriesType = (elem: Row): QueryCondition => ({
  [QueryConditionType.SERIES_TYPE__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapInningHalf = (elem: Row): QueryCondition => ({
  [QueryConditionType.INNING_HALF__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapEventType = (elem: Row): QueryCondition => ({
  [QueryConditionType.EVENT_TYPE__EQUALS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapEventDesc = (elem: Row): QueryCondition => ({
  [QueryConditionType.EVENT_DESCRIPTION__CONTAINS]: {
    value: elem.conditionValue,
    ...mapTolerance(elem),
  },
});

const mapTolerance = ({
  tolerance,
}: {
  [ImportColumnType.tolerance]: string;
}): {
  tolerance: {
    lead: number;
    lag: number;
  };
} => {
  const duration = temporal.parseDuration(tolerance);
  if (duration) {
    const milliseconds = temporal.durationToMs(duration) / 2;

    return {
      tolerance: {
        lead: milliseconds,
        lag: milliseconds,
      },
    };
  }
  return {
    tolerance: {
      lead: 30000,
      lag: 30000,
    },
  };
};
const mappers: { [k in string]: (elem: Row) => QueryCondition } = {
  CELEBRITY: mapCelebrity,
  OBJECT_OR_SCENE: mapObjectOrScene,
  FLAGGED_CONTENT: mapFlaggedContent,
  VIDEO_TITLE_CONTAINS: mapVideoTitleContains,
  VIDEO_DURATION: mapVideoDuration,
  SENTIMENT: mapSentiment,
  SPOKEN_WORD_OR_PHRASE: mapTranscriptContains,
  CLOSE_GAME: mapCloseGame,
  DELAYED_GAME: mapDelayedGame,
  DOUBLE_HEADER: mapDoubleHeader,
  NIGHT_GAME: mapNightGame,
  PRE_ALL_STAR: mapPreStar,
  POST_ALL_STAR: mapPostStar,
  EXTRA_INNINGS: mapExtraInnings,
  LINEUP_CHANGE: mapLineupChange,
  STOLEN_BASE: mapStolenBase,
  RUN_DIFFERENTIAL: mapRunnDiff,
  EVENT_LEVERAGE_INDEX: mapEventIndex,
  VENUE: mapVenue,
  SEASON_TYPE: mapSeasonType,
  HOME_TEAM: mapHomeTeam,
  AWAY_TEAM: mapAwayTeam,
  DIVISION: mapDivision,
  LEAGUE: mapLeague,
  PLAYER: mapPlayer,
  WINNING_TEAM: mapWinningTeam,
  SERIES_TYPE: mapSeriesType,
  INNING_HALF: mapInningHalf,
  EVENT_TYPE: mapEventType,
  EVENT_DESCRIPTION: mapEventDesc,
  EVENT_RUNS_SCORED: mapEventRunScored,
  INNING: mapInning,
};

export function SegmentImportDataToQuery(data: Row[]) {
  const validData =
    data.filter((row) => {
      const { success } = validate(row);
      return success;
    }) || [];

  return validData.reduce((acc: Query, elem) => {
    const mapper = mappers[ConvertToUpperSnakeCase(elem.conditionType)];
    let condition: QueryCondition | undefined = mapper && mapper(elem);

    switch (ConvertToUpperSnakeCase(elem.operator)) {
      case "ALL":
        if (condition) {
          const arr = acc.allOfTheFollowing || [];
          arr.push(condition);
          acc.allOfTheFollowing = arr;
        }
        break;
      case "ANY":
        if (condition) {
          const arr = acc.anyOfTheFollowing || [];
          arr.push(condition);
          acc.anyOfTheFollowing = arr;
        }
        break;
      case "EXCLUDE":
        if (condition) {
          const arr = acc.exclude || [];
          arr.push(condition);
          acc.exclude = arr;
        }
        break;
    }
    return acc;
  }, {});
}

const parseCompare = (val: string): [string, number] => {
  const numbs = val.match(/\d+/);
  if (numbs && numbs.length > 0) {
    return [val.replace(numbs[0], ""), +numbs[0]];
  }
  return [val, 0];
};
