import React from "react";
import { useLazyQuery } from "@apollo/client";
import { merge } from "lodash";

import { GenericExportDialog } from "@components/organisms/GenericExportDialog";
import {
  ExportSegments_ListSegmentJobsUnloadDocument,
  ExportSegments_ListSegmentJobsUnloadQueryVariables,
} from "@graphql/operations";

enum Category {
  SEGMENT_DATA = "Segment Data",
}

type UnloadSegmentsColumns =
  | "CreatedAt"
  | "Description"
  | "Error"
  | "ExecuteStatementId"
  | "Id"
  | "Query"
  | "RedshiftQueryStatus"
  | "SegmentCount"
  | "Status"
  | "Title"
  | "Version"
  | "VideoCount";

const segmentSelectableFields: Record<UnloadSegmentsColumns, string> = {
  CreatedAt: "Created At",
  Description: "Description",
  Error: "Error",
  ExecuteStatementId: "Execute Statement Id",
  Id: "Id",
  Query: "Query",
  RedshiftQueryStatus: "Redshift Query Status",
  SegmentCount: "Segment Count",
  Status: "Status",
  Title: "Title",
  Version: "Version",
  VideoCount: "Video Count",
};

const selectableFields = [
  {
    id: Category.SEGMENT_DATA,
    name: Category.SEGMENT_DATA,
    children: Object.entries(segmentSelectableFields).map(([k, v]) => ({
      id: k,
      name: v,
    })),
  },
];

const selectedNodesId = [...Object.keys(segmentSelectableFields)];

export type ExportSegmentsProps = {
  disabled: boolean;
};

export const ExportSegments = ({ disabled }: ExportSegmentsProps) => {
  const [unloadSegmentJob, { loading, error }] = useLazyQuery(
    ExportSegments_ListSegmentJobsUnloadDocument,
    {
      fetchPolicy: "no-cache",
    }
  );

  return (
    <GenericExportDialog
      disabled={disabled}
      title="Export"
      selectableFields={selectableFields}
      initialSelectedNodeIds={selectedNodesId}
      onExportSubmission={async (formData: string[]) => {
        let variables: ExportSegments_ListSegmentJobsUnloadQueryVariables = {};

        if (formData.length > 1) {
          variables = merge(variables, {
            unload: {
              // QUESTION: Why slice 1?
              columns: formData.slice(1),
            },
          });
        }

        const { data } = await unloadSegmentJob({ variables });

        return data?.listSegmentJobs?.unload?.href || undefined;
      }}
      loading={loading}
      error={error?.message}
    />
  );
};
