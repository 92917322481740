import React from "react";

import { classnames } from "@external/tailwindcss-classnames";

import { colorPalette } from "../color-palette";

export interface ProgressValue {
  name: string;
  value: number;
}

export interface ProgressChartProps {
  data: Array<ProgressValue>;
  color: keyof typeof colorPalette;
}

type ProgressBarProps = ProgressValue & {
  isAlternate: boolean;
  color: keyof typeof colorPalette;
  maxValue: number;
};

const ProgressBar = ({
  value,
  maxValue,
  name,
  isAlternate,
  color,
}: ProgressBarProps) => {
  const barClassName = classnames("h-2", "rounded-r-sm", {
    "bg-navy-300": color === "blue" && !isAlternate,
    "bg-navy-200": color === "blue" && isAlternate,
  });

  return (
    <div className="max-w-sm mt-7">
      <div className="flex flex-row justify-between">
        <p className="content-xs text-bodyXs">{name}</p>
        <p className="content-xs text-bodyXs text-coolGrey-400">{value}</p>
      </div>
      <div className="mt-1 h-2 bg-coolGrey-200 w-full">
        <div
          className={barClassName}
          style={{ width: `${100 * (value / maxValue)}%` }}
        />
      </div>
    </div>
  );
};

export const ProgressChart = ({ data, color }: ProgressChartProps) => {
  return (
    <>
      {data.map((value, idx) => (
        <ProgressBar
          key={idx}
          value={value.value}
          name={value.name}
          isAlternate={idx % 2 !== 0}
          maxValue={100}
          color={color}
        />
      ))}
    </>
  );
};
