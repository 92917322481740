import React from "react";
import { useLazyQuery } from "@apollo/client";
import { merge } from "lodash";

import {
  AdvancedSearchQuery,
  SegmentJobsExportDialog_SegmentJobUnloadDocument,
  SegmentJobsExportDialog_SegmentJobUnloadQueryVariables,
} from "@graphql/operations";

import { GenericExportDialog } from "../GenericExportDialog/GenericExportDialog";

enum Category {
  SEGMENT_DATA = "Segment Data",
  VIDEO_DATA = "Video Data",
  SEGMENT_CONDITIONS = "Segment Conditions",
}

type SegmentDataJobColumns =
  | "segment.createdAt"
  | "segment.description"
  | "segment.error"
  | "segment.executeStatementId"
  | "segment.segmentId"
  | "segment.redshiftQueryStatus"
  | "segment.segmentCount"
  | "segment.status"
  | "segment.title"
  | "segment.version"
  | "segment.videoCount";

type VideoDataJobColumns =
  | "video.id"
  | "video.secondaryIds"
  | "video.assetId"
  | "video.createdAt"
  | "video.href"
  | "video.itemType"
  | "video.mediaFile"
  | "video.thumbnail"
  | "video.thumbnailFile"
  | "video.title"
  | "video.workflowExecutionId"
  | "video.businessUnit"
  | "video.occurrences"
  | "video.occurrencesCount";

type SegmentConditionDataJobColumns =
  | "query.conditionType"
  | "query.conditionValue"
  | "query.confidenceScore"
  | "query.operator"
  | "query.within";

export const segmentSelectableFields: Record<SegmentDataJobColumns, string> = {
  "segment.createdAt": "Created At",
  "segment.description": "Description",
  "segment.error": "Error",
  "segment.executeStatementId": "Execute Statement Id",
  "segment.segmentId": "ID",
  "segment.redshiftQueryStatus": "Redshift Query Status",
  "segment.segmentCount": "Count",
  "segment.status": "Status",
  "segment.title": "Title",
  "segment.version": "Version",
  "segment.videoCount": "Video Count",
};

export const videoSelectableFields: Record<VideoDataJobColumns, string> = {
  "video.assetId": "Asset Id",
  "video.createdAt": "Created At",
  "video.href": "Href",
  "video.id": "Id",
  "video.secondaryIds": "Secondary Ids",
  "video.itemType": "Item Type",
  "video.mediaFile": "Media File",
  "video.thumbnail": "Thumbnail",
  "video.thumbnailFile": "Thumbnail File",
  "video.title": "Title",
  "video.workflowExecutionId": "Workflow Execution Id",
  "video.businessUnit": "Business Unit",
  "video.occurrences": "Occurrences",
  "video.occurrencesCount": "Occurrences Count",
};

const segmentConditionsSelectableFields: Record<
  SegmentConditionDataJobColumns,
  string
> = {
  "query.conditionType": "Condition type",
  "query.conditionValue": "Condition value",
  "query.confidenceScore": "Confidence score",
  "query.operator": "Operator (and / or / not)",
  "query.within": "Within",
};

export const selectableFields = [
  {
    id: Category.SEGMENT_DATA,
    name: Category.SEGMENT_DATA,
    children: Object.entries(segmentSelectableFields).map(([k, v]) => ({
      id: k,
      name: v,
    })),
  },
  {
    id: Category.VIDEO_DATA,
    name: Category.VIDEO_DATA,
    selected: true,
    children: Object.entries(videoSelectableFields).map(([k, v]) => ({
      id: k,
      name: v,
    })),
  },
  {
    id: Category.SEGMENT_CONDITIONS,
    name: Category.SEGMENT_CONDITIONS,
    selected: true,
    children: Object.entries(segmentConditionsSelectableFields).map(
      ([k, v]) => ({
        id: k,
        name: v,
      })
    ),
  },
];

export const selectedNodesId = [
  ...Object.keys(videoSelectableFields),
  ...Object.keys(segmentSelectableFields),
  ...Object.keys(segmentConditionsSelectableFields),
];

export type SegmentsExportDialogProps = {
  segmentId: string | undefined;
  segmentVersion: number;
  disabled?: boolean;
  inverse?: boolean;
  query?: AdvancedSearchQuery;
};

export const SegmentJobsExportDialog = ({
  disabled,
  inverse,
  query,
  segmentId,
  segmentVersion,
}: SegmentsExportDialogProps) => {
  const [unloadSegmentJob, { loading, error }] = useLazyQuery(
    SegmentJobsExportDialog_SegmentJobUnloadDocument,
    {
      fetchPolicy: "network-only",
    }
  );

  return (
    <GenericExportDialog
      disabled={disabled}
      title={`Export Segment ${segmentId}`}
      selectableFields={selectableFields}
      initialSelectedNodeIds={selectedNodesId}
      onExportSubmission={async (formData: string[]) => {
        let variables: SegmentJobsExportDialog_SegmentJobUnloadQueryVariables =
          {
            filter: {
              segmentId: {
                eq: segmentId,
              },
              segmentVersion: {
                eq: segmentVersion,
              },
            },
          };

        if (query) {
          variables = merge(variables, {
            filter: {
              query,
            },
          });
        }

        if ((formData.length || 0) >= 1) {
          variables = merge(variables, {
            unload: {
              columns: formData,
            },
          });
        }

        if (inverse) {
          variables = merge(variables, {
            unload: {
              inverse: true,
            },
          });
        }

        const { data } = await unloadSegmentJob({ variables });

        return (
          data?.segmentJob?.VideoAssociationConnection?.unload?.href ||
          undefined
        );
      }}
      loading={loading}
      error={error?.message}
    />
  );
};
