import React, { useState } from "react";

import { TextInput } from "@components/atoms";

import { useTreeViewModel } from "../hooks";

export const SearchInput = () => {
  const context = useTreeViewModel();
  const [value, setValue] = useState("");

  const handleTextChange = (e: any) => {
    const text = e.target.value;

    if (text.length >= 3 || text.length === 0) {
      context.search(text);
    }

    setValue(text);
  };

  return (
    <TextInput
      placeholder="Search label name"
      onChange={handleTextChange}
      value={value}
    />
  );
};
