import React, { HTMLAttributes } from "react";
import { Menu, Transition } from "@headlessui/react";

import { classnames } from "@external/tailwindcss-classnames";

export interface DropdownProps extends HTMLAttributes<HTMLDivElement> {
  position?: "above" | "below";
  relative?: boolean;
  button: React.ReactNode;
  children: React.ReactNode;
  className?: any;
}

export const Container = ({
  position = "below",
  relative = true,
  button,
  className,
  children,
  ...rest
}: DropdownProps) => {
  const menuButton = (
    <span
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Menu.Button as={React.Fragment}>{button}</Menu.Button>
    </span>
  );
  const transition = (
    <Transition
      as="div"
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      {children}
    </Transition>
  );

  return (
    <Menu
      {...rest}
      as="div"
      className={classnames(relative ? "relative" : "absolute", className)}
    >
      {position === "below" ? (
        <>
          {menuButton}
          {transition}
        </>
      ) : (
        <>
          {transition}
          {menuButton}
        </>
      )}
    </Menu>
  );
};
