export const copyToHandler =
  ({ value, onComplete }: { value?: string; onComplete?: () => void }) =>
  async () => {
    if (value) {
      await navigator.clipboard.writeText(value);
      if (onComplete) {
        onComplete();
      }
    }
  };
