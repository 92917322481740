import React, { ReactNode } from "react";
import { Dialog } from "@headlessui/react";

import { Teleport } from "@components/atoms/Portal";
import { classnames } from "@external/tailwindcss-classnames";

export interface TitleProps {
  className?: any;
  centerText?: boolean;
  children?: ReactNode;
  marginBottom?: any;
}

export const Title = ({
  className,
  children,
  marginBottom,
  centerText = true,
}: TitleProps) => (
  <Teleport refName="title">
    <Dialog.Title
      as="h3"
      className={classnames("text-navy-400", className, marginBottom, {
        "text-center": centerText,
        "mb-12": !marginBottom,
      })}
    >
      {children}
    </Dialog.Title>
  </Teleport>
);
