import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { isEmpty, isEqual } from "lodash";

import { Segment } from "@components/atoms";
import { SegmentBuilder } from "@components/templates";
import { Query } from "@components/types";
import {
  SegmentJobStatus,
  SegmentsJobEdit_SegmentJobDocument,
} from "@graphql/operations";
import {
  converters,
  FiltersProvider,
  ImportColumnType,
  useSegmentImport,
} from "@hooks/segments";

const segmentImport = gql`
  query SegmentsJobEdit_segmentImport {
    segmentImport @client {
      data
    }
  }
`;

export const SegmentsJobEdit = () => {
  const { deleteSegmentImport } = useSegmentImport();
  const { id, version = "1" } = useParams();
  const query = useRef<Query>();
  const { data, loading } = useQuery(SegmentsJobEdit_SegmentJobDocument, {
    returnPartialData: true,
    variables: {
      filter: {
        segmentId: {
          eq: id,
        },
        segmentVersion: {
          eq: parseInt(version, 10),
        },
      },
    },
  });
  const { data: segmentImportData, loading: segmentImportLoading } = useQuery<{
    segmentImport?: {
      data: { [key in ImportColumnType]: string }[];
    };
  }>(segmentImport);

  useEffect(() => {
    const hasData = segmentImportData?.segmentImport?.data;

    if (hasData && !segmentImportLoading) {
      deleteSegmentImport();
    }
  }, [deleteSegmentImport, segmentImportData, segmentImportLoading]);

  const segmentImportQuery = converters.SegmentImportDataToQuery(
    segmentImportData?.segmentImport?.data || []
  );

  if (!isEmpty(segmentImportQuery)) {
    query.current = segmentImportQuery;
  }

  if (isEmpty(query.current) && data?.segmentJob?.Query) {
    const updatedQuery = JSON.parse(data.segmentJob.Query);

    if (!isEqual(updatedQuery, query.current)) {
      query.current = JSON.parse(data.segmentJob.Query);
    }
  }

  const segmentJob = data?.segmentJob;

  if (!loading && !segmentJob) {
    return <>Unable to fetch segment job</>;
  }

  const operation =
    segmentJob?.Status === SegmentJobStatus.Draft
      ? Segment.OperationOptions.Draft
      : Segment.OperationOptions.Edit;

  return (
    <FiltersProvider
      segmentTitle={segmentJob?.Title || undefined}
      segmentDescription={segmentJob?.Description || undefined}
      version={segmentJob?.Version}
      query={query.current}
    >
      {loading ? (
        <SegmentBuilder loading operation={operation} />
      ) : (
        <SegmentBuilder
          loading={false}
          operation={operation}
          segmentId={segmentJob!.SegmentId}
          version={segmentJob!.Version}
        />
      )}
    </FiltersProvider>
  );
};
