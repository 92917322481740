import * as Icon from "@components/atoms/Icon";
import { Segment } from "@components/molecules";
import { FilterContextProps, FilterType } from "@hooks/segments";

import { Section } from "./Section";

type InsightsSectionProps = {
  filters: FilterContextProps;
};

export const InsightsSection = ({ filters }: InsightsSectionProps) => {
  const minOccurrences = filters.hasCondition(FilterType.MIN_OCCURRENCE__COUNT);
  const hasMinOccurrences =
    minOccurrences.allOfTheFollowing ||
    minOccurrences.anyOfTheFollowing ||
    minOccurrences.excludes;

  return (
    <Section title="Insights">
      <Segment.MinimisedCondition
        icon={<Icon.DocumentOutline size={16} />}
        type={FilterType.VIDEO_TITLE__CONTAINS}
      >
        Video Title
      </Segment.MinimisedCondition>
      <Segment.MinimisedCondition
        icon={<Icon.Tag size={16} />}
        type={FilterType.LABEL__CONTAINS}
      >
        Object Or Scene
      </Segment.MinimisedCondition>
      <Segment.MinimisedCondition
        icon={<Icon.ChatAlt size={16} />}
        type={FilterType.TRANSCRIPT__CONTAINS}
      >
        Spoken Word Or Phrase
      </Segment.MinimisedCondition>
      <Segment.MinimisedCondition
        icon={<Icon.Sentiment size={16} />}
        type={FilterType.SENTIMENT__EQUALS}
      >
        Sentiment
      </Segment.MinimisedCondition>
      <Segment.MinimisedCondition
        icon={<Icon.Star size={16} />}
        type={FilterType.CELEBRITY__EQUALS}
      >
        Celebrity
      </Segment.MinimisedCondition>
      <Segment.MinimisedCondition
        icon={<Icon.Flag size={16} />}
        type={FilterType.CONTENT_MODERATION__EQUALS}
      >
        Flagged Content
      </Segment.MinimisedCondition>
      <Segment.MinimisedCondition
        icon={<Icon.Time size={16} />}
        type={FilterType.VIDEO_DURATION__EQUALS}
      >
        Video Duration
      </Segment.MinimisedCondition>
      <Segment.MinimisedCondition
        icon={<Icon.Time size={16} />}
        type={FilterType.TEXT_IN_VIDEO__CONTAINS}
      >
        Text In Video
      </Segment.MinimisedCondition>
      <Segment.MinimisedCondition
        icon={<Icon.Metadata size={16} />}
        type={FilterType.METADATA__CONTAINS}
      >
        FMC Metadata
      </Segment.MinimisedCondition>
      <Segment.MinimisedCondition
        icon={<Icon.Time size={16} />}
        type={FilterType.MIN_OCCURRENCE__COUNT}
        showTooltip={hasMinOccurrences}
        tooltipText="Cannot add a second Occurences Condition to a group"
      >
        Occurences
      </Segment.MinimisedCondition>
    </Section>
  );
};
