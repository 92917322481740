import React, { useState } from "react";
import { useDebounce } from "react-use";

import { Button, TextInput } from "@components/atoms";
import { classnames } from "@external/tailwindcss-classnames";
import { FilterType, Location, useFilters } from "@hooks/segments";

const SPLIT_BY = "\n";

type MultilineShortcutProps = {
  conditionId: string;
  label: string;
  value?: string;
  placeholderTextName: string;
  location: Location;
  filterType: FilterType;
  onChange: (value?: string) => void;
  appendFilterProps?: object;
};

export const MultilineShortcut = ({
  conditionId,
  label,
  value,
  placeholderTextName,
  location,
  filterType,
  onChange,
  appendFilterProps = {},
}: MultilineShortcutProps) => {
  const { insertBetween } = useFilters();
  const [tempValue, setTempValue] = useState<string>(value || "");
  const isMultiple = tempValue.includes(SPLIT_BY);

  useDebounce(
    () => {
      onChange(tempValue);
    },
    200,
    [tempValue]
  );

  return (
    <div className={classnames("relative")}>
      <TextInput
        aria-label={label}
        onChange={(e) => {
          setTempValue(e.target.value);
        }}
        value={tempValue}
        placeholder={`Add each ${placeholderTextName} on a new line`}
        className="mb-3"
        error={isMultiple}
        multiline
      />
      {isMultiple && (
        <>
          <p className="text-red-300 font-semibold text-xs">
            Press ‘Apply’ to add these values to the Segment Conditions
          </p>
          <Button
            variant="text"
            className={classnames("absolute", "right-3", "top-2")}
            onClick={() => {
              if (tempValue) {
                const [first, ...rest] = tempValue.split(SPLIT_BY);

                onChange(first);
                setTempValue(first);

                rest.forEach((v) => {
                  insertBetween(location, filterType, conditionId, false, {
                    ...appendFilterProps,
                    value: v,
                  });
                });
              }
            }}
          >
            Apply
          </Button>
        </>
      )}
    </div>
  );
};
