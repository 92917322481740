import React from "react";

import { createSvgIcon } from "./createSvgIcon";

export const CalendarOutline = createSvgIcon({
  content: (
    <>
      <path
        d="M15.0222 2.2717C15.0778 2.2717 15.1111 2.31497 15.1111 2.35824V15.0371C15.1111 15.0912 15.0667 15.1236 15.0222 15.1236H0.977778C0.922222 15.1236 0.888889 15.0804 0.888889 15.0371V2.36906C0.888889 2.31497 0.933333 2.2717 0.977778 2.2717H15.0222ZM15.0222 1.40625H0.977778C0.444444 1.40625 0 1.83897 0 2.36906V15.0371C0 15.5672 0.444444 15.9891 0.977778 15.9891H15.0111C15.5556 15.9891 15.9889 15.5563 15.9889 15.0371V2.36906C16 1.83897 15.5556 1.40625 15.0222 1.40625Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.555664 4.86719H15.4446V5.73264H0.555664V4.86719Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00011 0C3.24557 0 3.44455 0.193737 3.44455 0.432725V1.40636C3.44455 1.64534 3.24557 1.83908 3.00011 1.83908C2.75465 1.83908 2.55566 1.64534 2.55566 1.40636V0.432725C2.55566 0.193737 2.75465 0 3.00011 0Z"
        className="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9557 0C13.2011 0 13.4001 0.193737 13.4001 0.432725V1.40636C13.4001 1.64534 13.2011 1.83908 12.9557 1.83908C12.7102 1.83908 12.5112 1.64534 12.5112 1.40636V0.432725C12.5112 0.193737 12.7102 0 12.9557 0Z"
        className="fill-current"
      />
    </>
  ),
  defaultColor: "black-100",
});
